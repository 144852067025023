import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  CMS_PROP_TYPES,
  FRAGMENT_NAME_CASE,
  DATE_FORMAT
} from 'Common/constants';
import { getOrElse } from 'Common/utils';
import { getVersions } from 'CreateCase/utils';
import CollapseCard from 'CreateCase/components/CollapseCard';
import CollapseSection from 'CreateCase/components/CollapseSection';
import {
  Tab2,
  Tabs2,
  Spinner,
  NonIdealState,
  Position,
  Toaster,
  Intent
} from '@blueprintjs/core';
import {
  get,
  find,
  filter,
  startCase,
  replace,
  forEach,
  isNil,
  uniqBy,
  split,
  last
} from 'lodash';
import submitCaseRevisionQuery from 'api/graphql/submitCaseRevisionQuery';
import withStyles from 'Common/components/Form/withStyles';
import { generateCSS } from 'Common/components/Form';
import moment from 'moment';
import stylesGenerator from './styles';

// The text that will be displayed if there is not any data for a specific schema section.
const NO_DATA_DEFINED_TEXT = 'No Data Defined';

const VERSION_TYPE_AE = 'ae';

const LABEL_MAP_PROPS = {
  ae: [
    { statePath: 'subcases.adverseEvent.assigneeName', label: 'Assignee' },
    {
      statePath: 'subcases.adverseEvent.completeWithoutSubmittedDate',
      label: 'Completed Without Submitted Date'
    },
    {
      statePath: 'subcases.adverseEvent.archiveComments',
      label: 'Archived Comments'
    },
    {
      statePath: 'subcases.adverseEvent.completedReason',
      label: 'Completed Reason'
    },
    {
      statePath: 'subcases.adverseEvent.archiveReason',
      label: 'Archived Reason'
    },
    { statePath: 'subcases.adverseEvent.reopened', label: 'Reopened' },
    {
      statePath: 'subcases.adverseEvent.submittedDate',
      label: 'Submitted Date'
    },
    { statePath: 'subcases.adverseEvent.id', label: 'ID' },
    { statePath: 'subcases.adverseEvent.version', label: 'Version' },
    { statePath: 'subcases.adverseEvent.createdDate', label: 'Created Date' },
    { statePath: 'subcases.adverseEvent.submitted', label: 'Submitted' },
    { statePath: 'subcases.adverseEvent.reopenedDate', label: 'Reopened Date' },
    {
      statePath: 'subcases.adverseEvent.submissionDue',
      label: 'Submission Due'
    },
    {
      statePath: 'subcases.adverseEvent.completedReasonComments',
      label: 'Completed Reason Comments'
    },
    { statePath: 'subcases.adverseEvent.status', label: 'Status' },
    { statePath: 'subcases.adverseEvent.newVersion', label: 'New Version' }
  ],
  pq: [
    { statePath: 'subcases.productQuality.assigneeName', label: 'Assignee' },
    {
      statePath: 'subcases.productQuality.completeWithoutSubmittedDate',
      label: 'Completed Without Submitted Date'
    },
    {
      statePath: 'subcases.productQuality.archiveComments',
      label: 'Archived Comments'
    },
    {
      statePath: 'subcases.productQuality.completedReason',
      label: 'Completed Reason'
    },
    {
      statePath: 'subcases.productQuality.archiveReason',
      label: 'Archived Reason'
    },
    { statePath: 'subcases.productQuality.reopened', label: 'Reopened' },
    {
      statePath: 'subcases.productQuality.submittedDate',
      label: 'Submitted Date'
    },
    { statePath: 'subcases.productQuality.id', label: 'ID' },
    { statePath: 'subcases.productQuality.version', label: 'Version' },
    { statePath: 'subcases.productQuality.createdDate', label: 'Created Date' },
    { statePath: 'subcases.productQuality.submitted', label: 'Submitted' },
    {
      statePath: 'subcases.productQuality.reopenedDate',
      label: 'Reopened Date'
    },
    {
      statePath: 'subcases.productQuality.submissionDue',
      label: 'Submission Due'
    },
    {
      statePath: 'subcases.productQuality.completedReasonComments',
      label: 'Completed Reason Comments'
    },
    { statePath: 'subcases.productQuality.status', label: 'Status' },
    { statePath: 'subcases.productQuality.newVersion', label: 'New Version' }
  ]
};

class PreviousVersion extends PureComponent {
  static propTypes = {
    trilogyCase: PropTypes.shape({
      currentRevision: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired
    }).isRequired,
    tacticalData: PropTypes.shape({
      'document-data': PropTypes.shape({
        'country-options': PropTypes.arrayOf(PropTypes.object).isRequired
      })
    }).isRequired,
    computedStyles: PropTypes.shape({
      previousVersionLink: PropTypes.object.isRequired,
      previousVersionLinkSelected: PropTypes.object.isRequired,
      contentItemLabel: PropTypes.object.isRequired,
      cardSubSection: PropTypes.object.isRequired,
      gridWrapper: PropTypes.object.isRequired,
      previousVersionList: PropTypes.object.isRequired,
      previousVersionListCol: PropTypes.object.isRequired,
      previousVersionContentCol: PropTypes.object.isRequired,
      elementLabelContainer: PropTypes.object.isRequired
    }).isRequired,
    schema: CMS_PROP_TYPES.schema.isRequired,
    fragments: PropTypes.arrayOf(PropTypes.object).isRequired,
    page: PropTypes.string
  };

  static defaultProps = {
    page: VERSION_TYPE_AE
  };

  toaster = null;
  state = { isFetching: false, latestTrilogyCase: null, caseRevisions: [] };

  componentDidMount() {
    const { trilogyCase } = this.props;

    this.state.selectedAeRevision = trilogyCase.currentRevision;
    this.toaster = Toaster.create({ position: Position.TOP });

    // Initialize the component
    this.initComponent().catch(() =>
      this.showErrorMessage('Unable to initialize this component')
    );
  }

  /**
   * Initializes the state of the component by building the mapping states from the schema and then fetching the current revision document from the backend.
   *
   * @returns {Promise<void>}
   */
  initComponent = async () => {
    const { trilogyCase, fragments, page } = this.props;

    this.setState({ isFetching: true });

    /*
        Unfortunately, the trilogy case that is in the props is incomplete because there is code
        up the chain that removes any subcase version that does not have a submitted date.
        This will not work for this component, as that field is needed.  A call to the backend
        must be made again to get a new trilogy case without the culling of subcase versions.
      */
    const latestTrilogyCase = await submitCaseRevisionQuery(
      null,
      trilogyCase.id,
      trilogyCase.currentRevision,
      fragments[FRAGMENT_NAME_CASE],
      true // suppress toaster notifications
    ).catch(() => {
      this.showErrorMessage(
        'An error occurred while trying to fetch the previous version information'
      );
      this.setState({ isFetching: false });
    });

    // Get the list of versions that will be rendered on the form.  This will be set to the component state.
    const versions = getVersions(latestTrilogyCase, page);

    /*
      Filter any versions that do not have a trilogy case.  When the components loads this filter should only
      return the latest version as the others haven't been pulled from the backend yet.
    */
    const caseRevisions = filter(versions, i => !isNil(i.trilogyCase)).map(
      v => v.trilogyCase
    );

    this.setState({
      caseRevisions,
      stateLabelMapping: this.buildMappingsFromSchema(),
      latestTrilogyCase,
      renderingCase: latestTrilogyCase,
      versions,
      isFetching: false
    });
  };

  showErrorMessage = msg => {
    if (!this.toaster) {
      const container = document.getElementById('modalContent');
      this.toaster = Toaster.create(
        {
          position: Position.TOP
        },
        container
      );
    }
    this.toaster.show({ message: msg, intent: Intent.DANGER });
  };

  /**
   * Takes a look at path values and determines if there is an instance of country in it.  If
   * there is a match then it will leverage the country options property to resolve the ISO country code
   * to its corresponding label.
   *
   * @param path - The path property to resolve
   * @param val - The value
   * @returns {*}
   */
  performCountryLookup = (path, val) => {
    const countries = this.props.tacticalData['document-data'][
      'country-options'
    ];
    if (/country/gi.test(path)) {
      const cntry = find(countries, c => c.value === val);
      return cntry ? cntry.label : val;
    }
    return val;
  };

  /**
   * Performs a query to the GraphQL service and get the case document for the requested version id.
   *
   * @param revision: The revision of the case document to retrieve.
   * @returns {Promise<*>}
   */
  fetchPreviousCase = async revision => {
    const { trilogyCase, fragments } = this.props;
    this.setState({ isFetching: true });
    return submitCaseRevisionQuery(
      null,
      trilogyCase.id,
      revision,
      fragments[FRAGMENT_NAME_CASE],
      true // suppress toaster notifications
    )
      .then(cs => {
        this.setState({ isFetching: false });
        return cs;
      })
      .catch(() => {
        this.showErrorMessage(
          'An error occurred while trying to fetch the previous version information'
        );
        this.setState({ isFetching: false });
      });
  };

  /**
   * Event handler when a previous version is selected on the unordered list.
   *
   * @param rev - The revision of the case that was selected.
   * @returns {Promise<void>}
   */
  handlePreviousVersionClick = async rev => {
    const { caseRevisions } = this.state;

    // First see if the revision was pulled already.
    const selectedCase = find(caseRevisions, r => r.currentRevision === rev);

    if (isNil(selectedCase)) {
      const renderingCase = await this.fetchPreviousCase(rev);

      this.setState({
        selectedAeRevision: rev,
        renderingCase,
        caseRevisions: [...caseRevisions, renderingCase]
      });
    } else {
      this.setState({
        selectedAeRevision: rev,
        renderingCase: selectedCase
      });
    }
  };

  /**
   * Renders an unordered list based on the subcase version that are in the current trilogy case.
   *
   * @returns {*}
   */
  renderPreviousVersionList = () => {
    const { computedStyles } = this.props;
    const { latestTrilogyCase, versions } = this.state;

    if (isNil(latestTrilogyCase) || isNil(versions)) return <div />;

    // Set the class of each list item depending on what is the currently selected version.
    const setClassName = aeRevision =>
      aeRevision === this.state.selectedAeRevision
        ? computedStyles.previousVersionLinkSelected
        : computedStyles.previousVersionLink;

    const formatSubmittedDate = dt =>
      !isNil(dt)
        ? `Submitted On: ${moment(dt).format(DATE_FORMAT)}`
        : 'Completed Without Submission';

    const getLinkText = li => {
      if (li.isCurrent)
        return `${li.id} (Current - ${
          li.submittedDate
            ? formatSubmittedDate(li.submittedDate)
            : 'Not Submitted'
        })`;
      return `${li.id} (${formatSubmittedDate(li.submittedDate)})`;
    };

    return (
      <ul className={computedStyles.previousVersionList}>
        {versions.map(li => (
          <a
            key={li.id}
            role="button"
            className={setClassName(li.revision)}
            onClick={() => this.handlePreviousVersionClick(li.revision)}
          >
            {getLinkText(li)}
          </a>
        ))}
      </ul>
    );
  };

  /**
   * Renders the loading indication while data is pulled from the GraphQL service.
   *
   * @returns {*}
   */
  renderLoading = () => {
    const loadingMessage = <span>Please wait while we retrieve the data.</span>;
    return (
      <div className={generateCSS({ margin: '100px auto' })}>
        <NonIdealState
          visual={<Spinner />}
          title="Loading"
          description={loadingMessage}
        />
      </div>
    );
  };

  /**
   * Traverses through the schema for the current tab and builds out the schema mapping so the state paths can resolve to a screen label.
   */
  buildMappingsFromSchema = () => {
    const { schema, page } = this.props;
    const schemaPage = find(schema.pages, p => p.path === page);

    if (schemaPage === undefined) return [];
    const mappings = [];

    forEach(schemaPage.tabs, t => {
      forEach(t.sections, s => {
        const secPath = this.createStatePath(undefined, s.statePath, s.title);
        const enumerateElements = (sp, elmnts) => {
          forEach(elmnts, el => {
            const elemPath = this.createStatePath(
              sp.statePath,
              el.statePath,
              el.label
            );
            // Get the options.
            elemPath.context = el;
            mappings.push(elemPath);
            enumerateElements(elemPath, el.elements); // Recursively go for the child elements.
          });
        };
        enumerateElements(secPath, s.elements);
      });
    });
    return mappings;
  };

  /**
   * Helper function to build the state path with respect to its parents statepath.  When parsing the schema object the path of the elements is
   * not a full path, so it is necessary to know that path of the parent when getting the full state path.
   *
   * @param parentStatePath: The state path of the parent element
   * @param currentStatePath: The state path of the current element
   * @param title: The title of the schema element that will be set to the screenLabel property in the result.  If the title is null or and empty string
   * the screenLabel property will be set to the startCase value of the currentStatePath argument
   * @returns {{screenLabel: *}}
   */
  createStatePath = (parentStatePath, currentStatePath, title) => {
    const newPath = { screenLabel: title };

    // If the title was not provided then build it from the current state Path
    if (title === undefined || title === '') {
      newPath.screenLabel = startCase(currentStatePath);
    }
    if (parentStatePath === undefined) {
      newPath.statePath = currentStatePath;
    } else if (parentStatePath.includes('/')) {
      newPath.statePath = parentStatePath.split('/')[1];
    } else if (currentStatePath === undefined) {
      newPath.statePath = parentStatePath;
    } else if (currentStatePath.includes('/')) {
      newPath.statePath = currentStatePath.split('/')[1];
    } else {
      newPath.statePath = `${parentStatePath}.${currentStatePath}`;
    }
    return newPath;
  };

  /**
   * Determines the list of the general version info properties based on the type of page it is.
   *
   * @returns {*}
   */
  getVersionInfoProps = () => LABEL_MAP_PROPS[this.props.page] || [];

  /**
   * Renders a version info tab.
   *
   * @returns {*}
   */
  renderVersionInfoTab = () => (
    <Tab2
      id={'genInfo'}
      title={'VERSION INFO'}
      panel={this.renderVersionInfoSection()}
    />
  );

  /**
   * Renders a version info section
   *
   * @returns {*}
   */
  renderVersionInfoSection = () => (
    <CollapseCard
      cardTitle={'Version Information'}
      cardContents={this.renderVersionInfoProperties()}
    />
  );

  /**
   * Renders all the properties for the version info tab.
   *
   * @returns {*}
   */
  renderVersionInfoProperties = () => {
    const { renderingCase } = this.state;
    const { computedStyles } = this.props;
    const props = filter(
      this.getVersionInfoProps(),
      p => !isNil(get(renderingCase, p.statePath))
    );
    return (
      <div>
        {props.map(p => (
          <div
            key={p.statePath}
            className={computedStyles.elementLabelContainer}
          >
            <span className={computedStyles.contentItemLabel}>{p.label}:</span>
            {get(renderingCase, p.statePath).toString()}
          </div>
        ))}
      </div>
    );
  };

  /**
   * Parses the schema document for the AE and build the structure dynamically on the page.
   *
   * @returns {*}
   */
  renderSchema = () => {
    if (this.state.renderingCase !== undefined) {
      const { schema, page } = this.props;
      const schemaPage = find(schema.pages, itm => itm.path === page);
      return this.renderTabs(schemaPage.tabs);
    }
    return <div />;
  };

  /**
   * Based on a passed array of tab from a schema document it will dynamically build the blueprint js tab components.
   *
   * @param tabs - The array of tabs to parse.
   * @returns {*}
   */
  renderTabs = tabs => (
    <Tabs2 id="tabsPreviousVersion">
      {this.renderVersionInfoTab()}
      {tabs.map(t => (
        <Tab2
          key={t.path}
          id={t.path}
          title={t.title.toUpperCase()}
          panel={this.renderSections(t.sections)}
        />
      ))}
    </Tabs2>
  );

  /**
   * Makes a collapse card component for each section that is provided to this function.  The functions are provided as part of the Trilogy document schema.
   *
   * @param sections - The array of sections that will be dynamically output on the page.
   * @returns {*}
   */
  renderSections = sections => (
    <Fragment>
      {filter(sections, i => i.title !== '').map(s => {
        const sectionInfo = {
          sectionSchema: s,
          basePath: s.statePath
        };
        return (
          <CollapseCard
            key={s.id}
            cardTitle={s.title}
            cardContents={this.renderElements(sectionInfo)}
          />
        );
      })}
    </Fragment>
  );

  /**
   * Will take a look at a specific path in a case document and then output all properties that are in that node.  The names of each of the property elements are defined
   * in the mapping document. If the path is not known or if the value of the property is null, the element node will not be output.
   *
   * Note: If the property node is an object or an array then it will recursively output the values in a collapse component.  This is especially true if the property is an array.
   *
   * @param basePath - The path to the node in the case document whose elements will be output.
   * @param sectionSchema - The section schema element node from the schema document.
   * @returns {*}
   */
  renderElements = ({ sectionSchema, basePath }) => {
    const { computedStyles } = this.props;
    const { renderingCase, stateLabelMapping } = this.state;
    const localBasePath =
      get(sectionSchema, 'title') === 'Submissions' ? 'submissions' : basePath;
    const context = get(renderingCase, localBasePath);

    const getElementSchema = ({ statePath }) => {
      const hit = find(
        stateLabelMapping,
        item => item.statePath === replace(statePath, /\[\d+\]/g, '')
      );
      return !isNil(hit) ? hit.context : null;
    };

    // Perform a lookup in the mapping file to get the corresponding screen label to the state path.
    const getElementScreenLabel = ({ statePath, propName }) => {
      const schemaElement = getElementSchema({ statePath });
      return !isNil(schemaElement) && !isNil(schemaElement.label)
        ? schemaElement.label
        : startCase(propName);
    };

    const parseElementValue = ({ schemaElement, propValue }) => {
      if (
        !isNil(schemaElement) &&
        !isNil(schemaElement.options) &&
        schemaElement.options.length > 0
      ) {
        const mappedValue = find(
          schemaElement.options,
          opt => opt.value === propValue
        );
        if (!isNil(mappedValue)) return mappedValue.label;
      }
      return propValue;
    };

    const getElementScreenValue = ({ schemaElement, propValue }) => {
      // Find the item in the state label mapping.
      if (isNil(schemaElement)) return propValue;
      return parseElementValue({ schemaElement, propValue });
    };

    const renderElementProperties = () => {
      if (typeof context === 'string') {
        return (
          <div className={computedStyles.elementLabelContainer}>
            <span className={computedStyles.contentItemLabel}>
              {getElementScreenLabel({
                statePath: localBasePath,
                propName: localBasePath.match(/(\.\w+$)/)[1] // Provide last part of statePath as fallback label
              })}:
            </span>
            {context.toString()}
          </div>
        );
      }
      return filter(
        Object.getOwnPropertyNames(context),
        s => context[s] !== null
      ).map((propName, idx) => {
        const statePath = `${localBasePath}.${propName}`;
        const propValue = context[propName];
        const schemaElement = getElementSchema({ statePath });
        const outputText = this.performCountryLookup(
          statePath,
          getElementScreenValue({ schemaElement, statePath, propValue })
        );

        // If the output is not an object then just display the text value.
        if (typeof outputText !== 'object' && !isNil(outputText)) {
          return (
            <div key={idx} className={computedStyles.elementLabelContainer}>
              <span className={computedStyles.contentItemLabel}>
                {getElementScreenLabel({
                  statePath,
                  propName
                })}:
              </span>
              {outputText.toString()}
            </div>
          );
        }
        return this.renderElementObject({
          elementName: propName,
          basePath: statePath
        });
      });
    };

    // Check to see if there might be a referenced element based on the schema section.
    if (isNil(context) && !isNil(get(sectionSchema, 'elements')))
      return this.renderReferencedElement({ sectionSchema });

    const refElements = this.getRefElements({ sectionSchema });
    const refObjects = this.getRefObjects({ sectionSchema, renderingCase });

    if (
      !isNil(context) &&
      ((!isNil(refElements) && refElements.length > 0) ||
        (!isNil(refObjects) && refObjects.length > 0))
    )
      return (
        <Fragment>
          {this.renderReferencedElement({ sectionSchema })}
          {renderElementProperties()}
        </Fragment>
      );

    if (isNil(context)) return <div>{NO_DATA_DEFINED_TEXT}</div>;

    return <Fragment>{renderElementProperties()}</Fragment>;
  };

  /**
   * Check the section schema and returns an array of elements that are references to other properties on the object.
   *
   * @param sectionSchema - The current level of the section schema that will be analyzed for referenced properties.
   * @returns {*}
   */
  getRefElements = ({ sectionSchema }) => {
    if (isNil(get(sectionSchema, 'elements'))) return null;
    return uniqBy(
      filter(
        sectionSchema.elements,
        el => !isNil(get(el, 'referencedProperties.value.statePath'))
      ),
      uel => getOrElse(uel, 'referencedProperties.value.statePath', '')
    );
  };

  /**
   * Check the section schema and returns an array of elements that are references to other objects.
   *
   * @param sectionSchema
   * @returns {*}
   */
  getRefObjects = ({ sectionSchema }) => {
    const { renderingCase } = this.state;
    if (isNil(get(sectionSchema, 'elements'))) return null;
    return filter(
      sectionSchema.elements.map(el => {
        const cleanPath = replace(el.statePath, /\//, ''); // Remove any invalid characters from the path.
        const caseObjectOrValue = get(renderingCase, cleanPath);
        const caseObject =
          typeof caseObjectOrValue === 'string'
            ? { caseObjectOrValue }
            : caseObjectOrValue;
        return !isNil(caseObject)
          ? { caseObject, statePath: cleanPath }
          : undefined;
      }),
      obj => !isNil(obj)
    );
  };

  /**
   * At times some of the elements on the schema are a reference and point to another part of the document.  There are two
   * cases that are supported in this function.  1. Referenced Properties 2. Referenced Elements.  This function will handle that
   * lookup or return an 'no data found response'
   *
   * @param sectionSchema - The element that corresponds to the section in the schema.
   * @returns {*}
   */
  renderReferencedElement = ({ sectionSchema }) => {
    const { renderingCase } = this.state;

    // Call all the unique schema elements that have a reference property in the section schema.  It is
    // possible to have duplicate statePath values, so that is why a lodash uniq is being performed.
    const refElements = this.getRefElements({ sectionSchema });

    if (!isNil(refElements) && refElements.length > 0) {
      return refElements.map(refElem => {
        const refProp = refElem.referencedProperties;
        const cleanPath = replace(refProp.value.statePath, /\[\d+\]/, ''); // Strip array brackets to get parent object
        if (!isNil(get(renderingCase, cleanPath))) {
          return this.renderElements({
            sectionSchema: null,
            basePath: refProp.value.statePath
          });
        }
        return null;
      });
    }

    // Sometimes there might be some elements that are not ref properties but point to another not on the object.
    // Do a quick look to see.  I.E '/subcases.adverseEvent.subCaseVersion
    const refObjects = this.getRefObjects({ sectionSchema });

    if (!isNil(refObjects) && refObjects.length > 0) {
      return refObjects.map(refObj => {
        if (Array.isArray(refObj.caseObject)) {
          const elementName = last(split(refObj.statePath, '.'));
          return this.renderElementObject({
            elementName,
            basePath: refObj.statePath
          });
        }
        return this.renderElements({ basePath: refObj.statePath });
      });
    }

    if (
      isNil(refElements) ||
      isNil(refObjects) ||
      refElements.length === 0 ||
      refObjects.length === 0
    )
      return <div>{NO_DATA_DEFINED_TEXT}</div>;

    return null;
  };

  /**
   * Sometimes and element is not a primitive value.  When an element is a array or object type then the rendering is somewhat different.
   * This will render and element object if the type of the element is an array.  It will output as as CollapseSection component.
   *
   * @param elementName: The name of the element to render
   * @param basePath: That path in the case document where this element is located.
   * @returns {*}
   */
  renderElementObject = ({ elementName, basePath }) => {
    const { renderingCase } = this.state;
    const context = get(renderingCase, basePath);
    const { computedStyles } = this.props;

    // Dynamically builds the tile of a element section if it is an array.
    const buildSectionTitle = idx => {
      let title = startCase(elementName);
      let showIndex = true;

      // A special case where the product name needs to be included in the reporter causality.
      if (
        /subcases\.adverseEvent\.product_section\.aeproducts$/.test(basePath)
      ) {
        const prodSummaries = get(
          renderingCase,
          `${basePath}[${idx}].product_summary`
        );
        if (prodSummaries && prodSummaries.length > 0) {
          if (prodSummaries[0].local_trade_name) {
            title += ` [${prodSummaries[0].local_trade_name}]`;
          } else if (prodSummaries[0].other_product) {
            title += ` [${prodSummaries[0].other_product}]`;
          }
        }
        showIndex = false;
      }

      // Another special case where if a reporter causality is found a look-up for the matching reaction index must be done.
      if (
        /subcases\.adverseEvent\.product_section\.aeproducts\[\d+\]\.reporter_causality/.test(
          basePath
        )
      ) {
        const reactionPath = basePath
          .replace(/product_section/, 'reactions')
          .replace(/aeproducts\[\d+\]/, `reaction[${idx}]`)
          .replace(/reporter_causality/, 'adverse_event');
        const reactionName = get(renderingCase, reactionPath);
        title += ` [${reactionName}]`;
        showIndex = false;
      }

      if (context.length > 1 && showIndex) {
        title = `${title} ${idx + 1}`;
      }
      return title;
    };

    // Check to see if it is an array.
    if (Array.isArray(context)) {
      return context.map((elem, idx) => {
        const subsection = (
          <div className={computedStyles.cardSubSection}>
            {this.renderElements({ basePath: `${basePath}[${idx}]` })}
          </div>
        );
        return (
          <CollapseSection
            key={idx}
            sectionTitle={buildSectionTitle(idx)}
            sectionContent={subsection}
          />
        );
      });
    }

    return (
      <CollapseSection
        sectionTitle={buildSectionTitle()}
        sectionContent={
          <div className={computedStyles.cardSubSection}>
            {this.renderElements({ basePath })}
          </div>
        }
      />
    );
  };

  render() {
    const { computedStyles } = this.props;

    const renderRightPanel = () => {
      if (this.state.isFetching) {
        return (
          <div className={computedStyles.previousVersionContentCol}>
            {this.renderLoading()}
          </div>
        );
      }
      return (
        <div className={computedStyles.previousVersionContentCol}>
          {this.renderSchema()}
        </div>
      );
    };

    return (
      <div id="modalContent" className={computedStyles.gridWrapper}>
        <div className={computedStyles.previousVersionListCol}>
          {this.renderPreviousVersionList()}
        </div>
        {renderRightPanel()}
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(PreviousVersion);
