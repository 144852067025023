import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Indicator from 'Common/components/Indicator';
import { get } from 'lodash';
import { csePropTypes } from '../propTypes';

class Label extends PureComponent {
  static propTypes = {
    ...csePropTypes,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validations: PropTypes.shape({
      required: PropTypes.shape({
        constraint: PropTypes.bool
      })
    }),
    computedStyles: PropTypes.shape({
      requiredText: PropTypes.object
    }).isRequired
  };

  static defaultProps = {
    label: '',
    validations: {}
  };

  needsReconciliation = () => {
    const { reconciliationPath, relativePath } = this.props;
    // Some `LabelValue` components do not have a `statePath` in order to avoid
    // model conflicts (ie. the LabelValue's own formatted value is assumed to
    // be a NEW value by CSE, and overwrites the model value of whatever field
    // it's supposed to display). The `reconciliationPath` prop is just tacked
    // on to the `relativePath` property in order to resolve the recon status
    const $id = reconciliationPath
      ? `${relativePath}.${reconciliationPath}`
      : this.props.$id;

    return (this.props.data.reconciliation || []).includes($id);
  };

  wasUpdatedThisVersion = () =>
    (this.props.data.diff || []).includes(this.props.$id);

  renderUpdateTag = () => <Indicator>U</Indicator>;
  renderReconciliationTag = () => <Indicator>R</Indicator>;

  renderRequiredText = () => (
    <span
      id={`${this.props.id}-validationsrequiredconstraint`}
      className={this.props.computedStyles.requiredText}
      htmlFor={this.props.id}
    >
      *
    </span>
  );

  renderLabel = () => `${this.props.label}`;

  render() {
    const {
      $id,
      schemaPath,
      id,
      label,
      validations,
      computedStyles
    } = this.props;

    const isRequired =
      get(validations, 'required.constraint', false) ||
      get(validations, 'required.queryConstraint');

    return (
      <div
        role="group"
        className={computedStyles.labelGroup}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        <label id={`label-${id}`} className={computedStyles.label} htmlFor={id}>
          {this.renderLabel(isRequired)}
        </label>
        {isRequired ? this.renderRequiredText() : null}
        {this.wasUpdatedThisVersion() ? this.renderUpdateTag() : null}
        {this.needsReconciliation() ? this.renderReconciliationTag() : null}
      </div>
    );
  }
}

export default Label;
