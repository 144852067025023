import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get, isNil, isString, find } from 'lodash';

import TableRow from 'Common/components/TableRow';
import DueDateDisplay from 'Common/components/DueDateDisplay';
import SeriousnessIndicator from 'Common/components/SeriousnessIndicator';
import { getAETasksCount } from 'Queue/utils';
import {
  processSeriousnessCriteria,
  getOrElse,
  getUserName,
  getSubcaseProductValue
} from 'Common/utils';
import { Checkbox, abbvieDateFormat } from 'Common/components/Form';
import {
  STATUSES_MAP,
  NOT_SPECIFIED,
  CC_SUB_CASE_TYPES_MAP,
  CMS_PROP_TYPES
} from 'Common/constants';
import {
  AE_STATE_PATHS,
  MASTER_STATE_PATHS,
  PQ_STATE_PATHS
} from 'Queue/constants';
import DescriptionLink from './DescriptionLink';

const hasStringData = input =>
  !isNil(input) && isString(input) && input.length > 0;

const AdverseEventsRow = ({
  computedStyles,
  row,
  caseLink,
  tasksLink,
  tasksCounts,
  processNonMomentDate,
  tacticalData,
  userDesiredColumns,
  selectedItemsToAssignOrArchive,
  handleSelectCaseOrTask
}) => {
  const seriousnessValue = processSeriousnessCriteria(row);
  const users = get(tacticalData, 'document-data.user-list', []);
  const subcaseId = getOrElse(row, AE_STATE_PATHS.ID);
  const isCombined = !!getOrElse(row, PQ_STATE_PATHS.ID);
  const tasksCount = getAETasksCount(tasksCounts, row.id);
  const country = getOrElse(
    row,
    AE_STATE_PATHS.COUNTRY_OF_REPORTER_LABEL,
    NOT_SPECIFIED
  );

  const methodOfReceiptOptions = get(
    tacticalData,
    'document-data.mastercase-options.method_of_receipt',
    []
  );
  const methodOfReceiptMatch = find(
    methodOfReceiptOptions,
    ({ value }) =>
      value === getOrElse(row, MASTER_STATE_PATHS.METHOD_OF_RECEIPT)
  );

  const methodOfReceipt = get(methodOfReceiptMatch, 'label', NOT_SPECIFIED);

  const maybeAssignee = getUserName(
    users,
    getOrElse(row, AE_STATE_PATHS.ASSIGNEE)
  );

  const productName = getSubcaseProductValue(
    row,
    tacticalData,
    CC_SUB_CASE_TYPES_MAP.ae,
    NOT_SPECIFIED
  );

  const dateOutputAE = abbvieDateFormat(
    getOrElse(row, AE_STATE_PATHS.ABBVIE_AWARENESS_DATE)
  );

  const abbvieAwarenessAE = hasStringData(dateOutputAE)
    ? dateOutputAE
    : NOT_SPECIFIED;

  let reactions = getOrElse(row, AE_STATE_PATHS.REACTIONS);
  reactions = reactions || NOT_SPECIFIED;

  return (
    <TableRow id="queue-ae-table-row">
      <td>
        <Checkbox
          onChange={evt => handleSelectCaseOrTask(subcaseId, evt, isCombined)}
          value={selectedItemsToAssignOrArchive.includes(subcaseId)}
        />
      </td>

      {(userDesiredColumns || []).map(column => {
        switch (column.label) {
          case 'Case Information':
            return (
              <td>
                {seriousnessValue ? (
                  <SeriousnessIndicator
                    value={seriousnessValue}
                    className={computedStyles.pullRight}
                  />
                ) : null}
                <Link
                  to={caseLink}
                  className={computedStyles.masterCaseId}
                  target="_blank"
                >
                  {subcaseId}
                </Link>
                <DescriptionLink row={row} />
              </td>
            );
          case 'Country':
            return <td>{country}</td>;
          case 'Product':
            return (
              <td className={computedStyles.productCell}>{productName}</td>
            );
          case 'Latest Received Date':
            return (
              <td>
                {processNonMomentDate(
                  abbvieDateFormat(
                    getOrElse(row, AE_STATE_PATHS.LAST_RECEIVED_DATE)
                  )
                )}
              </td>
            );
          case 'Method of Receipt':
            return <td>{methodOfReceipt}</td>;
          case 'Due Date':
            return (
              <td>
                <DueDateDisplay
                  date={getOrElse(row, AE_STATE_PATHS.SUBMISSION_DUE_DATE)}
                />
              </td>
            );
          case 'AE Owner':
            return <td>{maybeAssignee || 'Unassigned'}</td>;
          case 'Status':
            return (
              <td>{STATUSES_MAP[get(row, AE_STATE_PATHS.STATUS, 'NEW')]}</td>
            );
          case 'Submitted?':
            return (
              <td>{getOrElse(row, AE_STATE_PATHS.SUBMITTED) ? 'Yes' : 'No'}</td>
            );

          case 'Reactions':
            return <td>{reactions}</td>;
          case 'Abbvie Awareness Date':
            return <td>{abbvieAwarenessAE}</td>;
          case 'Actions':
            return (
              <td className={computedStyles.actions}>
                <div>
                  <Link to={caseLink} target="_blank">
                    View
                  </Link>
                </div>
                <div>
                  <Link to={tasksLink} target="_blank">
                    View Tasks {tasksCount ? `(${tasksCount})` : null}
                  </Link>
                </div>
              </td>
            );
          default:
            return <td>{NOT_SPECIFIED}</td>;
        }
      })}
    </TableRow>
  );
};

AdverseEventsRow.propTypes = {
  computedStyles: PropTypes.shape({
    masterCaseId: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    productCell: PropTypes.object.isRequired
  }).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    countryOfPrimaryReporterLabel: PropTypes.string.isRequired,
    assignee: PropTypes.object,
    status: PropTypes.string
  }).isRequired,
  caseLink: PropTypes.string.isRequired,
  tasksLink: PropTypes.string.isRequired,
  tasksCounts: PropTypes.arrayOf(PropTypes.object),
  processNonMomentDate: PropTypes.func.isRequired,
  tacticalData: CMS_PROP_TYPES.tacticalData,
  userDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
  handleSelectCaseOrTask: PropTypes.func
};

AdverseEventsRow.defaultProps = {
  tacticalData: {},
  tasksCounts: [],
  selectedItemsToAssignOrArchive: [],
  handleSelectCaseOrTask: () => {}
};

export default AdverseEventsRow;
