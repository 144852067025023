import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  DateInput,
  withStyles,
  Checkbox
} from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';
import { get, set, omit, has, cloneDeep } from 'lodash';
import { getDynamicFilterOptions } from 'Queue/utils';
import {
  EXPORT_NO_FILTERS,
  CUSTOM_DELIMITER_DYMANIC_FILTERS
} from 'Queue/constants';
import stylesGenerator from './styles';

class QueueFilterModal extends React.Component {
  static propTypes = {
    tacticalData: PropTypes.objectOf(PropTypes.object).isRequired,
    userDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    computedStyles: PropTypes.objectOf(PropTypes.object).isRequired,
    onCloseupdateFilter: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    dynamicFilters: PropTypes.shape({}),
    title: PropTypes.string,
    currentPage: PropTypes.string
  };
  static defaultProps = {
    pageName: '',
    dynamicFilters: [],
    title: 'QUEUE FILTERS',
    currentPage: ''
  };
  constructor(props) {
    super(props);
    this.state = {
      internalFilter: {},
      dynamicFilters: [],
      errorsCount: [],
      errorExist: false
    };
  }

  componentWillMount() {
    this.setState({ internalFilter: cloneDeep(this.props.dynamicFilters) });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ internalFilter: cloneDeep(nextProps.dynamicFilters) });
  }
  isDateFormat = date => {
    const datevalues = date.split('-');
    if (
      date &&
      date !== '{NOTEXISTS}' &&
      (!datevalues[0] || !datevalues[1] || !datevalues[2])
    ) {
      return 'Invalid full date.';
    }
    return '';
  };

  handleDropdownFilterChange = (e, entityPath) => {
    let internalFilter;
    if (has(this.state.internalFilter, entityPath) && !e) {
      internalFilter = omit(this.state.internalFilter, entityPath);
    } else {
      internalFilter = set(
        this.state.internalFilter,
        entityPath,
        e.split(CUSTOM_DELIMITER_DYMANIC_FILTERS)
      );
    }
    this.setState({ internalFilter });
  };

  handleDateFilterChange = (e, entityPathName) => {
    const error = this.isDateFormat(e, entityPathName);
    if (error) {
      const { errorsCount } = this.state;
      const isexist = errorsCount.find(x => x === entityPathName);
      if (!isexist) {
        errorsCount.push(entityPathName);
        this.setState({ errorsCount });
      }
    } else {
      let { errorsCount } = this.state;
      errorsCount = errorsCount.filter(x => x !== entityPathName);
      this.setState({ errorsCount });
    }

    let internalFilter;
    if (has(this.state.internalFilter, entityPathName) && !e) {
      internalFilter = omit(this.state.internalFilter, entityPathName);
    } else {
      internalFilter = set(this.state.internalFilter, entityPathName, e);
    }
    this.setState({ internalFilter });
  };

  renderDropdownFilter = (
    computedStyles,
    FilterOptions,
    tacticalData,
    label
  ) => {
    const { sourceData, entityPathName, useLabelsAsValue } = FilterOptions;

    const selectedValue = get(this.state.internalFilter, entityPathName, '');
    const { source, options } = sourceData;
    let filterOptions = options;
    if (source) {
      filterOptions = getDynamicFilterOptions(tacticalData, source);
      if (useLabelsAsValue) {
        filterOptions = filterOptions.map(option => ({
          label: option.label,
          value: option.label
        }));
      }
      const isNotexistedincluded = filterOptions.find(
        x => x.value === '{NOTEXISTS}'
      );
      if (!isNotexistedincluded && options) {
        options.forEach(option => {
          filterOptions.push(option);
        });
      }
    }
    return (
      <div
        // style={{ marginLeft: 22 }}
        key={`control-${label}`}
        className={computedStyles.filterDisplay}
      >
        <label
          id={`label-${entityPathName}`}
          className={computedStyles.controlLabelDisplay}
          htmlFor={entityPathName}
        >
          {label}
        </label>
        <Select
          className={computedStyles.controlDisplay}
          id={entityPathName}
          name={entityPathName}
          key={`dropdown-${this.state[entityPathName]}`}
          options={filterOptions}
          value={selectedValue}
          placeholder={label}
          onChange={e => this.handleDropdownFilterChange(e, entityPathName)}
          customDelimiter={CUSTOM_DELIMITER_DYMANIC_FILTERS}
          multi
        />
      </div>
    );
  };
  isLastRecord = (filterslength, index) => filterslength === index || false;

  renderDateTimeFilter = (
    computedStyles,
    FilterOptions,
    tacticalData,
    label,
    lastRecord
  ) => {
    const { entityPathName } = FilterOptions;
    const selectedValue = get(this.state.internalFilter, entityPathName, '');
    const error = this.isDateFormat(selectedValue);
    const size = lastRecord ? 150 : 50;

    if (error) {
      return (
        <div
          key={`control-${label}`}
          className={computedStyles.filterDisplay}
          style={{ marginBottom: size }}
        >
          <label
            id={`label-${entityPathName}`}
            className={computedStyles.errorlabel}
            htmlFor={entityPathName}
          >
            <span className={computedStyles.errorlabel}>{label} </span>
          </label>
          <div className={computedStyles.controlDisplay}>
            <DateInput
              id={entityPathName}
              key={'dropdownData'}
              value={selectedValue}
              placeholder={label}
              disabled={selectedValue === '{NOTEXISTS}'}
              style={{
                color: 'red',
                boxShadow: 'inset 0 0 0 2px #D0011B'
              }}
              onChange={e => this.handleDateFilterChange(e, entityPathName)}
            />
            <span className={computedStyles.errorlabel}>{error} </span>
          </div>
          <div className={computedStyles.checkboxDisplay}>
            <Checkbox
              helpText={'Unspecified Date'}
              className={computedStyles.controlcheckDisplay}
              value={selectedValue === '{NOTEXISTS}'}
              onChange={() =>
                this.handleDateFilterChange(
                  selectedValue === '{NOTEXISTS}' ? '' : '{NOTEXISTS}',
                  entityPathName
                )
              }
            />
          </div>
        </div>
      );
    }
    return (
      <div
        key={`control-${label}`}
        className={computedStyles.filterDisplay}
        style={{ marginBottom: size }}
      >
        <label
          id={`label-${entityPathName}`}
          className={`${
            computedStyles.controlLabelDisplay
          } ${error} ? errorlabel : ''`}
          htmlFor={entityPathName}
        >
          {label}
        </label>
        <div className={computedStyles.controlDisplay}>
          <DateInput
            id={entityPathName}
            key={'dropdownData'}
            value={selectedValue}
            placeholder={label}
            disabled={selectedValue === '{NOTEXISTS}'}
            onChange={e => this.handleDateFilterChange(e, entityPathName)}
          />
        </div>
        <div className={computedStyles.checkboxDisplay}>
          <Checkbox
            helpText={'Unspecified Date'}
            className={computedStyles.controlcheckDisplay}
            value={selectedValue === '{NOTEXISTS}'}
            onChange={() =>
              this.handleDateFilterChange(
                selectedValue === '{NOTEXISTS}' ? '' : '{NOTEXISTS}',
                entityPathName
              )
            }
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      computedStyles,
      userDesiredColumns,
      tacticalData,
      title,
      currentPage
    } = this.props;
    const filters = userDesiredColumns.filter(
      x =>
        x.isFilterRequired &&
        !(x.excludedForYourPage && currentPage.startsWith('your'))
    );
    const filtersLength = filters.length;
    return (
      <div
        className={`${modalStyles.base} ${modalStyles.modal} ${
          computedStyles.queuePopup
        }`}
      >
        <div className={modalStyles.title}>{title}</div>
        <div className={computedStyles.modifyFilterContainerPopup}>
          {filters.length > 0 ? (
            filters.map((filter, index) => {
              if (filter.FilterOptions.controlType === 'Select') {
                return this.renderDropdownFilter(
                  computedStyles,
                  filter.FilterOptions,
                  tacticalData,
                  filter.label
                );
              }
              return this.renderDateTimeFilter(
                computedStyles,
                filter.FilterOptions,
                tacticalData,
                filter.label,
                this.isLastRecord(filtersLength, index + 1)
              );
            })
          ) : (
            <span className={computedStyles.noResultsMessage}>
              {EXPORT_NO_FILTERS}
            </span>
          )}
        </div>
        <button
          className={computedStyles.modifyConfirmBtnPopup}
          onClick={() => {
            this.props.onCloseupdateFilter(this.state);
            this.props.closeModal();
          }}
          disabled={this.state.errorsCount.length > 0}
          style={{
            cursor:
              this.state.errorsCount.length > 0 ? 'not-allowed' : 'pointer',
            background:
              this.state.errorsCount.length > 0 ? '#071D20' : '#071D49'
          }}
        >
          CONFIRM
        </button>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(QueueFilterModal);
