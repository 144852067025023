import React from 'react';
import PropTypes from 'prop-types';

import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';

const CreateTaskLink = ({ computedStyles, onTaskCreate }) => (
  <div
    className={computedStyles.base}
    onClick={onTaskCreate}
    id="createNewTaskLink"
    role="button"
  >
    Create New Task
  </div>
);

CreateTaskLink.propTypes = {
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired
  }).isRequired,
  onTaskCreate: PropTypes.func.isRequired
};

export default withStyles(stylesGenerator)(CreateTaskLink);
