import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    width: '100%'
  }),

  title: generateCSS({
    padding: '34px'
  }),

  filter: filters =>
    generateCSS({
      padding: '0 10px 0 0',
      display: 'inline-block',
      width: `${100 / filters.length}%`,
      ':last-child': {
        padding: '0'
      },
      '> .Select': {
        '> .Select-control': {
          boxShadow: 'none'
        }
      }
    }),

  inputWrapper: filters =>
    generateCSS({
      display: 'inline-block',
      paddingRight: '10px',
      position: 'relative',
      top: '25px',
      width: `${100 / filters.length}%`,
      '> div > .pt-input': {
        border: '0 none !important'
      }
    }),

  subCaseLinks: generateCSS({
    padding: '20px 20px 20px 0px',
    width: '100%'
  }),

  filterWrapper: generateCSS({
    display: 'inline-block',
    height: '30px',
    margin: '5px 8px 10px 0',
    '.submitted': {
      margin: '5px 0px 10px 40px'
    }
  }),

  right: generateCSS({
    float: 'right'
  }),

  clearLeft: generateCSS({
    marginLeft: '100%'
  }),

  subcaseFilterWrapper: generateCSS({
    display: 'inline-block',
    height: '30px',
    width: '160px'
  }),

  actionMenu: generateCSS({
    display: 'inline-block',
    float: 'right',
    margin: '6px',
    position: 'relative',
    bottom: '40px'
  }),

  actionLinks: generateCSS({
    display: 'block',
    padding: '5px',
    color: '#9B9B9B',
    cursor: 'not-allowed'
  }),
  modalContainerDiv: generateCSS({
    ' div label': {
      width: '100%'
    }
  }),
  tableHeader: generateCSS({
    background: theme.colors.base,
    paddingTop: '16px'
  })
});

export default stylesGenerator;
