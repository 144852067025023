import React from 'react';
import PropTypes from 'prop-types';
import { handleKeyPress } from 'Common/utils';

const ReopenTask = props => {
  const { isEditing, task, emitTaskReopen } = props;

  if (!isEditing) {
    if (task.status === 'COMPLETED') {
      const handleReopen = () => emitTaskReopen(task);
      return (
        <a
          onClick={handleReopen}
          className="reopenTask"
          role="button"
          onKeyPress={handleKeyPress(handleReopen, ' ', 'Enter')}
        >
          Re-open
        </a>
      );
    }
  }
  return null;
};

ReopenTask.propTypes = {
  isEditing: PropTypes.bool,
  emitTaskReopen: PropTypes.func.isRequired,
  task: PropTypes.shape({
    form: PropTypes.shape({
      subcaseType: PropTypes.string
    })
  }).isRequired
};

ReopenTask.defaultProps = {
  isEditing: null
};

export default ReopenTask;
