import gql from 'graphql-tag';

const createCaseShell = caseFragment => {
  const fragment = gql`
    ${caseFragment}
  `;
  return gql`
    mutation CreateCaseShell($affiliate: String!) {
      createCaseShell(affiliate: $affiliate) {
        ...FullCaseWithSubcases
      }
    }
    ${fragment}
  `;
};

export default createCaseShell;
