import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { CMS_PROP_TYPES, NOT_SPECIFIED } from 'Common/constants';
import { getUserName, usersList } from 'Common/utils';
import { SimpleButton, Select } from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';

class ReassignAssigneeModal extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      push: PropTypes.func.isRequired,
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired,
      emitTempReassign: PropTypes.func.isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    currentAssignee: PropTypes.string.isRequired,
    handleAssign: PropTypes.func.isRequired,
    handleAssignToMe: PropTypes.func,
    tacticalData: CMS_PROP_TYPES.tacticalData
  };

  static defaultProps = {
    handleAssignToMe: null,
    tacticalData: {}
  };

  state = { tempAssignee: '' };

  handleCancelAssign = () => {
    this.props.actions.emitModalContentClear();
  };

  handleConfirm = () => {
    this.props.actions.emitModalContentClear();
    this.props.handleAssign(this.state.tempAssignee);
  };

  render() {
    const { tacticalData } = this.props;
    const users = get(this.props.tacticalData, 'document-data.user-list', []);
    const userList = usersList(
      get(tacticalData, 'document-data.user-list', [])
    );
    return (
      <div className={modalStyles.base}>
        <span className={modalStyles.title}>{this.props.title}</span>
        Current Owner:{' '}
        <span className={modalStyles.capitalize}>
          {getUserName(users, this.props.currentAssignee) || NOT_SPECIFIED}
        </span>
        <div>
          {typeof this.props.handleAssignToMe === 'function'
            ? this.props.handleAssignToMe()
            : null}
        </div>
        <Select
          label="Assign to another user"
          $id="modals.assignToAnotherUser"
          options={userList}
          placeholder="Select"
          onChange={value => this.setState(() => ({ tempAssignee: value }))}
          value={this.state.tempAssignee}
        />
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={this.handleCancelAssign}>Cancel</SimpleButton>
          <SimpleButton onClick={this.handleConfirm} primary>
            SAVE
          </SimpleButton>
        </div>
      </div>
    );
  }
}

export default ReassignAssigneeModal;
