/* global URL, FormData, File, XMLHttpRequest */
/* eslint no-underscore-dangle:0 */
import { get, uniqueId } from 'lodash';
import { saveAs } from 'file-saver';
import { getSession } from 'config/auth';

const FILE_TYPE_EXT = {
  'text/plain': 'txt',
  'text/markdown': 'txt'
};

export const isFileValue = value => value.find(file => file instanceof File);
export const isFileList = value => value instanceof Array && isFileValue(value);

export const mergeInputFiles = (lastValue = [], newValue = []) =>
  newValue.map(item => {
    const existing = lastValue.find(i => i._id === item._id) || {};
    return { ...existing, ...item };
  });

const fileToJSON = file =>
  !(file instanceof File)
    ? file
    : {
        // The `_id` is used as a unique UI identifier (similar to react's use of `key`)
        _id: file._id || uniqueId('file'),
        name: file.name.split('.').shift(),
        size: file.size,
        type: file.type,
        dataURL: URL.createObjectURL(file),
        ext: get(file.name.match(/\.(\w+)$/), '1', FILE_TYPE_EXT[file.type]),
        instance: file
      };

export const fileListToJSON = files => files.map(fileToJSON);

const setUpload = (file, upload = 0) =>
  Object.assign(file, { upload: `${upload}%` });

/** @typedef {Object} File */
/**
 * @typedef {Object[]} FileList
 * @enum {File} file
 */

/**
 * Uploads a given `FileList` with optional `onProgress` (called each time any
 * files progress is updated)
 *
 * @function
 * @param  {FileList|File} files - The File or FileList of attachements to upload
 * @param  {Function} onProgress - An optional function that receives the FileList
 *                                 as each file's upload progress is updated
 * @return {Promise}             - Resolves with a formatted JSON list of files and ids
 */
export const uploadAttachments = host => (files, onProgress) => {
  const formattedFiles = [].concat(files).map(file => {
    // TODO should this just be `return fileToJSON(file);`? the fn above handles this
    if (!(file instanceof File)) return fileToJSON(file);
    return { ...fileToJSON(file), instance: file };
  });

  onProgress(formattedFiles);

  return Promise.all(
    formattedFiles.map(
      ({ instance: fileData, ...file }, i, allFiles) =>
        new Promise((resolve, reject) => {
          if (!fileData) return resolve(file);

          const req = new XMLHttpRequest();
          const data = new FormData();
          const fullFileName = `${file.name}.${file.ext}`;
          data.append('file', fileData, fullFileName);
          req.open('POST', host, true);
          req.setRequestHeader(
            'Authorization',
            `Bearer ${getSession().access_token}`
          );

          req.upload.onprogress = ({ loaded, total }) => {
            if (onProgress) {
              const upload = loaded / total * 100;

              onProgress([
                ...allFiles.slice(0, i),
                setUpload(allFiles[i], upload),
                ...allFiles.slice(i + 1)
              ]);
            }
          };

          req.onreadystatechange = () => {
            if (req.readyState < 4) return null;
            if (/^2/.test(req.status)) {
              const response = JSON.parse(req.responseText);
              // try to release memory for any files successfully uploaded
              try {
                URL.revokeObjectURL(allFiles[i].dataURL);
                delete allFiles[i].dataURL; // eslint-disable-line
                delete allFiles[i].instance; // eslint-disable-line
              } catch (e) {
                // handleError
                console.error(e);
              }

              return resolve(
                Object.assign(allFiles[i], {
                  ...response,
                  upload: '100%'
                })
              );
            }
            return reject(req);
          };

          return req.send(data);
        })
    )
  );
};

export const downloadAttachment = URL => async file => {
  const fetchOptions = {
    method: 'GET'
  };
  try {
    const response = await fetch(URL, fetchOptions);
    const blob = await response.blob();
    const fullFileName = `${file.name}.${file.ext}`;
    saveAs(blob, fullFileName);
  } catch (e) {
    console.error(`Could not download attachemnt: ${URL}`);
  }
};
