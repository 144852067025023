import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import {
  get,
  isEmpty,
  isNil,
  filter,
  first,
  has,
  set,
  reduce,
  uniq,
  keys,
  pickBy,
  unset,
  merge,
  cloneDeep
} from 'lodash';

import { client, taskClient } from 'config/apollo';
import searchCase from 'api/graphql/queries/case/searchCase';

import SchemaUI from '@gmatas/cse';
import PropTypeSafety from 'Common/components/Form/PropTypeSafety';
import fetchTasksQuery from 'api/graphql/fetchTasksQuery';
import getTasksQuery from 'api/graphql/queries/task/getTasksQuery';
import withModal, { modalStyles } from 'Common/components/withModal';
import SimpleButton from 'Common/components/Form/SimpleButton';
import Notifier from 'Common/components/Notifier';
import QueueFilterModal from 'Queue/components/QueueFilterModal';

import {
  DOCUMENT_TITLE_MAP,
  TACTICAL_TYPE_MAP,
  CASE_STATUS_NEW,
  CASE_STATUS_COMPLETED,
  CASE_STATUS_ARCHIVED,
  SCHEMA_PATH_TASKS,
  CMS_PROP_TYPES,
  PAGE_STATUS,
  FRAGMENT_NAME_TASK,
  FRAGMENT_NAME_CASE,
  BULK_ASSIGN_TIMEOUT_MILLISECONDS
} from 'Common/constants';
import {
  TASKS_FILTERS,
  YOUR_TASKS_PATH,
  NEW_TASK_ID,
  MASTERCASE_TASK_PAGE_SLASH_COUNT,
  TASKS_PER_PAGE,
  ALL_TASKS_PATH,
  TASK_STATE_PATHS,
  CASE_STATE_PATHS,
  genNewTask,
  ITEMS_MAP,
  RECORDS_COUNT_DOWNLOAD_CSV,
  RECORDS_COUNT_DOWNLOAD_CSV_ERROR,
  DEFAULT_TASK_HEADER_STATE
} from 'Tasks/constants';
import {
  getOrElse,
  getAssignedUserName,
  getUser,
  protoToSearchCriteria,
  getColumnValueForCSVExport
} from 'Common/utils';
import { mergeCaseIntoTasks, reduceToIdObject } from 'Tasks/utils';
import * as formComponents from 'Common/components/Form/formComponents';
import { generateCSS } from 'Common/components/Form';
import Pagination from 'Common/components/Pagination';
import NoResults from 'Common/components/NoResults';
import wrappedInputComponents from 'Common/components/Form/wrappedInputComponents';
import AuditHistory, { AUDIT_TYPE_TASK } from 'Common/components/AuditHistory';
import QueueResultsModal from 'Queue/components/QueueResultsModal';
import AppliedFilters from 'Queue/components/AppliedFilters';
import * as tasksFormComponents from './Form';
import TasksHeader from './TasksHeader';
import Layout from './Layout';
import UnsavedChangesModal from './UnsavedChangesModal';

class TasksController extends Component {
  static propTypes = {
    loadedNextPage: PropTypes.bool.isRequired,
    taskStatus: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      emitCleanSlate: PropTypes.func.isRequired,
      emitTaskUpdate: PropTypes.func.isRequired,
      emitTaskCreate: PropTypes.func.isRequired,
      emitSchemaFetch: PropTypes.func.isRequired,
      emitInitAndSearch: PropTypes.func.isRequired,
      emitFilterInputUpdate: PropTypes.func.isRequired,
      emitFetchTaskCase: PropTypes.func.isRequired,
      emitFetchTaskCaseSuccess: PropTypes.func.isRequired,
      emitFetchTaskCaseFailure: PropTypes.func.isRequired,
      emitTaskReassign: PropTypes.func.isRequired,
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired,
      emitTaskComplete: PropTypes.func.isRequired,
      emitTaskReopen: PropTypes.func.isRequired,
      emitTaskCancel: PropTypes.func.isRequired,
      emitSortByUpdate: PropTypes.func.isRequired,
      emitFetchSearch: PropTypes.func.isRequired,
      emitTaskFilter: PropTypes.func.isRequired,
      emitTaskEdit: PropTypes.func.isRequired,
      emitTaskEditStop: PropTypes.func.isRequired,
      emitFetchUserView: PropTypes.func.isRequired,
      emitSetUserView: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        masterCaseId: PropTypes.string,
        subCase: PropTypes.string
      }).isRequired,
      url: PropTypes.string.isRequired
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }),
    session: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.string)
      ])
    ),
    fragments: PropTypes.objectOf(PropTypes.string).isRequired,
    searchBarQuery: PropTypes.string,
    filters: PropTypes.shape({}),
    schema: CMS_PROP_TYPES.schema.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData,
    isFetchingSchema: PropTypes.bool.isRequired, // eslint-disable-line
    isFetchingTacticalData: PropTypes.bool.isRequired, // eslint-disable-line
    model: PropTypes.shape({
      tasks: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
    }),
    sortBy: PropTypes.shape({
      'form.base.caseId': PropTypes.string,
      'form.base.dueDate': PropTypes.string
    }).isRequired,
    case: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      'subcases.adverseEvent.version': PropTypes.string,
      subcases: PropTypes.isRequired
    }),
    pageNumber: PropTypes.number.isRequired,
    lastUpdate: PropTypes.number,
    isFilteringTasks: PropTypes.bool.isRequired,
    modalContent: PropTypes.node,
    editTaskCount: PropTypes.number.isRequired,
    areAllCasesSelected: PropTypes.bool,
    selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
    masterCaseUserViewColumns: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.shape({}))
    ),
    inProgressView: PropTypes.bool,
    isAssigningTask: PropTypes.bool
  };

  static defaultProps = {
    session: {},
    tacticalData: {},
    location: {},
    model: {},
    case: {},
    modalContent: null,
    lastUpdate: null,
    areAllCasesSelected: false,
    selectedItemsToAssignOrArchive: [],
    masterCaseUserViewColumns: {},
    inProgressView: false,
    isAssigningTask: false,
    searchBarQuery: '',
    filters: {}
  };

  constructor(props) {
    super(props);
    const filtersFromStorage = window.sessionStorage.getItem(
      `filters-${window.location.pathname}`
    )
      ? JSON.parse(
          window.sessionStorage.getItem(`filters-${window.location.pathname}`)
        )
      : DEFAULT_TASK_HEADER_STATE;
    this.state = {
      update: false,
      hasSearched: false,
      path: window.location.pathname,
      ...filtersFromStorage
    };
  }

  componentDidMount = async () => {
    const { schema, actions, taskStatus } = this.props;
    // Only if the state is asking for the tasks on mount,
    // since if you load tasks -> queue -> tasks, we shouldn't initialize
    // the tasksQueue again
    if (isEmpty(schema.pages)) {
      await actions.emitSchemaFetch(SCHEMA_PATH_TASKS);
    }
    if (taskStatus === PAGE_STATUS.INIT) this.resolveTasks();
    actions.emitSortByUpdate(['form.base.dueDate', 'form.base.caseId'], 'desc');

    this.fetchUserView(this.props);
  };

  componentWillUpdate(nextProps) {
    const { taskStatus, loadedNextPage, lastUpdate: updated } = nextProps;
    const {
      model,
      lastUpdate,
      taskStatus: prevStatus,
      loadedNextPage: hadLoadedPage
    } = this.props;
    const { params } = this.props.match;
    const stoppedLoading =
      taskStatus === PAGE_STATUS.LOADING &&
      [PAGE_STATUS.LOADED, PAGE_STATUS.FAILED].includes(nextProps.taskStatus);

    const updateModel =
      nextProps.model.tasks.filter(
        // If the revisions differ on any task, the model was updated
        (task, i) =>
          !model.tasks[i] ||
          task.currentRevision !== model.tasks[i].currentRevision
      ).length > 0 ||
      lastUpdate < updated ||
      (taskStatus === PAGE_STATUS.LOADED && taskStatus !== prevStatus);

    if (params.masterCaseId === 'yours') {
      document.title = DOCUMENT_TITLE_MAP.yourTasks;
    } else if (isNil(params.masterCaseId)) {
      document.title = DOCUMENT_TITLE_MAP.allTasks;
    } else {
      document.title = `${DOCUMENT_TITLE_MAP.tasks} | ${params.masterCaseId}${
        !isNil(params.subCase) ? `-${params.subCase.toUpperCase()}` : ''
      }`;
    }

    if (
      this.form &&
      ((!hadLoadedPage && loadedNextPage === true) ||
        (stoppedLoading && model.tasks.length) ||
        nextProps.model.tasks.length !== model.tasks.length ||
        updateModel)
    )
      this.form.actions.emitInitializeModel({
        model: nextProps.model,
        schema: this.resolveSchema(),
        data: this.getData()
      });
  }

  componentDidUpdate(prevProps) {
    const { taskStatus, match } = this.props;
    const shouldLoadTasks =
      match.url !== prevProps.match.url ||
      this.isLoading(prevProps) !== this.isLoading();

    if (
      [PAGE_STATUS.LOADED, PAGE_STATUS.FAILED].includes(taskStatus) &&
      shouldLoadTasks
    ) {
      // passing in a boolean for now to prevent filters from reapplying
      this.resolveTasks(true);
    }
  }

  componentWillUnmount() {
    this.removeFiltersFromStorage();
    this.props.actions.emitCleanSlate();
  }

  fetchUserView = propsOrNextProps => {
    const { actions } = propsOrNextProps;
    actions.emitFetchUserView();
  };

  handleSearch = () => {
    const { actions } = this.props;
    if (this.isCurrentlyEditingOneOrMoreTasks()) {
      const handleDismiss = () => {
        this.fetchTasks();
        this.setState({ hasSearched: true });
        actions.emitModalContentClear();
      };
      this.showTaskEditingNavigateAwayModal(handleDismiss);
    } else {
      this.fetchTasks();
      this.setState({ hasSearched: true });
    }
  };

  onSortClick = (sortPath, order) => {
    const { actions, pageNumber } = this.props;
    if (this.isCurrentlyEditingOneOrMoreTasks()) {
      const handleDismiss = () => {
        actions.emitSortByUpdate([sortPath, 'form.base.caseId'], order);
        this.fetchTasks(pageNumber);
        actions.emitModalContentClear();
      };
      this.showTaskEditingNavigateAwayModal(handleDismiss);
    } else {
      actions.emitSortByUpdate([sortPath, 'form.base.caseId'], order);
      this.fetchTasks(pageNumber);
    }
  };
  isCurrentlyEditingOneOrMoreTasks = () => {
    const { editTaskCount } = this.props;
    return editTaskCount > 0;
  };

  showTaskEditingNavigateAwayModal = handleDismissFn => {
    const { actions } = this.props;
    actions.emitModalContentUpdate(
      <UnsavedChangesModal
        handleConfirm={actions.emitModalContentClear}
        handleDismiss={handleDismissFn}
      />
    );
  };

  getCaseId = () => {
    const { masterCaseId } = this.props.match.params;

    // NOTE: Fixed: ACFE-3922
    // Due to review/dashboard routing changes, we need to check for subCase value.
    // Eventually this should be fixed via routing/controller updates.
    const subCase =
      this.props.match.params.subCase === 'review'
        ? null
        : this.props.match.params.subCase;

    const getType = () => {
      if (!isNil(masterCaseId) && !isNil(subCase)) {
        return { type: 'subcase' };
      } else if (!isNil(masterCaseId)) {
        return { type: 'mastercase' };
      }
      return {};
    };

    return {
      caseId: isNil(masterCaseId) ? '' : masterCaseId,
      subcaseId: isNil(subCase) ? '' : subCase, // Note this is not an ID, it is ae|mi|pq
      ...getType()
    };
  };

  getStatusFromMasterCaseOrSubcase = (location, match, pathname) => {
    if (!this.isSubcasePath(pathname)) {
      return this.props.case.status || CASE_STATUS_NEW;
    }
    const subcasePrefix = pathname.substring(pathname.length - 2);
    const subcaseKey = TACTICAL_TYPE_MAP[subcasePrefix];
    const subcaseStatusKey = `subcases.${subcaseKey}.status`;
    return get(this.props.case, subcaseStatusKey);
  };

  syncInternalFilterState = filterData => {
    const statusFilters = filterData.statusFilters
      ? filterData.statusFilters
      : this.state.statusFilters;
    const typeFilters = filterData.typeFilters
      ? filterData.typeFilters
      : this.state.typeFilters;
    this.saveFilters(this.state.dynamicFilters, statusFilters, typeFilters);
    this.setState(filterData);
  };

  getFilters = ({ dynamicFilters, statusFilters, typeFilters }) => {
    const { location, session, match } = this.props;
    const pathname = get(location, 'pathname', match.url);

    const filters = {};
    set(filters, 'status', keys(pickBy(statusFilters)));
    set(filters, 'form.additional.type', keys(pickBy(typeFilters)));
    this.setState({
      dynamicFilters,
      typeFilters,
      statusFilters
    });

    if (pathname === YOUR_TASKS_PATH) {
      set(filters, 'form.base.assignee', session.sub);
    } else {
      const caseIdObject = this.getCaseId();
      switch (caseIdObject.type) {
        case 'mastercase':
          filters['form.base.caseId'] = caseIdObject.caseId;
          break;
        case 'subcase':
          filters['form.base.caseId'] = caseIdObject.caseId;
          filters['form.base.subcaseType'] = caseIdObject.subcaseId;
          break;
        default:
          break;
      }
    }
    if (has(dynamicFilters, 'form.base.dueDate')) {
      // we want to add * to dueDate to match within the string of multiple due dates
      // but avoid setting state with it
      const clone = cloneDeep(dynamicFilters);
      set(
        clone,
        'form.base.dueDate',
        `*${get(dynamicFilters, 'form.base.dueDate')}*`
      );
      return merge(filters, clone);
    }
    return merge(filters, dynamicFilters);
  };

  getDesiredColumns = () => {
    const { match, masterCaseUserViewColumns } = this.props;
    const { masterCaseId } = match.params;
    let desiredColumns = [];
    const pageName = masterCaseId ? 'yourTasks' : 'allTasks';
    if (masterCaseUserViewColumns) {
      if (
        masterCaseUserViewColumns[pageName] &&
        masterCaseUserViewColumns[pageName].length > 0
      ) {
        desiredColumns = masterCaseUserViewColumns[pageName];
      } else {
        desiredColumns = ITEMS_MAP.filter(x => x.isMandatoryColumn);
      }
    }
    return desiredColumns;
  };

  getData = () => ({
    ...this.props.tacticalData,
    location: this.props.match,
    sortBy: this.props.sortBy,
    userList: get(this.props.tacticalData, 'document-data.user-list', []),
    session: this.props.session,
    trilogyCase: this.props.case,
    userDesiredColumns: this.getDesiredColumns()
  });

  isSubcasePath = pathname => {
    const slashCount = pathname.split('/').length - 1;
    return !(slashCount === MASTERCASE_TASK_PAGE_SLASH_COUNT);
  };

  isLoading = (props = this.props) =>
    props.isFetchingSchema || props.isFetchingTacticalData;

  handleTaskCreate = () => {
    const alreadyCreatingNewTask =
      this.props.model.tasks.filter(t => t.id === NEW_TASK_ID).length > 0;
    if (!alreadyCreatingNewTask) {
      const caseIdObject = this.getCaseId();
      const version =
        caseIdObject.subcaseId === 'ae'
          ? get(this.props.case, 'subcases.adverseEvent.version')
          : '';
      const subcaseId =
        !isNil(caseIdObject.subcaseId) && caseIdObject.subcaseId.length === 0
          ? ''
          : `${caseIdObject.caseId}-${caseIdObject.subcaseId}${version}`;

      const subcaseType = caseIdObject.subcaseId;
      this.props.actions.emitTaskCreate(
        caseIdObject.caseId,
        subcaseId,
        subcaseType,
        this.props.case,
        this.props.session.sub
      );
    }
  };

  /**
   * pages are numbered starting with 0
   */
  handlePageChange = pageNumber => {
    this.fetchTasks(pageNumber);
  };

  handleChange = (action, state) => {
    const { match: { params }, case: trilogyCase, session } = this.props;
    if (action.type === 'EMIT_INPUT_UPDATE') {
      // This value will return the root path (eg. `tasks[2]`) of the task being updated
      // there will always be a `task[n]` at the root of a task field's `$id`
      const taskRootPath = action.payload.$id.match(/^tasks\[\d+\]/)[0];
      // We need to get the task's index from the path initially, as the latter case below
      // (changing `pregnancyRelated` value) can happen on _any_ task
      const currentTask = get(state.model, taskRootPath);
      const taskType = action.payload.value;
      // NOTE: We're using `tasks[0]` here, as the `type` can only change on NEW tasks, which
      // are ALWAYS the first task.
      if (action.payload.$id === 'tasks[0].form.additional.type') {
        this.form.actions.emitInitializeModel({
          model: Object.assign(state.model, {
            tasks: [
              genNewTask(
                taskType,
                trilogyCase,
                params.subCase,
                session.sub,
                currentTask
              ),
              ...state.model.tasks.slice(1)
            ]
          }),
          data: this.getData(),
          schema: this.resolveSchema()
        });
      } else if (
        action.payload.value &&
        action.payload.$id.indexOf('form.base.pregnancyRelated') > 0 &&
        // The `EDD` and `pregnancyRelated` values are only updated when CREATING a task
        get(trilogyCase, CASE_STATE_PATHS.EDD) &&
        currentTask.id === NEW_TASK_ID
      ) {
        this.form.actions.emitInitializeModel({
          model: set(
            state.model,
            `${taskRootPath}.${TASK_STATE_PATHS.EDD}`,
            get(trilogyCase, CASE_STATE_PATHS.EDD)
          ),
          data: this.getData()
        });
      }

      return;
    }

    if (!/UPDATE_COMPONENT|UPDATE|REMOVE|ADD/.test(action.type)) return;
    this.props.actions.emitTaskUpdate(state.model);
  };

  /**
   * This is called only the first time the user lands on a task page
   * E.g.(Your tasks, All tasks, Case tasks)
   */
  resolveTasks = stayedInTasks => {
    const { location, actions, match } = this.props;
    const pathname = match.url || get(location, 'pathname');
    actions.emitCleanSlate();
    actions.emitSortByUpdate(['form.base.dueDate', 'form.base.caseId'], 'desc');
    if (stayedInTasks) {
      this.removeFiltersFromStorage();
      this.setState({
        path: window.location.pathname,
        ...DEFAULT_TASK_HEADER_STATE
      });
      actions.emitUpdateFilters(this.getFilters(DEFAULT_TASK_HEADER_STATE));
    } else {
      const { dynamicFilters, statusFilters, typeFilters } = this.state;
      actions.emitUpdateFilters(
        this.getFilters({ dynamicFilters, statusFilters, typeFilters })
      );
    }

    if (pathname !== YOUR_TASKS_PATH && pathname !== ALL_TASKS_PATH) {
      const caseIdObject = this.getCaseId();
      actions.emitFetchTaskCase(
        caseIdObject.caseId,
        actions.emitFetchTaskCaseSuccess,
        actions.emitFetchTaskCaseFailure
      );
    }

    this.fetchTasks();
  };

  /**
   * Called everytime user changes something (filter, sort order, etc)
   */
  fetchTasks = (pageNumber = 0) => {
    const {
      fragments,
      location,
      match,
      actions,
      isFilteringTasks
    } = this.props;
    if (isFilteringTasks) {
      return;
    }
    actions.emitTaskFilter();
    const pathname = get(location, 'pathname', match.url);

    // Pagination only exists on All Tasks page
    if (pathname !== ALL_TASKS_PATH) {
      actions.emitFetchSearch(fetchTasksQuery(fragments), getTasksQuery);
      return;
    }
    actions.emitFetchSearch(
      fetchTasksQuery(fragments),
      getTasksQuery,
      null,
      null,
      pageNumber,
      TASKS_PER_PAGE
    );
  };

  shouldHideCreateNewTasks = () => {
    const { model, location, match } = this.props;

    const caseId = get(match, 'params.masterCaseId');
    const isValidCase = caseId && caseId !== 'yours';

    const alreadyCreatingNewTask =
      model.tasks.filter(t => t.id === NEW_TASK_ID).length > 0;

    const pathname = location ? window.location.pathname : match.url;
    const status =
      isValidCase &&
      this.getStatusFromMasterCaseOrSubcase(location, match, pathname);
    const isSubcase = this.isSubcasePath(pathname);

    return (
      !isValidCase ||
      alreadyCreatingNewTask ||
      status === CASE_STATUS_COMPLETED ||
      (isSubcase && status === CASE_STATUS_ARCHIVED)
    );
  };

  resolveSchema = () => this.props.schema.pages[0];

  renderPagination = () => {
    const {
      location,
      match,
      model,
      taskStatus,
      pageNumber,
      isFilteringTasks
    } = this.props;
    const pathname = get(location, 'pathname', match.url);

    // Pagination only exists on All Tasks page
    if (pathname !== ALL_TASKS_PATH) {
      return null;
    }

    return (
      <Pagination
        totalPages={Math.ceil(model.totalTasks / TASKS_PER_PAGE)}
        onPageChange={this.handlePageChange}
        disabled={
          taskStatus !== PAGE_STATUS.LOADED ||
          isFilteringTasks ||
          model.totalTasks === 0
        }
        pageOffset={pageNumber}
      />
    );
  };

  handleShowAuditLog = value => {
    const { actions } = this.props;
    const content = this.renderAuditHistoryModal(value);
    actions.emitModalContentUpdate(content);
  };

  handleDismiss = () => {
    const { actions } = this.props;
    actions.emitModalContentClear();
  };

  showModifyView = () => {
    this.setState({
      displayModifyView: true
    });
  };

  // For Dynamic Filters
  showFilters = () => {
    this.setState({
      displayFilters: true
    });
  };

  onCloseupdateFilter = (updatedFilters, force) => {
    const { actions } = this.props;
    const { statusFilters, typeFilters } = this.state;
    if (!force && Object.keys(updatedFilters.internalFilter).length <= 0) {
      this.setState({
        displayFilters: false
      });
      return;
    }
    const dynamicFilters = updatedFilters.internalFilter;
    const filters = this.getFilters({
      dynamicFilters,
      statusFilters,
      typeFilters
    });
    actions.emitUpdateFilters(filters);
    this.saveFilters(dynamicFilters, statusFilters, typeFilters);
    this.fetchTasks();
    this.setState({
      displayFilters: false,
      dynamicFilters
    });
  };

  onCloseModifyView = (desiredColumns = [], page) => {
    const { masterCaseUserViewColumns } = this.props;
    const { dynamicFilters } = this.state;
    const newDesiredColumns = {
      [page]: desiredColumns
    };
    const masterviewColumns = Object.assign({}, masterCaseUserViewColumns);
    delete masterviewColumns.inProgress;
    const totalDesiredColumns = Object.assign(
      {},
      masterviewColumns,
      newDesiredColumns
    );

    let dynamicFilterApplied = {};
    if (dynamicFilters) {
      totalDesiredColumns[page].forEach(rec => {
        if (rec.isFilterRequired) {
          if (has(dynamicFilters, rec.FilterOptions.entityPathName)) {
            const selectedValue = get(
              dynamicFilters,
              rec.FilterOptions.entityPathName,
              null
            );
            dynamicFilterApplied = set(
              dynamicFilterApplied,
              rec.FilterOptions.entityPathName,
              selectedValue
            );
          }
        }
      });
    }
    this.setState(
      {
        displayModifyView: false
      },
      () => {
        this.props.actions.emitSetUserView(totalDesiredColumns);
        this.onCloseupdateFilter(
          { internalFilter: dynamicFilterApplied },
          true
        );
      }
    );
  };

  handleExportToCSV = () => {
    const { model } = this.props;
    const { totalTasks } = model;
    if (RECORDS_COUNT_DOWNLOAD_CSV < totalTasks) {
      Notifier.show({
        message: RECORDS_COUNT_DOWNLOAD_CSV_ERROR,
        intent: Notifier.PRIMARY
      });
    } else {
      this.emitFetchCSVSearch();
    }
  };

  emitFetchCSVSearch = () => {
    const { searchBarQuery, filters, sortBy, fragments } = this.props;
    const csvFilter = protoToSearchCriteria(filters);
    const searchQuery = {
      query: searchBarQuery,
      ...csvFilter,
      sortBy: {
        ...sortBy
      }
    };
    const reduceObj = (acc, val, key) => set(acc, key, val);
    const variables = reduce(searchQuery, reduceObj, {});
    variables.sortBy = reduce(searchQuery.sortBy, reduceObj, {});
    const options = {
      q: variables,
      from: 0,
      size: 1000
    };
    let tasks;
    taskClient
      .query({
        query: getTasksQuery(fragments[FRAGMENT_NAME_TASK]),
        variables: options
      })
      .then(({ data }) => {
        const frozenTasks = data.essearch.hits || [];
        tasks = Object.isFrozen(frozenTasks)
          ? JSON.parse(JSON.stringify(frozenTasks))
          : frozenTasks;
        const caseIds = filter(
          uniq(tasks.map(task => get(task, 'form.base.caseId', null))),
          val => val !== null
        );

        return client.query({
          query: searchCase(fragments[FRAGMENT_NAME_CASE]),
          variables: {
            q: {
              id: caseIds
            },
            size: caseIds.length,
            from: 0
          }
        });
      })
      .then(caseData => {
        if (caseData) {
          // No need to unfreeze after reduce
          const cases = caseData.data.searchCase.hits.reduce(
            reduceToIdObject,
            {}
          );
          tasks = mergeCaseIntoTasks(tasks, cases);
        }
        this.downloadCSVFile(tasks);
      })
      .catch(error => {
        Notifier.show({
          message: 'Failed to fetch tasks',
          intent: Notifier.DANGER
        });
        console.log(error);
      });
  };

  downloadCSVFile(data) {
    try {
      const tasks = data || [];
      const { masterCaseId } = this.props.match.params;
      const { tacticalData } = this.props;
      const { subcases } = this.props.case;
      let columns = this.getDesiredColumns();
      const records = [];
      const ColumnstoDisplay = [];
      const currentPage = masterCaseId ? 'yourTasks' : 'allTasks';
      columns = columns.filter(x => x.label !== 'Actions');
      columns.map(col => ColumnstoDisplay.push(col.label));
      records.push(ColumnstoDisplay);

      tasks.forEach(rec => {
        const record = [];
        columns.forEach(col => {
          let recValue = getColumnValueForCSVExport(
            rec,
            col.label,
            currentPage,
            tacticalData,
            col,
            subcases
          );
          recValue = recValue || ' ';
          record.push(recValue);
        });
        records.push(record);
      });
      const ws = XLSX.utils.aoa_to_sheet(records);
      const wb = XLSX.utils.book_new();
      const bookName = masterCaseId ? 'Your Tasks' : 'All Tasks';
      XLSX.utils.book_append_sheet(wb, ws, bookName);
      /* generate XLSX file and send to client */
      const date = new Date();
      const getHour = (date.getHours() < 10 ? '0' : '') + date.getHours();
      const getMinutes =
        (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
      const currentHours = getHour.toString() + getMinutes.toString();
      const getMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1);
      const getDate = (date.getDate() < 10 ? '0' : '') + date.getDate();
      const currentDate =
        getMonth.toString() +
        getDate.toString() +
        date.getFullYear().toString();
      const fileTitle = masterCaseId ? 'Your_Tasks' : 'All_Tasks';
      const fileName = `${fileTitle}_${currentHours}-${currentDate}.xlsx`;
      XLSX.writeFile(wb, fileName);
    } catch (ex) {
      console.log('logging exception details,', ex.message);
    }
  }

  renderAuditHistoryModal = value => {
    const { schema } = this.props;
    const pageSchema = first(filter(schema.pages, { statePath: 'tasks' }));
    return (
      <div className={modalStyles.base}>
        <span className={modalStyles.title}>Task Audit History</span>
        <hr />
        <div
          className={generateCSS({
            marginBottom: '40px',
            marginTop: '20px',
            height: '90vh'
          })}
        >
          {
            <AuditHistory
              value={value}
              pageSchema={pageSchema}
              auditType={AUDIT_TYPE_TASK}
              {...this.props}
            />
          }
          <div className={modalStyles.buttonsContainer}>
            <SimpleButton onClick={this.handleDismiss} primary>
              Back to Task
            </SimpleButton>
          </div>
        </div>
      </div>
    );
  };

  getKeyname = () => {
    let keyname = '';
    this.getData().userDesiredColumns.map(
      col => (keyname += col.label.replace(/\s+/g, ''))
    );
    return keyname;
  };

  renderTasks = () => {
    const { model, actions } = this.props;
    const schema = this.resolveSchema();
    const setRef = ref => (this.form = ref); // eslint-disable-line
    const hasNoResults = isEmpty(model.tasks);

    return hasNoResults ? (
      <NoResults>No results found.</NoResults>
    ) : (
      <SchemaUI
        debug={!!process.env.CSE_DEBUG}
        key={this.getKeyname()}
        ref={setRef}
        config={{
          index: { key: 'statePath', inherit: true },
          isReadOnly: false
        }}
        defaultComponent={PropTypeSafety}
        onChange={this.handleChange}
        schema={schema}
        model={model}
        components={{
          ...formComponents,
          ...wrappedInputComponents,
          ...tasksFormComponents
        }}
        data={this.getData()}
        triggers={{
          onModalContentClear: actions.emitModalContentClear,
          onModalContentUpdate: actions.emitModalContentUpdate,
          onShowAuditLog: value => this.handleShowAuditLog(value),
          onTaskEditCancel: actions.emitTaskEditCancel,
          onTaskSave: actions.emitTaskSave,
          onTaskCancel: actions.emitTaskCancel,
          onSortClick: this.onSortClick,
          showReassignModal: modal =>
            actions.emitModalContentUpdate(modal, true),
          showModal: actions.emitModalContentUpdate,
          clearModal: actions.emitModalContentClear,
          completeTask: actions.emitTaskComplete,
          reopenTask: actions.emitTaskReopen,
          reassignTask: actions.emitTaskReassign,
          editTask: actions.emitTaskEdit,
          stopEditingTask: actions.emitTaskEditStop,
          handleSelectCaseOrTask: this.handleSelectCaseOrTask,
          handleSelectAllCasesOrTasks: this.handleSelectAllCasesOrTasks
        }}
      />
    );
  };

  handleSelectAllCasesOrTasks = evt => {
    const { model, actions } = this.props;
    if (evt) {
      const tasks = model.tasks;
      const selectedItems = tasks ? tasks.map(task => task.id) : [];
      actions.emitUpdateTaskSelection(selectedItems, true);
      return;
    }
    actions.emitUpdateTaskSelection([], false);
  };

  handleSelectCaseOrTask = (selectedTaskId, evt) => {
    const { actions, selectedItemsToAssignOrArchive } = this.props;
    let updatedSelectedTaskIds;
    if (evt) {
      updatedSelectedTaskIds = selectedItemsToAssignOrArchive.concat(
        selectedTaskId
      );
      actions.emitUpdateTaskSelection(updatedSelectedTaskIds, false);
      return;
    }
    updatedSelectedTaskIds = selectedItemsToAssignOrArchive.filter(
      caseId => caseId !== selectedTaskId
    );
    actions.emitUpdateTaskSelection(updatedSelectedTaskIds, false);
  };

  assignCaseOrTask = assigneeId => {
    const {
      selectedItemsToAssignOrArchive,
      session,
      tacticalData,
      actions,
      pageNumber
    } = this.props;
    const userId = assigneeId || session.sub;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const assigneeName = getAssignedUserName(users, userId, session);
    const assignee = getUser(users, userId);
    const assigneeFirstName = assignee ? assignee.fn : '';
    const assigneeLastName = assignee ? assignee.sn : '';
    const assigneeDetails = {
      userId,
      assigneeName,
      assigneeFirstName,
      assigneeLastName
    };
    actions
      .emitUpdateAssignTaskToUser(
        'BULK_ASSIGNMENT',
        assigneeDetails,
        selectedItemsToAssignOrArchive
      )
      .finally(() => {
        setTimeout(() => {
          this.fetchTasks(pageNumber);
          actions.emitUpdateAssignTaskToUserComplete();
        }, BULK_ASSIGN_TIMEOUT_MILLISECONDS);
      });
  };

  removeFiltersFromStorage = () => {
    window.sessionStorage.removeItem(`filters-${this.state.path}`);
  };
  saveFilters = (dynamicFilters, statusFilters, typeFilters) => {
    window.sessionStorage.setItem(
      `filters-${this.state.path}`,
      JSON.stringify({ dynamicFilters, statusFilters, typeFilters })
    );
  };
  clearFilters = (path, arrValue) => {
    const { actions } = this.props;
    const { statusFilters, typeFilters } = this.state;
    const dynamicFilters = { ...this.state.dynamicFilters };
    if (!path) {
      this.setState(DEFAULT_TASK_HEADER_STATE);
      this.removeFiltersFromStorage();
      actions.emitUpdateFilters(this.getFilters(DEFAULT_TASK_HEADER_STATE));
      this.fetchTasks();
      return;
    } else if (arrValue) {
      const arr = get(dynamicFilters, path);
      arr.splice(arr.indexOf(arrValue), 1);
      if (arr.length === 0) {
        unset(dynamicFilters, path);
      } else {
        set(dynamicFilters, path, arr);
      }
    } else {
      unset(dynamicFilters, path);
    }
    actions.emitUpdateFilters(
      this.getFilters({ dynamicFilters, statusFilters, typeFilters })
    );
    this.fetchTasks();
    this.saveFilters(dynamicFilters, statusFilters, typeFilters);
    this.setState({ dynamicFilters });
  };

  openQueueModal = modalOption => {
    const { match, model, tacticalData, actions } = this.props;
    const { masterCaseId } = match.params;
    const desiredColumns = this.getDesiredColumns();
    const pageName = masterCaseId ? 'yourTasks' : 'allTasks';

    if (modalOption === 'editColumns') {
      actions.emitModalContentUpdate(
        <QueueResultsModal
          onCloseModifyView={this.onCloseModifyView}
          closeModal={actions.emitModalContentClear}
          columns={ITEMS_MAP}
          userDesiredColumns={desiredColumns}
          filteredQueue={model.tasks}
          pageName={pageName}
        />
      );
    } else if (modalOption === 'showFilters') {
      actions.emitModalContentUpdate(
        <QueueFilterModal
          onCloseupdateFilter={this.onCloseupdateFilter}
          closeModal={actions.emitModalContentClear}
          columns={ITEMS_MAP}
          userDesiredColumns={desiredColumns}
          filteredQueue={null}
          pageName={pageName}
          tacticalData={tacticalData}
          filters={this.props.filters}
          dynamicFilters={this.state.dynamicFilters}
          title={TASKS_FILTERS}
          currentPage={pageName}
        />
      );
    }
  };

  render() {
    const { match, tacticalData } = this.props;

    // SchemaUI should only be rendered once schema is populated with correct data.
    // Attempting an initial render with a blank value will break the component.
    const isLoading =
      isEmpty(this.resolveSchema()) ||
      this.props.taskStatus !== PAGE_STATUS.LOADED;
    const desiredColumns = this.getDesiredColumns();
    return (
      <Fragment>
        <TasksHeader
          key={match.url}
          {...this.props}
          users={this.getData().userList}
          onSearch={this.handleSearch}
          assignCaseOrTask={this.assignCaseOrTask}
          handleExportToCSV={this.handleExportToCSV}
          showModifyView={this.showModifyView}
          showFilters={this.showFilters}
          updateInternalFilter={this.syncInternalFilterState}
          statusFilters={this.state.statusFilters}
          typeFilters={this.state.typeFilters}
          openQueueModal={this.openQueueModal}
          appliedFilters={
            <AppliedFilters
              appliedFilters={this.state.dynamicFilters}
              desiredColumns={desiredColumns}
              tacticalData={tacticalData}
              clearFilters={this.clearFilters}
            />
          }
        />

        <Layout
          {...this.props}
          onTaskCreate={this.handleTaskCreate}
          shouldHideCreateNewTaskLink={this.shouldHideCreateNewTasks()}
          isLoading={isLoading}
        >
          {!isLoading && this.renderTasks(desiredColumns)}
        </Layout>
        {this.renderPagination()}
      </Fragment>
    );
  }
}

export default withModal(TasksController);
