import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { getUserName } from 'Common/utils';
import { NOT_SPECIFIED, CMS_PROP_TYPES } from 'Common/constants';
import { abbvieDateFormat } from 'Common/components/Form';
import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';
import {
  MASTER_STATE_PATHS,
  AE_STATE_PATHS,
  PQ_STATE_PATHS,
  SERIOUSNESS_OPTIONS,
  REPLACEMENT_REQUIRED_OPTIONS,
  SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS,
  CUSTOMER_RESPONSE_REQUIRED_OPTIONS,
  GENERIC_OPTIONS,
  PRODUCT_TYPE_OPTIONS
} from '../../constants/display';

class AppliedFilters extends PureComponent {
  static propTypes = {
    appliedFilters: PropTypes.shape({
      countryOfPrimaryReporter: PropTypes.array,
      assignee: PropTypes.array,
      creatorid: PropTypes.array,
      tracking: PropTypes.object,
      subcases: PropTypes.object,
      summary: PropTypes.object,
      form: PropTypes.object
    }).isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    clearFilters: PropTypes.func.isRequired,
    computedStyles: PropTypes.shape({
      container: PropTypes.object.isRequired,
      filterBlock: PropTypes.object.isRequired,
      clearAll: PropTypes.object.isRequired
    }).isRequired
  };

  getFilterKeyMapping = key => {
    let filterBlocks = [];
    switch (key) {
      case 'automationStatus':
        filterBlocks.push(
          this.renderSelectBlocks(
            MASTER_STATE_PATHS.AUTOMATION_STATUS,
            'Automated Process',
            NOT_SPECIFIED,
            'document-data.mastercase-options.automation_status'
          )
        );
        break;
      case 'countryOfPrimaryReporter':
        filterBlocks.push(
          this.renderSelectBlocks(
            MASTER_STATE_PATHS.COUNTRY_OF_REPORTER,
            'Country of Reporter',
            NOT_SPECIFIED,
            'document-data.country-options'
          )
        );
        break;
      case 'assignee':
      case 'creatorid':
        filterBlocks.push(
          this.renderUserNameBlock(
            key,
            key === 'assignee' ? 'Owner' : 'Case Creator'
          )
        );
        break;
      case 'tracking':
        filterBlocks.push(
          this.renderSelectBlocks(
            MASTER_STATE_PATHS.PRODUCT_NAME,
            'Product',
            NOT_SPECIFIED,
            'document-data.mastercase-product-options'
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            MASTER_STATE_PATHS.METHOD_OF_RECEIPT,
            'Method of Receipt',
            NOT_SPECIFIED,
            'document-data.mastercase-options.method_of_receipt'
          )
        );
        break;
      case 'patient':
        filterBlocks.push(
          this.renderDateBlock(
            'Patient DOB',
            MASTER_STATE_PATHS.PATIENT_DOB_FILTER
          )
        );
        break;
      case 'contacts':
        filterBlocks.push(
          this.renderSelectBlocks(
            MASTER_STATE_PATHS.PROGRAM_ID_NAME_FILTER,
            'Program ID',
            NOT_SPECIFIED,
            'document-data.psp-options.psp_opi_number'
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            MASTER_STATE_PATHS.SERVICE_PROVIDER_FILTER,
            'Service Provider',
            NOT_SPECIFIED,
            'document-data.psp-options.psp_name'
          )
        );
        break;
      case 'subcases':
        filterBlocks.push(
          this.renderDateBlock('Due Date', AE_STATE_PATHS.SUBMISSION_DUE_DATE)
        );
        filterBlocks.push(
          this.renderDateBlock(
            'AE Latest Received Date',
            AE_STATE_PATHS.LAST_RECEIVED_DATE
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            AE_STATE_PATHS.PRODUCT_NAME_FILTER,
            'AE Suspect Product',
            NOT_SPECIFIED
          )
        );
        filterBlocks.push(
          this.renderUserNameBlock(PQ_STATE_PATHS.ASSIGNEE, 'PQ Owner')
        );
        filterBlocks.push(
          this.renderUserNameBlock(AE_STATE_PATHS.ASSIGNEE, 'AE Owner')
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            AE_STATE_PATHS.COUNTRY_OF_REPORTER,
            'Country of Reporter',
            NOT_SPECIFIED,
            'document-data.country-options'
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            PQ_STATE_PATHS.HIGH_IMPACT_FILTER,
            'High Impact',
            NOT_SPECIFIED,
            GENERIC_OPTIONS,
            false
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            PQ_STATE_PATHS.SAMPLE_AVAILABLE_FILTER,
            'Sample Available for Return',
            NOT_SPECIFIED
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            PQ_STATE_PATHS.REPLACEMENT_REQUIRED_FILTER,
            'Replacement Required',
            NOT_SPECIFIED,
            REPLACEMENT_REQUIRED_OPTIONS,
            false
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            PQ_STATE_PATHS.SAMPLE_REQUIRED_FOR_INVESTIGATION_FILTER,
            'Sample Required for Investigation',
            NOT_SPECIFIED,
            SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS,
            false
          )
        );
        filterBlocks.push(
          this.renderDateBlock(
            'Affiliate Return Sample Received Date',
            PQ_STATE_PATHS.AFFILIATE_RETURN_SAMPLE_RECEIVED_DATE_FILTER
          )
        );
        filterBlocks.push(
          this.renderDateBlock(
            'Affiliate Sample Ship Date',
            PQ_STATE_PATHS.AFFILIATE_SAMPLE_SHIP_DATE_FILTER
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            PQ_STATE_PATHS.CUSTOMER_RESPONSE_REQUIRED_FILTER,
            'Customer Response Required?',
            NOT_SPECIFIED,
            CUSTOMER_RESPONSE_REQUIRED_OPTIONS,
            false
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            PQ_STATE_PATHS.COUNTRY_OF_REPORTER,
            'Country of Reporter',
            NOT_SPECIFIED
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            PQ_STATE_PATHS.PRODUCT_NAME_FILTER,
            'Product (Marketed Name)',
            NOT_SPECIFIED
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            PQ_STATE_PATHS.LIST_NUMBER_FILTER,
            'List Number',
            NOT_SPECIFIED
          )
        );
        filterBlocks.push(
          this.renderDateBlock(
            'Submission Date to SolTRAQs',
            PQ_STATE_PATHS.SUBMISSION_DATE_TO_SOLTRAQS
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            MASTER_STATE_PATHS.PRODUCT_TYPE,
            'AGN/ABV',
            NOT_SPECIFIED,
            PRODUCT_TYPE_OPTIONS,
            false
          )
        );
        break;
      case 'summary':
        filterBlocks.push(
          this.renderDateBlock(
            'Affiliate Awareness Date',
            MASTER_STATE_PATHS.SUMMARY_AFFILIATE_AWARENESS_DATE
          )
        );
        filterBlocks.push(
          this.renderDateBlock(
            'Abbvie Awareness (AE)',
            MASTER_STATE_PATHS.AWARENESS_DATE_AE
          )
        );
        filterBlocks.push(
          this.renderDateBlock(
            'Abbvie Awareness (PQ)',
            MASTER_STATE_PATHS.AWARENESS_DATE_PQ
          )
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            MASTER_STATE_PATHS.SERIOUSNESS,
            'Seriousness',
            NOT_SPECIFIED,
            SERIOUSNESS_OPTIONS,
            false
          )
        );
        break;
      case 'trilogyLoadDate':
        filterBlocks = this.renderDateBlock(
          'Created Date',
          MASTER_STATE_PATHS.TRILOGYLOADDATE
        );
        break;
      case 'form':
        filterBlocks.push(
          this.renderDateBlock('Due Date', 'form.base.dueDate')
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            'form.additional.attempts.attempt[0].attemptNumber',
            'Number of attempt',
            NOT_SPECIFIED
          )
        );
        filterBlocks.push(
          this.renderUserNameBlock('form.base.assignee', 'Assignee')
        );
        filterBlocks.push(
          this.renderSelectBlocks(
            'form.base.followUp.type',
            'Follow Up Type',
            NOT_SPECIFIED,
            'document-data.task-options.pq_follow_up_type'
          )
        );
        break;
      default:
        break;
    }
    return filterBlocks;
  };

  renderUserNameBlock = (path, label) => {
    const { tacticalData, appliedFilters, clearFilters } = this.props;
    return get(appliedFilters, path, []).map(value => {
      const userName =
        getUserName(get(tacticalData, 'document-data.user-list', []), value) ||
        'Unassigned';
      return this.renderFilterBlock(label, userName, () =>
        clearFilters(path, value)
      );
    });
  };

  renderSelectBlocks = (
    path,
    label,
    nullLabel,
    optionsPath,
    useTacticalData = true
  ) => {
    const { tacticalData, appliedFilters, clearFilters } = this.props;
    return get(appliedFilters, path, []).map(value => {
      let displayValue;
      if (optionsPath && useTacticalData) {
        const options = get(tacticalData, optionsPath, []);
        displayValue = get(
          options.find(option => option.value === value),
          'label',
          nullLabel
        );
      } else if (!useTacticalData) {
        displayValue = get(
          optionsPath.options.find(option => option.value === value),
          'label',
          nullLabel
        );
      } else {
        displayValue = value === '{NOTEXISTS}' ? nullLabel : value;
      }
      return this.renderFilterBlock(label, displayValue, () =>
        clearFilters(path, value)
      );
    });
  };

  renderDateBlock = (label, path) => {
    const { appliedFilters, clearFilters } = this.props;
    const date = get(appliedFilters, path);
    const dateValue =
      date === '{NOTEXISTS}' ? 'Unspecified Date' : abbvieDateFormat(date);
    return dateValue
      ? this.renderFilterBlock(label, dateValue, () => clearFilters(path))
      : null;
  };

  renderFilterBlock = (label, value, clearFilters) => {
    const { computedStyles } = this.props;
    return (
      <button
        className={`${computedStyles.filterBlock}`}
        key={`${label}-${value}`}
      >
        <span
          className={`${computedStyles.closeButton}`}
          onClick={clearFilters}
          role="button"
        >
          x
        </span>
        {`${label}: `}
        <b>{value}</b>
      </button>
    );
  };

  renderAppliedFilters = () => {
    const { appliedFilters, computedStyles, clearFilters } = this.props;
    const filterBlocks = Object.keys(appliedFilters).map(key =>
      this.getFilterKeyMapping(key, appliedFilters[key])
    );
    return (
      <div className={`${computedStyles.container}`}>
        {filterBlocks}
        {filterBlocks.toString().replace(/,/g, '') && (
          <span
            className={`${computedStyles.clearAll}`}
            onClick={() => clearFilters()}
            key="clearAll"
            role="button"
          >
            {'Clear all filters'}
          </span>
        )}
      </div>
    );
  };

  render() {
    // checking tacticalData so that filters do not show until page loads
    const { tacticalData } = this.props;
    return tacticalData && Object.keys(tacticalData).length !== 0
      ? this.renderAppliedFilters()
      : null;
  }
}

export default withStyles(stylesGenerator)(AppliedFilters);
