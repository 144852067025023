import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { find, get, isNil, isString, capitalize } from 'lodash';

import TableRow from 'Common/components/TableRow';
import { CMS_PROP_TYPES, STATUSES_MAP, NOT_SPECIFIED } from 'Common/constants';
import { getOrElse, getSubcaseList, formatProductTypes } from 'Common/utils';
import { Checkbox, abbvieDateFormat } from 'Common/components/Form';
import { MASTER_STATE_PATHS, SERIOUSNESS_OPTIONS } from 'Queue/constants';
import { getTasksCount } from 'Queue/utils';
import DescriptionLink from './DescriptionLink';

class MasterCaseRow extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      masterCaseId: PropTypes.object.isRequired,
      subcaseList: PropTypes.object.isRequired,
      actions: PropTypes.object.isRequired,
      productCell: PropTypes.object.isRequired
    }).isRequired,
    row: PropTypes.shape({
      id: PropTypes.string,
      countryOfPrimaryReporterLabel: PropTypes.string.isRequired,
      status: PropTypes.string
    }).isRequired,
    caseLink: PropTypes.string.isRequired,
    tasksLink: PropTypes.string.isRequired,
    tasksCounts: PropTypes.arrayOf(PropTypes.object),
    tacticalData: CMS_PROP_TYPES.tacticalData,
    selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
    userDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleSelectCaseOrTask: PropTypes.func
  };

  static defaultProps = {
    tacticalData: {},
    tasksCounts: [],
    selectedItemsToAssignOrArchive: [],
    handleSelectCaseOrTask: () => {}
  };

  hasStringData = input => !isNil(input) && isString(input) && input.length > 0;

  renderMCActions = () => {
    const { computedStyles, caseLink } = this.props;

    return (
      <div className={computedStyles.actions}>
        <div>
          <Link to={caseLink} target="_blank">
            View
          </Link>
        </div>
        {this.renderTasksLink()}
      </div>
    );
  };

  renderTasksLink = () => {
    const { row, tasksLink, tasksCounts } = this.props;
    const tasksCount = getTasksCount(tasksCounts, row.masterCaseId);

    return (
      <div>
        <Link to={tasksLink} target="_blank">
          View Tasks {tasksCount ? `(${tasksCount})` : null}
        </Link>
      </div>
    );
  };

  renderCell(column) {
    const { computedStyles, row, caseLink, tacticalData } = this.props;

    const methodOfReceiptOptions = get(
      tacticalData,
      'document-data.mastercase-options.method_of_receipt',
      []
    );
    const methodOfReceiptMatch = find(
      methodOfReceiptOptions,
      ({ value }) =>
        value === getOrElse(row, MASTER_STATE_PATHS.METHOD_OF_RECEIPT)
    );

    const methodOfReceipt = get(methodOfReceiptMatch, 'label', NOT_SPECIFIED);

    const masterCaseProductOptions = get(
      tacticalData,
      'document-data.mastercase-product-options'
    );

    const masterCaseProductMatch = find(
      masterCaseProductOptions,
      ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.PRODUCT_NAME)
    );

    const masterCaseProduct = get(
      masterCaseProductMatch,
      'label',
      NOT_SPECIFIED
    );

    const seriousnessOptions = SERIOUSNESS_OPTIONS.options;

    const seriousnessOptionsMatch = find(
      seriousnessOptions,
      ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.SERIOUSNESS)
    );

    const seriousness = get(seriousnessOptionsMatch, 'label', NOT_SPECIFIED);

    const dateOutputAE = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_AE)
    );

    const dateOutputPQ = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_PQ)
    );

    const abbvieAwarenessAE = this.hasStringData(dateOutputAE)
      ? dateOutputAE
      : NOT_SPECIFIED;

    const abbvieAwarenessPQ = this.hasStringData(dateOutputPQ)
      ? dateOutputPQ
      : NOT_SPECIFIED;

    const affiliateAwarenessDate = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.SUMMARY_AFFILIATE_AWARENESS_DATE)
    );

    const dateAffiliateAwarenessDate = this.hasStringData(
      affiliateAwarenessDate
    )
      ? affiliateAwarenessDate
      : NOT_SPECIFIED;

    const trilogyLoadDate = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.TRILOGYLOADDATE)
    );

    const datetrilogyLoadDate = this.hasStringData(trilogyLoadDate)
      ? trilogyLoadDate
      : NOT_SPECIFIED;

    const typeofContactOptions = get(
      tacticalData,
      'document-data.mastercase-options.type_of_contact',
      []
    );
    const typeofContactMatch = find(
      typeofContactOptions,
      ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.CONTACT_TYPE)
    );

    const typeofContact = get(typeofContactMatch, 'label', NOT_SPECIFIED);

    const serviceProviderOptions = get(
      tacticalData,
      'document-data.psp-options.psp_name',
      []
    );
    const serviceProviderMatch = find(
      serviceProviderOptions,
      ({ value }) =>
        value === getOrElse(row, MASTER_STATE_PATHS.SERVICE_PROVIDER)
    );

    const serviceProvider = get(serviceProviderMatch, 'label', NOT_SPECIFIED);

    const programIDNameOptions = get(
      tacticalData,
      'document-data.psp-options.psp_opi_number',
      []
    );
    const programIDNameMatch = find(
      programIDNameOptions,
      ({ value }) =>
        value === getOrElse(row, MASTER_STATE_PATHS.PROGRAM_ID_NAME)
    );

    const programIDName = get(programIDNameMatch, 'label', NOT_SPECIFIED);

    let thirdPartyPatientID = getOrElse(
      row,
      MASTER_STATE_PATHS.PATIENT_REPORTER
    );
    thirdPartyPatientID = thirdPartyPatientID || NOT_SPECIFIED;

    let programMarketedName = getOrElse(
      row,
      MASTER_STATE_PATHS.PROGRAM_MARKETED_NAME
    );
    programMarketedName = programMarketedName || NOT_SPECIFIED;

    let patientDOB = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.PATIENT_DOB)
    );
    patientDOB = patientDOB || NOT_SPECIFIED;

    let patientInitials = getOrElse(row, MASTER_STATE_PATHS.PATIENT_INITIALS);
    patientInitials = patientInitials || NOT_SPECIFIED;

    let contactReferenceNumber = getOrElse(
      row,
      MASTER_STATE_PATHS.CONTACT_REFERENCE_NUMBER
    );
    contactReferenceNumber = contactReferenceNumber || NOT_SPECIFIED;

    const dueDate = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.DUEDATE)
    );

    const datedueDate = this.hasStringData(dueDate) ? dueDate : NOT_SPECIFIED;

    const highImpact = capitalize(get(row, MASTER_STATE_PATHS.HIGH_IMPACT, ''));

    let pQOwner = getOrElse(row, MASTER_STATE_PATHS.PQ_OWNER);
    pQOwner = pQOwner || NOT_SPECIFIED;

    let replacementRequired = getOrElse(
      row,
      MASTER_STATE_PATHS.REPLACEMENT_REQUIRED
    );
    replacementRequired = replacementRequired || NOT_SPECIFIED;

    let sampleAvailable = getOrElse(row, MASTER_STATE_PATHS.SAMPLE_AVAILABLE);
    sampleAvailable = sampleAvailable || NOT_SPECIFIED;

    let sampleRequiredforInvestigation = getOrElse(
      row,
      MASTER_STATE_PATHS.SAMPLE_REQUIRED_FOR_INVESTIGATION
    );
    sampleRequiredforInvestigation =
      sampleRequiredforInvestigation || NOT_SPECIFIED;

    let death = getOrElse(row, MASTER_STATE_PATHS.DEATH);
    death = death || NOT_SPECIFIED;

    let owner = getOrElse(row, MASTER_STATE_PATHS.OWNER);
    owner = owner || NOT_SPECIFIED;

    const aeSeriousOptionsMatch = find(
      seriousnessOptions,
      ({ value }) =>
        value ===
        getOrElse(row, MASTER_STATE_PATHS.ADVERSEEVENT_SAFETY_SERIOUSNESS)
    );

    const aeSerious = get(aeSeriousOptionsMatch, 'label', NOT_SPECIFIED);

    // let aeSerious = getOrElse(
    //   row,
    //   MASTER_STATE_PATHS.ADVERSEEVENT_SAFETY_SERIOUSNESS
    // );
    // aeSerious = aeSerious || NOT_SPECIFIED;

    let miStatus = getOrElse(row, MASTER_STATE_PATHS.MISTATUS);
    miStatus = miStatus || NOT_SPECIFIED;

    let batchLotNumber = getOrElse(row, MASTER_STATE_PATHS.BATCH_LOT_NUMBER);
    batchLotNumber = batchLotNumber || NOT_SPECIFIED;

    let reactions = getOrElse(row, MASTER_STATE_PATHS.REACTIONS);
    reactions = reactions || NOT_SPECIFIED;

    const automationStatus = getOrElse(
      row,
      MASTER_STATE_PATHS.AUTOMATION_STATUS,
      NOT_SPECIFIED
    );

    const productType = formatProductTypes(
      getOrElse(row, MASTER_STATE_PATHS.PRODUCT_TYPE, NOT_SPECIFIED)
    );

    switch (column.label) {
      case 'Case Information':
        return (
          <td>
            <Link
              to={caseLink}
              className={computedStyles.masterCaseId}
              target="_blank"
            >
              {row.masterCaseId}
            </Link>
            <span className={computedStyles.subcaseList}>
              {getSubcaseList(row, subcases => subcases.join(', '))}
            </span>
            <DescriptionLink row={row} />
          </td>
        );
      case 'Country of Reporter':
        return <td>{row.countryOfPrimaryReporterLabel}</td>;
      case 'Method of Receipt':
        return <td>{methodOfReceipt}</td>;
      case 'Abbvie Awareness (AE)':
        return <td>{abbvieAwarenessAE}</td>;
      case 'Abbvie Awareness (PQ)':
        return <td>{abbvieAwarenessPQ}</td>;
      case 'Status':
        if (column.sortpath === 'subcases.medicalInfo.status') {
          return <td>{miStatus}</td>;
        }
        return <td>{STATUSES_MAP[get(row, 'status', 'NEW')]}</td>;
      case 'Actions':
        return <td>{this.renderMCActions()}</td>;
      case 'Affiliate':
        return <td>{row.affiliate}</td>;
      case 'Current Revision':
        return <td>{row.currentRevision}</td>;
      case 'Archived':
        return <td>{row.archived}</td>;
      case 'Last Updated User Id':
        return <td>{row.lastUpdatedUserId}</td>;
      case 'Last Updated Username':
        return <td>{row.lastUpdatedUsername}</td>;
      case 'Subcase Creator UserName':
        return <td>{row.subcaseCreatorUserName}</td>;
      case 'Case Creator':
        return <td>{row.creatorUsername || NOT_SPECIFIED}</td>;
      case 'Subcase Creator Id':
        return <td>{row.subcaseCreatorid}</td>;
      case 'Affiliate Awareness Date':
        return <td>{dateAffiliateAwarenessDate}</td>;
      case 'Seriousness':
        return <td>{seriousness}</td>;
      case 'Master Case Product':
      case 'Product':
        return <td>{masterCaseProduct}</td>;
      case 'Trilogy Load Date':
        return <td>{datetrilogyLoadDate}</td>;
      case 'Type of Contact':
        return <td>{typeofContact}</td>;
      case 'Third Party Patient ID':
        return <td>{thirdPartyPatientID}</td>;
      case 'Service Provider':
        return <td>{serviceProvider}</td>;
      case 'Program ID/Name':
        return <td>{programIDName}</td>;
      case 'Product Marketed Name':
        return <td>{programMarketedName}</td>;
      case 'Date of Birth':
        return <td>{patientDOB}</td>;
      case 'Initials':
        return <td>{patientInitials}</td>;
      case 'Contact Reference Number':
        return <td>{contactReferenceNumber}</td>;
      case 'Due Date':
        return <td>{datedueDate}</td>;
      case 'High Impact':
        return <td>{highImpact}</td>;
      case 'PQ Owner':
        return <td>{pQOwner}</td>;
      case 'Replacement Required':
        return <td>{replacementRequired}</td>;
      case 'Sample Available':
        return <td>{sampleAvailable}</td>;
      case 'Sample Required for Investigation':
        return <td>{sampleRequiredforInvestigation}</td>;
      case 'Death':
        return <td>{death.toString()}</td>;
      case 'AE Serious':
        return <td>{aeSerious}</td>;
      case 'Batch/Lot Number':
        return <td>{batchLotNumber}</td>;
      case 'Date file Loaded to Trilogy':
      case 'Reactions':
        return <td>{reactions} </td>;
      case 'Owner':
        return <td>{owner}</td>;
      case 'Automated Process':
        return <td>{automationStatus}</td>;
      case 'AGN/ABV':
        return <td>{productType}</td>;

      default:
        return <td>{row.sortPath ? row.sortPath : NOT_SPECIFIED} </td>;
    }
  }

  render() {
    const { row, selectedItemsToAssignOrArchive } = this.props;

    return (
      <TableRow id="queue-mc-table-row">
        <td>
          <Checkbox
            onChange={evt => this.props.handleSelectCaseOrTask(row.id, evt)}
            value={selectedItemsToAssignOrArchive.includes(row.id)}
          />
        </td>

        {(this.props.userDesiredColumns || []).map(column =>
          this.renderCell(column)
        )}
      </TableRow>
    );
  }
}

export default MasterCaseRow;
