import React, { PureComponent } from 'react';
import { isNil } from 'lodash';

import { Position, Toaster, Intent } from '@blueprintjs/core';

import styles from './styles';

class Notifier extends PureComponent {
  static pendingNotifications = [];
  static activeNotifications = {};

  static SUCCESS = Intent.SUCCESS;
  static DANGER = Intent.DANGER;
  static PRIMARY = Intent.PRIMARY;

  static toaster = null;

  static clear = () => {
    if (!Notifier.toaster) return;
    Notifier.toaster.clear();
  };

  static show = ({ intent, ...messageOptions }) => {
    if (!Notifier.toaster) {
      const len = Notifier.pendingNotifications.length;
      const key = `toast-${len}`;
      Notifier.pendingNotifications.push(key);
      return key;
    }

    const key = Notifier.toaster.show({
      intent,
      timeout: 5000,
      ...messageOptions,
      onDismiss: fromTimeout => {
        // If message provided `onDismiss` call it
        if (messageOptions.onDismiss) messageOptions.onDismiss(fromTimeout);
        // remove notification key from active notifications
        Notifier.activeNotifications[intent] = null;
      },
      className: `${messageOptions.className || ''} ${styles.toast}`
    });

    if (!isNil(intent) && Notifier.activeNotifications[intent]) {
      Notifier.toaster.dismiss(Notifier.activeNotifications[intent]);
    }

    Notifier.activeNotifications[intent] = key;
    return key;
  };

  static update = (key, messageOptions) => {
    const i = Notifier.pendingNotifications.indexOf(key);

    if (!Notifier.toaster) {
      Notifier.toaster = Toaster.create({
        position: Position.TOP_CENTER,
        className: `${styles.base}`
      });
    }

    if (i >= 0) {
      Notifier.pendingNotifications[i] = {
        ...Notifier.pendingNotifications[i],
        ...messageOptions
      };

      return `toast-${i || 0}`;
    } else if (i >= 0) {
      const options = Notifier.pendingNotifications.splice(i, 1);
      return Notifier.show(options[0]);
    } else if (isNil(key)) {
      return Notifier.show(messageOptions);
    }

    return Notifier.toaster.update(key, {
      timeout: 5000,
      ...messageOptions,
      className: `${messageOptions.className || ''} ${styles.toast}`
    });
  };

  componentDidMount() {
    if (!Notifier.toaster) {
      Notifier.toaster = Toaster.create({
        position: Position.TOP_CENTER,
        className: `${styles.base}`
      });
    }

    while (Notifier.pendingNotifications.length > 0) {
      Notifier.show(Notifier.pendingNotifications.pop());
    }
  }

  render = () => <div id="notifier" />;
}

export default Notifier;
