import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SEARCH_BASE_PATH } from 'Search/constants';
import { LEGACY_SEARCH_URL } from 'config/values';

import { SimpleButton, withStyles } from 'Common/components/Form';
import { isNil, get } from 'lodash';
import stylesGenerator from './styles';

class SearchBar extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      searchBox: PropTypes.object.isRequired,
      searchIcon: PropTypes.object.isRequired,
      filterButton: PropTypes.object.isRequired,
      searchButton: PropTypes.object.isRequired
    }).isRequired,
    children: PropTypes.node,
    hideFiltersText: PropTypes.string,
    showFiltersText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    searchInput: PropTypes.node,
    stickyFilters: PropTypes.node,
    onToggleFilters: PropTypes.func,
    showFilters: PropTypes.bool,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired
    }).isRequired,
    renderCopyContactsButton: PropTypes.func
  };

  static defaultProps = {
    children: null,
    hideFiltersText: 'Hide Filters',
    showFiltersText: 'Show Filters',
    onToggleFilters: () => {},
    searchInput: null,
    stickyFilters: null,
    showFilters: false,
    renderCopyContactsButton: () => {}
  };

  toggleShowFilter = e => {
    const { onToggleFilters, showFilters } = this.props;
    e.preventDefault();
    onToggleFilters(!showFilters);
  };

  showLegacySearch = () => window.open(LEGACY_SEARCH_URL);

  handleSearch = e => {
    e.preventDefault();
    this.props.onSubmit();
  };

  renderFilterSection() {
    const { children, computedStyles, renderCopyContactsButton } = this.props;

    return (
      <div>
        {children}
        <div className={`${computedStyles.searchButton} container`}>
          <SimpleButton
            onClick={this.handleSearch}
            $id="submitFilterSearch"
            primary
            type="submit"
            width="200px"
          >
            SEARCH
          </SimpleButton>
          {renderCopyContactsButton()}
        </div>
      </div>
    );
  }

  render() {
    const {
      computedStyles,
      children,
      hideFiltersText,
      showFiltersText,
      searchInput,
      stickyFilters,
      showFilters,
      match
    } = this.props;

    const renderAELegacySearchButton = () => {
      if (isNil(get(match, 'path')) || match.path !== SEARCH_BASE_PATH)
        return null;

      return (
        <SimpleButton
          type="button"
          $id="dupeSearch"
          onClick={this.showLegacySearch}
        >
          AE Legacy Search
        </SimpleButton>
      );
    };

    return (
      <form id="searchBarForm" onSubmit={this.handleSearch}>
        {searchInput}
        {stickyFilters}
        {children ? (
          <div className={computedStyles.filterButton}>
            <SimpleButton
              type="button"
              $id="showFilters"
              onClick={this.toggleShowFilter}
            >
              {showFilters ? hideFiltersText : showFiltersText}
            </SimpleButton>
            {renderAELegacySearchButton()}
          </div>
        ) : null}
        {children && showFilters ? this.renderFilterSection() : null}
      </form>
    );
  }
}

export default withStyles(stylesGenerator)(SearchBar);
