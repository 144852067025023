import { generateCSS } from 'Common/components/Form';

export const modalStyles = {
  base: generateCSS({
    minWidth: '100px'
  }),

  title: generateCSS({
    margin: '10px 0 15px',
    display: 'block',
    font: '16px SackersGothicStd-Heavy',
    color: '#071D49',
    fontWeight: '900',
    textTransform: 'uppercase'
  }),

  buttonsContainer: generateCSS({
    display: 'block',
    margin: '20px 0',
    padding: '15px',
    width: '100%',
    left: '0',
    bottom: '0',
    '> button:nth-child(1)': {
      float: 'left'
    },
    '> button:nth-child(2)': {
      float: 'right'
    }
  }),

  buttonsContainerSingle: generateCSS({
    display: 'block',
    margin: '20px 0',
    padding: '15px',
    width: '100%',
    left: '0',
    bottom: '0',
    '> button:nth-child(1)': {
      float: 'right'
    }
  }),

  tall: generateCSS({
    minHeight: '337px',
    ' > div': {
      marginTop: '20px'
    }
  })
};

/*
 * Note that the frosted overlay effect is achieved by NavigationController
 * setting the .blur selector on all child elements not contained within .modal
 */
export const stylesGenerator = theme => ({
  overlay: shouldRender =>
    generateCSS({
      opacity: shouldRender ? '100' : '0',
      pointerEvents: shouldRender ? 'all' : 'none',
      display: 'flex',
      zIndex: '20',
      position: 'fixed',
      justifyContent: 'center',
      alignItems: 'center',
      left: '0',
      top: '0',
      width: '100vw',
      height: '100vh',
      background: 'rgba(51, 51, 51, 0.5)',
      transition: 'all 500ms ease',

      ' > *': {
        opacity: shouldRender ? 1 : 0
      }
    }),

  modal: generateCSS({
    background: theme.colors.base,
    position: 'relative',
    overflow: 'auto',
    zIndex: '20',
    maxHeight: '90vh'
  }),

  modalWithOverflow: generateCSS({
    background: theme.colors.base,
    position: 'relative',
    zIndex: '20'
  }),

  modalContent: generateCSS({
    alignSelf: 'center',
    padding: '20px',
    color: theme.colors.senary,
    backgroundColor: theme.colors.base
  }),

  close: generateCSS({
    alignSelf: 'flex-start',
    cursor: 'pointer',
    margin: '0',
    padding: '0',
    position: 'absolute',
    top: 30,
    right: 15,
    border: 'none',
    color: theme.colors.secondary,
    background: theme.colors.base
  })
});
