import gql from 'graphql-tag';

const getCaseByRevisionQuery = caseFragment => {
  const fragment = gql`
    ${caseFragment}
  `;

  return gql`
    query case($id: String!, $revision: Long!) {
      case(id: $id, revision: $revision) {
        ...FullCaseWithSubcases
      }
    }
    ${fragment}
  `;
};

export default getCaseByRevisionQuery;
