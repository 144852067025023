import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, filter } from 'lodash';
import moment from 'moment';
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core';
import { DateRangePicker as BluePrintDateRangePicker } from '@blueprintjs/datetime';
import TextInput from '../TextInput';

import { API_DATE_FORMAT } from '../constants';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import ValidationErrors from '../ValidationErrors';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';
import { calculateMinDate, calculateMaxDate, parseDateString } from '../utils';

class DateRangeInput extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    onBlur: PropTypes.func,
    value: PropTypes.arrayOf(Date)
  };

  static defaultProps = {
    ...formElementDefaultProps,
    layout: { width: '100%' },
    styles: { width: '100%' },
    value: [null, null]
  };

  state = {
    datePickerOpen: false,
    dateRange: this.props.value
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      dateParts: parseDateString(nextProps.value || '')
    });
  }

  buildDateRangeString = dateRange => {
    const startDate = dateRange[0];
    const endDate = dateRange[1];

    if (!startDate && !endDate) return '';
    if (startDate && endDate) {
      return `${moment(startDate).format(API_DATE_FORMAT)} to ${moment(
        endDate
      ).format(API_DATE_FORMAT)}`;
    }
    return startDate
      ? moment(startDate).format(API_DATE_FORMAT)
      : moment(endDate).format(API_DATE_FORMAT);
  };

  handleChange = dateRange => {
    const { onChange } = this.props;

    this.setState({
      dateRange,
      inputText: this.buildDateRangeString(dateRange)
    });

    // Remove null dates in date range array
    onChange(
      filter(dateRange, i => i).map(d => moment(d).format(API_DATE_FORMAT))
    );
  };

  handlePickerChange = dateRange => {
    this.setState({
      datePickerOpen: dateRange[0] === null || dateRange[1] === null
    });
    this.handleChange(dateRange);
  };

  handlePopoverInteraction = nextOpenState =>
    this.setState({ datePickerOpen: nextOpenState });

  focus = () => this.component.focus();

  render() {
    const {
      $id,
      schemaPath,
      computedStyles,
      id,
      config,
      helpText,
      validations,
      errors,
      disabled
    } = this.props;
    const { datePickerOpen, dateRange } = this.state;

    const restriction = get(validations, 'dateRestriction.constraint');
    const minDate = calculateMinDate(
      restriction === 'past' || restriction === 'both'
    ).toDate();
    const maxDate = calculateMaxDate(
      restriction === 'future' || restriction === 'both'
    ).toDate();

    const popoverProps = {
      isOpen: datePickerOpen,
      onInteraction: nextOpenState =>
        this.handlePopoverInteraction(nextOpenState),
      disabled,
      interactionKind: PopoverInteractionKind.CLICK,
      position: Position.BOTTOM_RIGHT,
      inline: false // keeps IE happy
    };

    const datePickerProps = {
      value: dateRange,
      disabled,
      className: computedStyles.base,
      minDate,
      maxDate,
      contiguousCalendarMonths: false,
      highlightCurrentDay: true,
      shortcuts: false,
      locale: config.locale || window.navigator.language || 'en-US',
      isDisabled: disabled, // May not work in Blueprint 2.0
      onChange: this.handlePickerChange
    };

    const inputProps = {
      ...this.props,
      disabled: true,
      value: this.buildDateRangeString(dateRange)
    };

    return (
      <div
        className={computedStyles.base}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        <div className={computedStyles.inputRow}>
          <TextInput {...inputProps} />
          <div className={computedStyles.popoverWrapper}>
            <Popover {...popoverProps}>
              <div
                id={`${id}-popover-target`}
                role="button"
                aria-disabled={disabled}
                className={computedStyles.datePickerControl}
              />
              <BluePrintDateRangePicker {...datePickerProps} />
            </Popover>
          </div>
        </div>

        <span className={computedStyles.helpText}>{helpText}</span>
        <ValidationErrors
          id={id}
          computedStyles={computedStyles}
          validationErrors={errors}
        />
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(DateRangeInput);
