import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    color: theme.colors.header,
    padding: '34px 34px 8px'
  }),

  title: generateCSS({
    display: 'inline-block',
    margin: '10px 0 15px',
    font: '30px SackersGothicStd-Medium',
    fontWeight: '900',
    textTransform: 'uppercase'
  }),

  row: generateCSS({
    width: '100%'
  }),

  search: generateCSS({
    display: 'inline-block',
    verticalAlign: 'top',
    float: 'right'
  }),

  filterRow: generateCSS({
    width: '100%',
    display: 'flex'
  }),

  filterColumn1: generateCSS({
    width: '250px',
    marginRight: '20px'
  }),

  filterColumn2: generateCSS({
    width: 'auto'
  }),

  label: generateCSS({
    color: theme.colors.senary,
    fontFamily: 'Calibri',
    fontSize: '16px',
    fontWeight: '200'
  }),

  actionMenu: generateCSS({
    display: 'inline-block',
    float: 'right',
    marginTop: '20px'
  }),

  taskTypeRow: generateCSS({
    margin: '0 108px 0'
  }),

  displayFlex: generateCSS({
    display: 'flex'
  }),

  headerFilterRow: generateCSS({
    margin: '19px 0px 0px'
  }),

  searchBox: generateCSS({
    height: '30px',
    width: '250px',
    margin: '21px 0 0',
    border: 'none',
    '::placeholder': {
      padding: '0 10px',
      color: '#D1D1D1',
      fontWeight: '200',
      font: '16px Calibri'
    }
  })
});

export default stylesGenerator;
