import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  CMS_PROP_TYPES,
  FRAGMENT_NAME_CASE,
  FRAGMENT_NAME_TASK
} from 'Common/constants/index';
import withStyles from 'Common/components/Form/withStyles/index';
import stylesGenerator from 'Common/components/AuditHistory/styles';
import { generateCSS } from 'Common/components/Form/index';
import { AUDIT_PATH_CASE, AUDIT_PATH_TASK } from 'Common/constants/services';
import fetchAuditData from 'api/rest/fetchAuditData';
import html2pdf from 'html2pdf.js';
import {
  Spinner,
  NonIdealState,
  Menu,
  MenuItem,
  Popover,
  Button,
  Position,
  Toaster,
  Intent
} from '@blueprintjs/core';
import submitCaseRevisionQuery from 'api/graphql/submitCaseRevisionQuery';
import submitTasksByRevisionQuery from 'api/graphql/submitTasksByRevisionQuery';
import moment from 'moment';
import {
  find,
  forEach,
  replace,
  startCase,
  capitalize,
  flatMapDeep,
  get,
  filter,
  isNil
} from 'lodash';

export const AUDIT_TYPE_CASE = AUDIT_PATH_CASE;
export const AUDIT_TYPE_TASK = AUDIT_PATH_TASK;

class AuditHistory extends PureComponent {
  static propTypes = {
    auditType: PropTypes.string,
    trilogyCase: CMS_PROP_TYPES.trilogyCase,
    value: CMS_PROP_TYPES.value,
    pageSchema: CMS_PROP_TYPES.schema.isRequired,
    fragments: PropTypes.arrayOf(PropTypes.object).isRequired,
    tacticalData: PropTypes.shape({
      'document-data': PropTypes.shape({
        'country-options': PropTypes.arrayOf(PropTypes.object).isRequired
      })
    }).isRequired,
    computedStyles: PropTypes.shape({
      auditHistoryListItem: PropTypes.object.isRequired,
      auditGridWrapper: PropTypes.object.isRequired,
      auditHistoryListCol: PropTypes.object.isRequired,
      auditHistoryContentCol: PropTypes.object.isRequired,
      auditTable: PropTypes.object.isRequired,
      auditHistoryList: PropTypes.object.isRequired,
      auditTableHeader: PropTypes.object.isRequired,
      fieldSection: PropTypes.object.isRequired,
      auditHistoryLink: PropTypes.object.isRequired,
      auditHistoryLinkSelected: PropTypes.object.isRequired
    }).isRequired
  };

  /**
   * The number of rows that will be output in the printable report before a page break occurs.
   * @type {number}
   */
  static MAX_ROW_PER_PAGE = 32;

  static defaultProps = {
    auditType: AUDIT_TYPE_CASE,
    trilogyCase: null,
    value: null
  };

  toaster = null;
  state = { isFetching: false, isPrinting: false };

  getAuditRecordId = () => {
    const { auditType, trilogyCase, value } = this.props;
    return auditType === AUDIT_TYPE_TASK ? value.id : trilogyCase.id;
  };

  getAuditRecord = () => {
    const { auditType, trilogyCase, value } = this.props;
    return auditType === AUDIT_TYPE_TASK ? value : trilogyCase;
  };

  fetchAuditLog = async () => {
    const { auditType } = this.props;

    this.setState({ isFetching: true });

    return fetchAuditData(auditType, this.getAuditRecordId())
      .then(log => {
        this.setState({ isFetching: false });
        return log;
      })
      .catch(() => {
        this.showErrorMessage('Unable to get audit log');
        this.setState({ isFetching: false });
      });
  };

  componentDidMount() {
    this.toaster = Toaster.create({
      position: Position.TOP
    });

    // Pull the audit logs for the requested master case.
    this.fetchAuditLog().then(ad => {
      if (!isNil(ad) && ad.length > 0)
        this.setState({
          auditData: ad,
          selectedChangeSet: ad[0], // Show the first change set as default
          schemaMapping: this.buildMappingFromSchema()
        });
    });
  }

  /**
   * Uses the blueprintjs toaster component to pop up a piece of toast with an error message
   *
   * @param msg - The error message that will be displayed in the toast.
   */
  showErrorMessage = msg => {
    if (!this.toaster) {
      const container = document.getElementById('modalContent');
      this.toaster = Toaster.create({ position: Position.TOP }, container);
    }
    this.toaster.show({
      message: msg,
      intent: Intent.DANGER
    });
  };

  fetchAuditingRecord = async cs => {
    const { auditType, trilogyCase, fragments, value } = this.props;

    if (auditType === AUDIT_TYPE_CASE) {
      return submitCaseRevisionQuery(
        null,
        trilogyCase.id,
        cs.revision,
        fragments[FRAGMENT_NAME_CASE]
      );
    }

    if (auditType === AUDIT_TYPE_TASK) {
      return submitTasksByRevisionQuery(
        value.id,
        cs.revision,
        fragments[FRAGMENT_NAME_TASK]
      );
    }

    return null;
  };

  /**
   * Event handler that is invoked when the current revision is changed in the list in the left panel.
   *
   * @param cs - The change set that are part of the selected revision.
   */
  handleRevisionChanged = cs => {
    const { auditType } = this.props;
    // If there isn't an auditing record bound to the change set then go back to the service and pull it for the revision.
    if (cs.parentRecord === undefined) {
      this.setState({ isFetching: true });
      this.fetchAuditingRecord(cs)
        .then(r => {
          cs.parentRecord = r; // eslint-disable-line no-param-reassign
          this.setState({
            selectedChangeSet: cs,
            isFetching: false
          });
        })
        .catch(() => {
          if (auditType === AUDIT_TYPE_CASE)
            this.showErrorMessage('Unable to get the case revision');
          if (auditType === AUDIT_TYPE_TASK)
            this.showErrorMessage('Unable to get the task revision');
          this.setState({ isFetching: false });
        });
    } else {
      this.setState({ selectedChangeSet: cs });
    }
  };

  /**
   * Renders a loading spinner to notify that the data is currently loading.
   *
   * @returns {*}
   */
  renderLoading = () => {
    const loadingMessage = <span>Please wait while we retrieve the data.</span>;
    return (
      <div className={generateCSS({ margin: '100px auto' })}>
        <NonIdealState
          visual={<Spinner />}
          title="Loading"
          description={loadingMessage}
        />
      </div>
    );
  };

  /**
   * Renders the list of change sets in the left panel
   *
   * @returns {*}
   */
  renderChangeSetList = () => {
    const { computedStyles } = this.props;
    const { auditData, selectedChangeSet } = this.state;

    return (
      <ul className={computedStyles.auditHistoryList}>
        {auditData.map((d, idx) => {
          if (selectedChangeSet === d) {
            return (
              <li
                key={d.revision}
                className={computedStyles.auditHistoryListItem}
              >
                <a
                  role="link"
                  className={computedStyles.auditHistoryLinkSelected}
                  onClick={() => {
                    this.handleRevisionChanged(auditData[idx]);
                  }}
                >{`Revision: ${d.revision} (${this.formatDateTime(
                  d.timestamp
                )})`}</a>
              </li>
            );
          }
          return (
            <li className={computedStyles.auditHistoryListItem}>
              <a
                role="link"
                className={computedStyles.auditHistoryLink}
                onClick={() => {
                  this.handleRevisionChanged(auditData[idx]);
                }}
              >{`Revision: ${d.revision} (${this.formatDateTime(
                d.timestamp
              )})`}</a>
            </li>
          );
        })}
      </ul>
    );
  };

  /**
   * Given a specific path to a property in the trilogy change set it will convert the ISO country code to the country label.
   *
   * @param path - Path to the property in the trilogy change set of the audit log.
   * @param val - The value of the property that will be converted from ISO country code to ISO country name.
   * @returns {*}
   */
  performCountryLookup = (path, val) => {
    const countries = this.props.tacticalData['document-data'][
      'country-options'
    ];
    if (/country/gi.test(path)) {
      const cntry = find(countries, c => c.value === val);
      return cntry ? cntry.label : val;
    }
    return val;
  };

  /**
   * Formats a date object to a string in 'DD MMM YYYY HH:mm:ss' format.
   *
   * @param dt - The date object that will be formatted.
   * @returns {string}
   */
  formatDateTime = dt => moment(dt).format('DD MMM YYYY HH:mm:ss');

  /**
   * Build the state path from the parent object.  This is a helper function to build the mapping between the schema and the
   * trilogy case/task object.
   *
   * @param parent - The parent object to base the state from.
   * @param current - The path to the current property
   * @param title - The title of the current property in the schema.
   * @returns {{screenLabel: *}}
   */
  createStatePath = (parent, current, title) => {
    const newPath = { screenLabel: title };

    // If the title was not provided then build it from the current state Path
    if ((title === undefined || title === '') && current !== undefined) {
      newPath.screenLabel = startCase(
        current.substring(current.lastIndexOf('.') + 1)
      );
    }
    if (parent === undefined) {
      newPath.statePath = current;
    } else {
      newPath.statePath = `${parent}.${current}`;
    }
    return newPath;
  };

  /**
   * Reads the pageSchema from the props object and builds a mapping between the schema and the trilogy case/task object.
   *
   * The mapping will later be used to determine what the application name is for each property in the report.
   *
   * @returns {Array}
   */
  buildMappingFromSchema = () => {
    const { pageSchema, auditType } = this.props;

    if (pageSchema === undefined) return [];
    const mappings = [];

    const enumerateElements = (sp, elmnts) => {
      forEach(elmnts, el => {
        const elemPath = this.createStatePath(
          sp.statePath,
          el.statePath,
          el.label
        );
        mappings.push(elemPath);
        enumerateElements(elemPath, el.elements); // Recursively go for the child elements.
      });
    };

    if (auditType === AUDIT_TYPE_CASE) {
      forEach(pageSchema.tabs, t => {
        forEach(t.sections, s => {
          const secPath = this.createStatePath(undefined, s.statePath, s.title);
          mappings.push(secPath);
          enumerateElements(secPath, s.elements);
        });
      });
    }

    if (auditType === AUDIT_TYPE_TASK)
      enumerateElements(pageSchema.statePath, pageSchema.elements);

    return filter(mappings, i => !isNil(i.statePath));
  };

  /**
   * Helper function to clean up and extra characters in the state path.
   *
   * @param path - The path string that will be cleaned up.
   * @returns {*}
   */
  cleanPathString = path => {
    let strippedPath = replace(path, /\[\d+\]/g, '');
    strippedPath = replace(strippedPath, /^\//, '');
    strippedPath = replace(strippedPath, /\/-$/, '');
    strippedPath = replace(strippedPath, /\//g, '.');
    return strippedPath;
  };

  /**
   * Enumerate all the change set items.
   *
   * @param auditItem - The parent audit item that all the change sets items are a member of.
   * @param cs - The change set to enumerate
   * @returns {*} - An array object that will be output in an html table in the render operation of this component.
   */
  enumChangeSet = (auditItem, cs) => {
    // Determine what the name is of the field based on the property name and path.
    const getFieldName = ({ path, propName }) => {
      let hit = find(
        this.state.schemaMapping,
        item => item.statePath === this.cleanPathString(path)
      );

      // Try to strip off the last token after the '/' character.
      if (hit === undefined) {
        const tempName = startCase(path.substring(path.lastIndexOf('/') + 1));
        if (tempName !== '') hit = { screenLabel: tempName };
      }
      return hit !== undefined ? hit.screenLabel : startCase(propName);
    };

    // Read the main section of the change set item.  This operation section is the starting point of the changeset.
    const readOperationSection = itm => {
      const op = {
        level: 1, // Always will be at the top of the hierarchy.
        fieldName: getFieldName({ path: itm.path, propName: itm.path }),
        fieldAction: capitalize(itm.op),
        isObject: itm.value && typeof itm.value === 'object'
      };

      if (itm.op === 'copy') {
        itm.value = get(this.getAuditRecord(), this.cleanPathString(itm.from)); // eslint-disable-line no-param-reassign
      }

      // Check to ensure there is a value property and that the property is not an object.  If so then set it
      // to the resulting object.
      if (itm.value !== null && itm.value !== undefined && !op.isObject) {
        op.fieldValue = this.performCountryLookup(
          itm.path,
          itm.value.toString()
        );
      }
      return op;
    };

    // Read the change set item if the value is an object (not array).
    const readObjectProperty = (itm, csi, lvl) => {
      if (typeof itm !== 'object' || itm === null) return [];

      const buildTableField = (prop, fldLvl = lvl) => {
        const newPath = `${csi.path}/${prop}`;
        return {
          level: fldLvl,
          fieldAction: capitalize(csi.op),
          fieldName: getFieldName({
            path: newPath,
            propName: prop
          }),
          isObject: typeof itm[prop] === 'object'
        };
      };

      // Read the change set item if the value is an array.
      const readObjectArrayProperty = arrProp => {
        if (typeof arrProp !== 'object' || !Array.isArray(arrProp)) return [];

        return flatMapDeep(
          arrProp,
          ai =>
            typeof ai === 'object'
              ? readObjectProperty(ai, csi, lvl)
              : { ...buildTableField(csi.path, lvl + 1), fieldValue: ai }
        );
      };

      if (Array.isArray(itm)) {
        return readObjectArrayProperty(itm);
      }

      lvl += 1; // eslint-disable-line no-param-reassign
      return Object.getOwnPropertyNames(itm).map(prop => {
        const newPath = `${csi.path}/${prop}`;
        const tableField = buildTableField(prop);
        if (typeof itm[prop] === 'object') {
          if (Array.isArray(itm[prop])) {
            return [[tableField], ...readObjectArrayProperty(itm[prop])];
          }
          return [[tableField], ...readObjectProperty(itm[prop], csi, lvl)];
        }
        return {
          ...tableField,
          ...{
            fieldValue: this.performCountryLookup(newPath, itm[prop].toString())
          }
        };
      });
    };

    // Evaluate the change set item.
    const evalChangeSetItem = csi => [
      readOperationSection(csi),
      ...readObjectProperty(csi.value, csi, 1)
    ];

    // The results could be a jagged/nested array.  Therefore flatten the results.
    return flatMapDeep(cs, csi => evalChangeSetItem(csi));
  };

  /**
   * Renders the change details table.  This is the first table that is output in the right-hand panel
   *
   * @param cs - The change set to render.
   * @returns {*}
   */
  renderChangeHeader = cs => {
    const { computedStyles, auditType } = this.props;

    return (
      <div>
        <h4>Revision Details</h4>
        <table className={computedStyles.auditTable} cellSpacing={0}>
          <thead>
            <tr>
              <th className={computedStyles.auditTableHeader}>
                {auditType === AUDIT_TYPE_CASE && 'Master Case ID'}
                {auditType === AUDIT_TYPE_TASK && 'Task ID'}
              </th>
              <th className={computedStyles.auditTableHeader}>Revision</th>
              <th className={computedStyles.auditTableHeader}>User Name</th>
              <th className={computedStyles.auditTableHeader}>User ID</th>
              <th className={computedStyles.auditTableHeader}>
                Date of Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{cs.logEntryId}</td>
              <td>{cs.revision}</td>
              <td>{cs.userName}</td>
              <td>{cs.userId}</td>
              <td>{this.formatDateTime(cs.timestamp)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  /**
   * Renders the fields table.  This will handle both screen rendered and printable output.
   *
   * @param cs - The change set that will be rendered
   * @param printable - Flag that determines whether to output the table in printable format or not.
   * @returns {*}
   */
  renderChangeFieldsTable = (cs, printable = false) => {
    const { computedStyles } = this.props;
    const indent = 15;

    const rows = this.enumChangeSet(cs, cs.changeSet);
    const tables = [];

    // Generate the array of tables depending on whether or not the table is printable.
    if (printable) {
      let table = [];
      forEach(rows, (r, idx) => {
        if (idx % AuditHistory.MAX_ROW_PER_PAGE === 0) {
          table = [r];
          tables.push(table);
        } else {
          table.push(r);
        }
      });
    } else {
      tables.push(rows);
    }

    return (
      <div className={generateCSS({ marginTop: '50px' })}>
        {tables.map((t, idx) => (
          <div>
            {idx === 0 ? (
              <h4>Field Information</h4>
            ) : (
              <h4>Field Information Cont.</h4>
            )}
            <table className={computedStyles.auditTable} cellSpacing={0}>
              <thead>
                <tr>
                  <th className={computedStyles.auditTableHeader}>
                    Field Name
                  </th>
                  <th className={computedStyles.auditTableHeader}>Value</th>
                  <th className={computedStyles.auditTableHeader}>Action</th>
                </tr>
              </thead>
              <tbody>
                {t.map(r => {
                  if (r.isObject) {
                    return (
                      <tr>
                        <td
                          className={computedStyles.fieldSection}
                          style={{
                            paddingLeft: `${(r.level - 1) * indent}px`
                          }}
                        >
                          {r.fieldName}
                        </td>
                        <td>{r.fieldValue}</td>
                        <td>{r.fieldAction}</td>
                      </tr>
                    );
                  }
                  return (
                    <tr>
                      <td
                        className={generateCSS({
                          paddingLeft: `${(r.level - 1) * indent}px`
                        })}
                      >
                        {r.fieldName}
                      </td>
                      <td>{r.fieldValue}</td>
                      <td>{r.fieldAction}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {printable &&
              idx + 1 < tables.length && (
                <div className="html2pdf__page-break" />
              )}
          </div>
        ))}
      </div>
    );
  };

  /**
   * Leverages the html2pdf.js library to output the contents of a div in the DOM as a pdf report.
   *
   * @param selectedOnly - Flag to specify whether to output the selected revision or all revisions in the output pdf report.
   */
  generatePdfOutput = selectedOnly => {
    const { auditData, selectedChangeSet } = this.state;
    const { auditType } = this.props;
    const tasks = [];
    this.setState({ isPrinting: true });

    // Make sure all the trilogy cases are pulled in for each change set.
    let cases = [selectedChangeSet];
    if (!selectedOnly) {
      cases = [auditData];
    }

    // Build the list of task that must be completed before the report can be generated.
    cases.map(cs => {
      if (isNil(cs.parentRecord)) {
        // Add a task to pull the case revision from the backend.
        tasks.push(
          this.fetchAuditingRecord(cs).then(r => (cs.parentRecord = r)) // eslint-disable-line no-param-reassign
        );
      }
      return null;
    });

    // Wait for all the tasks to complete before settings the state.
    Promise.all(tasks)
      .then(() => {
        // Set the state to determine if all revisions should be printed or not.  Since setState is async, a callback function
        // is provided to ensure the form isn't printed before the setState operation is complete.
        this.setState({ printAll: !selectedOnly }, () => {
          const output = document.getElementById('pdfExportContainer');
          const auditRecordId = this.getAuditRecordId();

          // PDF report output options.
          const opts = {
            margin: [1.25, 0.5, 1, 0.5],
            filename: `Audit History ${capitalize(
              auditType
            )} (${auditRecordId}).pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
          };

          // Physically render the pdf document.
          html2pdf()
            .from(output)
            .set(opts)
            .toPdf()
            .get('pdf')
            .then(pdfObject => {
              // Add the header and footer sections for each page in the pdf object.
              const pages = pdfObject.internal.pages;

              for (let i = 1; i < pages.length; i += 1) {
                pdfObject.setPage(i);

                /** ** Header Section *** */
                pdfObject.setFont('times', 'normal');

                // Build the report title
                const title = `${capitalize(auditType)} Audit History`;

                // Write the page header
                pdfObject.setFont('times', 'bold');
                pdfObject.setFontSize(12);
                pdfObject.text(title, 0.5, 0.5);
                pdfObject.setFont('times', 'normal');
                pdfObject.setFontSize(10);

                if (auditType === AUDIT_TYPE_CASE)
                  pdfObject.text(`Master Case ID: ${auditRecordId}`, 6, 0.5);
                if (auditType === AUDIT_TYPE_TASK)
                  pdfObject.text(`Task ID: ${auditRecordId}`, 6, 0.5);

                const scId = get(
                  selectedChangeSet,
                  'trilogyCase.subcases.adverseEvent.id'
                );
                if (scId) pdfObject.text(`Subcase ID: ${scId}`, 6, 0.7);

                /** ** Footer Section *** */
                pdfObject.setFont('times', 'italic');
                pdfObject.setFontSize(10);
                pdfObject.text(
                  'The  information contained in this report is CONFIDENTIAL to AbbVie Inc., its subsidiaries or affiliates',
                  0.5,
                  10.75
                );
                pdfObject.text(
                  `Page ${i} of ${pdfObject.internal.getNumberOfPages()}`,
                  4,
                  10.5
                );
                pdfObject.text(
                  moment()
                    .format('DD-MMM-YYYY')
                    .toString(),
                  7.25,
                  10.5
                );
              }
            })
            .save()
            .finally(() => this.setState({ isPrinting: false }));
        });
      })
      .catch(() => {
        this.showErrorMessage(
          'Unable to generate the pdf report due to an error'
        );
        this.setState({ isPrinting: false });
      });
  };

  /**
   * Renders a hidden container that will be used to output the report table to later be used for PDF printing.
   * @returns {*}
   */
  renderPrintContainer = () => {
    const { auditData, selectedChangeSet } = this.state;
    if (this.state.printAll === true) {
      // Iterate through all the change sets and add appropriate page breaks.
      return (
        <div id="pdfExportContainer">
          {auditData.map((cs, idx) => (
            <div>
              {this.renderChangeHeader(cs)}
              {this.renderChangeFieldsTable(cs, true)}
              {idx !== auditData.length - 1 && (
                <div className="html2pdf__page-break" />
              )}
            </div>
          ))}
        </div>
      );
    }
    return (
      <div id="pdfExportContainer">
        {this.renderChangeHeader(selectedChangeSet)}
        {this.renderChangeFieldsTable(selectedChangeSet, true)}
      </div>
    );
  };

  render() {
    const { computedStyles } = this.props;
    const { auditData, selectedChangeSet, isPrinting } = this.state;

    const renderPrintButton = () => (
      <div className={generateCSS({ float: 'right', cursor: 'pointer' })}>
        <Popover
          inline
          content={
            <Menu>
              <MenuItem
                onClick={() => this.generatePdfOutput(true)}
                text="Selected revision"
              />
              <MenuItem
                onClick={() => this.generatePdfOutput(false)}
                text="All revisions"
              />
            </Menu>
          }
          position={Position.LEFT}
        >
          <Button
            title="Click to print pdf report"
            iconName="pt-icon-print"
            loading={isPrinting}
          />
        </Popover>
      </div>
    );

    if (this.state.isFetching && !auditData) {
      return (
        <div id="modalContent" className={generateCSS({ width: '80vw' })}>
          {this.renderLoading()}
        </div>
      );
    }

    if (this.state.isFetching && auditData && auditData.length > 0) {
      return (
        <div id="modalContent" className={computedStyles.auditGridWrapper}>
          <div className={computedStyles.auditHistoryListCol}>
            {this.renderChangeSetList()}
          </div>
          <div className={computedStyles.auditHistoryContentCol}>
            {this.renderLoading()}
          </div>
        </div>
      );
    }

    if (!this.state.isFetching && auditData && auditData.length > 0) {
      return (
        <div id="modalContent" className={computedStyles.auditGridWrapper}>
          <div className={computedStyles.auditHistoryListCol}>
            {this.renderChangeSetList()}
          </div>
          <div className={computedStyles.auditHistoryContentCol}>
            {renderPrintButton()}
            {this.renderChangeHeader(selectedChangeSet)}
            {this.renderChangeFieldsTable(selectedChangeSet)}
          </div>
          <div style={{ display: 'none' }}>{this.renderPrintContainer()}</div>
        </div>
      );
    }
    return <div id="modalContent" />;
  }
}

export default withStyles(stylesGenerator)(AuditHistory);
