/* eslint no-buffer-constructor:0 */
/* globals window XMLHttpRequest */
import { get, isNil } from 'lodash';
import { isTestEnv } from './values';

/** @module Auth Utils */

const atob = str =>
  isTestEnv ? new Buffer(str, 'base64').toString('utf8') : window.atob(str);

/**
 * Interface to whatever storage method the environment needs (currently
 * testing happens outside of the browser)
 */
const storage = {
  get: key =>
    // return `true` if in TEST environment, otherwise browser storage value
    isTestEnv ? storage[key] : window.sessionStorage.getItem(key),
  set: (key, val) =>
    // return `true` if in TEST environment, otherwise set the browser storage value
    isTestEnv
      ? Object.assign(storage, { [key]: val })
      : window.sessionStorage.setItem(key, val),
  remove: key =>
    isTestEnv
      ? Object.assign(storage, { [key]: null })
      : window.sessionStorage.removeItem(key)
};

/**
 * The browser `storage` key to persist sessions under
 * @type {String}
 */
export const SESSION_STORAGE_KEY = 'Ⅲ_session';
/**
 * The Authentication host (ie pingFederate)
 * @type {String}
 */
export const AUTH_HOST = '';
/**
 * The `token_key` as given via redirect by auth provider
 * @type {String}
 */
const TOKEN_KEY = 'jwt';

/**
 * The `error_key` as given via redirect by auth provider
 * @type {String}
 */
const ERROR_KEY = 'error';

/**
 * The key to begin a session sharing (if another session is open, try to get its sessionStorage)
 */
const SESSION_INIT_BROADCAST_KEY = '_p';

/**
 * The key to transmit session sharing information.
 */
const SESSION_BROADCAST_KEY = '_tok';

/**
 * The key to record the last activity time for localStorage.
 */
const SESSION_LAST_ACTIVITY = '_la';

/**
 * Path to the logout page
 */
export const LOGOUT_PATH = '/logout.html';

const { protocol, hostname, port, pathname } = window.location;

/**
 * TODO: OpenID configuration
 */
export const config = {
  redirect_uri: `${protocol}//${hostname}${port ? `:${port}` : ''}${pathname}`,
  client_id: 'Ⅲ',
  state: 'login',
  response_type: 'id_token token',
  scope: 'openid profile'
};

/**
 * Parse the given URL `hashValue` for an access_token
 * @function
 * @param  {String} hashValue - `window.location.hash`
 * @return {Boolean}          - Is there an OpenID-compliant JWT serialized in URL hash
 */
export const isAuthHashValuePresent = hashValue =>
  new RegExp(`${TOKEN_KEY}=`, 'i').test(hashValue);
/**
 * Parse the given URL `hashValue` for a JWT authentication error
 * @function
 * @param  {String} hashValue - `window.location.hash`
 * @return {Boolean}          - Is there an OpenID-compliant JWT error in the URL hash
 */
export const isAuthHashErrorPresent = hashValue =>
  new RegExp(`${ERROR_KEY}=`, 'i').test(hashValue);

/**
 * Decoding JWT value (from base64 -> Object)
 * @function
 * @param  {String} token - The token as returned from the auth server
 * @return {Object}       - The decoded JWT
 */
export const decodeJWT = token =>
  token
    .split('.')
    .slice(0, 2)
    .map(val => JSON.parse(atob(val))) // using browser's base64 encoding (IE 10 compat)
    .reduce((obj, val) => Object.assign({}, val));

/**
 * Processes the openid hash value for the session value
 * @function
 * @param  {String} value - The hash value as set by Auth server redirect
 * @return {Object}       - An object mapping all hash keys to their values
 */
export const processHashValue = value => {
  if (!value) return null;

  return value
    .substr(1)
    .split('&')
    .reduce((values, param) => {
      const [key, val] = param.split('=');
      const isToken = key === TOKEN_KEY;
      if (isToken) Object.assign(values, { access_token: val });
      return Object.assign(values, {
        [key]: isToken ? decodeJWT(val) : val
      });
    }, {});
};

/**
 * Given user session, returns the expiration status
 * @param  {Object} jwt       A deserialized JWT
 * @param  {Date.now} issued  The time the JWT was created
 * @return {Boolean}          Is the JWT expired
 */
const expired = ({ jwt: { iat, exp }, issued }) =>
  exp - iat < (Date.now() - issued) / 1000;

/**
 * Returns the current active deserialized JWT
 */
let cachedValue;
const cachedSession = () => {
  if (cachedValue) return cachedValue;
  cachedValue = processHashValue(storage.get(SESSION_STORAGE_KEY));
  return cachedValue;
};

/**
 * Persist JWT session to browser storage
 * @param  {String} hash - JWT hash
 * @return {Object}      - The deserialized JWT
 */
const persistSession = hash => {
  storage.set(SESSION_STORAGE_KEY, hash);
  return cachedSession();
};
/**
 * Remove the cached JWT from browser storage
 */
const resetSession = () => {
  // Test env doesn't have window or localStorage...
  try {
    window.localStorage.setItem(SESSION_LAST_ACTIVITY, '0');
  } catch (e) {} // eslint-disable-line
  storage.remove(SESSION_STORAGE_KEY);
  cachedValue = undefined;
};

/**
 * Given a URL hash (window.location.hash), check to see if an authentication flow
 * has completed, if so, persist the session
 * @function
 * @param {string} hash
 */
export const beginAuthSession = hash => {
  // If there's no preexisting session, don't do anything else.
  if (!hash) return;

  if (isAuthHashValuePresent(hash)) {
    persistSession(`${hash}&issued=${Date.now()}`);
  } else if (isAuthHashErrorPresent(hash)) {
    persistSession(hash); // currently storing error
  }

  // Using `location.replace` which replaces the local history item
  // The `history` API only altered the session state (but retained JWT's in local history)
  window.location.replace(
    window.location.href.replace(window.location.search, '')
  );
};

/**
 * Determines if the user's session exists, and if it is still valid
 * @function
 * @return {Boolean} - Is user session valid (true/false)
 */
export const isLoggedIn = () => {
  const session = cachedSession();
  return isNil(session) ? false : !expired(session);
};

/**
 * Redirects the user to the Identity Provider specified by the config
 */
const redirectToIdP = trilogyConfig => {
  const issuer = trilogyConfig['auth-svc'].issuer;
  const params = issuer ? `?issuer=${issuer}` : '';

  const req = new XMLHttpRequest();
  req.open('GET', `${trilogyConfig['auth-svc'].uri}/request${params}`, true);

  req.onreadystatechange = () => {
    if (req.readyState < 4) return;
    const { actionUri, samlRequest, stateParam } = JSON.parse(req.responseText);
    const callbackUri = window.location.href;

    // Create a disconnected form
    const $f = document.createElement('form');
    $f.action = actionUri;
    $f.method = 'POST';

    // Create the saml request
    const $samlRequest = document.createElement('input');
    $samlRequest.type = 'hidden';
    $samlRequest.name = 'SAMLRequest';
    $samlRequest.value = samlRequest;
    $f.appendChild($samlRequest);

    // Create the relay state
    const $relayState = document.createElement('input');
    $relayState.type = 'hidden';
    $relayState.name = stateParam;
    $relayState.value = callbackUri;
    $f.appendChild($relayState);

    // Append the form to the body
    document.body.appendChild($f);

    // Go to login
    $f.submit();
  };

  req.send(null);
};

/**
 * Initialize a sessionStorage => localStorage => sessionStorage broadcast pipeline for new browser windows.
 * @function
 */
export const initSessionBroadcast = () => {
  // set up a listener for storage events to attempt to assist new windows with session tokens
  window.addEventListener('storage', e => {
    if (
      e.key === SESSION_INIT_BROADCAST_KEY &&
      window.sessionStorage.getItem(SESSION_STORAGE_KEY)
    ) {
      // If I notice an initializer AND I have a session, send a broadcast
      window.localStorage.setItem(
        SESSION_BROADCAST_KEY,
        window.sessionStorage.getItem(SESSION_STORAGE_KEY)
      );
    } else if (
      e.key === SESSION_BROADCAST_KEY &&
      !window.sessionStorage.getItem(SESSION_STORAGE_KEY)
    ) {
      // If I found a session broadcast AND I don't have a session, obtain the broadcast
      window.sessionStorage.setItem(SESSION_STORAGE_KEY, e.newValue);
      window.localStorage.removeItem(SESSION_BROADCAST_KEY);
    }
  });

  // if I don't have a session, set a new value in _p to initiate a session transfer
  if (!window.sessionStorage.getItem(SESSION_STORAGE_KEY)) {
    window.localStorage.setItem(SESSION_INIT_BROADCAST_KEY, Date.now());
  }
};

/**
 * Trilogy-specific authentication
 *
 * Determine user's authorization state. Follow authority redirects as necessary
 * @function
 */
export const authorize = () => {
  const trilogyConfig = window.trilogyConfig;

  if (!isLoggedIn() && trilogyConfig && !isTestEnv) {
    // give the browser time (500ms) to send the localstorage 'signal'
    setTimeout(() => {
      if (isLoggedIn()) {
        // refresh the page
        document.location = document.location;
      } else {
        // no session token available, redirect to IdP
        redirectToIdP(trilogyConfig);
      }
    }, 1500);
  }
};

/**
 * An aesthetically chosen convenience method.
 * Outside of this module, `endSession` sounds better than `resetSession`
 */
export const endSession = resetSession;

/**
 * An aesthetically chosen convenience method.
 * Outside of this module, `getSession` reads better in-context than `cachedSession`
 * @function
 * @return {Object} A deserialized JWT session (or empty object if no active session is found)
 */
export const getSession = () => cachedSession() || { access_token: null };

/**
 * Get the JWT roles
 * @function
 * @returns {Array} Roles
 */
export const getRoles = () => get(cachedSession(), 'jwt.rs') || [];

export const isAuthorized = role => getRoles().includes(role);

export const isSuperUser = () => isAuthorized('super-user');

let lastActivity;

const eventHandler = () => {
  lastActivity = Date.now();
};

const idleHandler = timeoutMs => () => {
  const now = Date.now();
  const sessionLastActivity = +window.localStorage.getItem(
    SESSION_LAST_ACTIVITY
  );

  if (sessionLastActivity + timeoutMs < now) {
    // session expired
    endSession();
    window.location = LOGOUT_PATH;
  } else {
    // store the latest "last activity"
    window.localStorage.setItem(
      SESSION_LAST_ACTIVITY,
      Math.max(sessionLastActivity, lastActivity)
    );
  }
};

/**
 * @function
 * @param {*} timeoutMs
 */
export const initIdleHandler = timeoutMs => {
  lastActivity = Date.now();
  document.onkeypress = eventHandler;
  document.onmousemove = eventHandler;
  document.onscroll = eventHandler;
  window.localStorage.setItem(SESSION_LAST_ACTIVITY, Date.now());
  window.setInterval(idleHandler(timeoutMs), 1000);
};
