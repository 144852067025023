/* eslint no-useless-escape:0 no-template-curly-in-string:0 */
module.exports = {
  path: 'master',
  title: 'Case Creation',
  component: 'PropTypeSafety',
  tabs: [
    {
      component: 'Tab',
      sections: [
        {
          id: 'summary',
          component: 'Section',
          statePath: 'summary',
          title: 'Description',

          elements: [
            {
              id: 'description',
              label: 'Description',
              component: 'TextArea',
              statePath: 'narrative.long2',
              layout: {
                width: '50%'
              },
              styles: {
                height: '176px',
                width: '100%'
              },
              validations: {
                required: {
                  constraint: false,
                  errorMessage: ''
                },
                maxLength: {
                  constraint: 20000,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              },
              referencedProperties: {
                'validations.required.constraint': {
                  statePath: 'summary.narrative.categories.product_quality',
                  $if: [{ $eq: true }, { $set: true }],
                  $else: { $set: false }
                }
              }
            },
            {
              name: 'NarrativeCategories',
              component: 'Section',
              statePath: 'narrative.categories',
              layout: {
                display: 'inline-flex',
                flexDirection: 'column',
                width: '50%'
              },
              elements: [
                {
                  component: 'Checkbox',
                  label: 'Adverse Event',
                  statePath: 'adverse_event',
                  validations: {
                    required: {
                      queryConstraint: {
                        relative: true,
                        $or: [
                          {
                            adverse_event: { $eq: true }
                          },
                          {
                            medical_inquiry: { $eq: true }
                          },
                          {
                            product_quality: { $eq: true }
                          },
                          {
                            $set: false
                          }
                        ]
                      }
                    }
                  }
                },
                {
                  component: 'Checkbox',
                  label: 'Medical Inquiry',
                  statePath: 'medical_inquiry',
                  disabled: 'true',
                  validations: {
                    required: {
                      queryConstraint: {
                        relative: true,
                        $or: [
                          {
                            adverse_event: { $eq: true }
                          },
                          {
                            medical_inquiry: { $eq: true }
                          },
                          {
                            product_quality: { $eq: true }
                          },
                          {
                            $set: false
                          }
                        ]
                      }
                    }
                  }
                },
                {
                  component: 'Checkbox',
                  label: 'Product Quality',
                  statePath: 'product_quality',
                  validations: {
                    required: {
                      queryConstraint: {
                        relative: true,
                        $or: [
                          {
                            adverse_event: { $eq: true }
                          },
                          {
                            medical_inquiry: { $eq: true }
                          },
                          {
                            product_quality: { $eq: true }
                          },
                          {
                            $set: false
                          }
                        ]
                      }
                    }
                  }
                }
              ]
            },
            {
              id: 'DescriptionAE',
              component: 'Section',
              title: 'Adverse Event',
              statePath: '',
              elements: [
                {
                  $ref: 'DisplayValueToggle',
                  label: 'Seriousness',
                  component: 'Select',
                  placeholder: 'Select seriousness',
                  statePath: 'narrative.seriousness',
                  layout: {
                    width: '50%'
                  },
                  options: [
                    { label: 'Serious', value: 'serious' },
                    { label: 'Non Serious', value: 'nonserious' }
                  ],
                  referencedProperties: {
                    'layout.display': {
                      statePath: 'summary.narrative.categories.adverse_event',
                      $if: [{ $eq: true }, { $set: 'inline-block' }],
                      $else: { $set: 'none' }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  component: 'RadioGroup',
                  statePath: 'narrative.adverse_event.type',

                  options: [
                    { label: 'Initial Case', value: 'initial_case' },
                    { label: 'AE Follow-up', value: 'follow_up' }
                  ],

                  layout: {
                    display: 'inline-block',
                    width: '50%'
                  },

                  styles: {
                    width: '100%'
                  },

                  referencedProperties: {
                    'layout.display': {
                      statePath: 'summary.narrative.categories',
                      $if: [
                        { adverse_event: { $eq: true } },
                        { $set: 'inline-block' }
                      ],
                      $else: {
                        $set: 'none'
                      }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  label: 'Global Case ID',
                  component: 'TextInput',
                  statePath: 'follow_up_case',

                  layout: {
                    marginLeft: '50%',
                    width: '50%'
                  },

                  referencedProperties: {
                    'layout.display': {
                      $if: [
                        {
                          'summary.narrative.categories.adverse_event': {
                            $eq: true
                          }
                        },
                        {
                          'summary.narrative.adverse_event.type': {
                            $eq: 'follow_up'
                          }
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggleChild',
                  label: 'AbbVie Awareness Date – Adverse Event',
                  component: 'DateInput',
                  statePath: 'awareness_date',

                  layout: {
                    width: '50%'
                  },
                  validations: {
                    required: {
                      constraint: false,
                      errorMessage: ''
                    },
                    date: {
                      constraint: 'full',
                      errorMessage: 'Invalid full date'
                    },
                    dateRestriction: {
                      constraint: 'future',
                      errorMessage: 'Date must not occur in the future'
                    }
                  },

                  referencedProperties: {
                    'validations.required.constraint': {
                      $if: [
                        {
                          $or: [
                            {
                              'summary.narrative.categories.adverse_event': {
                                $eq: true
                              }
                            },
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            }
                          ]
                        },
                        { $set: true }
                      ],
                      $else: { $set: false }
                    },
                    'layout.marginRight': {
                      $if: [
                        {
                          'summary.narrative.categories.adverse_event': {
                            $eq: true
                          }
                        },
                        { $set: '0' }
                      ],
                      $else: { $set: '50%' }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggleChild',
                  label: 'Affiliate Awareness Date - Adverse Event',
                  component: 'DateInput',
                  statePath: 'affiliate_awareness_date',

                  layout: {
                    width: '50%'
                  },

                  validations: {
                    required: {
                      constraint: false,
                      errorMessage: ''
                    },
                    date: {
                      constraint: 'full',
                      errorMessage: 'Full date required'
                    },
                    dateRestriction: {
                      constraint: 'future',
                      errorMessage: 'Date must not occur in the future'
                    }
                  },

                  referencedProperties: {
                    'validations.required.constraint': {
                      $if: [
                        {
                          'summary.narrative.categories.adverse_event': {
                            $eq: true
                          }
                        },
                        { $set: true }
                      ],
                      $else: { $set: false }
                    }
                  }
                }
              ],
              referencedProperties: {
                'layout.display': {
                  statePath: 'summary.narrative.categories.adverse_event',
                  $if: [{ $eq: true }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              id: 'DescriptionPQ',
              component: 'Section',
              title: 'Product Complaint',
              statePath: '',
              elements: [
                {
                  $ref: 'DisplayValueToggle',
                  label: 'AbbVie Awareness Date - PQ Complaint',
                  component: 'DateInput',
                  statePath: 'productQuality.awareness_date',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    required: {
                      constraint: true,
                      errorMessage: ''
                    },
                    date: {
                      constraint: 'full',
                      errorMessage: 'Invalid full date'
                    },
                    dateRestriction: {
                      constraint: 'future',
                      errorMessage: 'Date must not occur in the future'
                    }
                  },

                  referencedProperties: {
                    'validations.required.constraint': {
                      $if: [
                        {
                          'summary.narrative.categories.product_quality': {
                            $eq: true
                          }
                        },
                        { $set: true }
                      ],
                      $else: { $set: false }
                    },
                    'layout.display': {
                      $if: [
                        {
                          'summary.narrative.categories.product_quality': {
                            $eq: true
                          }
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  label: 'Affiliate Awareness Date - PQ Complaint',
                  component: 'DateInput',
                  statePath: 'productQuality.affiliate_awareness_date',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    required: {
                      constraint: true,
                      errorMessage: ''
                    },
                    date: {
                      constraint: 'full',
                      errorMessage: 'Invalid full date'
                    },
                    dateRestriction: {
                      constraint: 'future',
                      errorMessage: 'Date must not occur in the future'
                    }
                  },

                  referencedProperties: {
                    'validations.required.constraint': {
                      $if: [
                        {
                          'summary.narrative.categories.product_quality': {
                            $eq: true
                          }
                        },
                        { $set: true }
                      ],
                      $else: { $set: false }
                    },
                    'layout.display': {
                      $if: [
                        {
                          'summary.narrative.categories.product_quality': {
                            $eq: true
                          }
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  label: 'PQ Complaint Event Date',
                  component: 'DateInput',
                  statePath: 'productQuality.event_date',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    date: {
                      constraint: 'full',
                      errorMessage: 'Invalid full date'
                    },
                    dateRestriction: {
                      constraint: 'future',
                      errorMessage: 'Date must not occur in the future'
                    }
                  },

                  referencedProperties: {
                    'layout.display': {
                      $if: [
                        {
                          'summary.narrative.categories.product_quality': {
                            $eq: true
                          }
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    },
                    disabled: {
                      $get: 'summary.productQuality.event_date_unavailable'
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  component: 'Checkbox',
                  label: 'Unavailable',
                  statePath: 'productQuality.event_date_unavailable',
                  layout: {
                    paddingLeft: '15px',
                    paddingTop: '20px'
                  },
                  referencedProperties: {
                    'layout.display': {
                      $if: [
                        {
                          'summary.narrative.categories.product_quality': {
                            $eq: true
                          }
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    },
                    disabled: {
                      statePath: 'summary.productQuality.event_date',
                      $if: [{ $ne: null }, { $set: true }],
                      $else: { $set: false }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  label: 'Why is the event date unavailable?',
                  component: 'Select',
                  statePath: 'productQuality.event_date_unavailable_reason',
                  layout: {
                    width: '50%',
                    marginRight: '50%',
                    display: 'none'
                  },
                  options: [
                    {
                      label: 'Patient contact information not available',
                      value: 'patient_contact_info_not_available'
                    },
                    {
                      label: 'Primary Reporter did not have Event Date',
                      value: 'primary_reporter_had_no_event_date'
                    },
                    {
                      label: 'After follow-up, Event Date is not available',
                      value: 'after_followup_not_available'
                    },
                    {
                      label: 'Due Diligence for Event Date in process',
                      value: 'due_diligence_in_process'
                    },
                    {
                      label: 'Primary Reporter declined further contact',
                      value: 'primary_reporter_declined'
                    },
                    {
                      label:
                        'Primary Reporter contact information was not provided',
                      value: 'primary_reporter_contact_info_not_provided'
                    },
                    {
                      label:
                        'Event Date was not provided in source document/voicemail',
                      value: 'event_date_not_provided_in_source'
                    },
                    { label: 'Other', value: 'other' }
                  ],
                  referencedProperties: {
                    'validations.required.constraint': {
                      $get: 'summary.productQuality.event_date_unavailable'
                    },
                    'layout.display': {
                      $if: [
                        {
                          $and: [
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            },
                            {
                              $get:
                                'summary.productQuality.event_date_unavailable'
                            }
                          ]
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  id: 'event_date_unavailable_reason_comments',
                  label: 'Additional Comments',
                  component: 'TextArea',
                  statePath:
                    'productQuality.event_date_unavailable_reason_comments',
                  layout: {
                    width: '50%',
                    marginRight: '50%',
                    display: 'none'
                  },
                  styles: {
                    height: '176px',
                    width: '100%'
                  },
                  referencedProperties: {
                    'layout.display': {
                      $if: [
                        {
                          $and: [
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            },
                            {
                              // need this in case Unavailable becomes deselected
                              $get:
                                'summary.productQuality.event_date_unavailable'
                            },
                            {
                              'summary.productQuality.event_date_unavailable_reason': {
                                $eq: 'other'
                              }
                            }
                          ]
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  label: 'Sample available for return?',
                  component: 'Select',
                  statePath: 'productQuality.complaint.processing.available',

                  layout: {
                    width: '100%'
                  },

                  styles: {
                    width: '50%'
                  },

                  options: [
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                    {
                      label: 'Asked but Unknown (ASKU)',
                      value: 'Asked but Unknown (ASKU)'
                    },
                    {
                      label: 'No Information (NI)',
                      value: 'No Information (NI)'
                    },
                    {
                      label: 'Not Applicable (NA)',
                      value: 'Not Applicable (NA)'
                    }
                  ],
                  referencedProperties: {
                    'layout.display': {
                      $if: [
                        {
                          'summary.narrative.categories.product_quality': {
                            $eq: true
                          }
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    },
                    'layout.width': {
                      $if: [
                        {
                          'summary.productQuality.complaint.processing.available': {
                            $eq: 'no'
                          }
                        },
                        { $set: '50%' }
                      ],
                      $else: { $set: '100%' }
                    },
                    'styles.width': {
                      $if: [
                        {
                          'summary.productQuality.complaint.processing.available': {
                            $eq: 'no'
                          }
                        },
                        { $set: '100%' }
                      ],
                      $else: { $set: '50%' }
                    }
                  },
                  validations: {
                    required: {
                      constraint: true,
                      errorMessage: ''
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  label: 'Why is the sample unavailable?',
                  component: 'Select',
                  statePath:
                    'productQuality.complaint.processing.availableReason',
                  layout: {
                    width: '50%'
                  },
                  options: [
                    { label: 'Discarded', value: 'discarded' },
                    { label: 'Still in use', value: 'inuse' },
                    { label: 'Other', value: 'other' }
                  ],
                  referencedProperties: {
                    'layout.display': {
                      $if: [
                        {
                          $and: [
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            },
                            {
                              'summary.productQuality.complaint.processing.available': {
                                $eq: 'no'
                              }
                            }
                          ]
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  component: 'TextInput',
                  label: 'Sample Unavailable Reason',
                  statePath:
                    'productQuality.complaint.processing.sampleUnavailableReason',
                  layout: {
                    width: '100%'
                  },
                  referencedProperties: {
                    'layout.display': {
                      $if: [
                        {
                          $and: [
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            },
                            {
                              'summary.productQuality.complaint.processing.available': {
                                $eq: 'no'
                              }
                            },
                            {
                              'summary.productQuality.complaint.processing.availableReason': {
                                $eq: 'other'
                              }
                            }
                          ]
                        },
                        { $set: 'inline-block' }
                      ],
                      $else: { $set: 'none' }
                    }
                  }
                }
              ],
              referencedProperties: {
                'layout.display': {
                  statePath: 'summary.narrative.categories.product_quality',
                  $if: [{ $eq: true }, { $set: 'inline-block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              $ref: 'AdditionalNotes',
              layout: {
                display: 'none'
              }
            }
          ]
        },
        {
          id: 'Contact',
          component: 'Section',
          title: 'Case Contacts',
          statePath: 'contacts',

          elements: [
            {
              $ref: 'LookupContactButton'
            },
            {
              $ref: 'ContactList',
              label: 'another contact',
              statePath: 'contact',
              length: 1
            }
          ]
        },
        {
          id: 'patient',
          component: 'Section',
          title: 'Patient Information',
          statePath: 'patient',

          elements: [
            {
              $ref: 'LookupPatientButton'
            },
            {
              $ref: 'PatientInfo',
              statePath: 'patient'
            }
          ]
        },
        {
          $ref: 'TrackingInformationSection',
          title: 'Tracking Information',
          statePath: 'tracking'
        },
        {
          $ref: 'DocumentUploader'
        },
        {
          id: 'ReferenceNumbers',
          component: 'Section',
          statePath: 'reference_numbers',
          title: 'Reference Numbers',
          elements: [
            { $ref: 'ReferenceNumber' },
            {
              $ref: 'LinkedCases',
              statePath: '/linkedCases'
            },
            {
              $ref: 'ClonedFrom'
            },
            { $ref: 'AERNumbers' }
          ]
        },
        {
          title: '',
          component: 'Section',
          id: 'create-case-footer-section',
          statePath: '/create-case-footer',
          elements: [
            {
              id: 'saveDraftSmartButton',
              label: 'Save',
              component: 'SmartButton',
              triggers: {
                onClick: 'onSaveCaseCSE'
              },
              layout: {
                width: '50%',
                display: 'flex',
                flexDirection: 'row'
              },
              styles: {
                backgroundColor: '#F2F2F2',
                color: '#00A9E0',
                border: '2px solid #00A9E0'
              }
            },
            {
              id: 'createCaseSmartButton',
              label: 'Create A Case',
              component: 'SmartButton',
              triggers: {
                onClick: 'onValidate'
              },
              layout: {
                width: '50%',
                display: 'flex',
                flexDirection: 'row'
              },
              styles: {
                appearance: 'none',
                backgroundColor: '#071D49',
                border: 'none',
                color: '#FFFFFF',
                marginLeft: 'auto'
              }
            }
          ],
          layout: {
            background: 'none transparent',
            padding: '20px'
          }
        }
      ]
    }
  ]
};
