import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import {
  SimpleButton,
  Select,
  TextInput,
  CheckboxGroup,
  withStyles,
  RadioGroup,
  TextArea
} from 'Common/components/Form';
import TabHeader from 'Common/components/TabHeader';
import MultiToggle from 'Common/components/MultiToggle';
import {
  QUEUE_MASTER_CASES,
  QUEUE_CASES_TO_REVIEW,
  QUEUE_ADVERSE_EVENT,
  QUEUE_MEDICAL_INQUIRY,
  QUEUE_PRODUCT_QUALITY,
  QUEUE_YOUR_CASES
} from 'Queue/constants';
import { SUB_CASE_TYPES_MAP } from 'Common/constants';
import { KEY_CODE_ENTER } from 'Common/constants/keyCodes';
import { modalStyles } from 'Common/components/withModal';
import CaseActionsMenu from 'CreateCase/components/PageHeader/CaseActionsMenu';
import AuthorizedLink from 'Common/components/AuthorizedLink';
import ReassignAssigneeModal from 'Common/components/ReassignAssigneeModal';
import stylesGenerator from './styles';

class QueueHeader extends PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      submitted: PropTypes.bool.isRequired,
      nonSubmitted: PropTypes.bool.isRequired,
      NEW: PropTypes.bool.isRequired,
      IN_PROGRESS: PropTypes.bool.isRequired,
      COMPLETED: PropTypes.bool.isRequired,
      AE: PropTypes.bool.isRequired,
      MI: PropTypes.bool.isRequired,
      PQ: PropTypes.bool.isRequired,
      masterCaseProduct: PropTypes.string.isRequired,
      suspectProduct: PropTypes.string.isRequired,
      miProduct: PropTypes.string.isRequired,
      pqProduct: PropTypes.string.isRequired,
      caseCreators: PropTypes.string.isRequired,
      methodOfReceipt: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      aeCountry: PropTypes.string.isRequired,
      seriousness: PropTypes.string.isRequired,
      caseType: PropTypes.string.isRequired,
      aeOwner: PropTypes.string.isRequired,
      miOwner: PropTypes.string.isRequired,
      pqOwner: PropTypes.string.isRequired,
      reconciliationNeeded: PropTypes.isRequired
    }).isRequired,
    dropdownFilters: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        placeholder: PropTypes.string,
        options: PropTypes.array,
        multi: PropTypes.bool
      })
    ).isRequired,
    disabled: PropTypes.bool.isRequired,
    handleReconciliationFilterChange: PropTypes.func.isRequired,
    handleStatusFilterChange: PropTypes.func.isRequired,
    handleSubcaseFilterChange: PropTypes.func.isRequired,
    handleDropdownFilterChange: PropTypes.func.isRequired,
    handleTextInputChange: PropTypes.func.isRequired,
    handleTextInputBlur: PropTypes.func.isRequired,
    handleExportToCSV: PropTypes.func.isRequired,
    customizeQueueResults: PropTypes.element.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        page: PropTypes.string
      }).isRequired
    }).isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      filter: PropTypes.func.isRequired,
      inputWrapper: PropTypes.func.isRequired,
      subCaseLinks: PropTypes.object.isRequired,
      filterWrapper: PropTypes.object.isRequired,
      subcaseFilterWrapper: PropTypes.object.isRequired,
      title: PropTypes.object.isRequired
    }).isRequired,
    isSubCaseView: PropTypes.bool,
    locale: PropTypes.string,
    assignCaseOrTask: PropTypes.func,
    actions: PropTypes.shape({
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired
    }).isRequired,
    bulkArchiveCase: PropTypes.func,
    selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
    appliedFilters: PropTypes.element.isRequired
  };

  static defaultProps = {
    locale: 'US',
    tacticalData: {},
    isSubCaseView: false,
    assignCaseOrTask: () => {},
    bulkArchiveCase: () => {},
    actions: {
      emitModalContentUpdate: () => {}
    },
    selectedItemsToAssignOrArchive: []
  };

  state = {
    showFilters: false,
    archiveReason: '',
    archiveComments: ''
  };

  SUB_CASE_TITLES = {
    [QUEUE_ADVERSE_EVENT]: 'Adverse Events',
    [QUEUE_MEDICAL_INQUIRY]: 'Medical Inquiries',
    [QUEUE_PRODUCT_QUALITY]: 'Product Complaints',
    [QUEUE_MASTER_CASES]: 'Master Cases',
    [QUEUE_CASES_TO_REVIEW]: 'Cases to Review',
    [QUEUE_YOUR_CASES]: 'Your cases'
  };

  title = () => {
    const { page } = this.props.match.params;
    return (
      <div className={this.props.computedStyles.title}>
        {this.SUB_CASE_TITLES[page]}
      </div>
    );
  };

  handleToggleFilters = e => {
    e.preventDefault();
    this.setState(prevState => ({ showFilters: !prevState.showFilters }));
  };

  handleTextInputKeyDown = (eventKeyCode, value, filterType) => {
    // this will trigger a submit when the "Enter" key is hit
    // and this will only happen if the text input has onKeyDown prop
    if (eventKeyCode === KEY_CODE_ENTER) {
      this.props.handleTextInputBlur(value, filterType);
    }
  };

  // Wrapper to process the CheckboxGroup onChange event
  handleStatusFilterChange = ({ $id, value }) => {
    const { handleStatusFilterChange } = this.props;
    handleStatusFilterChange(value, $id);
  };

  shouldRenderFilters = () =>
    Object.keys(SUB_CASE_TYPES_MAP)
      .concat(QUEUE_CASES_TO_REVIEW) // Trusty Master Case page key
      .includes(this.props.match.params.page) && this.state.showFilters;

  renderSubCaseFilters = currentPage => {
    const { AE, MI, PQ } = this.props.filters;
    const {
      handleSubcaseFilterChange,
      computedStyles,
      isSubCaseView
    } = this.props;
    // const showFilters = this.state.showFilters;
    return !isSubCaseView && currentPage !== QUEUE_YOUR_CASES ? (
      <div className={computedStyles.subcaseFilterWrapper}>
        <div className={this.props.computedStyles.subCaseLinks}>
          <MultiToggle
            options={[
              { label: 'AE', value: 'AE', selected: AE },
              { label: 'MI', value: 'MI', selected: MI },
              { label: 'PQ', value: 'PQ', selected: PQ }
            ]}
            onChange={handleSubcaseFilterChange}
          />
        </div>
      </div>
    ) : null;
  };

  renderReconciliationFilter = currentPage => {
    const { computedStyles, filters: { reconciliationNeeded } } = this.props;
    return [QUEUE_ADVERSE_EVENT, QUEUE_PRODUCT_QUALITY].includes(
      currentPage
    ) ? (
      <div
        className={`${computedStyles.filterWrapper} ${computedStyles.right}`}
      >
        <CheckboxGroup
          onChange={({ value }) =>
            this.props.handleReconciliationFilterChange(value, currentPage)
          }
          options={[
            {
              value: 'reconciliationNeeded',
              label: 'Needs Reconciliation',
              checked: !isNil(reconciliationNeeded)
            }
          ]}
          renderHorizontal
        />
      </div>
    ) : null;
  };

  renderSubmittedFilter = currentPage => {
    const { computedStyles } = this.props;
    const {
      submitted,
      nonSubmitted,
      reconciliationNeeded
    } = this.props.filters;
    return currentPage === QUEUE_ADVERSE_EVENT ||
      currentPage === QUEUE_PRODUCT_QUALITY ? (
      <div
        className={`${computedStyles.filterWrapper} submitted ${
          computedStyles.right
        }`}
      >
        <CheckboxGroup
          onChange={this.handleStatusFilterChange}
          options={[
            {
              value: 'nonSubmitted',
              label: 'Non-Submitted',
              checked: nonSubmitted
            },
            {
              value: 'submitted',
              label: 'Submitted',
              checked: submitted
            }
          ]}
          renderHorizontal
          disabled={!!reconciliationNeeded}
        />
      </div>
    ) : null;
  };

  renderStatusFilters = currentPage => {
    const { computedStyles } = this.props;
    const {
      NEW,
      IN_PROGRESS,
      COMPLETED,
      reconciliationNeeded
    } = this.props.filters;
    return (
      <div className={`${computedStyles.filterWrapper} submitted`}>
        <CheckboxGroup
          onChange={this.handleStatusFilterChange}
          options={[
            {
              value: 'NEW',
              label: 'New',
              disabled:
                currentPage === QUEUE_CASES_TO_REVIEW ||
                currentPage === QUEUE_YOUR_CASES ||
                !!reconciliationNeeded,
              checked: NEW
            },
            {
              value: 'IN_PROGRESS',
              label: 'In Progress',
              disabled:
                currentPage === QUEUE_CASES_TO_REVIEW || !!reconciliationNeeded,
              checked: IN_PROGRESS
            },
            {
              value: 'COMPLETED',
              label: 'Complete',
              disabled:
                currentPage === QUEUE_CASES_TO_REVIEW || !!reconciliationNeeded,
              checked: COMPLETED
            }
          ]}
          renderHorizontal
          disabled={!!reconciliationNeeded}
        />
      </div>
    );
  };

  renderFilter = (filter, i) => {
    const {
      handleDropdownFilterChange,
      handleTextInputChange,
      handleTextInputBlur,
      match,
      computedStyles,
      filters,
      locale,
      dropdownFilters
    } = this.props;
    const currentPage = match.params.page;

    const PLACEHOLDER_MAP = {
      [QUEUE_MASTER_CASES]: 'Master Case Product',
      [QUEUE_CASES_TO_REVIEW]: 'Master Case Product',
      [QUEUE_YOUR_CASES]: 'Product',
      [QUEUE_ADVERSE_EVENT]: 'Suspect Product',
      [QUEUE_MEDICAL_INQUIRY]: 'Abbvie Product',
      [QUEUE_PRODUCT_QUALITY]: 'Abbvie Product'
    };

    // TODO remove this whole block in future stories
    if (
      filter.type === 'product' ||
      filter.type === 'miProduct' ||
      filter.type === 'pqProduct'
    ) {
      return (
        <div
          key={`${filter.type}-${i}`}
          className={computedStyles.inputWrapper(dropdownFilters)}
          onKeyDown={event =>
            this.handleTextInputKeyDown(
              event.keyCode,
              event.target.value,
              filter.type
            )
          }
          role="searchBox"
        >
          <TextInput
            controlled={false}
            placeholder={PLACEHOLDER_MAP[currentPage]}
            locale={locale}
            value={filters[filter.type]}
            onChange={value => handleTextInputChange(value, filter.type)}
            onBlur={value => handleTextInputBlur(value, filter.type)}
          />
        </div>
      );
    }

    return (
      <Select
        key={`${filter.type}-${i}`}
        className={computedStyles.filter(dropdownFilters)}
        options={filter.options}
        value={filters[filter.type]}
        placeholder={filter.placeholder}
        onChange={value => handleDropdownFilterChange(value, filter.type)}
        multi={!!filter.multi}
      />
    );
  };

  handleArchiveConfirm = () => {
    const { archiveReason, archiveComments } = this.state;
    this.props.bulkArchiveCase(archiveReason, archiveComments);
    this.handleDismiss();
  };

  handleDismiss = () => {
    // Clear action menu modal state on close
    this.setState({
      archiveReason: '',
      archiveComments: ''
    });
    this.props.actions.emitModalContentClear();
  };

  handleArchiveChange = (key, value) => {
    this.setState({ [key]: value });
    // Modal content needs an action to update its content,
    // so we must explicitly pass the new state for the modal content
    const newState = { ...this.state, [key]: value };
    this.handleShowArchiveModal(newState);
  };

  renderArchiveModal = ({ archiveReason, archiveComments }) => {
    const { computedStyles } = this.props;
    const isDisabled = archiveComments === '' || archiveReason === '';
    return (
      <div
        className={`${modalStyles.base} ${modalStyles.tall} ${
          computedStyles.modalContainerDiv
        }`}
      >
        <span className={modalStyles.title}>Archive Cases</span>
        <RadioGroup
          className={`${computedStyles.reason}`}
          label="Please choose why this case should be archived:"
          options={[
            {
              label:
                'Case created in error (No confirmation/acknowledgment email)',
              value: 'creation_error'
            },
            { label: 'Case is non-qualifiable', value: 'non_qualifiable' },
            { label: 'Case has insufficient data', value: 'insufficient_data' },
            {
              label: 'Follow-up to Existing Case',
              value: 'follow_up_existing_case'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]}
          value={archiveReason}
          onChange={value => this.handleArchiveChange('archiveReason', value)}
          locale="US"
        />
        <TextArea
          label="Comments"
          value={archiveComments}
          required
          validations={{
            required: {
              constraint: true
            }
          }}
          onChange={value => this.handleArchiveChange('archiveComments', value)}
          styles={{
            height: '175px'
          }}
          locale="US"
        />
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={this.handleDismiss}>No, cancel</SimpleButton>
          <SimpleButton
            onClick={this.handleArchiveConfirm}
            primary
            disabled={isDisabled}
          >
            Yes, continue
          </SimpleButton>
        </div>
      </div>
    );
  };

  handleShowArchiveModal = newState => {
    const { actions } = this.props;
    // Use explicit new state object for modal's form update and this.state for initial load
    const content = this.renderArchiveModal(newState || this.state);
    actions.emitModalContentUpdate(content);
  };

  getAccessRole = () => {
    const { match, selectedItemsToAssignOrArchive } = this.props;
    const shouldDisableOptions = selectedItemsToAssignOrArchive.length <= 0;
    const affiliate = window.affiliateCode
      ? window.affiliateCode.toLowerCase()
      : '';
    const currentPage = match.params.page;
    switch (currentPage) {
      case 'ae':
        return shouldDisableOptions ? '' : 'edit-ae';
      case 'pq':
        return shouldDisableOptions ? '' : 'edit-pq';
      default:
        return shouldDisableOptions ? '' : `access-${affiliate}`;
    }
  };

  ExportToCSV = () => (
    <AuthorizedLink
      accessRole={this.getAccessRole()}
      override
      disabled={this.props.disabled}
      onClick={() => this.props.handleExportToCSV()}
      role="button"
    >
      Export to csv
    </AuthorizedLink>
  );

  reassignSubcaseConfirm = assignee => {
    this.props.assignCaseOrTask(assignee);
  };
  assignSubcaseModal = () => {
    const { actions } = this.props;
    const content = (
      <ReassignAssigneeModal
        {...this.props}
        title="REASSIGN CASE"
        handleAssign={assignTo => this.reassignSubcaseConfirm(assignTo)}
      />
    );
    actions.emitModalContentUpdate(content, true);
  };

  renderAssignToUserLink = () => (
    <AuthorizedLink
      accessRole={this.getAccessRole()}
      onClick={() => this.assignSubcaseModal()}
      role="button"
    >
      Assign to another Trilogy user
    </AuthorizedLink>
  );

  renderAssignToMeLink = () => (
    <AuthorizedLink
      accessRole={this.getAccessRole()}
      onClick={() => this.props.assignCaseOrTask()}
      role="button"
    >
      Assign to me
    </AuthorizedLink>
  );

  renderArchiveCaseLink = () => {
    const { match } = this.props;
    const currentPage = match.params.page;
    if (!currentPage) {
      return null;
    }
    return (
      <AuthorizedLink
        accessRole={this.getAccessRole()}
        role="link"
        onClick={() => this.handleShowArchiveModal(null)}
      >
        Archive case
      </AuthorizedLink>
    );
  };

  renderActionMenuLinks = () => {
    const { computedStyles } = this.props;
    return (
      <div className={computedStyles.actionMenu}>
        <CaseActionsMenu>
          <div>
            {this.renderAssignToMeLink()}
            {this.renderAssignToUserLink()}
            {this.ExportToCSV()}
            {this.renderArchiveCaseLink()}
          </div>
        </CaseActionsMenu>
      </div>
    );
  };

  renderActionMenuLinksForExport = () => {
    const { computedStyles } = this.props;
    return (
      <div className={computedStyles.actionMenu}>
        <CaseActionsMenu>
          <div>{this.ExportToCSV()}</div>
        </CaseActionsMenu>
      </div>
    );
  };

  render() {
    const {
      computedStyles,
      match,
      disabled,
      customizeQueueResults,
      appliedFilters
    } = this.props;
    const currentPage = match.params.page;
    return (
      <div className={computedStyles.base}>
        <TabHeader title={this.title()} />
        <div>
          {!disabled && currentPage !== 'mi'
            ? this.renderActionMenuLinks()
            : ''}
        </div>
        <div className={computedStyles.tableHeader}>
          <div>
            {this.renderStatusFilters(currentPage)}
            {this.renderReconciliationFilter(currentPage)}
            {this.renderSubmittedFilter(currentPage)}
          </div>
          <div>
            {customizeQueueResults}
            {this.renderSubCaseFilters(currentPage)}
            {appliedFilters}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(QueueHeader);
