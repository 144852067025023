import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'Common/components/Form';
import { handleKeyPress } from 'Common/utils';
import successCheckmark from 'public/images/success-checkmark.svg';
import stylesGenerator from './styles';

const CompleteTask = props => {
  const { isEditing, computedStyles, task, emitTaskComplete } = props;

  if (!isEditing) {
    if (task.status === 'COMPLETED') {
      return (
        <div>
          <img
            src={successCheckmark}
            className={computedStyles.successCheckmark}
          />
          <span className={computedStyles.completed}>Completed</span>
        </div>
      );
    }
    if (task.status === 'OPEN') {
      const handleClick = () => emitTaskComplete(task);
      return (
        <span className={computedStyles.action}>
          <a
            tabIndex="0"
            onClick={handleClick}
            id="completeTask"
            role="button"
            onKeyPress={handleKeyPress(handleClick, ' ', 'Enter')}
          >
            Complete Task
          </a>
        </span>
      );
    }
    if (task.status === 'CANCELLED') {
      return <span className={computedStyles.cancelled}>Cancelled</span>;
    }
  }
  return null;
};

CompleteTask.propTypes = {
  isEditing: PropTypes.bool,
  emitTaskComplete: PropTypes.func.isRequired,
  task: PropTypes.shape({
    form: PropTypes.shape({
      subcaseType: PropTypes.string
    })
  }).isRequired,
  computedStyles: PropTypes.shape({
    action: PropTypes.object.isRequired,
    successCheckmark: PropTypes.object.isRequired,
    completed: PropTypes.object.isRequired,
    cancelled: PropTypes.object.isRequired
  }).isRequired
};

CompleteTask.defaultProps = {
  isEditing: null
};

export default withStyles(stylesGenerator)(CompleteTask);
