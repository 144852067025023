import React, { PureComponent, Fragment } from 'react';
import { Redirect, matchPath } from 'react-router';
import PropTypes from 'prop-types';
import {
  get,
  set,
  cloneDeep,
  includes,
  isEmpty,
  isNil,
  defer,
  has,
  every,
  isUndefined
} from 'lodash';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { isLoggedIn } from 'config/auth';

import fetchCaseReconciliationDelta from 'api/rest/fetchCaseReconciliationDelta';

import Notifier from 'Common/components/Notifier';
import NavigationIntercept from 'Common/components/NavigationIntercept';

import {
  FORM_BASE_PATH,
  CONFLICT_ERRORS,
  CASE_FORM_PATH,
  AE_STATE_PATHS,
  MI_STATE_PATHS,
  PQ_STATE_PATHS,
  MASTER_STATE_PATHS,
  AE_TYPES,
  PQ_TYPES,
  SUBCASE_TYPE_PATHS,
  RECONCILIATION_PATH_MAP
} from 'CreateCase/constants';

import {
  getOrElse,
  isSubcaseRoute,
  unSubscribe,
  subscribeToCase,
  promptDocumentLocked,
  promptDocumentSaved,
  getAssignedUserName,
  isDummyUserOrGroup
} from 'Common/utils';
import {
  SCHEMA_PATH_FORM,
  OPEN_DUPE_SEARCH,
  PARENT_CASE,
  CMS_PROP_TYPES,
  PAGE_STATUS
} from 'Common/constants';
import {
  setSubcaseFlagOnMasterCase,
  getNextFormRoute,
  hasConflicts,
  assignSubcase,
  assigneeNameSubcase,
  setCreatedDate,
  submitSubcase,
  completeSubcase,
  resetActionFlags,
  findCreatedPath,
  genCountryOfPrimaryReporter,
  lastIndexForGroup,
  writeToExistingInstance,
  searchCriteriaCopy,
  DUPLICATE_SEARCH_WINDOW,
  shouldRedirectDueToCaseRules,
  filterReportedCategories
} from 'CreateCase/utils';
import SendModal from 'Common/components/SendModal';
import withModal, { modalStyles } from 'Common/components/withModal';
import {
  generateCSS,
  RadioGroup,
  SimpleButton,
  TextArea
} from 'Common/components/Form';
import SUB_GQL from 'api/graphql/subscriptions/caseSubscription';
import { subscriptionClient } from 'config/apollo';
import {
  LOOKUP_CONTACT,
  LOOKUP_PATIENT,
  LOOKUP_BASE_PATH,
  LOOKUP_WINDOW_NAME
} from 'Lookup/constants';
import { QUEUE_BASE_PATH, QUEUE_YOUR_CASES } from 'Queue/constants';
import Layout from './Layout';
import ConflictSaveErrorModal from './ConflictSaveErrorModal';
import MultipleEditorsWarningModal from './MultipleEditorsWarningModal';
import SameEditorWarningModal from './SameEditorWarningModal';
import { SUBCASE_INITIAL_VERSION } from '../utils/case';
import { isAuthorized } from '../../config/auth';

const uuidv4 = require('uuid/v4');

class CreateCaseController extends PureComponent {
  static propTypes = {
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired,
    hasSaved: PropTypes.bool.isRequired,
    caseStatus: PropTypes.string.isRequired,
    isLinkingCase: PropTypes.bool.isRequired,
    isCaseLocked: PropTypes.bool,
    isCaseLockedManualDismiss: PropTypes.bool,
    isFetchingSchema: PropTypes.bool.isRequired,
    isFetchingTacticalData: PropTypes.bool.isRequired,
    hasCaseAndTaskFragments: PropTypes.bool.isRequired,
    isSavingCase: PropTypes.bool.isRequired,
    isRegisteringDuplicateSearch: PropTypes.bool.isRequired,
    schema: CMS_PROP_TYPES.schema.isRequired,
    modalContent: PropTypes.node.isRequired,
    actions: PropTypes.shape({
      emitInputUpdate: PropTypes.func.isRequired,
      emitInputBatchUpdate: PropTypes.func.isRequired,
      emitSchemaFetch: PropTypes.func.isRequired,
      emitLinkCases: PropTypes.func.isRequired,
      emitUnlinkCases: PropTypes.func.isRequired,
      emitSaveCase: PropTypes.func.isRequired,
      emitFetchNewCaseId: PropTypes.func.isRequired,
      emitFetchCaseById: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired,
      emitCaseReset: PropTypes.func.isRequired,
      emitRegisterDuplicateSearch: PropTypes.func.isRequired,
      emitLockCase: PropTypes.func.isRequired,
      emitLockCaseManualDismiss: PropTypes.func.isRequired,
      emitUnlockCase: PropTypes.func.isRequired,
      emitUnlockCaseCompletely: PropTypes.func.isRequired,
      emitNavTabSelected: PropTypes.func.isRequired,
      emitMergeCases: PropTypes.func.isRequired,
      emitSetReconciliation: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        page: PropTypes.string,
        masterCaseId: PropTypes.string
      })
    }).isRequired,
    session: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
    ).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string
    }),
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    caseIdAttempts: PropTypes.number.isRequired,
    reconciliationFetched: PropTypes.bool.isRequired,
    reconciliationData: PropTypes.arrayOf(PropTypes.any).isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData
  };

  static defaultProps = {
    location: {},
    isCaseLocked: false,
    isCaseLockedManualDismiss: false,
    tacticalData: {}
  };

  state = {
    isCompletingWithoutSubmission: false,
    completedReason: '',
    completedReasonComments: ''
  };

  static SubcaseAssigneePath = {
    ae: AE_STATE_PATHS.ASSIGNEE,
    mi: MI_STATE_PATHS.ASSIGNEE,
    pq: PQ_STATE_PATHS.ASSIGNEE
  };

  componentDidMount() {
    const { schema, actions } = this.props;

    if (isEmpty(schema.pages)) {
      actions.emitSchemaFetch(SCHEMA_PATH_FORM);
    }

    actions.emitNavTabSelected('none');
    window.addEventListener('beforeunload', this.handleClosePage);

    this.resolveNewCase();
  }

  fetchReconciliationData(forceFetch = false) {
    const { trilogyCase, reconciliationFetched } = this.props;
    const caseId = get(this.props, 'trilogyCase.id');

    if (!caseId) return;

    const AEneedsReconciliation = get(trilogyCase, RECONCILIATION_PATH_MAP.ae);
    const PQneedsReconciliation = get(trilogyCase, RECONCILIATION_PATH_MAP.pq);

    // Load reconciliation data as needed.
    if (
      ((AEneedsReconciliation || PQneedsReconciliation) &&
        !reconciliationFetched) ||
      forceFetch
    ) {
      fetchCaseReconciliationDelta(
        caseId,
        this.setReconciliationData,
        // this is intentionally using the same callback here, endpoints
        // don't return JSON-responses on non-200 requests
        () => this.setReconciliationData([])
      );
    } else if (!reconciliationFetched) {
      // If no reconciliation was needed, set it to empty so that we don't unintentionally refetch on re-renders
      this.setReconciliationData([]);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      actions,
      isCaseLocked,
      isCaseLockedManualDismiss,
      location
    } = this.props;

    // Check for duplicate search parameter
    if (includes(get(location, 'search', ''), OPEN_DUPE_SEARCH)) {
      // Reset the search parameter
      actions.replace({
        search: ''
      });
      actions.emitRegisterDuplicateSearch(true);
    }

    if (!isCaseLocked && !isCaseLockedManualDismiss) {
      document.body.addEventListener('keydown', this.handleKeys, true);
    } else {
      document.body.removeEventListener('keydown', this.handleKeys, true);
    }

    this.resolveNewCase(prevProps.match.params.masterCaseId);
    this.fetchReconciliationData();
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps;
    // If a subcase form was intialized on dashboard, hide it again if navigating away
    if (match.tab !== 'dashboard' && this.state.newSubcaseType) {
      this.setState({ newSubcaseType: null });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.emitCaseReset();
    actions.emitUnlockCaseCompletely();
    this.unsubscribeCase();
    window.removeEventListener('beforeunload', this.handleClosePage);
    document.body.removeEventListener('keydown', this.handleKeys, true);
  }

  setReconciliationData = reconciliationData =>
    this.props.actions.emitSetReconciliation(reconciliationData || []);

  onFormValidated = () => {
    const { actions, match, trilogyCase } = this.props;
    const isSubcase = isSubcaseRoute(match);
    const isAESubcase = match.params.page === 'ae';
    const isPQSubcase = match.params.page === 'pq';
    const isAEFollowUp =
      getOrElse(trilogyCase, AE_STATE_PATHS.SAFETY_VERSION) ===
      AE_TYPES.FOLLOW_UP;
    const isAEInitialVersion =
      getOrElse(
        trilogyCase,
        AE_STATE_PATHS.VERSION,
        SUBCASE_INITIAL_VERSION
      ) === SUBCASE_INITIAL_VERSION;

    const isPQFollowUp =
      getOrElse(trilogyCase, MASTER_STATE_PATHS.PQ_TYPE) === PQ_TYPES.FOLLOW_UP;
    const isPQInitialVersion =
      getOrElse(
        trilogyCase,
        PQ_STATE_PATHS.VERSION,
        SUBCASE_INITIAL_VERSION
      ) === SUBCASE_INITIAL_VERSION;

    if (isSubcase && this.state.isCompletingWithoutSubmission) {
      this.handleShowCompletedModal();
    } else if (isSubcase) {
      if (
        (isAESubcase && isAEFollowUp && !isAEInitialVersion) ||
        (isPQSubcase && isPQFollowUp && !isPQInitialVersion)
      ) {
        // Cleared Fields modal
        const content = this.renderClearedFieldsAcknowledgement(
          this.handleSubcaseSubmit,
          actions.emitModalContentClear
        );
        actions.emitModalContentUpdate(content);
      } else if (isPQSubcase && this.checkProcessingInfoSent() !== -1) {
        // we handle sending replacement/credit separate from submissions
        // this is to block the agent from submitting until they
        // send a request if the subcase follows that flow
        Notifier.show({
          message:
            'Please fill out required fields for product credit/replacement and send prior to submitting this case.',
          intent: Notifier.DANGER
        });
      } else {
        // Subcase submission path
        this.handleSubcaseSubmit();
      }
    } else {
      // Reset `newSubcaseType` (used on mastercase dash)
      this.setState({ newSubcaseType: null });
      // Set the created date (if not previously set) for any selected subcases
      const updatedCase = setCreatedDate(trilogyCase);
      actions.emitInputBatchUpdate(updatedCase);
      actions
        .emitSaveCase(updatedCase, match.params, true)
        .then(this.handleFormSubmitSuccess, this.genHandleFormSubmitError);
    }

    // Reset completed action state
    this.setState({ isCompletingWithoutSubmission: false });
  };

  // returns -1 if the user is safe to submit
  // returns a positive integer if user needs to send a PQ replacement/credit request
  checkProcessingInfoSent = () => {
    const { trilogyCase } = this.props;
    const products = get(
      trilogyCase,
      'subcases.productQuality.pqproduct.products',
      []
    );
    return products.findIndex(product => {
      const replacement = get(product, 'complaint.processing.replacement');
      const forwardTo = get(product, 'complaint.processing.forwardTo');
      const sentDate = get(
        product,
        'complaint.processing.lastCustomerServiceEmailSentDate'
      );
      return (
        (replacement === 'USCreditOnly' ||
          (replacement === 'yes' &&
            (forwardTo === 'US Customer Service' ||
              forwardTo === 'US Pharmacy Solutions'))) &&
        !sentDate
      );
    });
  };

  resolveNewCase = prevMasterCaseId => {
    const {
      actions,
      match,
      trilogyCase,
      isRegisteringDuplicateSearch,
      caseStatus,
      isLinkingCase,
      isFetchingSchema,
      isFetchingTacticalData,
      hasCaseAndTaskFragments,
      isSavingCase,
      caseIdAttempts,
      history
    } = this.props;

    if (
      isSavingCase ||
      (isFetchingSchema && isFetchingTacticalData) ||
      !hasCaseAndTaskFragments ||
      caseStatus === PAGE_STATUS.LOADING ||
      isLinkingCase
    ) {
      return;
    }

    const { masterCaseId } = match.params;
    const fetchCase = masterCaseId && trilogyCase.id !== masterCaseId;
    const fetchNewCase =
      !masterCaseId && (prevMasterCaseId !== masterCaseId || !trilogyCase.id);

    if (fetchNewCase) {
      actions.emitUnlockCaseCompletely();
      this.unsubscribeCase();
      if (caseIdAttempts > 3) {
        history.push('/queue/yours');
      } else {
        actions.emitFetchNewCaseId().then(this.handleFetchCaseSuccess);
      }
    } else if (fetchCase) {
      actions.emitUnlockCaseCompletely();
      this.unsubscribeCase();
      actions
        .emitFetchCaseById(masterCaseId)
        .then(this.handleFetchCaseSuccess, this.handleFetchCaseFailure);
    } else if (trilogyCase.id && !masterCaseId) {
      // replace the URL with the new `trilogyCase.id` ONLY if trilogyCase has an ID
      actions.replace(`/${FORM_BASE_PATH}/${trilogyCase.id}`);
    } else if (!masterCaseId && prevMasterCaseId) {
      actions.emitCaseReset();
    } else if (isRegisteringDuplicateSearch) {
      this.registerDuplicateSearchWindow();
    }
  };

  unsubscribeCase = () => {
    unSubscribe(Notifier.clear, this.subscriptionObservable);
    this.subscriptionObservable = null;
  };

  redirect = destination => {
    const { actions } = this.props;
    actions.emitCaseReset();
    actions.push(destination);
  };

  handleClosePage = e => {
    const { hasSaved } = this.props;
    if (!hasSaved && isLoggedIn()) {
      const confirmationMessage =
        'Changes you made may not be saved. Do you want to leave this site?';
      e.returnValue = confirmationMessage;
      // Some WebKit-based browsers don't follow the spec
      return confirmationMessage;
    }
    return undefined;
  };

  caseStatusIsInSavingOrFailedState = () =>
    get(this.props, 'caseStatus') === PAGE_STATUS.SAVING ||
    get(this.props, 'caseStatus') === PAGE_STATUS.FAILED;

  handleKeys = e => {
    if ((e.ctrlKey || e.metaKey) && (e.key === 'S' || e.key === 's')) {
      e.preventDefault();
      const prevActive = document.activeElement;
      if (document.activeElement) document.activeElement.blur();
      if (
        !this.isReadOnly() &&
        this.props.trilogyCase &&
        !this.caseStatusIsInSavingOrFailedState()
      ) {
        this.handleSaveCase(this.props.trilogyCase).then(() => {
          if (prevActive) prevActive.focus();
        });
      }
    }
  };

  isReadOnly = () =>
    this.isArchivedOrCompleted() ||
    this.isCreatedAndAtPageCreation() ||
    this.isUnauthorized();

  isArchivedOrCompleted = () => {
    const { match, trilogyCase } = this.props;
    const completedStatusRE = /COMPLETED|ARCHIVED/;

    switch (getOrElse(match, 'params.page')) {
      case 'ae':
        return completedStatusRE.test(
          getOrElse(trilogyCase, 'subcases.adverseEvent.status', '')
        );
      case 'mi':
        return completedStatusRE.test(
          getOrElse(trilogyCase, 'subcases.medicalInfo.status', '')
        );
      case 'pq':
        return completedStatusRE.test(
          getOrElse(trilogyCase, 'subcases.productQuality.status', '')
        );
      default:
        return completedStatusRE.test(trilogyCase.status);
    }
  };

  isCreatedAndAtPageCreation = () => {
    const {
      trilogyCase,
      match: { params: { masterCaseId, page } }
    } = this.props;
    return !isNil(trilogyCase.created) && masterCaseId && !page;
  };

  isUnauthorized = () => {
    const { match: { params: { page } } } = this.props;
    return !!page && page !== 'review' && !isAuthorized(`edit-${page}`);
  };

  handleFetchCaseSuccess = () => {
    const { match, trilogyCase, actions } = this.props;
    // check case rules for navigation and determine if we need to redirect from original destination
    const rulesResult = shouldRedirectDueToCaseRules(trilogyCase, match);
    const currentModalContent = () => this.props.modalContent;
    if (rulesResult.redirect) {
      this.redirect(rulesResult.redirectTo);
    } else if (match.params.masterCaseId) {
      this.subscribe(match.params.masterCaseId, {
        showMultipleEditorsWarningModal: data =>
          this.showMultipleEditorsWarningModal(data),
        showSameEditorWarningModal: () => this.showSameEditorWarningModal(),
        unlockAndDismissModal: () => {
          actions.emitUnlockCase();
          const currentModal = currentModalContent();
          const modalName = get(currentModal.props, 'modalName', null);
          // Limit modal dismissal to the same editor warning and multiple editors modals.
          if (
            currentModal !== undefined &&
            !!currentModal &&
            (modalName === 'SameEditorWarningModal' ||
              modalName === 'MultipleEditorsWarningModal')
          ) {
            actions.emitModalContentClear();
          }
        }
      });
    }
  };

  showMultipleEditorsWarningModal = data => {
    const { actions } = this.props;
    const content = (
      <MultipleEditorsWarningModal data={data} actions={actions} />
    );
    actions.emitModalContentUpdate(content, true, false);
  };

  showSameEditorWarningModal = () => {
    const { actions } = this.props;
    const content = <SameEditorWarningModal actions={actions} />;
    actions.emitModalContentUpdate(content, true, false);
  };

  handleFetchCaseFailure = () => {
    this.redirect('/404');
  };

  handleFormSubmitSuccess = () => {
    const { actions, trilogyCase } = this.props;
    const nextRoute = getNextFormRoute(trilogyCase);
    actions.push(nextRoute);
  };

  genHandleFormSubmitError = (err = {}) => {
    const { actions } = this.props;
    const { graphQLErrors } = err;
    const wasAlreadySubmitted = findCreatedPath(graphQLErrors[0]);

    if (wasAlreadySubmitted) {
      Notifier.show({
        message: CONFLICT_ERRORS.CASE_EXISTS,
        intent: Notifier.DANGER
      });
    } else if (hasConflicts(graphQLErrors)) {
      // Once a user receives a conflict they will continue to do so until they refresh the page so this warning isn't
      // needed anymore as the user can't save at all.
      window.removeEventListener('beforeunload', this.handleClosePage);
      const content = <ConflictSaveErrorModal />;
      actions.emitModalContentUpdate(content);
    } else {
      actions.emitModalContentClear();
      this.focusFirstInvalidField();
    }
  };

  handleAssignSubcase = subcaseType => {
    const { session, trilogyCase, tacticalData } = this.props;
    const userId = session.sub;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const assigneeName = getAssignedUserName(users, userId, session);
    // Statepath gets resolved from the onClick of the LabelValue
    assigneeNameSubcase(trilogyCase, subcaseType, assigneeName);
    const updatedCase = assignSubcase(trilogyCase, subcaseType, session.sub);
    this.handleSaveCase(updatedCase);
  };

  handleSubcaseCreation = subcaseType => {
    const {
      trilogyCase,
      isCaseLocked,
      match,
      isCaseLockedManualDismiss,
      actions
    } = this.props;

    const caseWithSubcase = setCreatedDate(
      setSubcaseFlagOnMasterCase(trilogyCase, subcaseType)
    );

    if (!isCaseLocked && !isCaseLockedManualDismiss) {
      return actions
        .emitSaveCase(caseWithSubcase, match.params, true)
        .then(this.handleCaseSaveSuccess, this.genHandleFormSubmitError);
    }
    return Promise.reject();
  };

  handleDashboardSubcaseCreation = subcaseType => {
    const newCase = set(
      this.props.trilogyCase,
      SUBCASE_TYPE_PATHS[subcaseType],
      true
    );
    this.setState({ newSubcaseType: subcaseType }, () =>
      this.props.actions.emitInputBatchUpdate(newCase)
    );
  };

  handleCompleteNoSubmit = cseInstance => {
    // Indicate to `onFormValidated` that this shouldn't be treated as submission
    this.setState(
      prevState => ({
        ...prevState,
        isCompletingWithoutSubmission: true
      }),
      () => defer(cseInstance.actions.emitValidations)
    );
  };

  handleCompleteNoSubmitConfirmed = () => {
    const { completedReason, completedReasonComments } = this.state;
    const { actions, trilogyCase, match, tacticalData, session } = this.props;
    const subcase = match.params.page;

    // auto-assign case to current user if unassigned
    let assignee;
    let updatedCase = trilogyCase;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const assigneeName = getAssignedUserName(users, session.sub, session);
    if (subcase === 'ae') {
      assignee = trilogyCase.subcases.adverseEvent.assignee;
    } else if (subcase === 'pq') {
      assignee = trilogyCase.subcases.productQuality.assignee;
    }
    if (!assignee) {
      updatedCase = assignSubcase(trilogyCase, subcase, session.sub);
      updatedCase = assigneeNameSubcase(updatedCase, subcase, assigneeName);
    }

    const completedCase = completeSubcase(
      updatedCase,
      match.params.page,
      completedReason,
      completedReasonComments
    );
    this.handleSaveCase(completedCase).then(savedTrilogyCase => {
      actions.emitInputBatchUpdate(
        resetActionFlags(savedTrilogyCase, subcase),
        true
      );
      this.fetchReconciliationData(true);
    });
    this.handleHideCompletedModal();
  };

  handleNavigateToSubcase = subCasePath => {
    const { actions, match } = this.props;
    actions.push(
      `/${FORM_BASE_PATH}/${match.params.masterCaseId}/${subCasePath}`
    );
  };

  isValidRoute = () => {
    const { masterCaseId, page } = this.props.match.params;
    return masterCaseId || !page;
  };

  focusFirstInvalidField = errors => {
    // Reset completed action state
    this.setState({ isCompletingWithoutSubmission: false });

    if (!errors) {
      return;
    }
    const activeElement = get(document.activeElement, 'tagName', null);
    // Don't move the user's focus
    if (/text|input|select/.test(activeElement)) return;

    const elSchemaPath = Object.keys(errors)[0];
    const firstEl = document.querySelector(
      `[data-schema-path="${elSchemaPath}"]`
    );

    const input = firstEl ? firstEl.querySelector('select,input') : null;

    if (input) {
      input.focus();
    } else if (firstEl) {
      firstEl.focus();
    }
  };

  /**
   * Save the case passed in to the first parameter. Defaults to existing trilogyCase on props.
   * @param caseToSave
   * @returns {Promise<never> | Promise<any>}
   */
  handleSaveCase = caseToSave => {
    const {
      actions,
      match,
      isCaseLocked,
      isCaseLockedManualDismiss
    } = this.props;

    if (caseToSave && this.caseStatusIsInSavingOrFailedState()) {
      return Promise.resolve();
    }
    if (caseToSave && !isCaseLocked && !isCaseLockedManualDismiss) {
      const updatedCase = Object.assign({}, caseToSave);
      const isPQSubcase = match.params.page === 'pq';
      if (isPQSubcase) {
        const complaintData = get(
          updatedCase,
          'subcases.productQuality.complaint.reportedCategories'
        );
        if (complaintData && !isNil(complaintData)) {
          filterReportedCategories(
            updatedCase,
            'subcases.productQuality.complaint.reportedCategories',
            complaintData
          );
        }
      }

      const isPageToReview = match.params.page === 'review';
      if (isPageToReview) {
        const isPQCase = get(
          updatedCase,
          'summary.narrative.categories.product_quality'
        );
        if (isPQCase) {
          const hasPQData = get(updatedCase, 'subcases.productQuality');

          if (
            isNil(hasPQData) ||
            isEmpty(hasPQData) ||
            isUndefined(hasPQData) ||
            every(
              hasPQData,
              element =>
                isNil(element) || isEmpty(element) || isUndefined(element)
            )
          ) {
            set(
              updatedCase,
              'summary.narrative.categories.product_quality',
              false
            );
          }
        }

        const isAECase = get(
          updatedCase,
          'summary.narrative.categories.adverse_event'
        );
        if (isAECase) {
          const hasAEData = get(updatedCase, 'subcases.adverseEvent');

          if (
            isNil(hasAEData) ||
            isEmpty(hasAEData) ||
            !has(hasAEData, 'aerinfo.safety.affiliate_awareness_date')
          ) {
            set(
              updatedCase,
              'summary.narrative.categories.adverse_event',
              false
            );
          }
        }
      }

      actions.emitInputBatchUpdate(updatedCase);

      return actions
        .emitSaveCase(caseToSave, match.params)
        .then(this.handleCaseSaveSuccess, this.genHandleFormSubmitError);
    }
    return Promise.reject();
  };

  /**
   * The first argument `e` is a CSE component when `saveCase` is called as
   * a `trigger` (save buttons)
   */
  // eslint-disable-next-line no-unused-vars
  handleSaveCaseCSE = e => this.handleSaveCase(this.props.trilogyCase);

  handleSaveOnConfirm = location => {
    const { actions } = this.props;
    const navigate = () => actions.push({ ...location, force: true });

    this.handleSaveCase().then(navigate);
  };

  handleSubmitConfirmedSuccess = () => {
    const { actions } = this.props;
    actions.push(`${QUEUE_BASE_PATH}/${QUEUE_YOUR_CASES}`);
  };

  handleCaseSaveSuccess = mutationSuccessPayload =>
    mutationSuccessPayload.payload;

  handleSubcaseSubmit = () => {
    const { actions, match, trilogyCase } = this.props;
    const isAESubcase = match.params.page === 'ae';
    const isPQSubcase = match.params.page === 'pq';
    const currentAssignee = get(
      trilogyCase,
      CreateCaseController.SubcaseAssigneePath[match.params.page]
    );
    const hasAssignee = !!currentAssignee;
    if (
      isDummyUserOrGroup(currentAssignee) ||
      ((isAESubcase || isPQSubcase) && !hasAssignee)
    ) {
      const subcase = match.params.page;
      const { tacticalData, session } = this.props;
      const userId = session.sub;
      const users = getOrElse(tacticalData, 'document-data.user-list', []);
      const assigneeName = getAssignedUserName(users, userId, session);
      const updatedCaseUser = assignSubcase(trilogyCase, subcase, userId);
      // Assigning case to logged in user
      assigneeNameSubcase(updatedCaseUser, subcase, assigneeName);
    }

    const updatedCase = submitSubcase(match, trilogyCase);
    if (isPQSubcase) {
      const complaintData = get(
        updatedCase,
        'subcases.productQuality.complaint.reportedCategories'
      );
      if (complaintData && !isNil(complaintData)) {
        filterReportedCategories(
          updatedCase,
          'subcases.productQuality.complaint.reportedCategories',
          complaintData
        );
      }
    }
    actions.emitInputBatchUpdate(updatedCase);
    actions
      .emitSaveCase(updatedCase, match.params)
      .then(this.handleSubmitConfirmedSuccess, this.genHandleFormSubmitError);
  };

  registerDuplicateSearchWindow = () => {
    const { actions, trilogyCase } = this.props;
    actions.emitRegisterDuplicateSearch(false);
    // save before new window
    actions
      .emitSaveCase(trilogyCase, this.props.match.params)
      .then(saveCaseSuccess => {
        if (!isNil(window[DUPLICATE_SEARCH_WINDOW])) {
          window[
            DUPLICATE_SEARCH_WINDOW
          ].copyClick = this.handleDuplicateSearchCopyContacts;
          window[DUPLICATE_SEARCH_WINDOW][PARENT_CASE] =
            saveCaseSuccess.payload;
        }
      });
  };

  // Copy contact or patient search _criteria_ to case
  handleDuplicateSearchCopyContacts = data => {
    const { actions, trilogyCase } = this.props;
    // Replace the last instance of the contact or patient input group with the lookup value
    const lastContactIndex = lastIndexForGroup(trilogyCase, 'contacts.contact');
    const lastPatientIndex = lastIndexForGroup(trilogyCase, 'patient.patient');

    const writeToExistingContact = writeToExistingInstance(
      trilogyCase,
      'contacts.contact',
      lastContactIndex
    );

    const contactAndPatientCriteria = searchCriteriaCopy(
      trilogyCase,
      data,
      writeToExistingContact ? lastContactIndex : lastContactIndex + 1,
      lastPatientIndex
    );

    actions.emitInputBatchUpdate(contactAndPatientCriteria);
  };

  // caseIdToMerge gets merged into parentCaseId. parentCaseId becomes archived.
  // cancelling should abandon the merge process and close the archive windows
  handleMergeAndArchiveCase = (
    parentCaseId,
    caseIdToMerge,
    archiveReason,
    archiveComments
  ) => {
    const { actions, trilogyCase } = this.props;
    const documentId = trilogyCase.id;
    if (caseIdToMerge) {
      actions
        .emitMergeCases(
          parentCaseId,
          caseIdToMerge,
          archiveComments,
          archiveReason
        )
        .then(
          () => {
            actions
              .emitFetchCaseById(documentId)
              .then(this.handleFetchCaseSuccess);
          },
          err => {
            console.log(`Error merging cases: ${err}`);
            Notifier.show({
              message: 'Could not merge cases',
              intent: Notifier.DANGER
            });
          }
        );
    }
  };

  // Unlinks a case from a list/InputGroup via a SmartButton trigger
  handleUnlinkCase = ({ $id }) => {
    const { actions, trilogyCase } = this.props;
    const errorUnlinking = err => {
      console.log(`Error unlinking cases: ${err}`);
      Notifier.show({
        message: 'Could not unlink cases',
        intent: Notifier.DANGER
      });
    };
    const statePath = `${$id}.id`;
    const caseId = get(trilogyCase, statePath, undefined);
    if (isNil(caseId)) {
      errorUnlinking('Could not get caseId from button reference');
    }
    const unlinkConfirm = () => {
      actions.emitModalContentClear();
      actions.emitUnlinkCases(trilogyCase.id, caseId).then(
        () => {
          actions
            .emitFetchCaseById(trilogyCase.id)
            .then(this.handleFetchCaseSuccess);
        },
        err => {
          errorUnlinking(err);
        }
      );
    };
    const unlinkModal = this.renderUnlinkModal(
      trilogyCase.id,
      caseId,
      unlinkConfirm,
      actions.emitModalContentClear
    );
    actions.emitModalContentUpdate(unlinkModal);
  };

  handleOpenCase = (_statepath, event) => {
    window.open(
      `/form/${event.value}/review/dashboard`,
      '_blank',
      'noopener, noreferrer'
    );
  };

  handleLookupContact = () => {
    this.handleOpenLookupWindow(LOOKUP_CONTACT);
  };

  handleLookupPatient = () => {
    this.handleOpenLookupWindow(LOOKUP_PATIENT);
  };

  // Add Contact or Patient search _result_ to Case (should be a new instance on the group)
  handleAddContact = contactToAdd => {
    const caseKey = 'contacts.contact';

    // Do not copy certain fields from the contact/patient to the case
    const contactOmitPaths = ['reporter.primary', 'source_reference', 'aer'];
    contactOmitPaths.forEach(p => {
      set(contactToAdd, p, null);
    });

    this.addContactOrPatient(caseKey, contactToAdd);
  };

  // Add Contact or Patient search _result_ to Case (should be a new instance on the group)
  handleAddPatient = patientToAdd => {
    const caseKey = 'patient.patient';

    // patient fields. we do this separately since contact also has a key 'reporter' but is different
    set(patientToAdd, 'reporter', null);

    this.addContactOrPatient(caseKey, patientToAdd);
  };

  addContactOrPatient = (caseKey, contactOrPatientToAdd) => {
    const { trilogyCase } = this.props;

    const { actions } = this.props;

    // Replace the last instance of the contact or patient input group with the lookup value
    const lastIndex = lastIndexForGroup(trilogyCase, caseKey);
    const writeToExistingContact = writeToExistingInstance(
      trilogyCase,
      caseKey,
      lastIndex
    );

    const updatedCase = cloneDeep(trilogyCase);
    set(
      updatedCase,
      `${caseKey}[${writeToExistingContact ? lastIndex : lastIndex + 1}]`,
      contactOrPatientToAdd
    );
    // Also update the countryOfPrimaryReporter
    const countryOfPrimaryReporter = genCountryOfPrimaryReporter(updatedCase);
    set(updatedCase, 'countryOfPrimaryReporter', countryOfPrimaryReporter);
    actions.emitInputBatchUpdate(updatedCase);
  };

  // Copy contact or patient search _criteria_ to case
  handleCopyCriteria = (lookupType, contactOrPatientCriteria) => {
    const { actions, trilogyCase } = this.props;
    const caseKey =
      lookupType === LOOKUP_CONTACT ? 'contacts.contact' : 'patient.patient';
    // Replace the last instance of the contact or patient input group with the lookup value
    const lastIndex = lastIndexForGroup(trilogyCase, caseKey);
    const writeToExisting = writeToExistingInstance(
      trilogyCase,
      caseKey,
      lastIndex
    );
    const updatedCaseWithCriteria = searchCriteriaCopy(
      trilogyCase,
      contactOrPatientCriteria,
      writeToExisting ? lastIndex : lastIndex + 1,
      lastIndex
    );
    actions.emitInputBatchUpdate(updatedCaseWithCriteria);
  };

  handleOpenLookupWindow = lookupType => {
    const windowOptions =
      'toolbar=0,location=0,menubar=0,width=1200,height=1200';
    window.abbvLookupWindow = window.open(
      `/${LOOKUP_BASE_PATH}/${lookupType}`,
      LOOKUP_WINDOW_NAME,
      windowOptions
    );
    window.abbvLookupWindow.handleAddContact = this.handleAddContact;
    window.abbvLookupWindow.handleAddPatient = this.handleAddPatient;
    window.abbvLookupWindow.handleCopyCriteria = this.handleCopyCriteria;
  };

  handleShowCompletedModal = newState => {
    const { actions } = this.props;
    // Use explicit new state object for modal's form update and this.state for initial load
    const content = this.renderCompleteModal(newState || this.state);
    actions.emitModalContentUpdate(content);
  };

  handleHideCompletedModal = () => {
    this.setState({
      completedReason: '',
      completedReasonComments: ''
    });
    this.props.actions.emitModalContentClear();
  };

  handleCompletedChange = (key, value) => {
    this.setState({ [key]: value });
    // Modal content needs an action to update its content,
    // so we must explicitly pass the new state for the modal content
    const newState = { ...this.state, [key]: value };
    this.handleShowCompletedModal(newState);
  };

  subscribe = (id, emitModalContentUpdate) => {
    const { session, actions } = this.props;
    const userClientId = uuidv4();
    if (!this.subscriptionObservable && process.env.BACKEND_ENV !== null) {
      this.subscriptionObservable = subscribeToCase(
        subscriptionClient(actions.emitUnlockCase, actions.emitLockCase),
        emitModalContentUpdate,
        {
          query: SUB_GQL,
          variables: { id, userClientId }
        },
        session,
        userClientId
      );
    }
  };

  shouldIntercept = (historyPathname = '/') => {
    const { history, hasSaved } = this.props;
    const pageMatch = matchPath(history.location.pathname, {
      path: CASE_FORM_PATH
    });
    const nextPageMatch = matchPath(historyPathname, {
      path: CASE_FORM_PATH
    });
    const pageParams = get(pageMatch, 'params', {});
    const nextPageParams = get(nextPageMatch, 'params', {});
    const leavingPage =
      pageParams.masterCaseId !== nextPageParams.masterCaseId ||
      pageParams.page !== nextPageParams.page;
    const isDuplicateSearch = historyPathname.includes('openDuplicateSearch');

    return !hasSaved && leavingPage && !isDuplicateSearch;
  };

  shouldNavigate = location => {
    const { isCaseLocked, isCaseLockedManualDismiss, actions } = this.props;

    if (isCaseLocked || isCaseLockedManualDismiss) {
      return promptDocumentLocked(location, this.handleSaveOnConfirm);
    }

    const shouldNavigate = !promptDocumentSaved(
      location,
      this.handleSaveOnConfirm
    );

    if (shouldNavigate) {
      actions.emitCaseReset();
    }

    return shouldNavigate;
  };

  renderAssigneeRequired = () => {
    const { actions } = this.props;
    const close = () => actions.emitModalContentClear();
    return (
      <div className={modalStyles.base}>
        <span className={modalStyles.title}>CASE MUST BE ASSIGNED</span>
        You must assign this case before marking the case as submitted.
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={close} primary>
            Back to Case
          </SimpleButton>
        </div>
      </div>
    );
  };

  renderCompleteModal = ({ completedReason, completedReasonComments }) => {
    const isDisabled = completedReason === '' || completedReasonComments === '';
    const inlineStyle = {
      maxWidth: '980px'
    };
    return (
      <div
        style={inlineStyle}
        className={`${modalStyles.base} ${modalStyles.tall}`}
      >
        <span className={modalStyles.title}>COMPLETE WITHOUT SUBMISSION</span>
        <RadioGroup
          className=""
          label="Please choose why this case should be complete without submission:"
          options={[
            {
              label: 'Document added/modified',
              value: 'document_modified'
            },
            { label: 'Task created/modified', value: 'task_modified' },
            {
              label: 'Version created in error',
              value: 'version_created_error'
            },
            {
              label: 'Version is non-submittable',
              value: 'version_nonsubmittable'
            },
            {
              label: 'Case is non-qualifiable',
              value: 'non_qualifiable'
            },
            {
              label: 'Not a PQC',
              value: 'not_pqc'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]}
          value={completedReason}
          onChange={value =>
            this.handleCompletedChange('completedReason', value)
          }
          locale="US"
        />
        <TextArea
          label="Comments"
          value={completedReasonComments}
          required
          validations={{
            required: {
              constraint: true
            }
          }}
          onChange={value =>
            this.handleCompletedChange('completedReasonComments', value)
          }
          styles={{
            height: '175px'
          }}
          locale="US"
        />
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={this.handleHideCompletedModal}>
            No, cancel
          </SimpleButton>
          <SimpleButton
            onClick={this.handleCompleteNoSubmitConfirmed}
            primary
            disabled={isDisabled}
          >
            Yes, continue
          </SimpleButton>
        </div>
      </div>
    );
  };

  renderClearedFieldsAcknowledgement = (handleConfirm, handleCancel) => (
    <div className={modalStyles.base}>
      <span className={modalStyles.title}>CLEARED FIELD(S)</span>
      Document information for any cleared field(s)
      <div className={modalStyles.buttonsContainer}>
        <SimpleButton onClick={() => handleCancel()}>
          Cancel Submission
        </SimpleButton>
        <SimpleButton onClick={() => handleConfirm()} primary>
          CONFIRM
        </SimpleButton>
      </div>
    </div>
  );

  renderLoading = () => {
    const loadingMessage = (
      <span>Please wait while we retrieve your case.</span>
    );
    return (
      <div className={generateCSS({ margin: '100px auto' })}>
        <NonIdealState
          visual={<Spinner />}
          title="Loading"
          description={loadingMessage}
        />
      </div>
    );
  };

  renderUnlinkModal = (caseId, linkedCaseId, onConfirm, onCancel) => (
    <div className={modalStyles.base}>
      <span className={modalStyles.title}>CONFIRM UNLINK CASE</span>
      Are you sure you wish to unlink&nbsp;
      {caseId} and {linkedCaseId}?&nbsp; Once you confirm:
      <ul>
        <li>
          {caseId} will no longer display&nbsp;
          {linkedCaseId} as a Trilogy Linked Case
        </li>
        <li>
          {linkedCaseId} will no longer display&nbsp;
          {caseId} as a Trilogy Linked Case
        </li>
      </ul>
      <div className={modalStyles.buttonsContainer}>
        <SimpleButton onClick={onCancel}>No, cancel</SimpleButton>
        <SimpleButton onClick={onConfirm} primary>
          Yes, unlink
        </SimpleButton>
      </div>
    </div>
  );

  handleCopyProduct = event => {
    const { actions, trilogyCase } = this.props;

    // get product from patient section
    const newProductFromPatient = get(trilogyCase, `${event.relativePath}`);

    // create new product for product section
    const newProduct = {
      reporter_causality: [
        {
          reporter_causality: null,
          alternative_etiology: null
        },
        {
          reporter_causality: null,
          alternative_etiology: null
        }
      ],
      product_indication: null,
      product_summary: [
        {
          drug_obtain_country: null,
          coding_class: null,
          suspect_product: null,
          other_product: newProductFromPatient.allergy_type,
          action_taken: null,
          local_trade_name: null,
          dechallenge: null,
          rechallenge: null,
          type: null,
          product_flag: 'past'
        }
      ],
      product_therapy: [
        {
          expiration_date: null
        }
      ]
    };

    // get product array and add new product
    const productArray = get(
      trilogyCase,
      'subcases.adverseEvent.product_section.aeproducts'
    );
    const newProductArray = productArray.concat([newProduct]);
    set(
      trilogyCase,
      'subcases.adverseEvent.product_section.aeproducts',
      newProductArray
    );
    actions.emitInputBatchUpdate(trilogyCase);
  };

  handleSend = event => {
    const { actions, tacticalData, trilogyCase } = this.props;
    const processingPath = get(event, '["../"].$id');
    const forwardTo = get(trilogyCase, `${processingPath}.forwardTo`);
    const patientPrivacy = get(trilogyCase, 'patient.patient[0].privacy');
    const prescriberPrivacy = get(
      get(trilogyCase, 'contacts.contact', []).find(
        contact => get(contact, 'pq.prescriber') === true
      ),
      'reporter.private'
    );
    if (
      patientPrivacy ||
      (forwardTo === 'US Pharmacy Solutions' && prescriberPrivacy)
    ) {
      Notifier.show({
        message:
          'Please uncheck the "Do Not Report Name (Privacy)" checkbox for the patient/prescriber in the Contacts Tab in order to send a product credit/replacement.',
        intent: Notifier.DANGER
      });
    } else {
      const productPath = get(event, '["../"].relativePath');
      const flow = `${get(
        trilogyCase,
        `${processingPath}.replacement`
      )}.${forwardTo}`;

      const content = (
        <SendModal
          flow={flow}
          productPath={productPath}
          tacticalData={tacticalData}
          trilogyCase={trilogyCase}
          actions={actions}
        />
      );
      actions.emitModalContentUpdate(content);
    }
  };

  renderForm = () => (
    <Layout
      {...this.props}
      isCreatingDashboardSubcase={!!this.state.newSubcaseType}
      newSubcaseType={this.state.newSubcaseType}
      onCompleteWithoutSubmit={this.handleCompleteNoSubmit}
      reconciliationFetched={this.props.reconciliationFetched}
      reconciliationData={this.props.reconciliationData}
      triggers={{
        on404: this.handleFetchCaseFailure,
        onSaveCase: this.handleSaveCase,
        onSaveCaseCSE: this.handleSaveCaseCSE,
        onSubmitValidationSuccess: this.onFormValidated,
        onSubmitValidationFailure: this.focusFirstInvalidField,
        onUnlinkCase: this.handleUnlinkCase,
        onAssignSubcase: this.handleAssignSubcase,
        onNavigateToSubcase: this.handleNavigateToSubcase,
        onDashboardSubcaseCreation: this.handleDashboardSubcaseCreation,
        onSubcaseCreation: this.handleSubcaseCreation,
        onLookupContact: this.handleLookupContact,
        onLookupPatient: this.handleLookupPatient,
        onMergeAndArchive: this.handleMergeAndArchiveCase,
        onOpenCase: this.handleOpenCase,
        onCreateProductPerAllergy: this.handleCopyProduct,
        onSend: this.handleSend
      }}
      registerDuplicateSearchWindow={this.registerDuplicateSearchWindow}
      isReadOnly={this.isReadOnly()}
      saveAndSubmitButtonDisabled={this.caseStatusIsInSavingOrFailedState()}
    />
  );

  renderRedirect = () => <Redirect to={{ pathname: '/form' }} />;

  render = () => {
    const {
      schema,
      isFetchingSchema,
      isFetchingTacticalData,
      caseStatus,
      trilogyCase
    } = this.props;
    const isLoading =
      !trilogyCase ||
      !trilogyCase.id ||
      (isFetchingSchema && isFetchingTacticalData);

    if (
      isEmpty(schema.pages) ||
      isLoading ||
      caseStatus === PAGE_STATUS.LOADING
    ) {
      return this.renderLoading();
    }

    return (
      <Fragment>
        <NavigationIntercept
          shouldIntercept={this.shouldIntercept}
          shouldNavigate={this.shouldNavigate}
        />
        {this.isValidRoute() ? this.renderForm() : this.renderRedirect()}
      </Fragment>
    );
  };
}

export default withModal(CreateCaseController);
