import { capitalize, isNil, startsWith } from 'lodash';
import { DUMMY_USER_GROUP_PATTERN } from 'Common/constants';

/** @module User Mapping Utils */

const generateUserName = user =>
  `${capitalize(user.fn)} ${capitalize(user.sn)}`;

/**
 * @function
 * @param {*} users
 */
export const usersList = users =>
  users
    .map(u => ({
      value: u.sub,
      label: `${capitalize(u.sn || '')}, ${capitalize(u.fn.trim() || '')}`
    }))
    .sort((a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0));

/**
 * @function
 * @param {*} users
 * @param {*} sub
 */
export const getUserName = (users = [], sub) => {
  const matchingUser = users.find(user => user.sub === sub);
  return isNil(matchingUser) ? null : generateUserName(matchingUser);
};

/**
 * @function
 * @param {*} users
 * @param {*} username
 */
export const getByUserName = (users = [], username) => {
  const matchingUser = users.find(user => user.un === username);
  return isNil(matchingUser) ? null : generateUserName(matchingUser);
};

/**
 * @function
 * @param {*} users
 * @param {*} username
 */
export const getAssignedUserName = (users = [], sub, session = {}) => {
  const matchingUser = users.find(user => user.sub === sub);
  return matchingUser
    ? `${capitalize(matchingUser.fn)} ${capitalize(matchingUser.sn)}`
    : `${capitalize(session.fn)} ${capitalize(session.sn)}`;
};

/**
 * @function
 * @param {*} users
 * @param {*} sub
 */
export const getUser = (users = [], sub) => {
  const matchingUser = users.find(user => user.sub === sub);
  return isNil(matchingUser) ? null : matchingUser;
};

/**
 * @function
 * @param {*} userObj
 */
export const getUserFirstLastNames = userObj => {
  const empty = {
    firstName: null,
    lastName: null
  };
  return isNil(userObj)
    ? empty
    : {
        firstName: userObj.fn || null,
        lastName: userObj.sn || null
      };
};

/**
 * @function
 * @param {*} userId
 */
export const isDummyUserOrGroup = (userId = '') => {
  if (isNil(userId)) return false;
  return startsWith(userId, DUMMY_USER_GROUP_PATTERN);
};
