import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, get, isEqual, defer, set, isNil, isEmpty } from 'lodash';
import SchemaUI from '@gmatas/cse';
import PropTypeSafety from 'Common/components/Form/PropTypeSafety';

import { CMS_PROP_TYPES } from 'Common/constants';
import { withStyles } from 'Common/components/Form';
import SectionsOverview from 'CreateCase/components/SectionsOverview';
import * as formComponents from 'Common/components/Form/formComponents';
import wrappedInputComponents from 'Common/components/Form/wrappedInputComponents';
import { DESKTOP_BREAKPOINT } from 'Common/styles';

import {
  abbvieDateFormat,
  abbvieDateFormatOnlyForFullDates,
  abbvieDateAndAtTime
} from 'Common/components/Form/utils';
import { getOrElse } from 'Common/utils';

import {
  genCountryOfPrimaryReporter,
  removeAEProduct,
  removeAEProtocol,
  removePQProduct
} from 'CreateCase/utils/case';

import Notifier from 'Common/components/Notifier';
import fetchProducts from 'api/graphql/fetchProducts';

import stylesGenerator from './styles';
import ProductLookUpModal from '../ProductLookUpModal';
import ProtocolLookupModal from '../ProtocolLookupModal';
import PQProductLookupModal from '../PQProductLookupModal';

class Page extends Component {
  static propTypes = {
    formRef: PropTypes.func,
    pageSchema: PropTypes.shape({
      title: PropTypes.string.isRequired,
      tabs: PropTypes.arrayOf(PropTypes.object).isRequired
    }).isRequired,
    pageChanged: PropTypes.bool.isRequired,
    computedStyles: PropTypes.shape({
      sectionsMap: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        tab: PropTypes.string,
        page: PropTypes.string,
        masterCaseId: PropTypes.string
      }).isRequired
    }).isRequired,
    clientWidth: PropTypes.number.isRequired,
    schema: CMS_PROP_TYPES.schema.isRequired,
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    actions: PropTypes.shape({
      emitSaveAttachments: PropTypes.func.isRequired,
      emitDownloadAttachment: PropTypes.func.isRequired,
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalConfirmChange: PropTypes.func.isRequired,
      emitModalConfirmDelete: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired,
      emitUpdateValidationErrors: PropTypes.func.isRequired,
      emitLockCase: PropTypes.func.isRequired,
      emitLockCaseManualDismiss: PropTypes.func.isRequired,
      emitSetNewVersion: PropTypes.func.isRequired,
      emitUnlockCase: PropTypes.func.isRequired,
      emitUnlockCaseCompletely: PropTypes.func.isRequired,
      emitFetchVersionDiff: PropTypes.func.isRequired
    }).isRequired,
    isSavingCase: PropTypes.bool.isRequired,
    isCaseLocked: PropTypes.bool,
    isCaseLockedManualDismiss: PropTypes.bool,
    isReadOnly: PropTypes.bool.isRequired,
    isNewVersion: PropTypes.bool.isRequired,
    triggers: PropTypes.shape({
      onSaveCase: PropTypes.func.isRequired
    }).isRequired,
    hasSaved: PropTypes.bool.isRequired,
    formValidationErrors: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.string)
    ).isRequired,
    lastUpdate: PropTypes.number,
    isCreatingDashboardSubcase: PropTypes.bool.isRequired,
    newSubcaseType: PropTypes.string,
    reconciliationData: PropTypes.arrayOf(PropTypes.string),
    versionDiff: PropTypes.shape({
      'TE-12345-AE01': PropTypes.arrayOf(PropTypes.string), // example keys
      'TE-12345-PQ02': PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    isFetchingVersionDiff: PropTypes.bool.isRequired
  };

  static defaultProps = {
    isCaseLocked: false,
    isCaseLockedManualDismiss: false,
    formRef: () => {},
    newSubcaseType: null,
    reconciliationData: [],
    lastUpdate: 0
  };

  state = { submitted: false };

  componentDidMount() {
    if (this.props.trilogyCase.contacts) this.setRACValueFromOldCheckbox();

    // Adds support for schema-editor bookmarklet
    if (process.env.NODE_ENV !== 'production') {
      window.UPDATE_TRILOGY_SCHEMA = updatedElementSchema => {
        this.form.actions.emitComponentUpdate(updatedElementSchema);
      };
    }
  }

  setRACValueFromOldCheckbox() {
    const { trilogyCase } = this.props;
    const contacts = trilogyCase.contacts.contact;

    contacts.forEach((contact, index) => {
      if (contact.pq) {
        const regAgency =
          'regulatory_agency_communication' in contact.pq
            ? contact.pq.regulatory_agency_communication
            : null;
        if (regAgency === 'true') {
          set(
            trilogyCase,
            `contacts.contact[${index}].pq.regulatory_agency_communication`,
            'Yes'
          );
        } else if (regAgency === 'false') {
          set(
            trilogyCase,
            `contacts.contact[${index}].pq.regulatory_agency_communication`,
            'No'
          );
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    // Comparing lastUpdate's of the case model. If the time is out-of-sync
    // with CSE's `lastUpdate` then UI has updated the model since.
    const cseLastUpdate = get(
      this.form,
      'state.lastUpdate',
      nextProps.lastUpdate
    );
    // Recon data is retrieved in `Layout/index`
    const newReconData =
      nextProps.reconciliationData.length !==
      this.props.reconciliationData.length;

    const newDiffData = !isEqual(this.props.versionDiff, nextProps.versionDiff);
    // See Layout for pageChanged description
    if (
      newDiffData ||
      newReconData ||
      nextProps.pageChanged ||
      nextProps.lastUpdate > cseLastUpdate
    ) {
      // only set submitted to false when re-setting the form, we don't want
      // to flip the flag if we're only updating due to `lastUpdate` sync
      this.setState({ submitted: !nextProps.pageChanged });

      this.form.actions.emitInitializeModel({
        model: nextProps.trilogyCase,
        data: this.getData(nextProps)
      });
    }
  }

  shouldComponentUpdate() {
    return this.modal !== true;
  }

  componentDidUpdate(prevProps) {
    this.checkIfCaseIsLocked(prevProps);

    if (this.props.isNewVersion) {
      this.form.actions.emitInitializeSchema(
        this.getSchema(this.props.pageSchema),
        this.getConfig(),
        this.props.trilogyCase,
        this.getData()
      );

      this.props.actions.emitSetNewVersion(false);
    }

    this.resolveVersionDiffs();
  }

  subcaseIdPathMap = {
    ae: 'trilogyCase.subcases.adverseEvent.id',
    pq: 'trilogyCase.subcases.productQuality.id',
    mi: 'trilogyCase.subcases.medicalInfo.id' // Future work
  };

  subcaseVersionPathMap = {
    ae: 'trilogyCase.subcases.adverseEvent.version',
    pq: 'trilogyCase.subcases.productQuality.version',
    mi: 'trilogyCase.subcases.medicalInfo.version'
  };

  cloneProduct = product => {
    const clonedProduct = {
      ...product,
      attachmentsSent: null,
      complaint: {
        ...product.complaint,
        processing: {
          ...product.complaint.processing,
          patientInfo: null,
          available: null,
          availableReason: null,
          sampleUnavailableReason: null,
          listNumber: null,
          marketedName: null,
          lastCustomerServiceEmailSentDate: undefined
        },
        reportedCategories: [
          {
            reportedCategory: null,
            reportedSubcategory: null
          }
        ]
      },
      details: [
        {
          ...product.details[0],
          additional_comments: null,
          availability: null,
          listNumber: undefined,
          lotNumber: undefined,
          marketedName: undefined,
          serialNumber: undefined,
          reason: null
        }
      ],
      tracking: {
        ...product.tracking,
        pqComments: null
      }
    };
    delete clonedProduct.trilogyProductId;
    return clonedProduct;
  };

  copyAndAddProduct = element => {
    const { actions, trilogyCase } = this.props;
    const productPath = get(element, '["../"].$id');

    // get productToBeCloned
    const productToBeCloned = get(trilogyCase, productPath);

    // clear attributes that should not be cloned
    const newProduct = this.cloneProduct(productToBeCloned);

    // add new product to the trilogy case
    const productArray = get(
      trilogyCase,
      'subcases.productQuality.pqproduct.products'
    );

    const newProductArray = productArray.concat([newProduct]);
    set(
      trilogyCase,
      'subcases.productQuality.pqproduct.products',
      newProductArray
    );
    actions.emitInputBatchUpdate(trilogyCase);
  };

  getData = nextProps => {
    // Need to use `nextProps` args since this can be called in `componentWillReceiveProps`
    const props = nextProps || this.props;
    const {
      tacticalData,
      isCaseLocked,
      isCaseLockedManualDismiss,
      isReadOnly,
      versionDiff,
      session,
      match,
      saveAndSubmitButtonDisabled
    } = props;

    const subcaseVersionId = get(
      this.props,
      this.subcaseIdPathMap[match.params.page]
    );

    return {
      ...tacticalData,
      diff: versionDiff[subcaseVersionId],
      submitted: this.state.submitted,
      reconciliation: props.reconciliationData,
      location: getOrElse(props, 'match.params', {}),
      userMap: getOrElse(tacticalData, 'document-data.user-list', []).reduce(
        (map, u) => ({ ...map, [u.sub]: `${u.fn} ${u.sn}` }),
        {}
      ),
      userList: getOrElse(tacticalData, 'document-data.user-list', []).map(
        u => ({
          label: `${u.fn} ${u.sn}`,
          value: u.sub
        })
      ),
      isReadOnly,
      buttonDisabled: saveAndSubmitButtonDisabled,
      isCaseLocked: isCaseLocked || isCaseLockedManualDismiss,
      roles: session.rs,
      isCreatingDashboardSubcase: this.props.isCreatingDashboardSubcase,
      newSubcaseType: this.props.newSubcaseType
    };
  };

  // Minor conversion to get existing for schema which has "tabs" and "sections" to follow the "elements" convention.
  getSchema = ({ tabs, ...pageSchema }) => ({
    ...pageSchema,
    elements: tabs.map(({ sections, ...tab }) => ({
      ...tab,
      elements: sections
    }))
  });

  getConfig = () => ({
    locale: this.props.schema.locale,
    index: { key: 'statePath', inherit: true },
    location: this.props.match.params
  });

  resolveVersionDiffs = () => {
    const { actions, match, isFetchingVersionDiff, versionDiff } = this.props;

    const subcaseVersionId = get(
      this.props,
      this.subcaseIdPathMap[match.params.page]
    );
    const versionNo = get(
      this.props,
      this.subcaseVersionPathMap[match.params.page]
    );
    if (
      versionNo > 0 &&
      isEmpty(versionDiff[subcaseVersionId]) &&
      !isFetchingVersionDiff
    ) {
      actions.emitFetchVersionDiff(subcaseVersionId);
    }
  };

  checkIfCaseIsLocked(prevProps) {
    const { trilogyCase, isCaseLocked, isCaseLockedManualDismiss } = this.props;

    const lockedStatusChanged =
      isCaseLocked !== prevProps.isCaseLocked ||
      isCaseLockedManualDismiss !== prevProps.isCaseLockedManualDismiss;

    if (lockedStatusChanged) {
      this.form.actions.emitInitializeModel({
        model: trilogyCase,
        data: this.getData()
      });
    }
  }

  handleValidate = () => {
    this.setState(
      prevState => ({
        ...prevState,
        submitted: true
      }),
      () => defer(this.form.actions.emitValidations)
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleInputUpdate = (action, state) => {
    if (
      !/ADD|REMOVE|INPUT_UPDATE|UPDATE_STATE|VALIDATIONS|INITIALIZE/.test(
        action.type
      )
    )
      return;
    const { actions, triggers } = this.props;
    /**
     * only emit an inputBatchUpdate action when there is
     * an actual change to avoid unnecessarily showing the warning modal when the user attempts to navigate away
     * and for performance reasons as well.
     */
    if (/ADD|REMOVE|INPUT_UPDATE|UPDATE_MODEL/.test(action.type)) {
      const newDocument = { ...state.model };
      // Also update country of primary reporter (if contacts or patient updated)
      set(
        newDocument,
        'countryOfPrimaryReporter',
        genCountryOfPrimaryReporter(newDocument)
      );

      const hasSaved = /UPDATE_MODEL/.test(action.type)
        ? this.props.hasSaved
        : false;

      actions.emitInputBatchUpdate(newDocument, hasSaved, state.lastUpdate);
    }

    if (!isEqual(state.errors, this.props.formValidationErrors)) {
      actions.emitUpdateValidationErrors(cloneDeep(state.errors));
    }

    if (action.type === 'EMIT_VALIDATIONS_PASS') {
      triggers.onSubmitValidationSuccess();
    } else if (action.type === 'EMIT_VALIDATIONS_FAIL') {
      triggers.onSubmitValidationFailure(action.payload.errors);
    }
  };

  handleConfirmModal = ({ title, text }, onConfirm) => {
    this.props.actions.emitModalConfirmChange(
      title,
      text,
      () => {
        this.modal = true;
        onConfirm();
        this.props.actions.emitModalContentClear();
        setTimeout(() => (this.modal = false), 10);
      },
      this.props.actions.emitModalContentClear
    );
  };

  handleProductLookUp = element => {
    const $id = element['../'].$id;
    const { actions, trilogyCase } = this.props;
    const onProductSelected = updatedCase => {
      actions.emitInputBatchUpdate(updatedCase);
      actions.emitModalContentClear();
      this.forceUpdate();
    };

    const content = (
      <ProductLookUpModal
        onProductSelected={onProductSelected}
        baseStatePath={$id}
        trilogyCase={trilogyCase}
      />
    );
    actions.emitModalContentUpdate(content);
  };

  handlePQBatchSearch = element => {
    const $id = element['../'].$id;
    const { actions, trilogyCase } = this.props;
    const updatedCase = cloneDeep(trilogyCase);
    const lotNumber = get(updatedCase, `${$id}.lotNumber`, null);
    if (!lotNumber || lotNumber === '') {
      return;
    }
    fetchProducts(lotNumber).then(data => {
      if (!data || data.length === 0) {
        // handle failure
        set(updatedCase, `${$id}.listNumber`, null);
        set(updatedCase, `${$id}.marketedName`, null);
        const message = data
          ? 'No results! Please verify the Batch/Lot Number.'
          : 'Something went wrong with the Batch/Lot Number lookup. Please use the List Number lookup instead.';
        Notifier.show({
          message,
          intent: Notifier.DANGER
        });
      } else if (data) {
        // handle success
        set(updatedCase, `${$id}.listNumber`, data[0].listNumber);
        set(updatedCase, `${$id}.marketedName`, data[0].trilogyMarketedName);
      }
      actions.emitInputBatchUpdate(updatedCase);
      actions.emitModalContentClear();
      this.forceUpdate();
    });
  };

  handlePQProductLookup = element => {
    const $id = element['../'].$id;
    const { actions, trilogyCase } = this.props;
    const onPQProductSelected = updatedCase => {
      actions.emitInputBatchUpdate(updatedCase);
      actions.emitModalContentClear();
      this.forceUpdate();
    };

    const content = (
      <PQProductLookupModal
        onProductSelected={onPQProductSelected}
        baseStatePath={$id}
        trilogyCase={trilogyCase}
      />
    );
    actions.emitModalContentUpdate(content);
  };

  handlePQProductLookupClear = cseNode => {
    const { trilogyCase, actions } = this.props;
    const productPath = get(cseNode, '["../"].$id');
    if (isNil(productPath)) {
      console.error('Could not get statePath for PQ Product Removal');
      return;
    }

    const newDocument = removePQProduct(trilogyCase, productPath);

    actions.emitInputBatchUpdate(newDocument);
  };

  handleProductLookUpClear = cseNode => {
    const { trilogyCase, actions } = this.props;
    const productPath = get(cseNode, '["../"].$id');
    // To get the expiration date for the current InputGroup instance, go up to the common parent,
    // and then find the Expiration Date field.
    const expirationDatePath = get(
      cseNode,
      '["../"]["../"]["../"].elements[3].instances[0].elements[1].$id'
    );
    if (isNil(productPath)) {
      console.error('Could not get statePath for Product Removal');
      return;
    }
    const newDocument = removeAEProduct(
      trilogyCase,
      productPath,
      expirationDatePath
    );

    actions.emitInputBatchUpdate(newDocument);
  };

  handleProtocolLookup = element => {
    const $id = element['../'].$id;
    const { actions, trilogyCase } = this.props;
    const onProtocolSelected = updatedCase => {
      actions.emitInputBatchUpdate(updatedCase);
      actions.emitModalContentClear();
      this.forceUpdate();
    };
    const content = (
      <ProtocolLookupModal
        onProtocolSelected={onProtocolSelected}
        baseStatePath={$id}
        trilogyCase={trilogyCase}
      />
    );
    actions.emitModalContentUpdate(content);
  };

  handleProtocolLookUpClear = cseNode => {
    const { trilogyCase, actions } = this.props;
    const statePath = get(cseNode, '["../"].$id');
    if (isNil(statePath)) {
      console.error('Could not get statePath for Protocol Removal');
      return;
    }
    const newDocument = removeAEProtocol(trilogyCase, statePath);

    actions.emitInputBatchUpdate(newDocument);
  };

  // copies over the list number and marketed name
  // into the replacement fields for current product
  replaceOriginalProduct = cseNode => {
    const { actions, trilogyCase } = this.props;
    const basePath = get(cseNode, '["../"].["../"].$id');
    const replacementPath = get(cseNode, '["../"].$id');
    set(
      trilogyCase,
      `${replacementPath}.listNumber`,
      get(trilogyCase, `${basePath}.details[0].listNumber`)
    );
    set(
      trilogyCase,
      `${replacementPath}.marketedName`,
      get(trilogyCase, `${basePath}.details[0].marketedName`)
    );
    actions.emitInputBatchUpdate(trilogyCase);
  };

  syncPatientBiometrics = (callback, cseNode) => {
    const { trilogyCase } = this.props;
    const biometricsMapping = {
      age: 'age_at_event',
      ageUnits: 'age_at_event_unit',
      weight: 'weight',
      weightUnits: 'weight_unit'
    };
    if (cseNode.relativePath === 'subcases.adverseEvent.patient.information') {
      set(
        trilogyCase,
        `patient.patient[0].${Object.keys(biometricsMapping).find(
          key => biometricsMapping[key] === cseNode.statePath
        )}`,
        cseNode.value
      );
    } else {
      set(
        trilogyCase,
        `subcases.adverseEvent.patient.information.${
          biometricsMapping[cseNode.statePath]
        }`,
        cseNode.value
      );
    }
    set(trilogyCase, cseNode.$id, cseNode.value);
    callback();
  };

  // Render on case creation, form pages but not the review screen
  shouldRenderOverview = () => {
    const { match } = this.props;
    const tab = getOrElse(match, 'params.tab');
    return (
      this.props.clientWidth >= DESKTOP_BREAKPOINT &&
      (tab || !match.params.page)
    );
  };

  currentTabForPath = page => {
    const { match } = this.props;
    return page.tabs.find(t => t.path === match.params.tab);
  };

  renderSectionsOverview = page => {
    const tab = this.currentTabForPath(page);
    const schema = getOrElse(this.form, 'state.schema', {});
    return this.shouldRenderOverview() ? (
      <SectionsOverview {...this.props} {...tab} schema={schema} />
    ) : null;
  };

  render() {
    const {
      computedStyles,
      actions,
      match,
      pageSchema,
      trilogyCase,
      triggers,
      formRef
    } = this.props;

    if (!pageSchema || !trilogyCase) return null;

    const handleFormRef = cseInstance => {
      this.form = cseInstance;
      formRef(cseInstance);
    };

    return (
      <div className={computedStyles.content}>
        {this.renderSectionsOverview(pageSchema)}
        <div
          className={computedStyles.sectionsMap(this.shouldRenderOverview())}
        >
          <SchemaUI
            debug={!!process.env.CSE_DEBUG}
            key={match.params.page}
            ref={handleFormRef}
            components={{ ...formComponents, ...wrappedInputComponents }}
            config={this.getConfig()}
            defaultComponent={PropTypeSafety}
            schema={this.getSchema(pageSchema)}
            model={trilogyCase}
            onChange={this.handleInputUpdate}
            data={this.getData()}
            triggers={{
              ...triggers,
              onCreateDashboardSubcase: this.handleValidate,
              onValidate: this.handleValidate,
              onModalConfirmDelete: actions.emitModalConfirmDelete,
              onModalContentClear: actions.emitModalContentClear,
              onModalContentUpdate: actions.emitModalContentUpdate,
              onUploadAttachments: actions.emitSaveAttachments,
              onDownloadAttachment: actions.emitDownloadAttachment,
              onProductLookUp: this.handleProductLookUp,
              onPQBatchSearch: this.handlePQBatchSearch,
              onPQProductLookup: this.handlePQProductLookup,
              onPQProductLookUpClear: this.handlePQProductLookupClear,
              onProductLookUpClear: this.handleProductLookUpClear,
              onProtocolLookUp: this.handleProtocolLookup,
              onProtocolLookupClear: this.handleProtocolLookUpClear,
              showConfirmModal: this.handleConfirmModal,
              getAbbvieDate: abbvieDateFormat,
              getAbbvieDateIfValidFullDate: abbvieDateFormatOnlyForFullDates,
              getAbbvieDateAndAtTime: abbvieDateAndAtTime,
              onCopyProduct: this.copyAndAddProduct,
              replaceOriginalProduct: this.replaceOriginalProduct,
              syncPatientBiometrics: this.syncPatientBiometrics
            }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(Page);
