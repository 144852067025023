/* eslint no-useless-escape:0 no-template-curly-in-string:0 */

/**
 * masterCase.js is the schema for the Master case and subcase forms.
 * It includes/requires additional files to load the schemas for each subcase schema and the dashboard.
 * These additional files are nested in the mocks/schemas/pages directory.
 */
module.exports.schema = {
  id: 'masterCase',
  locale: 'US',
  component: 'PropTypeSafety',
  prefabs: [
    {
      description:
        'Sets a components `layout.display` to `none` if the parent is not visible',
      id: 'DisplayValueToggleChild',
      referencedProperties: {
        value: {
          schemaPath: './',
          relative: true,
          $if: [
            {
              $get: '../visible'
            },
            {
              $get: 'value'
            }
          ],
          $else: { $set: null }
        }
      }
    },
    {
      description:
        'Sets a components model value to `null` whenever its layout.display is set to `null`',
      id: 'DisplayValueToggle',
      referencedProperties: {
        value: {
          relative: true,
          schemaPath: './',
          $if: [{ 'layout.display': { $eq: 'none' } }, { $set: null }],
          $else: { $get: 'value' }
        }
      }
    },
    {
      id: 'ContactList',
      component: 'InputGroup',
      statePath: 'contact',
      title: '',
      label: '',
      addButtonLabel: 'Add another contact',
      multiple: true,
      length: 1,

      validations: {
        contactHcp: {
          errorMessage:
            'Primary reporters cannot be HCP when Medically Confirmed is set to NO for all Reactions',
          queryConstraint: {
            // Validations should only run for cases with AE
            $if: [
              {
                $or: [
                  {
                    // If the case being submitted is NOT ae, validations pass
                    '$this.data.location.page': { $ne: 'ae' }
                  },
                  {
                    'summary.narrative.categories.adverse_event': {
                      // If no AE subcase, this returns `true` and validations pass
                      $ne: true
                    }
                  },
                  {
                    created: {
                      // If the case hasn't been created, pass validations
                      $eq: null
                    }
                  }
                ]
              },
              {
                $set: true
              }
            ],
            $elseIf: [
              {
                // if no contact is HCP, validation passes
                $not: {
                  'contacts.contact': {
                    $count: {
                      $filter: {
                        'aer[0].hcp': {
                          $eq: true
                        }
                      }
                    }
                  }
                }
              },
              {
                // this validation passes
                $set: true
              }
            ],
            $else: [
              {
                'subcases.adverseEvent.reactions.reaction': {
                  $count: {
                    $filter: {
                      medically_confirmed: {
                        $eq: 'yes'
                      }
                    }
                  }
                }
              },
              {
                'contacts.contact': {
                  $count: {
                    $filter: {
                      'aer[0].hcp': {
                        $eq: true
                      }
                    }
                  }
                }
              },
              {
                $set: true
              }
            ]
          }
        },
        hcpRequired: {
          errorMessage:
            'A Primary reporter must be HCP when Medically Confirmed is set to YES for any Reaction',
          queryConstraint: {
            $if: [
              {
                $or: [
                  {
                    // If the case being submitted is NOT ae, validations pass
                    '$this.data.location.page': { $ne: 'ae' }
                  },
                  {
                    'summary.narrative.categories.adverse_event': {
                      // If no AE subcase, this returns `true` and validations pass
                      $ne: true
                    }
                  },
                  {
                    created: {
                      // If the case hasn't been created, pass validations
                      $eq: null
                    }
                  }
                ]
              },
              {
                $set: true
              }
            ],
            $elseIf: [
              {
                $not: {
                  'subcases.adverseEvent.reactions.reaction': {
                    $count: {
                      $filter: {
                        medically_confirmed: {
                          $eq: 'yes'
                        }
                      }
                    }
                  }
                }
              },
              {
                $set: true
              }
            ],
            $else: {
              'contacts.contact': {
                $count: {
                  $filter: {
                    $and: [
                      {
                        'reporter.primary': {
                          $eq: true
                        }
                      },
                      {
                        'aer[0].hcp': {
                          $eq: true
                        }
                      }
                    ]
                  }
                }
              }
            }
          }
        }
      },

      referencedProperties: {
        label: {
          relative: true,
          statePath: './',
          $if: [{ type: { $eq: 'patient' } }, 'Patient - See next section'],
          $else: {
            $or: [
              {
                $concat: [
                  {
                    $if: [{ 'reporter.private': { $eq: true } }, 'Private'],
                    $else: {
                      $concat: [
                        { $or: [{ $get: 'name.first' }, ''] },
                        {
                          $and: [
                            { $get: 'name.first' },
                            { $get: 'name.last' },
                            ' '
                          ]
                        },
                        { $or: [{ $get: 'name.last' }, ''] }
                      ]
                    }
                  },
                  {
                    $if: [
                      { type: { $ne: null } },
                      {
                        $concat: [
                          {
                            $and: [
                              {
                                $or: [
                                  { $get: 'name.last' },
                                  { $get: 'name.first' }
                                ]
                              },
                              { type: { $ne: null } },
                              {
                                $and: [{ type: { $ne: null } }, ' – ']
                              }
                            ]
                          },
                          {
                            $or: [
                              {
                                $if: [
                                  { type: { $eq: 'psp' } },
                                  { $set: '(Patient Support Program (PSP))' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'mrp' } },
                                  { $set: '(Market Research Program (MRP))' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'physician' } },
                                  { $set: '(Physician)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'hcp' } },
                                  {
                                    $set: '(Other Healthcare Provider (HCP))'
                                  }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'pharmacist' } },
                                  { $set: '(Pharmacist)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'non_patient' } },
                                  { $set: '(Non-Patient Consumer)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'employee' } },
                                  { $set: '(Company Employee)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'legal' } },
                                  { $set: '(Legal)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'management' } },
                                  { $set: '(Health Care Management)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'authority' } },
                                  { $set: '(Regulatory/Health Authority)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'registry' } },
                                  { $set: '(Registry)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'distributor' } },
                                  { $set: '(Distributor)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'co_marketer' } },
                                  { $set: '(Co-Marketer)' }
                                ]
                              },
                              {
                                $if: [
                                  { type: { $eq: 'other' } },
                                  { $set: '(Other)' }
                                ]
                              },
                              { $set: '' }
                            ]
                          }
                        ]
                      }
                    ],
                    $else: { $set: '' }
                  },
                  {
                    $and: [
                      { 'reporter.primary': { $eq: true } },
                      {
                        $or: [
                          { $get: 'name.last' },
                          { $get: 'name.first' },
                          { type: { $ne: null } }
                        ]
                      },
                      ' – '
                    ]
                  },
                  {
                    $if: [
                      { 'reporter.primary': { $eq: true } },
                      'Primary Reporter/Requester'
                    ]
                  }
                ]
              },
              { $set: 'New Contact' }
            ]
          }
        },

        'styles.display': {
          schemaPath: './',
          relative: true,
          $if: [{ collapsed: { $eq: true } }, { $set: 'none' }],
          $else: { $set: 'block' }
        },

        collapsed: {
          schemaPath: './',
          relative: true,
          $if: [
            { component: { $eq: 'InputGroupInstance' } },
            { collapsed: { $eq: null } },
            { $not: { $get: 'last' } }
          ],
          $else: {
            $get: 'collapsed'
          }
        }
      },

      elements: [
        {
          component: 'Section',
          statePath: 'reporter',
          layout: {
            margin: 0,
            padding: 15
          },
          elements: [
            {
              component: 'Checkbox',
              statePath: 'primary',
              label: 'Primary Reporter/Requester?',
              layout: {
                width: '50%',
                display: 'block'
              }
            },
            {
              $ref: 'DisplayValueToggle',
              component: 'Checkbox',
              statePath: 'private',
              label: 'Do Not Report Name (Privacy)',
              layout: {
                width: '300px',
                display: 'block'
              }
              // referencedProperties: {
              //   'layout.display': {
              //     statePath: '../type',
              //     relative: true,
              //     $if: [{ $eq: 'patient' }, { $set: 'none' }],
              //     $else: { $set: 'inline-block' }
              //   }
              // }
            }
          ]
        },
        {
          component: 'Select',
          statePath: 'type',
          label: 'Type of Contact',
          placeholder: 'Select type',
          layout: {
            marginRight: '50%',
            width: '50%'
          },
          options: [],

          referencedProperties: {
            'layout.marginRight': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'non_patient' }, { $set: '0' }],
              $elseIf: [{ $eq: 'employee' }, { $set: '0' }],
              $else: { $set: '50%' }
            },

            options: {
              schemaPath: './',
              $get: 'data.document-data.mastercase-options.type_of_contact'
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'Consumer Type',
          component: 'Select',
          statePath: 'non_patient.type',
          placeholder: 'Select Consumer Type',
          layout: {
            width: '50%'
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'non_patient' }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          },

          options: [
            { label: 'Caregiver', value: 'caregiver' },
            { label: 'Consumer', value: 'consumer' },
            { label: 'Public Domain', value: 'public_domain' },
            { label: 'Relative', value: 'relative' }
          ]
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'Employee Type',
          component: 'Select',
          statePath: 'employee.type',
          placeholder: 'Select Employee Type',
          layout: {
            width: '50%'
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'employee' }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          },

          options: [
            { label: 'Medical', value: 'medical' },
            { label: 'Medical Science Liaison', value: 'liason' },
            { label: 'Sales Representative', value: 'sales_representative' },
            { label: 'Affiliate', value: 'affiliate' },
            { label: 'Other (AbbVie Internal)', value: 'other' }
          ]
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'HCP Specialty',
          component: 'Select',
          statePath: 'hcp.specialty',
          placeholder: 'Select specialty',
          layout: {
            width: '50%'
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [
                {
                  $or: [{ $eq: 'hcp' }, { $eq: 'physician' }]
                },
                { $set: 'inline-block' }
              ],
              $else: { $set: 'none' }
            },
            options: {
              schemaPath: './',
              $get: 'data.document-data.mastercase-options.hcp_specialty'
            }
          },

          options: []
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'HCP License Number',
          component: 'TextInput',
          statePath: 'hcp.license_number',

          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 50,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [
                {
                  $or: [{ $eq: 'hcp' }, { $eq: 'physician' }]
                },
                { $set: 'inline-block' }
              ],
              $else: { $set: 'none' }
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          component: 'Select',
          statePath: 'psp.name',
          label: 'Service Provider',
          placeholder: 'Select Provider',

          layout: {
            width: '50%'
          },

          options: [],

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [
                {
                  $or: [{ $eq: 'psp' }, { $eq: 'mrp' }]
                },
                { $set: 'inline-block' }
              ],
              $else: { $set: 'none' }
            },
            'validations.required.constraint': {
              relative: true,
              $if: [
                {
                  $and: [
                    {
                      '/tracking.methodOfReceipt': {
                        $or: [{ $eq: 'email' }, { $contains: 'Email' }]
                      }
                    },
                    {
                      $this: {
                        'data.location.page': {
                          $ne: 'pq'
                        }
                      }
                    }
                  ]
                },
                { $set: true }
              ],
              $else: { $set: false }
            },
            options: {
              schemaPath: './',
              $get: 'data.document-data.psp-options.psp_name'
            }
          },

          validations: {
            required: {
              constraint: true,
              errorMessage: ''
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'Program ID/Name',
          component: 'Select',
          statePath: 'psp.opi_number',

          layout: {
            width: '50%'
          },

          options: [],

          validations: {
            required: {
              constraint: false,
              errorMessage: ''
            }
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [
                {
                  $or: [{ $eq: 'psp' }, { $eq: 'mrp' }]
                },
                { $set: 'inline-block' }
              ],
              $else: { $set: 'none' }
            },
            'validations.required.constraint': {
              statePath: 'tracking.methodOfReceipt',
              $if: [
                {
                  $and: [
                    {
                      '/tracking.methodOfReceipt': {
                        $or: [{ $eq: 'email' }, { $contains: 'Email' }]
                      }
                    },
                    {
                      $this: {
                        'data.location.page': {
                          $ne: 'pq'
                        }
                      }
                    }
                  ]
                },
                { $set: true }
              ],
              $else: { $set: false }
            },
            options: {
              schemaPath: './',
              $get: 'data.document-data.psp-options.psp_opi_number'
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          component: 'Select',
          statePath: 'other.third_party',
          label: 'Third Party',
          placeholder: 'Select Third Party',

          layout: {
            width: '50%'
          },

          options: [
            { value: 'commercial_vendor', label: 'Commercial Vendor' },
            { value: 'specialty_pharmacy', label: 'Specialty Pharmacy' }
          ],

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'other' }, { $set: 'block' }],
              $else: { $set: 'none' }
            },
            disabled: {
              statePath: 'other.type',
              relative: true,
              $if: [{ $ne: null }, { $set: true }],
              $else: { $set: false }
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'Other Type',
          component: 'TextInput',
          statePath: 'other.type',
          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 50,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },
          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'other' }, { $set: 'block' }],
              $else: { $set: 'none' }
            },
            disabled: {
              statePath: 'other.third_party',
              relative: true,
              $if: [{ $ne: null }, { $set: true }],
              $else: { $set: false }
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'Contact Reference Number',
          component: 'TextInput',
          statePath: 'source_reference',

          layout: {
            width: '50%',
            display: 'inline-block'
          },

          validations: {
            required: {
              constraint: false,
              errorMessage: ''
            },
            maxLength: {
              constraint: 100,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            },
            'validations.required.constraint': {
              relative: true,
              $if: [
                {
                  $and: [
                    {
                      type: {
                        $or: [{ $eq: 'psp' }, { $eq: 'mrp' }]
                      }
                    },
                    {
                      '/tracking.methodOfReceipt': {
                        $or: [{ $eq: 'email' }, { $contains: 'Email' }]
                      }
                    }
                  ]
                },
                { $set: true }
              ],
              $else: { $set: false }
            }
          }
        },
        {
          $ref: 'GenderSelect',
          statePath: 'gender',

          layout: {
            width: '50%',
            display: 'inline-block'
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            },
            value: {
              relative: true,
              schemaPath: './',
              $if: [{ 'layout.display': { $eq: 'none' } }, { $set: null }],
              $else: { $get: 'value' }
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'First Name',
          component: 'TextInput',
          statePath: 'name.first',

          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 35,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'Last Name',
          component: 'TextInput',
          statePath: 'name.last',

          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 35,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },

          styles: {
            width: '100%'
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            },
            'validations.required.constraint': {
              relative: true,
              $if: [
                {
                  $and: [
                    {
                      'pq.initial_reporter': { $eq: true }
                    },
                    {
                      '/summary.narrative.categories.product_quality': {
                        $eq: true
                      }
                    },
                    {
                      $this: {
                        'data.location.page': {
                          $eq: 'pq'
                        }
                      }
                    }
                  ]
                },
                { $set: true }
              ],
              $else: { $set: false }
            }
          }
        },
        {
          $ref: 'CountryList',
          statePath: 'country',
          layout: {
            width: '50%',
            display: 'inline-block'
          },
          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            },
            value: {
              relative: true,
              schemaPath: './',
              $if: [{ 'layout.display': { $eq: 'none' } }, { $set: null }],
              $else: { $get: 'value' }
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'Organization / Company',
          component: 'TextInput',
          statePath: 'organization',

          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 60,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            }
          }
        },
        {
          $ref: 'EmailAddressList',
          statePath: 'email',

          layout: {
            display: 'block',
            width: '50%'
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            }
          }
        },
        {
          $ref: 'PhoneNumberList',
          statePath: 'phone',

          layout: {
            marginTop: 0,
            width: '50%'
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            },
            length: {
              schemaPath: './',
              relative: true,
              $if: [
                {
                  $or: [{ length: { $eq: null } }, { length: { $lt: 1 } }]
                },
                { $set: 1 }
              ],
              $else: {
                $get: 'length'
              }
            }
          }
        },
        {
          id: 'Address Information',
          component: 'InputGroup',
          statePath: 'address',
          collapsible: false,
          multiple: false,
          length: 1,

          layout: {
            display: 'block',
            width: '100%'
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'block' }
            }
          },

          elements: [
            {
              $ref: 'DisplayValueToggleChild',
              label: 'Address (Line 1)',
              component: 'TextInput',
              statePath: 'line1',
              layout: {
                display: 'block',
                marginRight: '50%',
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 40,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              label: 'Address (Line 2)',
              component: 'TextInput',
              statePath: 'line2',
              layout: {
                display: 'block',
                marginRight: '50%',
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 18,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              label: 'City',
              component: 'TextInput',
              statePath: 'city',
              layout: {
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 35,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              label: 'State/Province/Region',
              component: 'TextInput',
              statePath: 'state',
              layout: {
                width: '50%'
              },
              styles: {
                width: '100%'
              },
              validations: {
                maxLength: {
                  constraint: 40,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              label: 'Postal/Zip Code',
              component: 'TextInput',
              statePath: 'postal_code',
              layout: {
                width: '50%',
                marginRight: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 15,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggle',
              component: 'Select',
              statePath: 'useAddressFor',
              label: 'Use Address For',
              placeholder: 'Select...',

              layout: {
                width: '50%'
              },

              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.mastercase-options.use-address-for'
                }
              }
            }
          ]
        },
        {
          $ref: 'DisplayValueToggle',
          id: 'aerInfo',
          title: 'AE Specific Information',
          component: 'InputGroup',
          statePath: 'aer',
          multiple: false,
          collapsible: false,
          length: 1,

          layout: {
            width: '100%'
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'summary.narrative.categories.adverse_event',
              $if: [{ $eq: true }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          },

          elements: [
            {
              label: 'Occupation',
              component: 'TextInput',
              statePath: 'occupation',
              layout: {
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 25,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggle',
              component: 'Select',
              statePath: 'reporter_type',
              label: 'Reporter Type',
              placeholder: 'Select',

              layout: {
                width: '50%'
              },

              options: [],

              validations: {
                required: {
                  constraint: false
                }
              },

              referencedProperties: {
                'layout.display': {
                  statePath: '../reporter.primary',
                  relative: true,
                  $if: [
                    {
                      $eq: true
                    },
                    {
                      $set: 'block'
                    }
                  ],
                  $else: {
                    $set: 'none'
                  }
                },

                'validations.required.constraint': {
                  relative: true,
                  statePath: '../reporter.primary',
                  $if: [
                    {
                      $and: [{ $eq: true }, { '/created': { $ne: null } }]
                    },
                    { $set: true }
                  ],
                  $else: { $set: false }
                },

                options: {
                  schemaPath: './',
                  $get: 'data.document-data.ae-options.reporter_type'
                }
              }
            },
            {
              $ref: 'DisplayValueToggle',
              component: 'Checkbox',
              statePath: 'hcp',
              label: 'HCP',
              layout: {
                padding: 15,
                width: '300px'
              },
              referencedProperties: {
                'layout.display': {
                  statePath: '../reporter.primary',
                  relative: true,
                  $if: [
                    {
                      $eq: true
                    },
                    {
                      $set: 'block'
                    }
                  ],
                  $else: {
                    $set: 'none'
                  }
                }
              }
            }
          ]
        },
        {
          $ref: 'DisplayValueToggle',
          id: 'pqInfo',
          title: 'PQ Specific Information',
          component: 'Section',
          statePath: 'pq',

          layout: {
            width: '100%',
            paddingBottom: 15
          },

          referencedProperties: {
            'layout.display': {
              statePath: 'summary.narrative.categories.product_quality',
              $if: [{ $eq: true }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          },

          elements: [
            {
              component: 'Checkbox',
              statePath: 'initial_reporter',
              label: 'PQ Initial Reporter',
              layout: {
                padding: 15
              },
              referencedProperties: {
                // This is used to force a re-render of this field whenever ANY contact
                // updates its value. This is a hack, but until (if) CSE can ever address
                // these issues with queries/dependency graph this is the only way around
                // situations like this. Particularly queries involving nested paths and
                // references to other groups relative to another.
                _render: {
                  schemaPath: 'contacts.contact',
                  $count: {
                    $filter: {
                      'pq.credit_recipient': {
                        $ne: null
                      }
                    }
                  }
                },
                // only allow one checkbox to be checked for this value, also we ignore
                // the checkbox that is checked, so it can be unchecked
                disabled: {
                  relative: true,
                  $if: [
                    { initial_reporter: { $ne: true } },
                    {
                      '/contacts.contact': {
                        $count: {
                          $filter: {
                            pq: {
                              initial_reporter: { $eq: true }
                            }
                          }
                        }
                      }
                    },
                    {
                      $set: true
                    }
                  ],
                  $else: { $set: false }
                }
              }
            },
            {
              component: 'Select',
              statePath: 'regulatory_agency_communication',
              label: 'Regulatory Agency Communication',
              layout: {
                padding: 15
              },
              options: [],
              placeholder: 'Select...',
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get:
                    'data.document-data.mastercase-options.regulatory_agency_communication'
                },
                // This is required in order for the following `disabled` query to be
                // evaluated when both the `replacement` or `credit_recipient` fields
                // change (this one covers the `replacement`)
                _disabled: {
                  statePath:
                    'subcases.productQuality.complaint.processing.replacement'
                },
                disabled: {
                  relative: true,
                  statePath: 'credit_recipient',
                  $if: [
                    { $eq: true },
                    {
                      $this: {
                        'model.subcases.productQuality.complaint.processing.replacement': {
                          $eq: 'creditOnly'
                        }
                      }
                    }
                  ],
                  $else: { $set: false }
                }
              }
            },
            {
              component: 'Checkbox',
              statePath: 'prescriber',
              label: 'Prescriber',
              layout: {
                padding: 15,
                paddingTop: 0,
                paddingBottom: 0
              },
              referencedProperties: {
                // Only allow one checkbox to be checked for this value
                disabled: {
                  relative: true,
                  $if: [
                    { prescriber: { $ne: true } },
                    {
                      '/contacts.contact': {
                        $count: {
                          $filter: {
                            pq: {
                              prescriber: { $eq: true }
                            }
                          }
                        }
                      }
                    },
                    {
                      $set: true
                    }
                  ],
                  $else: { $set: false }
                },
                'layout.display': {
                  statePath: '../type',
                  relative: true,
                  $if: [{ $eq: 'patient' }, { $set: 'none' }],
                  $else: { $set: 'block' }
                },
                value: {
                  statePath: '../type',
                  relative: true,
                  $if: [{ $eq: 'patient' }, { $set: null }],
                  $else: { $set: { $this: { $get: 'value' } } }
                },
                // forces re-render if type of any other contact changes
                _render: {
                  schemaPath: 'contacts.contact',
                  $count: {
                    $filter: {
                      type: {
                        $ne: null
                      }
                    }
                  }
                }
              }
            },
            {
              component: 'Checkbox',
              statePath: 'credit_recipient',
              label: 'Credit Recipient',
              layout: {
                display: 'none',
                padding: 15
              },
              validations: {
                required: {
                  constraint: false
                }
              },
              referencedProperties: {
                'validations.required.constraint': {
                  statePath: 'subcases.productQuality.pqproduct.products',
                  $if: [
                    {
                      $and: [
                        {
                          $count: {
                            $filter: {
                              'complaint.processing.replacement': {
                                $eq: 'creditOnly'
                              }
                            }
                          }
                        },
                        {
                          // If the box is disabled, pass validations
                          $this: {
                            disabled: {
                              $not: {
                                $eq: true
                              }
                            }
                          }
                        },
                        {
                          $this: {
                            'data.location.page': {
                              $eq: 'pq'
                            }
                          }
                        }
                      ]
                    },
                    {
                      $set: true
                    }
                  ],
                  $else: {
                    $set: false
                  }
                },
                'layout.display': {
                  statePath: 'subcases.productQuality.pqproduct.products',
                  $if: [
                    {
                      $count: {
                        $filter: {
                          'complaint.processing.replacement': {
                            $eq: 'creditOnly'
                          }
                        }
                      }
                    },
                    { $set: 'block' }
                  ],
                  $else: { $set: 'none' }
                },
                value: {
                  statePath: 'subcases.productQuality.pqproduct.products',
                  $if: [
                    { $this: { 'layout.display': { $eq: 'block' } } },
                    {
                      $count: {
                        $filter: {
                          'complaint.processing.replacement': {
                            $eq: 'creditOnly'
                          }
                        }
                      }
                    },
                    { $set: { $this: { $get: 'value' } } }
                  ],
                  $else: { $set: null }
                },
                // This is used to force a re-render of this field whenever ANY contact
                // updates its value. This is a hack, but until (if) CSE can ever address
                // these issues with queries/dependency graph this is the only way around
                // situations like this. Particularly queries involving nested paths and
                // references to other groups relative to another.
                _render: {
                  schemaPath: 'contacts.contact',
                  $count: {
                    $filter: {
                      'pq.credit_recipient': {
                        $ne: null
                      }
                    }
                  }
                },
                // only allow one checkbox to be checked for this value, also we ignore
                // the checkbox that is checked, so it can be unchecked
                disabled: {
                  relative: true,
                  $if: [
                    { credit_recipient: { $ne: true } },
                    {
                      '/contacts.contact': {
                        $count: {
                          $filter: {
                            pq: {
                              credit_recipient: { $eq: true }
                            }
                          }
                        }
                      }
                    },
                    {
                      $set: true
                    }
                  ],
                  $elseIf: { regulatory_agency_communication: { $eq: true } },
                  $else: {
                    $set: false
                  }
                }
              }
            }
          ]
        }
      ]
    },
    {
      id: 'PatientInfo',
      component: 'InputGroup',
      statePath: 'contact',
      length: 1,
      collapsible: false,
      multiple: false,

      elements: [
        {
          label: 'First Name',
          component: 'TextInput',
          statePath: 'firstName',
          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 35,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          label: 'Last Name',
          component: 'TextInput',
          statePath: 'lastName',
          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 35,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          component: 'Checkbox',
          statePath: 'privacy',
          label: 'Do Not Report Name (Privacy)',
          layout: {
            padding: 15,
            width: '300px'
          }
        },
        {
          label: 'Initials',
          component: 'TextInput',
          statePath: 'initials',
          layout: {
            marginRight: '50%',
            width: '50%'
          },
          styles: {
            width: '100%'
          },
          validations: {
            maxLength: {
              constraint: 360,
              errorMessage: 'The maximum number of characters has been reached'
            },
            minLength: {
              constraint: 1
            },
            pattern: {
              constraint: '^[a-zA-Z]*$',
              errorMessage: 'Please only enter letters'
            }
          }
        },
        {
          label: 'Date of Birth',
          component: 'DateInput',
          statePath: 'dob',
          validations: {
            date: {
              constraint: 'partial',
              errorMessage: ''
            },
            dateRestriction: {
              constraint: 'future',
              errorMessage: 'Date must not occur in the future'
            }
          }
        },
        {
          $ref: 'GenderSelect',
          statePath: 'gender'
        },
        {
          component: 'TextInput',
          statePath: 'age',
          label: 'Age',
          layout: {
            width: '50%'
          },
          validations: {
            pattern: {
              constraint: '^\\d*\\.?\\d+$',
              errorMessage: 'Please only enter numbers'
            },
            maxLength: {
              constraint: 3,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },
          referencedProperties: {
            'validations.required.constraint': {
              statePath: 'ageUnits',
              relative: true,

              $if: [{ $ne: null }, { $set: true }],
              $else: { $set: false }
            },
            value: {
              $if: [
                {
                  $get: 'subcases.adverseEvent.patient.information.age_at_event'
                },
                {
                  $get: 'subcases.adverseEvent.patient.information.age_at_event'
                }
              ],
              $else: { $set: { $this: { $get: 'value' } } }
            }
          },
          triggers: {
            onChange: 'syncPatientBiometrics'
          }
        },
        {
          component: 'Select',
          label: 'Age Units',
          statePath: 'ageUnits',
          layout: {
            width: '50%'
          },
          options: [],
          referencedProperties: {
            options: {
              schemaPath: './',
              $get: 'data.document-data.ae-options.age_units'
            },
            'validations.required.constraint': {
              relative: true,
              statePath: 'age',
              $if: [{ $ne: null }, { $set: true }],
              $else: { $set: false }
            },
            value: {
              $if: [
                {
                  $get:
                    'subcases.adverseEvent.patient.information.age_at_event_unit'
                },
                {
                  $get:
                    'subcases.adverseEvent.patient.information.age_at_event_unit'
                }
              ],
              $else: { $set: { $this: { $get: 'value' } } }
            }
          },
          triggers: {
            onChange: 'syncPatientBiometrics'
          }
        },
        {
          component: 'TextInput',
          statePath: 'weight',
          label: 'Weight',
          layout: {
            width: '50%'
          },
          validations: {
            pattern: {
              constraint: '^\\d*\\.?\\d+$',
              errorMessage: 'Please only enter numbers'
            },
            maxLength: {
              constraint: 4,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },
          referencedProperties: {
            'validations.required.constraint': {
              statePath: 'weightUnits',
              relative: true,

              $if: [{ $ne: null }, { $set: true }],
              $else: { $set: false }
            },
            value: {
              $if: [
                { $get: 'subcases.adverseEvent.patient.information.weight' },
                { $get: 'subcases.adverseEvent.patient.information.weight' }
              ],
              $else: { $set: { $this: { $get: 'value' } } }
            }
          },
          triggers: {
            onChange: 'syncPatientBiometrics'
          }
        },
        {
          component: 'Select',
          label: 'Weight Units',
          statePath: 'weightUnits',
          layout: {
            width: '50%'
          },
          options: [],
          referencedProperties: {
            options: {
              schemaPath: './',
              $get: 'data.document-data.ae-options.weight_unit'
            },
            'validations.required.constraint': {
              statePath: 'weight',
              relative: true,

              $if: [{ $ne: null }, { $set: true }],
              $else: { $set: false }
            },
            value: {
              $if: [
                {
                  $get: 'subcases.adverseEvent.patient.information.weight_unit'
                },
                {
                  $get: 'subcases.adverseEvent.patient.information.weight_unit'
                }
              ],
              $else: { $set: { $this: { $get: 'value' } } }
            }
          },
          triggers: {
            onChange: 'syncPatientBiometrics'
          }
        },
        {
          label: 'Ethnicity',
          component: 'TextInput',
          statePath: 'ethnicity',
          layout: { width: '50%' },
          validations: {
            maxLength: {
              constraint: 100,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          label: 'Race',
          component: 'Select',
          statePath: 'race',
          placeholder: "Select patient's race",
          options: [],
          layout: {
            width: '50%'
          },
          referencedProperties: {
            options: {
              schemaPath: './',
              $get: 'data.document-data.mastercase-options.patient_race'
            }
          }
        },
        {
          $ref: 'ClinicalStudySelect',
          statePath: 'clinicalStudy'
        },
        {
          label: 'Third Party Patient ID',
          component: 'TextInput',
          statePath: 'reporter',
          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 20,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },
          referencedProperties: {
            disabled: {
              statePath: 'subcases.adverseEvent.aerinfo',
              $if: [
                {
                  // check that the study section is showing
                  'safety.source': {
                    $count: {
                      $filter: {
                        sources: {
                          $or: [{ $eq: 'Clinical Study' }, { $eq: 'Solicited' }]
                        }
                      }
                    }
                  }
                },
                {
                  'study.study_patient': {
                    $ne: null
                  }
                },
                {
                  $set: true
                }
              ],
              $else: { $set: false }
            },
            helpText: {
              statePath: 'subcases.adverseEvent.aerinfo.study.study_patient',
              $if: [
                { $ne: null },
                {
                  $set:
                    'Cannot have both Third Party Patient ID and Study Patient Number populated'
                }
              ],
              $else: { $set: '' }
            }
          }
        },
        {
          label: 'PQ Patient Reference Number',
          component: 'TextInput',
          statePath: 'pqPatientRefNumber',
          layout: { width: '50%' },
          validations: {
            maxLength: {
              constraint: 50,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          id: 'Contact Information',
          statePath: 'contact_info',
          component: 'InputGroup',
          multiple: false,
          collapsible: false,
          addButtonLabel: 'Add contact information',
          length: 1,

          layout: {
            marginTop: 0
          },

          elements: [
            {
              $ref: 'EmailAddressList',
              statePath: 'email',

              layout: {
                display: 'block',
                width: '50%'
              },

              referencedProperties: {
                'layout.display': {
                  statePath: 'type',
                  relative: true,
                  $if: [{ $eq: 'patient' }, { $set: 'none' }],
                  $else: { $set: 'inline-block' }
                }
              }
            },
            {
              $ref: 'PhoneNumberList',
              statePath: 'phone',

              layout: {
                marginTop: 0,
                width: '50%'
              },

              referencedProperties: {
                'layout.display': {
                  statePath: 'type',
                  relative: true,
                  $if: [{ $eq: 'patient' }, { $set: 'none' }],
                  $else: { $set: 'inline-block' }
                }
              }
            },
            {
              label: 'Address (Line 1)',
              component: 'TextInput',
              statePath: 'line1',
              layout: {
                display: 'block',
                marginRight: '50%',
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 40,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              label: 'Address (Line 2)',
              component: 'TextInput',
              statePath: 'line2',
              layout: {
                display: 'block',
                marginRight: '50%',
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 18,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              label: 'City',
              component: 'TextInput',
              statePath: 'city',
              layout: {
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 35,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              label: 'State/Province/Region',
              component: 'TextInput',
              statePath: 'state',
              layout: {
                width: '50%'
              },
              styles: {
                width: '100%'
              },
              validations: {
                maxLength: {
                  constraint: 40,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              label: 'Postal/Zip Code',
              component: 'TextInput',
              statePath: 'postal_code',
              layout: {
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 15,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'CountryList',
              statePath: 'country',
              validations: {
                required: {
                  constraint: false,
                  errorMessage: ''
                }
              },
              referencedProperties: {
                _render: {
                  schemaPath: 'model',
                  relative: false
                },
                'validations.required.constraint': {
                  $if: [
                    {
                      $and: [
                        {
                          '/contacts.contact': {
                            $count: {
                              $filter: {
                                $and: [
                                  {
                                    pq: {
                                      initial_reporter: { $eq: true }
                                    }
                                  },
                                  {
                                    type: {
                                      $eq: 'patient'
                                    }
                                  }
                                ]
                              }
                            }
                          }
                        },
                        {
                          '$this.data.location.page': { $eq: 'pq' }
                        }
                      ]
                    },
                    {
                      $set: true
                    }
                  ],
                  $else: { $set: false }
                }
              }
            }
          ]
        }
      ]
    },
    {
      $ref: 'DisplayValueToggle',
      id: 'CountryList',
      component: 'Select',
      statePath: 'country',
      label: 'Country',
      placeholder: 'Select country',
      options: [],
      layout: {
        width: '50%'
      },
      referencedProperties: {
        options: {
          schemaPath: './',
          $get: 'data.document-data.country-options'
        }
      }
    },
    {
      id: 'StateProvList',
      label: 'State/Province/Region',
      component: 'Select',
      statePath: 'state',
      options: [
        { label: 'Alabama', value: 'alabama' },
        { label: 'Alaska', value: 'alaska' },
        { label: 'Arizona', value: 'arizona' },
        { label: 'Arkansas', value: 'arkansas' },
        { label: 'California', value: 'california' },
        { label: 'Colorado', value: 'colorado' },
        { label: 'Connecticut', value: 'connecticut' },
        { label: 'Delaware', value: 'delaware' },
        { label: 'Florida', value: 'florida' },
        { label: 'Georgia', value: 'georgia' },
        { label: 'Hawaii', value: 'hawaii' },
        { label: 'Idaho', value: 'idaho' },
        { label: 'Illinois', value: 'illinois' },
        { label: 'Indiana', value: 'indiana' },
        { label: 'Iowa', value: 'iowa' },
        { label: 'Kansas', value: 'kansas' },
        { label: 'Kentucky', value: 'kentucky' },
        { label: 'Louisiana', value: 'louisiana' },
        { label: 'Maine', value: 'maine' },
        { label: 'Maryland', value: 'maryland' },
        { label: 'Massachusetts', value: 'massachusetts' },
        { label: 'Michigan', value: 'michigan' },
        { label: 'Minnesota', value: 'minnesota' },
        { label: 'Mississippi', value: 'mississippi' },
        { label: 'Missouri', value: 'missouri' },
        { label: 'Montana', value: 'montana' },
        { label: 'Nebraska', value: 'nebraska' },
        { label: 'Nevada', value: 'nevada' },
        { label: 'New Hampshire', value: 'new hampshire' },
        { label: 'New Jersey', value: 'new jersey' },
        { label: 'New Mexico', value: 'new mexico' },
        { label: 'New York', value: 'new york' },
        { label: 'North Carolina', value: 'north carolina' },
        { label: 'North Dakota', value: 'north dakota' },
        { label: 'Ohio', value: 'ohio' },
        { label: 'Oklahoma', value: 'oklahoma' },
        { label: 'Oregon', value: 'oregon' },
        { label: 'Pennsylvania', value: 'pennsylvania' },
        { label: 'Rhode Island', value: 'rhode island' },
        { label: 'South Carolina', value: 'south carolina' },
        { label: 'South Dakota', value: 'south dakota' },
        { label: 'Tennessee', value: 'tennessee' },
        { label: 'Texas', value: 'texas' },
        { label: 'Utah', value: 'utah' },
        { label: 'Vermont', value: 'vermont' },
        { label: 'Virginia', value: 'virginia' },
        { label: 'Washington', value: 'washington' },
        { label: 'West Virginia', value: 'west virginia' },
        { label: 'Wisconsin', value: 'wisconsin' },
        { label: 'Wyoming', value: 'wyoming' }
      ]
    },
    {
      $ref: 'DisplayValueToggle',
      id: 'EmailAddressList',
      collapsible: false,
      label: 'another',
      component: 'InputGroup',
      statePath: 'email',
      multiple: true,
      length: 1,

      elements: [
        {
          $ref: 'DisplayValueToggleChild',
          label: 'Email Address',
          component: 'TextInput',
          statePath: 'address',
          layout: {
            width: '100%'
          },
          validations: {
            email: {
              errorMessage: 'Must be a valid email address'
            },
            maxLength: {
              constraint: 100,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        }
      ]
    },
    {
      $ref: 'DisplayValueToggle',
      id: 'PhoneNumberList',
      label: 'another',
      component: 'InputGroup',
      statePath: 'phone',
      multiple: true,
      collapsible: false,
      length: 1,
      layout: {
        marginTop: 0
      },
      referencedProperties: {
        'layout.display': {
          relative: true,
          statePath: 'type',
          $if: [{ $eq: 'patient' }, { $set: 'none' }],
          $else: { $set: 'block' }
        }
      },
      elements: [
        {
          $ref: 'DisplayValueToggleChild',
          component: 'TextInput',
          statePath: 'number',
          label: 'Phone Number',
          layout: {
            flex: '0 1 auto',
            width: '53%'
          },
          styles: {
            width: '100%'
          },
          validations: {
            maxLength: {
              constraint: 25,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          $ref: 'DisplayValueToggleChild',
          component: 'TextInput',
          statePath: 'extension',
          label: 'Extension',
          layout: {
            flex: '0 1 auto',
            padding: 0,
            width: '25%'
          },
          validations: {
            maxLength: {
              constraint: 10,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          $ref: 'DisplayValueToggleChild',
          component: 'Checkbox',
          statePath: 'isFax',
          label: 'Fax?',
          layout: {
            alignSelf: 'center',
            display: 'flex',
            margin: '0 0 0 10px',
            paddingTop: '8px',
            width: 'auto'
          },
          referencedProperties: {
            'layout.display': {
              statePath: 'type',
              relative: true,
              $if: [{ $eq: 'patient' }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            }
          }
        }
      ]
    },
    {
      $ref: 'DisplayValueToggle',
      id: 'GenderSelect',
      label: 'Gender',
      name: 'Gender',
      component: 'Select',
      statePath: 'gender',
      layout: {
        width: '50%'
      },
      referencedProperties: {
        options: {
          schemaPath: './',
          $get: 'data.document-data.mastercase-options.gender'
        }
      }
    },
    {
      $ref: 'DisplayValueToggle',
      id: 'ClinicalStudySelect',
      label: 'Did the patient participate in a study?',
      name: 'ClinicalStudy',
      component: 'Select',
      statePath: 'clinicalStudy',
      layout: {
        width: '50%'
      },
      options: [
        {
          label: 'Yes',
          value: 'yes'
        },
        {
          label: 'No',
          value: 'no'
        },
        {
          label: 'Patient does not know',
          value: 'does_not_know'
        }
      ]
    },
    {
      id: 'DocumentUploader',
      component: 'Section',
      title: 'Documents',
      statePath: 'documents',

      elements: [
        {
          component: 'FileInput',
          label: 'Upload a new attachment',
          statePath: 'attachments',

          multiple: true,
          modal: true,

          layout: {
            display: 'block',
            paddingLeft: 0,
            width: '100%'
          }
        }
      ]
    },
    {
      id: 'PatientAlcoholHistory',
      component: 'InputGroup',
      statePath: 'alcohol_history',
      collapsible: false,
      multiple: false,
      length: 1,
      elements: [
        {
          $ref: 'DisplayValueToggleChild',
          component: 'TextInput',
          statePath: 'alcohol_type_and_quantity',
          label: 'Type and Quantity',
          layout: {
            width: '50%',
            marginRight: '50%'
          },
          validations: {
            maxLength: {
              constraint: 81,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          $ref: 'DisplayValueToggleChild',
          component: 'DateInput',
          statePath: 'alcohol_start_date',
          label: 'When did the patient start using alcohol?',
          validations: {
            date: {
              constraint: 'partial',
              errorMessage: 'Invalid partial date'
            },
            dateRestriction: {
              constraint: 'future',
              errorMessage: 'Date must not occur in the future'
            }
          }
        },
        {
          $ref: 'DisplayValueToggleChild',
          component: 'DateInput',
          statePath: 'alcohol_stop_date',
          label: 'When did the patient stop using alcohol?',
          validations: {
            date: {
              constraint: 'partial',
              errorMessage: 'Invalid partial date'
            },
            dateRestriction: {
              constraint: 'future',
              errorMessage: 'Date must not occur in the future'
            }
          }
        }
      ]
    },
    {
      id: 'PatientTobaccoHistory',
      component: 'InputGroup',
      statePath: 'tobacco_history',
      collapsible: false,
      multiple: false,
      length: 1,
      elements: [
        {
          $ref: 'DisplayValueToggleChild',
          component: 'TextInput',
          statePath: 'tobacco_type_and_quantity',
          label: 'Type and Quantity',
          layout: {
            width: '50%',
            marginRight: '50%'
          },
          validations: {
            maxLength: {
              constraint: 81,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          $ref: 'DisplayValueToggleChild',
          component: 'DateInput',
          statePath: 'tobacco_start_date',
          label: 'When did the patient start using tobacco?',
          validations: {
            date: {
              constraint: 'partial',
              errorMessage: 'Invalid partial date'
            },
            dateRestriction: {
              constraint: 'future',
              errorMessage: 'Date must not occur in the future'
            }
          }
        },
        {
          $ref: 'DisplayValueToggleChild',
          component: 'DateInput',
          statePath: 'tobacco_stop_date',
          label: 'When did the patient stop using tobacco?',
          validations: {
            date: {
              constraint: 'partial',
              errorMessage: 'Invalid partial date'
            },
            dateRestriction: {
              constraint: 'future',
              errorMessage: 'Date must not occur in the future'
            }
          }
        }
      ]
    },
    {
      id: 'PatientAllergyHistory',
      component: 'InputGroup',
      statePath: 'allergies_section.allergies_list',
      label: 'another allergy',
      collapsible: false,
      multiple: true,
      length: 1,
      referencedProperties: {
        label: {
          relative: true,
          $or: [
            {
              $concat: [{ $or: [{ $get: 'allergy_type' }, ''] }]
            },
            { $set: 'another allergy' }
          ]
        },
        'layout.display': {
          relative: true,
          $if: [{ allergies: { $eq: 'yes' } }, { $set: 'block' }],
          $else: { $set: 'none' }
        }
      },
      elements: [
        {
          $ref: 'DisplayValueToggleChild',
          component: 'TextInput',
          label: 'Type of Allergy / Substance',
          statePath: 'allergy_type',
          layout: {
            width: '50%'
          },
          validations: {
            required: {
              constraint: true,
              errorMessage: ''
            },
            maxLength: {
              constraint: 30,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          $ref: 'DisplayValueToggleChild',
          component: 'TextInput',
          label: 'Associated Manifestations',
          statePath: 'allergy_manifestations',
          layout: {
            width: '35%'
          },
          validations: {
            maxLength: {
              constraint: 50,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        },
        {
          label: 'Add as a product',
          component: 'SmartButton',
          triggers: {
            onClick: 'onCreateProductPerAllergy'
          },
          layout: {
            width: '15%',
            display: 'flex',
            flexDirection: 'row'
          },
          styles: {
            appearance: 'none',
            backgroundColor: '#FFFFFF',
            border: '1px solid #0082BA',
            color: '#0082BA',
            margin: '20px 10px 0 0',
            width: '134px',
            height: '30px',
            fontFamily: 'Calibri, "Microsoft Sans Serif"',
            fontSize: '12px',
            float: 'right'
          }
        }
      ]
    },
    {
      id: 'AEAdditionalInfoSection',
      component: 'Section',
      title: 'AE Additional Information',
      statePath: 'tracking',
      elements: [
        {
          component: 'InputGroup',
          statePath: 'case_type',
          collapsible: false,
          multiple: true,
          label: 'another',
          layout: {
            display: 'inline-block'
          },
          length: 1,
          elements: [
            {
              label: 'Case Type',
              placeholder: 'Select Case Type',
              component: 'Select',
              statePath: 'value',
              options: [
                {
                  label: 'Biosimilar',
                  value: 'Biosimilar'
                },
                {
                  label: 'Lack of Efficacy',
                  value: 'lack_of_efficacy'
                },
                {
                  label: 'Off label use without AE',
                  value: 'off_label_use_without_ae'
                },
                {
                  label: 'Off label use with AE',
                  value: 'off_label_use_with_ae'
                },
                {
                  label: 'Pregnancy Related',
                  value: 'pregnancy_related'
                },
                {
                  label: 'Product Complaint',
                  value: 'Product Complaint'
                },
                {
                  label: 'Medication Error',
                  value: 'medication_error'
                }
              ]
            }
          ]
        },
        {
          label: 'Source of Information',
          placeholder: 'Select source',
          component: 'Select',
          statePath: 'source_of_information',
          options: [],
          layout: {
            display: 'inline-block',
            width: '50%'
          },
          referencedProperties: {
            options: {
              schemaPath: './',
              $get:
                'data.document-data.mastercase-options.source_of_information'
            }
          }
        },
        {
          label: 'Type of Report',
          statePath: 'type_of_report',
          component: 'RadioGroup',
          options: [
            {
              label: 'Spontaneous',
              value: 'spontaneous'
            },
            {
              label: 'Solicited',
              value: 'solicited'
            },
            {
              label: 'Clinical',
              value: 'clinical'
            }
          ],
          layout: {
            display: 'inline-block',
            minHeight: '130px',
            width: '50%'
          },
          styles: {
            width: '200px'
          },
          referencedProperties: {
            disabled: {
              relative: true,
              statePath: 'source_of_information',
              $if: [
                {
                  $or: [
                    { $eq: 'clinical_interventional' },
                    { $eq: 'compassionate_use' },
                    { $eq: 'mrp' },
                    { $eq: 'psp' },
                    { $eq: 'pmos' },
                    { $eq: 'registry' },
                    { $eq: 'other_spontaneous' },
                    { $eq: 'spontaneous' },
                    { $eq: 'social_media' }
                  ]
                },
                {
                  $set: true
                }
              ],
              $else: { $set: false }
            },
            value: {
              relative: true,
              $if: {
                source_of_information: {
                  $or: [
                    {
                      $if: [
                        { $eq: 'clinical_interventional' },
                        { $set: 'clinical' }
                      ]
                    },
                    {
                      $if: [{ $eq: 'compassionate_use' }, { $set: 'solicited' }]
                    },
                    { $if: [{ $eq: 'mrp' }, { $set: 'solicited' }] },
                    { $if: [{ $eq: 'psp' }, { $set: 'solicited' }] },
                    { $if: [{ $eq: 'pmos' }, { $set: 'solicited' }] },
                    { $if: [{ $eq: 'registry' }, { $set: 'solicited' }] },
                    {
                      $if: [
                        { $eq: 'other_spontaneous' },
                        { $set: 'spontaneous' }
                      ]
                    },
                    {
                      $if: [{ $eq: 'spontaneous' }, { $set: 'spontaneous' }]
                    },
                    {
                      $if: [{ $eq: 'social_media' }, { $set: 'spontaneous' }]
                    }
                  ]
                }
              },
              $else: {
                $get: 'type_of_report'
              }
            }
          }
        },
        {
          label: 'Follow-up query required?',
          component: 'Select',
          statePath: 'follow_up_required',
          options: [],
          validations: {
            required: {
              constraint: false,
              errorMessage: ''
            }
          },
          layout: {
            width: '50%',
            marginRight: '50%'
          },
          referencedProperties: {
            options: {
              schemaPath: './',
              $get: 'data.document-data.mastercase-options.follow_up_required'
            },
            'layout.marginRight': {
              statePath: 'follow_up_required',
              relative: true,
              $if: [{ $eq: 'no' }, { $set: '0' }],
              $else: { $set: '50%' }
            },
            'validations.required.constraint': {
              statePath: 'subcases.adverseEvent.aerinfo.safety.version',
              $if: [{ $eq: 'initial_case' }, { $set: true }],
              $else: { $set: false }
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          label: 'PV No Query Reason',
          component: 'Select',
          statePath: 'no_query_reason',
          options: [
            {
              label: 'PV No Query/Declined',
              value: 'declined'
            },
            {
              label: 'PV No Query/Done in Safety database - US Only',
              value: 'done_in_AEGIS_US_Only',
              disabled: true
            },
            {
              label: 'PV No Query/Litigation',
              value: 'litigation'
            },
            {
              label: 'PV No Query/No Contact Info',
              value: 'no_contact_info'
            },
            {
              label: 'PV No Query/Case Complete',
              value: 'case_complete'
            },
            {
              label: 'PV No Query/No Further Info',
              value: 'no_further_info'
            },
            {
              label: 'PV No Query/No Query Required',
              value: 'no_query_required'
            }
          ],
          layout: {
            width: '50%'
          },
          validations: {
            required: {
              constraint: false,
              errorMessage: ''
            }
          },
          referencedProperties: {
            'layout.display': {
              statePath: 'subcases.adverseEvent.tracking.follow_up_required',
              $if: [{ $eq: 'no' }, { $set: 'inline-block' }],
              $else: { $set: 'none' }
            },
            'validations.required.constraint': {
              statePath: 'subcases.adverseEvent.tracking.follow_up_required',
              $if: [{ $eq: 'no' }, { $set: true }],
              $else: { $set: false }
            }
          }
        }
      ]
    },
    {
      id: 'AESubmissionSection',
      component: 'Section',
      title: 'Submission',
      elements: [
        {
          component: 'InfoTable',
          statePath: '/subcases.adverseEvent.subcaseVersions',
          length: 1,
          elements: [
            {
              component: 'InfoTableRow',
              elements: [
                {
                  component: 'InfoTableColumn',
                  title: 'Version',
                  statePath: 'id' // TODO replace statePaths with data added by BE TRGY-1055
                },
                {
                  component: 'InfoTableColumn',
                  title: 'Submission Date (UTC)',
                  statePath: 'submittedDateFormatted'
                },
                {
                  component: 'InfoTableColumn',
                  title: 'RCT Number',
                  statePath: 'rctNumber'
                },
                {
                  component: 'InfoTableColumn',
                  title: 'AER Number',
                  statePath: 'aerNumber'
                },
                {
                  component: 'InfoTableColumn',
                  title: 'Argus Number',
                  statePath: 'argusNumber'
                },
                {
                  component: 'InfoTableColumn',
                  title: 'Intake ID',
                  statePath: 'inboxId'
                },
                {
                  component: 'InfoTableColumn',
                  title: 'Veeva ID',
                  statePath: 'veevaId'
                }
              ]
            }
          ]
        },

        // Obsolete field, conditionally shown as read-only on old cases with unmigrated data
        {
          label: 'RCT Number',
          component: 'TextInput',
          statePath: 'rct_number',

          layout: {
            width: '50%'
          },

          validations: {
            required: {
              constraint: true,
              errorMessage: ''
            },
            maxLength: {
              constraint: 50,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },

          referencedProperties: {
            'layout.display': {
              statePath: './',
              relative: true,
              $if: [{ rct_number: { $eq: null } }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            }
          },

          disabled: true
        },

        // Obsolete field, conditionally shown as read-only on old cases with unmigrated data
        {
          component: 'DateInput',
          statePath: 'submission_date',
          label: 'Submission Date',

          validations: {
            required: {
              constraint: true,
              errorMessage: ''
            },
            date: {
              constraint: 'full',
              errorMessage: 'Full date required'
            },
            dateRestriction: {
              constraint: 'future',
              errorMessage: 'Date must not occur in the future'
            }
          },

          referencedProperties: {
            'layout.display': {
              statePath: './',
              relative: true,
              $if: [{ submission_date: { $eq: null } }, { $set: 'none' }],
              $else: { $set: 'inline-block' }
            }
          },

          disabled: true
        },
        {
          component: 'Select',
          statePath: 'late_pps_reason',
          label: 'Late to PPS Reason',
          options: [
            {
              label: 'AbbVie Staff (Not PV) - Commercial',
              value: 'AbbVie Staff (Not PV) - Commercial'
            },
            {
              label: 'AbbVie Staff (Not PV) - Medical',
              value: 'AbbVie Staff (Not PV) - Medical'
            },
            {
              label: 'AbbVie Staff (Not PV) - Other',
              value: 'AbbVie Staff (Not PV) - Other'
            },
            {
              label: 'AbbVie Staff (Not PV) - Product Quality',
              value: 'AbbVie Staff (Not PV) - Product Quality'
            },
            {
              label: 'Affiliate PV - Correction / QC',
              value: 'Affiliate PV - Correction / QC'
            },
            {
              label: 'Affiliate PV - Incorrect Triage',
              value: 'Affiliate PV - Incorrect Triage'
            },
            { label: 'Affiliate PV - Other', value: 'Affiliate PV - Other' },
            { label: 'Affiliate PV - Volume', value: 'Affiliate PV - Volume' },
            {
              label: 'Out Of Office Hours (Not Late)',
              value: 'Out Of Office Hours (Not Late)'
            },
            {
              label: 'System unavailable/issue',
              value: 'System unavailable/issue'
            },
            { label: 'Third Party - Partner', value: 'Third Party - Partner' },
            {
              label: 'Third Party - Service provider',
              value: 'Third Party - Service provider'
            }
          ],
          layout: {
            width: '50%'
          }
        },
        {
          component: 'DateInput',
          statePath: 'pv_awareness_date',
          label: 'PV Awareness Date',
          validations: {
            date: {
              constraint: 'full',
              errorMessage: 'Full date required'
            },
            dateRestriction: {
              constraint: 'future',
              errorMessage: 'Date must not occur in the future'
            }
          }
        }
      ]
    },
    {
      id: 'TrackingInformationSection',
      component: 'Section',
      title: 'Master Case Information',
      statePath: 'tracking',

      elements: [
        {
          component: 'Select',
          statePath: 'productName',
          label: 'Master Case Product',
          placeholder: 'Select area',
          options: [],
          layout: {
            width: '50%',
            marginRight: '50%'
          },
          referencedProperties: {
            'layout.marginRight': {
              statePath: 'tracking.productName',
              $if: [{ $eq: 'other' }, { $set: '0' }],
              $else: { $set: '50%' }
            },
            options: {
              schemaPath: './',
              $get: 'data.document-data.mastercase-product-options'
            }
          }
        },
        {
          component: 'TextInput',
          label: 'Specify Product',
          $ref: 'DisplayValueToggle',
          statePath: 'product_grouping',
          layout: {
            width: '50%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: 'tracking.productName',
              $if: [{ $eq: 'other' }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          }
        },
        {
          label: 'Master Case Language',
          component: 'Select',
          placeholder: 'Select language',
          statePath: 'language',
          layout: {
            width: '50%',
            marginRight: '50%'
          },
          options: [],
          referencedProperties: {
            options: {
              schemaPath: './',
              $get: 'data.document-data.language-options'
            }
          }
        },
        {
          label: 'Method of Receipt',
          component: 'Select',
          placeholder: 'Select method',
          statePath: 'methodOfReceipt',
          layout: {
            width: '50%',
            marginRight: '0'
          },
          options: [],
          validations: {
            methodOfReceiptMismatch: {
              queryConstraint: {
                schemaPath: './',
                $or: [
                  {
                    'data.submitted': {
                      $ne: true // not submitted = passing
                    }
                  },
                  {
                    // return TRUE (pass validation) if NO sources are set to `Literature`
                    // if `$count` returns 0 (no sources of Literature), `$not 0` is equal to `true`,
                    $not: {
                      $count: {
                        'model.subcases.adverseEvent.aerinfo.safety.source': {
                          $filter: {
                            sources: {
                              $eq: 'Literature'
                            }
                          }
                        }
                      }
                    }
                  },
                  // If there WAS a source set to `Literature` validate `methofOfReceipt`
                  {
                    'model.tracking.methodOfReceipt': {
                      $eq: 'literature'
                    }
                  }
                ]
              },
              errorMessage:
                'Must have Method of Receipt of "Literature" if a Source is "Literature"'
            }
          },
          referencedProperties: {
            'layout.marginRight': {
              statePath: 'tracking.methodOfReceipt',
              $if: [{ $eq: 'literature' }, { $set: '50%' }],
              $else: { $set: '0' }
            },
            options: {
              schemaPath: './',
              $get: 'data.document-data.mastercase-options.method_of_receipt'
            },
            'validations.required.constraint': {
              $if: [
                {
                  $count: {
                    // 0 = false, !0 = true
                    'subcases.adverseEvent.aerinfo.safety.source': {
                      $filter: {
                        sources: {
                          $eq: 'Literature'
                        }
                      }
                    }
                  }
                },
                {
                  $set: true
                }
              ],
              $else: { $set: false }
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          component: 'TextInput',
          label: 'Conference Name',
          statePath: 'conference_name',
          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 50,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },
          referencedProperties: {
            'layout.display': {
              statePath: 'tracking.methodOfReceipt',
              $if: [
                {
                  $or: [{ $eq: 'medinfo_booth' }, { $eq: 'conference' }]
                },
                { $set: 'block' }
              ],
              $else: { $set: 'none' }
            }
          }
        },
        {
          $ref: 'DisplayValueToggle',
          component: 'TextInput',
          label: 'Other Method of Receipt',
          statePath: 'other_method_of_receipt',
          layout: {
            width: '50%'
          },
          validations: {
            maxLength: {
              constraint: 50,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },
          referencedProperties: {
            'layout.display': {
              statePath: 'tracking.methodOfReceipt',
              $if: [{ $eq: 'other' }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          }
        },
        {
          component: 'InputGroup',
          id: 'literature',
          statePath: 'literature',
          multiple: false,
          collapsible: false,
          length: 1,
          referencedProperties: {
            'layout.display': {
              statePath: 'tracking.methodOfReceipt',
              $if: [{ $eq: 'literature' }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          },
          elements: [
            {
              $ref: 'DisplayValueToggleChild',
              label: 'Article Title',
              component: 'TextInput',
              statePath: 'title',
              layout: {
                width: '50%'
              },
              validations: {
                maxLength: {
                  constraint: 240,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              label: 'Journal Title',
              component: 'TextInput',
              statePath: 'journal',
              layout: {
                width: '40%'
              },
              validations: {
                maxLength: {
                  constraint: 240,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              $ref: 'DisplayValueToggleChild',
              label: 'Edition',
              component: 'TextInput',
              statePath: 'edition',
              layout: {
                width: '10%'
              },
              validations: {
                maxLength: {
                  constraint: 20,
                  errorMessage:
                    'The maximum number of characters has been reached'
                }
              }
            },
            {
              id: 'pages',
              component: 'InputGroup',
              statePath: 'pages',
              multiple: false,
              collapsible: false,
              length: 1,

              layout: {
                display: 'block'
              },

              elements: [
                {
                  $ref: 'DisplayValueToggleChild',
                  label: 'Pages',
                  component: 'TextInput',
                  statePath: 'start',
                  layout: {
                    width: '10%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 10,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggleChild',
                  component: 'TextInput',
                  statePath: 'end',
                  layout: {
                    width: '10%',
                    padding: '20px 15px 10px',
                    position: 'relative',
                    '::before': {
                      display: 'block',
                      content: '"to"',
                      position: 'absolute',
                      bottom: 'calc(50% - 0.5em)',
                      textIndent: '-20px'
                    }
                  },
                  validations: {
                    maxLength: {
                      constraint: 10,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                }
              ]
            },
            {
              $ref: 'DisplayValueToggleChild',
              label: 'Publication Date',
              component: 'DateInput',
              statePath: 'publication_date',
              layout: {
                width: '50%',
                display: 'block'
              },
              validations: {
                date: {
                  constraint: 'partial',
                  errorMessage: ''
                }
              }
            },
            {
              id: 'authors',
              statePath: 'authors',
              component: 'InputGroup',
              multiple: true,
              collapsible: false,
              length: 1,
              label: 'another author',

              elements: [
                {
                  $ref: 'DisplayValueToggleChild',
                  label: 'Author First Name',
                  component: 'TextInput',
                  statePath: 'names.first',
                  layout: {
                    width: '40%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 30,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggleChild',
                  label: 'Middle',
                  component: 'TextInput',
                  statePath: 'names.middle',
                  layout: {
                    width: '10%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 30,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggleChild',
                  label: 'Author Last Name',
                  component: 'TextInput',
                  statePath: 'names.last',
                  layout: {
                    width: '40%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 30,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggleChild',
                  label: 'Title',
                  component: 'TextInput',
                  statePath: 'names.title',
                  layout: {
                    width: '10%'
                  },
                  validations: {
                    maxLength: {
                      constraint: 9,
                      errorMessage:
                        'The maximum number of characters has been reached'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 'AESaveSubmitActionSection',
      component: 'Section',
      title: '',
      statePath: '/ae-footer',
      elements: [
        {
          id: 'aeSaveSmartButton',
          label: 'Save',
          component: 'SmartButton',
          triggers: {
            onClick: 'onSaveCaseCSE'
          },
          layout: {
            width: '50%',
            display: 'flex',
            flexDirection: 'row'
          },
          styles: {
            backgroundColor: '#F2F2F2',
            color: '#00A9E0',
            border: '2px solid #00A9E0'
          }
        },
        {
          id: 'aeMarkSubmittedSmartButton',
          label: 'SUBMIT',
          component: 'SmartButton',
          triggers: {
            onClick: 'onValidate'
          },
          layout: {
            width: '50%',
            display: 'flex',
            flexDirection: 'row'
          },
          styles: {
            appearance: 'none',
            backgroundColor: '#071D49',
            border: 'none',
            color: '#FFFFFF',
            marginLeft: 'auto'
          },
          referencedProperties: {
            label: {
              statePath: 'subcases.adverseEvent.submitted',
              $if: [{ $eq: true }, { $set: 'SUBMITTED' }],
              $else: { $set: 'SUBMIT' }
            }
          }
        }
      ],
      layout: {
        background: 'none transparent',
        padding: '20px'
      }
    },
    {
      id: 'MISaveSubmitActionSection',
      component: 'Section',
      title: '',
      statePath: '/mi-footer',
      elements: [
        {
          id: 'miSaveSmartButton',
          label: 'Save',
          component: 'SmartButton',
          triggers: {
            onClick: 'onSaveCaseCSE'
          },
          layout: {
            width: '50%',
            display: 'flex',
            flexDirection: 'row'
          },
          styles: {
            backgroundColor: '#F2F2F2',
            color: '#00A9E0',
            border: '2px solid #00A9E0'
          }
        },
        {
          id: 'miMarkSubmittedSmartButton',
          label: 'SUBMIT',
          component: 'SmartButton',
          triggers: {
            onClick: 'onValidate'
          },
          layout: {
            width: '50%',
            display: 'flex',
            flexDirection: 'row'
          },
          styles: {
            appearance: 'none',
            backgroundColor: '#071D49',
            border: 'none',
            color: '#FFFFFF',
            marginLeft: 'auto'
          },
          referencedProperties: {
            label: {
              statePath: 'subcases.medicalInfo.submitted',
              $if: [{ $eq: true }, { $set: 'SUBMITTED' }],
              $else: { $set: 'SUBMIT' }
            }
          }
        }
      ],
      layout: {
        background: 'none transparent',
        padding: '20px'
      }
    },
    {
      id: 'PQSaveSubmitActionSection',
      component: 'Section',
      title: '',
      statePath: '/pq-footer',
      elements: [
        {
          id: 'pqSaveSmartButton',
          label: 'Save',
          component: 'SmartButton',
          triggers: {
            onClick: 'onSaveCaseCSE'
          },
          layout: {
            width: '50%',
            display: 'flex',
            flexDirection: 'row'
          },
          styles: {
            backgroundColor: '#F2F2F2',
            color: '#00A9E0',
            border: '2px solid #00A9E0'
          }
        },
        {
          id: 'pqMarkSubmittedSmartButton',
          label: 'SUBMIT',
          component: 'SmartButton',
          triggers: {
            onClick: 'onValidate'
          },
          layout: {
            width: '50%',
            display: 'flex',
            flexDirection: 'row'
          },
          styles: {
            appearance: 'none',
            backgroundColor: '#071D49',
            border: 'none',
            color: '#FFFFFF',
            marginLeft: 'auto'
          },
          referencedProperties: {
            label: {
              statePath: 'subcases.productQuality.submitted',
              $if: [{ $eq: true }, { $set: 'SUBMITTED' }],
              $else: { $set: 'SUBMIT' }
            }
          }
        }
      ],
      layout: {
        background: 'none transparent',
        padding: '20px'
      }
    },
    {
      id: 'InternalCommentsSection',
      component: 'Section',
      title: 'INTERNAL COMMENTS',
      statePath: 'comments',
      elements: [
        {
          id: 'internalComments',
          label: 'These comments will not be sent to downstream systems.',
          component: 'TextArea',
          statePath: 'internal_comments',
          layout: {
            width: '50%'
          },
          styles: {
            height: '176px',
            width: '100%'
          },
          validations: {
            required: {
              constraint: false,
              errorMessage: ''
            },
            maxLength: {
              constraint: 50000,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        }
      ],
      layout: {
        padding: '20px',
        width: '100%'
      }
    },
    {
      id: 'AdditionalNotes',
      component: 'InputGroup',
      statePath: 'notes',
      collapsible: true,
      multiple: false,
      label: 'Notes',
      length: 0,

      layout: {
        width: '100%'
      },
      referencedProperties: {
        'styles.display': {
          schemaPath: './',
          relative: true,
          $if: [{ collapsed: { $eq: true } }, { $set: 'none' }],
          $else: { $set: 'block' }
        }
      },
      elements: [
        {
          component: 'TextArea',
          statePath: 'note',
          layout: {
            display: 'block',
            width: '100%'
          }
        }
      ]
    },
    {
      id: 'UnitSelect',
      component: 'Select',
      label: 'Units',
      options: [],
      referencedProperties: {
        options: {
          schemaPath: './',
          $get: 'data.document-data.ae-options.unit_select'
        }
      }
    },
    {
      component: 'InputGroup',
      id: 'ReferenceNumber',
      statePath: 'reference_number',
      label: 'another reference number',
      collapsible: false,
      multiple: true,
      length: 1,
      layout: {
        position: 'relative'
      },
      elements: [
        {
          statePath: 'type',
          label: 'Type of Reference Number',
          placeholder: 'Select type',
          component: 'Select',
          options: [],
          layout: {
            width: '50%'
          },
          validations: {
            required: {
              constraint: false,
              errorMessage: ''
            }
          },
          referencedProperties: {
            options: {
              schemaPath: './',
              $get: 'data.document-data.mastercase-options.reference_type'
            },
            'validations.required.constraint': {
              statePath: 'number',
              relative: true,
              $if: [{ $ne: null }, { $set: true }],
              $else: { $set: false }
            }
          }
        },
        {
          statePath: 'number',
          label: 'Reference Number',
          component: 'TextInput',
          layout: {
            width: '50%'
          },
          validations: {
            required: {
              constraint: false,
              errorMessage: ''
            },
            maxLength: {
              constraint: 50,
              errorMessage: 'The maximum number of characters has been reached'
            }
          },
          referencedProperties: {
            'validations.required.constraint': {
              statePath: 'type',
              relative: true,
              $if: [{ $ne: null }, { $ne: '$no_selection' }, { $set: true }],
              $else: { $set: false }
            }
          }
        }
      ]
    },
    {
      component: 'InputGroup',
      id: 'LinkedCases',
      statePath: '',
      title: 'Trilogy Linked Cases',
      collapsible: false,
      multiple: true,
      length: 0,
      layout: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        ' > p': {
          marginLeft: '15px',
          width: '100%'
        },
        ' > span ': {
          display: 'none'
        },
        ' > section ': {
          width: '33%'
        },
        ' > section > div > div ': {
          width: '25%'
        },
        ' > section > span ': {
          display: 'none'
        }
      },

      referencedProperties: {
        helpText: {
          schemaPath: './',
          relative: true,
          $if: [
            {
              length: {
                $eq: 0
              }
            },
            {
              $set: 'There are currently no linked cases.'
            }
          ],
          $else: {
            $set: ''
          }
        }
      },

      elements: [
        {
          statePath: 'id',
          label: '',
          component: 'LabelValue',
          layout: {
            width: 'auto !important',
            paddingRight: '0',
            marginBottom: '15px',
            ' > span ': {
              display: 'list-item',
              listStyleType: 'square',
              marginLeft: '20px',
              cursor: 'pointer'
            },
            ' > span > span:hover': {
              fontWeight: 'bold'
            }
          },
          triggers: {
            onClick: 'onOpenCase'
          }
        },
        {
          id: 'isClonedCaseIndicator',
          label: 'C',
          component: 'PlainText',
          layout: {
            marginLeft: '0',
            paddingLeft: '10px',
            marginBottom: '15px',
            display: 'flex',
            width: 'initial'
          },
          referencedProperties: {
            label: {
              relative: true,
              statePath: 'type',
              $if: [
                {
                  $or: [{ $eq: 'CLONE_CHILD' }, { $eq: 'CLONE_SIBLING' }]
                },
                {
                  $set: 'C'
                }
              ],
              $elseIf: [{ $eq: 'CLONE_PARENT' }, { $set: 'PC' }],
              $else: [{ $set: '' }]
            }
          }
        },
        {
          id: 'unlinkCaseButton',
          statePath: '',
          label: 'x',
          component: 'SmartButton',
          triggers: {
            onClick: 'onUnlinkCase'
          },
          layout: {
            marginLeft: '1%',
            marginBottom: '15px',
            marginTop: '-5px',
            display: 'flex',
            width: 'initial',
            transform: 'scale(0.6)'
          },
          styles: {
            borderRadius: '100%',
            height: '30px',
            width: '30px',
            backgroundColor: '#FFFFFF',
            color: '#FF0000',
            border: '2px solid red',
            alignContent: 'center',
            padding: '0px'
          },
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'type',
              $if: [{ $ne: 'CLONE_PARENT' }, { $set: 'flex' }],
              $else: { $set: 'none' }
            }
          }
        }
      ]
    },
    {
      component: 'Section',
      id: 'ClonedFrom',
      title: 'Cloned From',
      statePath: '',
      layout: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        ' > p': {
          marginLeft: '15px',
          width: '100%'
        },
        ' > span ': {
          display: 'none'
        },
        ' > section ': {
          width: '33%'
        },
        ' > section > div > div ': {
          width: '25%'
        },
        ' > section > span ': {
          display: 'none'
        },
        ' > h2': {
          fontSize: '16px',
          marginLeft: '0',
          marginBottom: '15px'
        }
      },
      elements: [
        {
          component: 'LabelValue',
          label: '',
          statePath: '/clonedFrom',
          layout: {
            ' > span ': {
              cursor: 'pointer'
            },
            ' > span > span:hover': {
              fontWeight: 'bold'
            }
          },
          triggers: {
            onClick: 'onOpenCase'
          }
        }
      ]
    },
    {
      component: 'InputGroup',
      id: 'AERNumbers',
      statePath: 'aer_number',
      collapsible: false,
      multiple: true,
      title: 'Global Case ID(s)',
      helpText: 'Example: AER (Aegis), RCT (ECR), Argus ID',
      label: 'another',
      length: 1,
      layout: {
        position: 'relative',
        width: '50%',
        marginRight: '50%'
      },
      elements: [
        {
          statePath: 'number',
          label: 'Global Case ID',
          component: 'TextInput',
          layout: {
            width: '100%'
          },
          validations: {
            maxLength: {
              constraint: 30,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        }
      ],
      referencedProperties: {
        'layout.display': {
          statePath: 'created',
          $if: [{ $ne: null }, { $set: 'block' }],
          $else: { $set: 'none' }
        }
      }
    },
    {
      component: 'TextInput',
      id: 'GMISNumber',
      label: 'GMIS Number',
      statePath: 'gmis_number',
      title: 'GMIS Number',
      layout: {
        width: '50%',
        marginRight: '50%'
      },
      referencedProperties: {
        'layout.display': {
          $if: [
            {
              $and: [
                { $get: 'summary.narrative.categories.medical_inquiry' },
                { $get: 'status' }
              ]
            },
            { $set: 'inline-block' }
          ],
          $else: { $set: 'none' }
        }
      }
    },
    {
      id: 'LookupContactButton',
      label: 'Look up case contact',
      component: 'SmartButton',
      statePath: '',
      triggers: {
        onClick: 'onLookupContact'
      },
      layout: {
        display: 'block',
        width: 'initial',
        position: 'absolute',
        right: '30px'
      },
      styles: {
        appearance: 'none',
        backgroundColor: '#071D49',
        border: 'none',
        color: '#FFFFFF',
        marginLeft: 'auto',
        width: '200px',
        height: '30px',
        fontFamily: 'Calibri, "Microsoft Sans Serif"',
        fontSize: '14px',
        float: 'right'
      }
    },
    {
      id: 'LookupPatientButton',
      label: 'Look up patient',
      component: 'SmartButton',
      statePath: '',
      triggers: {
        onClick: 'onLookupPatient'
      },
      layout: {
        width: 'initial',
        display: 'block',
        position: 'absolute',
        right: '30px'
      },
      styles: {
        appearance: 'none',
        backgroundColor: '#071D49',
        border: 'none',
        color: '#FFFFFF',
        marginLeft: 'auto',
        width: '200px',
        height: '30px',
        fontFamily: 'Calibri, "Microsoft Sans Serif"',
        fontSize: '14px',
        float: 'right'
      }
    },
    {
      component: 'Section',
      id: 'SharedCaseNotes',
      title: 'Shared Case Notes',
      statePath: '',

      layout: {
        display: 'inline-flex',
        width: '100%'
      },

      elements: [
        {
          component: 'PlainText',
          label: 'INITIAL DESCRIPTION',
          statePath: '/summary.narrative.long2',
          layout: {
            display: 'block',
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              $if: [
                { 'summary.narrative.long2': { $eq: null } },
                { $set: 'none' }
              ],
              $else: {
                $set: 'block'
              }
            }
          }
        },
        {
          component: 'TextArea',
          statePath: '/common.notes[0].note',
          layout: {
            display: 'block',
            width: '100%'
          },
          styles: {
            height: '100px',
            width: '100%'
          },
          validations: {
            maxLength: {
              constraint: 20000,
              errorMessage: 'The maximum number of characters has been reached'
            }
          }
        }
      ]
    },
    {
      title: 'SHARED INFO',
      id: 'SharedInfo',
      component: 'Tab',
      path: 'sharedinfo',
      descend: true,
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'sharedinfo' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'aeSharedInfoSection',
          component: 'Section',
          title: 'Adverse Event',
          referencedProperties: {
            'layout.display': {
              statePath: 'summary.narrative.categories.adverse_event',
              $if: [{ $eq: true }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          },
          elements: [
            {
              component: 'SubHeading',
              title: 'AER INFO'
            },
            {
              statePath: 'subcases.adverseEvent.aerinfo.safety.source',
              length: 0,
              component: 'Section',
              layout: {
                '> div': {
                  width: '100%'
                }
              },
              elements: [
                {
                  component: 'LabelValue',
                  statePath: 'sources',
                  label: 'Source:',
                  value: 'sources',
                  layout: {
                    width: '100%'
                  }
                }
              ]
            },
            {
              component: 'SubHeading',
              title: 'REACTIONS'
            },
            {
              statePath: 'subcases.adverseEvent.reactions.reaction',
              length: 0,
              component: 'Section',
              elements: [
                {
                  component: 'Section',
                  layout: {
                    padding: 0,
                    marginBottom: 0
                  },
                  elements: [
                    {
                      component: 'LabelValue',
                      statePath: 'adverse_event',
                      label: 'Adverse Event: ',
                      layout: {
                        width: '100%'
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'reaction_seriousness',
                      label: 'Seriousness: ',
                      layout: {
                        width: '100%'
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'serious_criteria.death',
                      label: 'Seriousness Criteria: Results in death',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          statePath: 'serious_criteria.death',
                          $if: [{ $eq: true }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'serious_criteria.life_threat',
                      label: 'Seriousness Criteria: Life threatening',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          statePath: 'serious_criteria.life_threat',
                          $if: [{ $eq: true }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'serious_criteria.hospital',
                      label: 'Seriousness Criteria: Hospitalization',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          statePath: 'serious_criteria.hospital',
                          $if: [{ $eq: true }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'serious_criteria.prolonged_hospital',
                      label: 'Seriousness Criteria: Prolonged hospitalization',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          statePath: 'serious_criteria.prolonged_hospital',
                          $if: [{ $eq: true }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'serious_criteria.disabling_incapacitating',
                      label:
                        'Seriousness Criteria: Disabling or incapacitating',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          statePath:
                            'serious_criteria.disabling_incapacitating',
                          $if: [{ $eq: true }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'serious_criteria.cong_birth_anomaly',
                      label:
                        'Seriousness Criteria: Congenital anomaly or birth defect',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          statePath: 'serious_criteria.cong_birth_anomaly',
                          $if: [{ $eq: true }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'serious_criteria.other',
                      label:
                        'Seriousness Criteria: Other medically important condition',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          statePath: 'serious_criteria.other',
                          $if: [{ $eq: true }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'duration[0].duration_outcome',
                      label: 'Outcome: ',
                      layout: {
                        width: '100%'
                      }
                    }
                  ]
                }
              ]
            },

            {
              component: 'SubHeading',
              title: 'PRODUCT'
            },
            {
              component: 'Section',
              statePath: 'subcases.adverseEvent.product_section.aeproducts',
              length: 0,
              elements: [
                {
                  component: 'Section',
                  layout: {
                    padding: 0,
                    marginBottom: 0
                  },
                  elements: [
                    {
                      component: 'LabelValue',
                      statePath: 'product_summary[0].suspect_product',
                      label: 'AbbVie Suspect Product: ',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              'product_summary[0].product_flag': {
                                $eq: 'abbvie_suspect_product'
                              }
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'product_summary[0].local_trade_name',
                      label: 'Local Trade Name: ',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              'product_summary[0].product_flag': {
                                $eq: 'abbvie_suspect_product'
                              }
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'product_summary[0].other_product',
                      label: 'Other Product: ',
                      layout: {
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              'product_summary[0].product_flag': {
                                $eq: 'treatment'
                              }
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      statePath: 'product_indication',
                      length: 0,
                      component: 'Section',
                      layout: {
                        padding: 0,
                        marginBottom: 0,
                        width: '100%',
                        '> div': {
                          width: '100%'
                        }
                      },
                      elements: [
                        {
                          component: 'LabelValue',
                          statePath: 'drug_indications',
                          label: 'Drug Indication: ',
                          layout: {
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      component: 'Section',
                      length: 0,
                      statePath: 'product_therapy',
                      layout: {
                        padding: 0
                      },
                      elements: [
                        {
                          component: 'Section',
                          layout: {
                            padding: 0
                          },
                          elements: [
                            {
                              component: 'LabelValue',
                              label: 'Batch / Lot Number: ',
                              statePath: 'batch_lot_no',
                              layout: {
                                width: '100%'
                              }
                            },
                            {
                              component: 'LabelValue',
                              label: 'Unknown: ',
                              value: '',
                              layout: {
                                width: '100%'
                              },
                              reconciliationPath: 'availability.unknown',
                              referencedProperties: {
                                value: {
                                  statePath: 'availability.unknown',
                                  relative: true,
                                  $if: [{ $eq: true }, { $set: 'True' }],
                                  $else: [{ $set: 'False' }]
                                }
                              }
                            },
                            {
                              component: 'LabelValue',
                              label: 'Not Available: ',
                              value: '',
                              layout: {
                                width: '100%'
                              },
                              reconciliationPath: 'availability.unavailable',
                              referencedProperties: {
                                value: {
                                  relative: true,
                                  statePath: 'availability.unavailable',
                                  $if: [{ $eq: true }, { $set: 'True' }],
                                  $else: [{ $set: 'False' }]
                                }
                              }
                            },
                            {
                              component: 'LabelValue',
                              label: 'Why is the lot number unavailable?:',
                              value: '',
                              layout: {
                                width: '100%'
                              },
                              reconciliationPath: 'reason',
                              referencedProperties: {
                                value: {
                                  statePath: 'reason',
                                  relative: true,
                                  $or: [
                                    {
                                      $and: [
                                        { $eq: 'packaging_discarded' },
                                        {
                                          $set:
                                            'Primary reporter does not have the lot number information, because the packaging was discarded.'
                                        }
                                      ]
                                    },
                                    {
                                      $and: [
                                        { $eq: 'physician_inaccessible' },
                                        {
                                          $set:
                                            'Primary reporter does not have the lot number information, because the information is not accessible to the physician at his/her clinical setting.'
                                        }
                                      ]
                                    },
                                    {
                                      $and: [
                                        { $eq: 'not_available' },
                                        {
                                          $set:
                                            "Primary reporter does not have the lot number information, because the information is not available in the patient's file."
                                        }
                                      ]
                                    },
                                    {
                                      $and: [
                                        { $eq: 'not_original_packaging' },
                                        {
                                          $set:
                                            'Primary reporter does not have the lot number information, because the patient did not receive the product in its original package.'
                                        }
                                      ]
                                    },
                                    {
                                      $and: [
                                        { $eq: 'not_legible' },
                                        {
                                          $set:
                                            'Primary reporter does not have the lot number information, because the lot number was not legible on product package.'
                                        }
                                      ]
                                    },
                                    {
                                      $and: [
                                        { $eq: 'declined_to_provide' },
                                        {
                                          $set:
                                            'Primary reporter declined to provide lot number information.'
                                        }
                                      ]
                                    },
                                    {
                                      $and: [
                                        { $eq: 'contact_unsuccessful' },
                                        {
                                          $set:
                                            'Primary reporter contact for lot number information was unsuccessful.'
                                        }
                                      ]
                                    },
                                    {
                                      $and: [
                                        { $eq: 'contact_unavailable' },
                                        {
                                          $set:
                                            'Primary reporter’s contact information was not available.'
                                        }
                                      ]
                                    },
                                    {
                                      $and: [
                                        { $eq: 'declined_contact' },
                                        {
                                          $set:
                                            'Primary reporter declined to be contacted.'
                                        }
                                      ]
                                    },
                                    {
                                      $and: [
                                        { $eq: 'other' },
                                        {
                                          $set:
                                            'Primary reporter does not have the lot number information, because...'
                                        }
                                      ]
                                    },
                                    { $set: '' }
                                  ]
                                }
                              }
                            },
                            {
                              component: 'LabelValue',
                              label: 'Additional Comments:',
                              statePath: 'additional_comments',
                              layout: {
                                width: '100%'
                              }
                            },
                            {
                              component: 'LabelValue',
                              label: 'Related to PQ: ',
                              layout: {
                                width: '100%'
                              },
                              value: '',
                              referencedProperties: {
                                value: {
                                  relative: true,
                                  statePath: 'related_to_product_complaint',
                                  $if: [{ $ne: true }, { $set: 'False' }],
                                  $else: [{ $set: 'True' }]
                                }
                              }
                            }
                          ],
                          referencedProperties: {
                            'layout.display': {
                              statePath: '../product_summary[0].product_flag',
                              relative: true,
                              $if: [
                                {
                                  $eq: 'abbvie_suspect_product'
                                },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            }
                          }
                        }
                      ]
                    }
                  ],
                  referencedProperties: {
                    'layout.display': {
                      statePath: './',
                      relative: true,
                      $if: [
                        {
                          '$this.instance': { $eq: true }
                        },
                        {
                          'product_summary[0].product_flag': {
                            $or: [
                              { $eq: 'abbvie_suspect_product' },
                              { $eq: 'treatment' }
                            ]
                          }
                        },
                        { $set: 'block' }
                      ],
                      $elseif: [
                        // why isnt this elseIf with capital? does this work?
                        { '$this.instance': { $eq: false } },
                        { $set: 'block' }
                      ],
                      $else: { $set: 'none' }
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          id: 'pqSharedInfoSection',
          component: 'Section',
          title: 'Product Quality',
          referencedProperties: {
            'layout.display': {
              statePath: 'summary.narrative.categories.product_quality',
              $if: [{ $eq: true }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          },

          elements: [
            {
              component: 'Section',
              statePath: 'subcases.productQuality.pqproduct.products',
              length: 0,
              layout: {
                padding: 0
              },
              elements: [
                {
                  component: 'Section',
                  layout: {
                    padding: 0
                  },
                  elements: [
                    {
                      component: 'LabelValue',
                      label: 'PRODUCT',
                      layout: {
                        width: '100%',
                        // making this look more like a header
                        ' > span > span': {
                          fontFamily: 'SackersGothicStd-Heavy, sans-serif',
                          fontSize: '16px',
                          color: '#071D49'
                        }
                      },
                      referencedProperties: {
                        value: {
                          schemaPath: '../',
                          relative: true,
                          $get: 'order'
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'description',
                      label: 'PQ Description: ',
                      layout: {
                        width: '100%'
                      }
                    },
                    {
                      component: 'LabelValue',
                      statePath: 'event_date',
                      label: 'PQ Complaint Event Date: ',
                      layout: {
                        width: '100%'
                      }
                    },
                    {
                      statePath: 'details',
                      length: 0,
                      component: 'Section',
                      layout: {
                        padding: 0
                      },
                      elements: [
                        {
                          component: 'LabelValue',
                          statePath: 'marketedName',
                          label: 'Marketed Name: ',
                          layout: {
                            width: '100%'
                          }
                        },
                        {
                          component: 'LabelValue',
                          statePath: 'lotNumber',
                          label: 'Batch Lot Number: ',
                          layout: {
                            width: '100%'
                          }
                        },
                        {
                          component: 'LabelValue',
                          label: 'Unavailable: ',
                          layout: {
                            width: '100%'
                          },
                          reconciliationPath: 'availability',
                          referencedProperties: {
                            value: {
                              statePath: 'availability',
                              relative: true,
                              $if: [{ $eq: true }, { $set: 'True' }],
                              $else: [{ $set: 'False' }]
                            }
                          }
                        },
                        {
                          component: 'LabelValue',
                          statePath: 'reason',
                          label: 'Why is the batch/lot number unavailable?: ',
                          layout: {
                            width: '100%'
                          }
                        },
                        {
                          component: 'LabelValue',
                          statePath: 'additional_comments',
                          label: 'Batch Number Additional Comments: ',
                          layout: {
                            width: '100%'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 'miSharedInfoSection',
          component: 'Section',
          title: 'Medical Inquiry',
          referencedProperties: {
            'layout.display': {
              statePath: 'summary.narrative.categories.medical_inquiry',
              $if: [{ $eq: true }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          },
          elements: []
        },
        {
          component: 'Section',
          elements: [
            {
              component: 'SubHeading',
              title: 'ATTACHMENTS'
            },
            {
              statePath: '/documents.attachments',
              length: 0,
              component: 'Section',
              layout: {
                margin: 0
              },
              elements: [
                {
                  component: 'Section',
                  layout: {
                    padding: 0,
                    marginBottom: 0
                  },
                  elements: [
                    {
                      component: 'LabelValue',
                      statePath: 'name',
                      label: 'File name: ',
                      layout: {
                        width: '100%'
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  dynamicOptions: [
    {
      id: 'mastercaseProducts',
      documentMeta: {
        documentDataId: 'mastercase-product-options',
        documentDataType: 'documentDataList',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'userList',
      documentMeta: {
        documentDataId: 'user-list',
        documentDataType: 'documentDataList',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'suspectProduct',
      documentMeta: {
        documentDataId: 'ae-product-data',
        documentDataType: 'productData',
        documentDataField: 'preferredProductName',
        tacticalType: 'product-data'
      }
    },
    {
      id: 'pqProductData',
      documentMeta: {
        documentDataId: 'pq-product-data',
        documentDataType: 'productData',
        documentDataField: 'products',
        tacticalType: 'product-data'
      }
    },
    {
      id: 'formOfAdmin',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'form_of_admin',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'country',
      documentMeta: {
        documentDataId: 'country-options',
        documentDataType: 'documentDataList',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'regCountry',
      documentMeta: {
        documentDataId: 'regcountry-options',
        documentDataType: 'documentDataList',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'safetySource',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'safety_source',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'studyDesign',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'study_design',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'studyType',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'study_type',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'studyPhase',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'study_phase',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'protocol',
      documentMeta: {
        documentDataId: 'ae-protocol-data',
        documentDataType: 'protocolData',
        documentDataField: 'protocol_number',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'causalityPerReporter',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'causality_per_reporter',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'routeOfAdmin',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'route_of_admin',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'testUnits',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'test_units',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'durationOutcome',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'duration_outcome',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'durationLatency',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'duration_latency',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'durationUnits',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'duration_units',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'unitSelect',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'unit_select',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'strengthUnits',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'strength_units',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'frequencyUnits',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'frequency_units',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'ageUnits',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'age_units',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'ageGroup',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'age_group',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'heightUnit',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'height_unit',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'weightUnit',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'weight_unit',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'gestationUnits',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'gestation_units',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'codingClass',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'coding_class',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'reporterType',
      documentMeta: {
        documentDataId: 'ae-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'reporter_type',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'coLicensingPartner',
      documentMeta: {
        documentDataId: 'co-licensing-partner-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'co-licensing-partner-options',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'languages',
      documentMeta: {
        documentDataId: 'language-options',
        documentDataType: 'documentDataList',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'attachmentsTags',
      documentMeta: {
        documentDataId: 'attachment-tags',
        documentDataType: 'documentDataList',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'manufacturingSite',
      documentMeta: {
        documentDataId: 'pq-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'manufacturing_site',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'finishingSite',
      documentMeta: {
        documentDataId: 'pq-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'finishing_site',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'qaSite',
      documentMeta: {
        documentDataId: 'pq-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'qa_site',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'marketedName',
      documentMeta: {
        documentDataId: 'pq-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'marketed_name',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'lotNumberUnavailableReason',
      documentMeta: {
        documentDataId: 'pq-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'lot_number_unavailable_reason',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'miAbbvieProduct',
      documentMeta: {
        documentDataId: 'mi-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'abbvie_product',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'miIndication',
      documentMeta: {
        documentDataId: 'mi-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'indication',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'pspOpiNumber',
      documentMeta: {
        documentDataId: 'psp-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'psp_opi_number',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'pspName',
      documentMeta: {
        documentDataId: 'psp-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'psp_name',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'hcpSpecialty',
      documentMeta: {
        documentDataId: 'mastercase-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'hcp_specialty',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'methodOfReceipt',
      documentMeta: {
        documentDataId: 'mastercase-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'method_of_receipt',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'typeOfContact',
      documentMeta: {
        documentDataId: 'mastercase-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'type_of_contact',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'reCategory',
      documentMeta: {
        documentDataId: 'reported-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'reported_category',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'reSubCategory',
      documentMeta: {
        documentDataId: 'reported-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'reported_sub_category',
        tacticalType: 'document-data'
      }
    }
  ],
  pages: [
    /* eslint-disable global-require */
    require('./pages/caseCreation'),
    require('./pages/medicalInquiry'),
    require('./pages/productQuality'),
    require('./pages/adverseEvent'),
    require('./pages/caseReview')
    /* eslint-enable global-require */
  ]
};

// Convenience for rendering the initial form object in plaintext
module.exports.stringified = JSON.stringify(module.exports.form, null, 2);
