/* eslint no-useless-escape:0 */

export * from './display';
export * from './routing';

export const TABS_TOP_MARGIN = 5;

export const PROTO_INDEX = 'createCase';

export const SUBCASE_CATEGORY_MAP = {
  adverse_event: 'adverseEvent',
  medical_inquiry: 'medicalInquiry',
  product_quality: 'productQuality'
};

export const SUBCASE_TYPE_PATHS = {
  ae: 'summary.narrative.categories.adverse_event',
  mi: 'summary.narrative.categories.medical_inquiry',
  pq: 'summary.narrative.categories.product_quality'
};

export const CASE_FE_ONLY_FIELDS = [
  '__typename',
  'id',
  'masterCaseId',
  'status',
  'version',
  'versionCreated',
  'submissionDue',
  'submissions',
  'completedDate',
  'summary.newDescription',
  'completed',
  'completedDate',
  'archivedDate',
  'reopenedDate',
  'revisions',
  'subcases.adverseEvent.subcaseVersions',
  'subcases.productQuality.subcaseVersions'
];

export const ATTACHMENTS_FE_ONLY_FIELDS = [
  '__typename',
  '_id',
  'size',
  'upload',
  'originalFilename',
  'hash',
  'mimeType'
];

export const CONFLICT_DIFFS = {
  ATTEMPTED: 'attempted',
  CURRENT: 'current'
};

export const CONFLICT_OP_TYPES = {
  ADD: 'add',
  REPLACE: 'replace',
  REMOVE: 'remove'
};

export const AUTO_RESOLVABLE_PROTO_KEY_REGEXES = [
  /undefined/,
  /latestDraft/,
  /trilogyLoadDate/,
  /subcases.adverseEvent.subcaseVersions\[\d+\].revision/,
  /[patient.patient[\d\].id]/,
  /creatorid/,
  /creatorUsername/,
  /lastUpdatedUserId/,
  /lastUpdatedUsername/,
  /affiliate/
];

// Values generated by the BE that should never be submitted along with a trilogyCase
export const EXTRANEOUS_VALUES_TO_OMIT = [
  'documentType',
  'completed',
  'completedDate',
  'archivedDate',
  'reopenedDate',
  'status',
  'created',
  'submittedDate',
  'archivedDate',
  'reopenedDate',
  'version',
  'versionCreated',
  'submissionDue',
  'subcaseVersions',
  'createdTimestamp',
  'currentRevision'
];

/*
 * Conflict paths used to test if a set of conflict errors are due to
 * trying to save a case that has already been submitted or archived.
 */
export const SUBMISSION_PATHS = [
  '/archivedDate',
  '/created',
  '/subcases/adverseEvent/submittedDate',
  '/subcases/medicalInfo/submittedDate',
  '/subcases/productQuality/submittedDate'
];

export const CONFLICT_ERRORS = {
  CASE_EXISTS:
    'Case has already been created or marked as submitted by another user. Navigate to the master case dashboard or sub-case form for this case to take further action.'
};

export const RECONCILIATION_PATH_MAP = {
  ae: 'subcases.adverseEvent.reconciliationNeeded',
  pq: 'subcases.productQuality.reconciliationNeeded',
  mi: 'subcases.medicalInfo.reconciliationNeeded'
};
