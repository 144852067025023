/* eslint no-useless-escape:0 no-template-curly-in-string:0 */

/**
 * advancedSearch.js is the schema for the filters on the Advanced Search page (/search, click Advanced Search).
 */
module.exports.schema = {
  id: 'advancedSearch',
  locale: 'US',
  pages: [
    {
      elements: [
        {
          component: 'Section',
          layout: {
            background: 'initial',
            margin: 'initial'
          },
          elements: [
            {
              label: 'Product',
              component: 'TextInput',
              statePath: 'product',
              generalQuery: true,
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Trilogy Case ID',
              component: 'TextInput',
              statePath: 'id',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Text Search',
              component: 'TextInput',
              statePath: 'referenceNumber',
              generalQuery: true,
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Country of Primary Reporter/Requester',
              component: 'Select',
              multi: true,
              statePath: 'countryOfPrimaryReporter',
              options: [],
              layout: {
                flex: '0 1 auto',
                width: '33%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.country-options'
                }
              }
            },
            {
              label: 'Latest Received Date',
              component: 'DateRangeInput',
              statePath:
                'subcases.adverseEvent.aerinfo.safety.last_received_date',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'PQ Abbvie Awareness Date',
              component: 'DateRangeInput',
              statePath: 'summary.productQuality.affiliate_awareness_date',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Submitted Date to SolTRAQs',
              component: 'DateRangeInput',
              statePath: 'subcases.productQuality.submittedDate',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Submitted Date to ECR/Aegis/Argus',
              component: 'DateRangeInput',
              statePath: 'subcases.adverseEvent.submittedDate',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            }
          ]
        },
        {
          component: 'Section',
          title: 'Patient',
          statePath: 'patient.patient[0]',
          layout: {
            background: 'initial',
            margin: 'initial'
          },
          elements: [
            {
              label: 'Initials',
              component: 'TextInput',
              statePath: 'initials',
              layout: {
                flex: '0 1 auto',
                width: '10%'
              }
            },
            {
              label: 'Gender',
              component: 'Select',
              statePath: 'gender',
              layout: {
                flex: '0 1 auto',
                width: '23%'
              },
              options: [
                {
                  value: 'm',
                  label: 'Male'
                },
                {
                  value: 'f',
                  label: 'Female'
                },
                {
                  value: 'not_specified',
                  label: 'Not Specified'
                }
              ],
              placeholder: 'Select'
            },
            {
              label: 'First Name',
              component: 'TextInput',
              statePath: 'firstName',
              layout: {
                flex: '1 0 auto',
                width: '33%'
              }
            },
            {
              label: 'Last Name',
              component: 'TextInput',
              statePath: 'lastName',
              layout: {
                flex: '1 0 auto',
                width: '33%'
              }
            },
            {
              label: 'Date of Birth',
              component: 'DateInput',
              statePath: 'dob',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              },
              validations: {
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              }
            },
            {
              label: 'Phone Number',
              component: 'TextInput',
              statePath: 'contact_info[0].phone[0].number',
              layout: {
                flex: '1 0 auto',
                width: 'initial'
              }
            },
            {
              label: 'Email',
              component: 'TextInput',
              statePath: 'contact_info[0].email[0].address',
              layout: {
                flex: '1 0 auto',
                width: 'initial'
              }
            },
            {
              label: 'Third Party Patient ID',
              component: 'TextInput',
              statePath: 'reporter',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            }
          ]
        },
        {
          component: 'Section',
          title: 'Other Contact',
          statePath: 'contacts.contact[0]',
          layout: {
            background: 'initial',
            margin: 'initial'
          },
          elements: [
            {
              label: 'Type of Contact',
              component: 'Select',
              statePath: 'type',
              layout: {
                flex: '1 0 auto',
                width: '33%'
              },
              placeholder: 'Select',
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.mastercase-options.type_of_contact'
                }
              }
            },
            {
              label: 'First Name',
              component: 'TextInput',
              statePath: 'name.first',
              layout: {
                flex: '1 0 auto',
                width: '33%'
              }
            },
            {
              label: 'Last Name',
              component: 'TextInput',
              statePath: 'name.last',
              layout: {
                flex: '1 0 auto',
                width: '33%'
              }
            },
            {
              label: 'Program ID/Name',
              component: 'Select',
              statePath: 'psp.opi_number',
              layout: {
                flex: '1 0 auto',
                width: '33%'
              },
              placeholder: 'Select',
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.psp-options.psp_opi_number'
                }
              }
            },
            {
              label: 'Phone Number',
              component: 'TextInput',
              statePath: 'phone[0].number',
              layout: {
                flex: '1 0 auto',
                width: '33%'
              }
            },
            {
              label: 'Email',
              component: 'TextInput',
              statePath: 'email[0].address',
              layout: {
                flex: '1 0 auto',
                width: '33%'
              }
            }
          ]
        }
      ]
    }
  ]
};

// Convenience for rendering the initial form object in plaintext
module.exports.stringified = JSON.stringify(module.exports.form, null, 2);
