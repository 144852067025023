import { generateCSS } from 'Common/components/Form';

const styleGenerator = theme => ({
  footer: generateCSS({
    height: '50px',
    backgroundColor: theme.colors.quinary
  }),
  footerText: generateCSS({
    fontFamily: 'Calibri',
    color: theme.colors.secondary,
    fontWeight: 300,
    fontSize: '12px',
    margin: '0 35px',
    paddingTop: '20px'
  })
});

export default styleGenerator;
