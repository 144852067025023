module.exports = [
  {
    $ref: 'DisplayValueToggle',
    label: 'AbbVie Awareness Date - PQ Complaint',
    component: 'DateInput',
    statePath: '/summary.productQuality.awareness_date',
    layout: {
      width: '100%'
    },
    validations: {
      required: {
        constraint: true,
        errorMessage: ''
      },
      date: {
        constraint: 'full',
        errorMessage: 'Invalid full date'
      },
      dateRestriction: {
        constraint: 'future',
        errorMessage: 'Date must not occur in the future'
      }
    }
  },
  {
    $ref: 'DisplayValueToggle',
    label: 'Affiliate Awareness Date - PQ Complaint',
    component: 'DateInput',
    statePath: '/summary.productQuality.affiliate_awareness_date',
    layout: {
      width: '100%'
    },
    validations: {
      required: {
        constraint: true,
        errorMessage: ''
      },
      date: {
        constraint: 'full',
        errorMessage: 'Invalid full date'
      },
      dateRestriction: {
        constraint: 'future',
        errorMessage: 'Date must not occur in the future'
      }
    }
  },
  {
    $ref: 'DisplayValueToggle',
    label: 'PQ Complaint Event Date',
    component: 'DateInput',
    statePath: '/summary.productQuality.event_date',
    layout: {
      width: '100%'
    },
    validations: {
      date: {
        constraint: 'full',
        errorMessage: 'Invalid full date'
      },
      dateRestriction: {
        constraint: 'future',
        errorMessage: 'Date must not occur in the future'
      }
    },
    referencedProperties: {
      disabled: {
        $get: 'summary.productQuality.event_date_unavailable'
      }
    }
  },
  {
    $ref: 'DisplayValueToggle',
    component: 'Checkbox',
    label: 'Unavailable',
    statePath: '/summary.productQuality.event_date_unavailable',
    layout: {
      paddingLeft: '15px',
      paddingTop: '20px'
    },
    referencedProperties: {
      disabled: {
        statePath: 'summary.productQuality.event_date',
        $if: [{ $ne: null }, { $set: true }],
        $else: { $set: false }
      }
    }
  },
  {
    $ref: 'DisplayValueToggle',
    label: 'Why is the event date unavailable?',
    component: 'Select',
    statePath: '/summary.productQuality.event_date_unavailable_reason',
    layout: {
      width: '100%',
      display: 'none'
    },
    options: [
      {
        label: 'Patient contact information not available',
        value: 'patient_contact_info_not_available'
      },
      {
        label: 'Primary Reporter did not have Event Date',
        value: 'primary_reporter_had_no_event_date'
      },
      {
        label: 'After follow-up, Event Date is not available',
        value: 'after_followup_not_available'
      },
      {
        label: 'Due Diligence for Event Date in process',
        value: 'due_diligence_in_process'
      },
      {
        label: 'Primary Reporter declined further contact',
        value: 'primary_reporter_declined'
      },
      {
        label: 'Primary Reporter contact information was not provided',
        value: 'primary_reporter_contact_info_not_provided'
      },
      {
        label: 'Event Date was not provided in source document/voicemail',
        value: 'event_date_not_provided_in_source'
      },
      { label: 'Other', value: 'other' }
    ],
    referencedProperties: {
      'layout.display': {
        $if: [
          {
            $and: [
              {
                'summary.narrative.categories.product_quality': {
                  $eq: true
                }
              },
              {
                $get: 'summary.productQuality.event_date_unavailable'
              }
            ]
          },
          { $set: 'block' }
        ],
        $else: { $set: 'none' }
      }
    }
  },
  {
    $ref: 'DisplayValueToggle',
    id: 'event_date_unavailable_reason_comments',
    label: 'Additional Comments',
    component: 'TextArea',
    statePath: '/summary.productQuality.event_date_unavailable_reason_comments',
    layout: {
      width: '100%',
      display: 'none'
    },
    styles: {
      height: '176px',
      width: '100%'
    },
    referencedProperties: {
      'layout.display': {
        $if: [
          {
            $and: [
              {
                'summary.narrative.categories.product_quality': {
                  $eq: true
                }
              },
              {
                // need this in case Unavailable becomes deselected
                $get: 'summary.productQuality.event_date_unavailable'
              },
              {
                'summary.productQuality.event_date_unavailable_reason': {
                  $eq: 'other'
                }
              }
            ]
          },
          { $set: 'block' }
        ],
        $else: { $set: 'none' }
      }
    }
  },
  {
    $ref: 'DisplayValueToggle',
    label: 'Sample available for return?',
    component: 'Select',
    statePath: '/summary.productQuality.complaint.processing.available',
    layout: {
      width: '100%'
    },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
      {
        label: 'Asked but Unknown (ASKU)',
        value: 'Asked but Unknown (ASKU)'
      },
      { label: 'No Information (NI)', value: 'No Information (NI)' },
      { label: 'Not Applicable (NA)', value: 'Not Applicable (NA)' }
    ],
    referencedProperties: {
      'layout.display': {
        $if: [
          {
            'summary.narrative.categories.product_quality': {
              $eq: true
            }
          },
          { $set: 'block' }
        ],
        $else: { $set: 'none' }
      }
    }
  },
  {
    $ref: 'DisplayValueToggle',
    label: 'Why is the sample unavailable?',
    component: 'Select',
    statePath: '/summary.productQuality.complaint.processing.availableReason',
    layout: {
      width: '100%'
    },
    options: [
      { label: 'Discarded', value: 'discarded' },
      { label: 'Still in use', value: 'inuse' },
      { label: 'Other', value: 'other' }
    ],
    referencedProperties: {
      'layout.display': {
        $if: [
          {
            $and: [
              {
                'summary.narrative.categories.product_quality': {
                  $eq: true
                }
              },
              {
                'summary.productQuality.complaint.processing.available': {
                  $eq: 'no'
                }
              }
            ]
          },
          { $set: 'block' }
        ],
        $else: { $set: 'none' }
      }
    }
  },
  {
    component: 'TextInput',
    label: 'Sample Unavailable Reason',
    statePath:
      '/summary.productQuality.complaint.processing.sampleUnavailableReason',
    layout: {
      width: '100%'
    },
    referencedProperties: {
      'layout.display': {
        $if: [
          {
            $and: [
              {
                'summary.narrative.categories.product_quality': {
                  $eq: true
                }
              },
              {
                'summary.productQuality.complaint.processing.available': {
                  $eq: 'no'
                }
              },
              {
                'summary.productQuality.complaint.processing.availableReason': {
                  $eq: 'other'
                }
              }
            ]
          },
          { $set: 'block' }
        ],
        $else: { $set: 'none' }
      }
    }
  },
  {
    $ref: 'DisplayValueToggle',
    label: 'High impact?',
    component: 'RadioGroup',
    statePath: '/summary.productQuality.complaint.processing.highImpact',
    options: [{ label: 'No', value: 'no' }, { label: 'Yes', value: 'yes' }],
    layout: {
      width: '100%'
    },
    referencedProperties: {
      'layout.display': {
        $if: [
          {
            'summary.narrative.categories.product_quality': {
              $eq: true
            }
          },
          { $set: 'block' }
        ],
        $else: { $set: 'none' }
      }
    }
  },
  {
    id: 'pqSmartButton',
    component: 'SmartButton',
    label: 'Create PQ',
    triggers: {
      onClick: ['onCreateDashboardSubcase', 'pq']
    },
    layout: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 20,
      width: '100%'
    },
    styles: {
      backgroundColor: '#F2F2F2',
      color: '#00A9E0',
      border: '2px solid #00A9E0',
      textAlign: 'center',
      width: '100%'
    }
  }
];
