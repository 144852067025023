import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  queuePopup: generateCSS({
    maxWidth: '1000px'
  }),
  modifyFilterContainerPopup: generateCSS({
    justifyContent: 'center',
    marginTop: '30px',
    maxHeight: '60vh',
    overflowY: 'auto',
    width: '800px'
  }),
  controlDisplay: generateCSS({
    width: '50%',
    display: 'inline-block',
    float: 'right'
  }),
  base: generateCSS({
    width: '50%',
    display: 'inline-block'
  }),
  filterDisplay: generateCSS({
    marginLeft: '30px',
    marginRight: '30px',
    clear: 'both',
    marginBottom: '50px'
  }),
  checkboxDisplay: generateCSS({
    marginBottom: '25px',
    '> div': {
      width: '50%',
      float: 'right',
      clear: 'both',
      '>span': {
        padding: '3px 0 0px 0px',
        display: 'inline-block',
        verticalAlign: 'top'
      }
    }
  }),
  controlLabelDisplay: generateCSS({
    verticalAlign: 'bottom',
    fontSize: '16px',
    paddingTop: '10px'
  }),
  modifyConfirmBtnPopup: generateCSS({
    background: '#071D49',
    color: 'white',
    width: '100px',
    border: '1px solid #071D49',
    borderRadius: '5px',
    padding: '8px',
    margin: '20px auto',
    display: 'block',
    cursor: 'pointer'
  }),
  errorlabel: generateCSS({
    color: 'red'
  }),
  errorlabelControl: generateCSS({
    color: 'red',
    boxShadow: 'inset 0 0 0 2px #D0011B'
  }),
  noResultsMessage: generateCSS({
    display: 'block',
    margin: '10% 0 10% 30%',
    fontSize: 24
  })
});

export default stylesGenerator;
