/* eslint no-use-before-define: ["error", { "variables": false }] */
import { jsPDF as JsPDF } from 'jspdf';
import {
  get,
  find,
  filter,
  startCase,
  replace,
  forEach,
  isNil,
  split,
  last
} from 'lodash';

/** utilities related to PDF formatting and exporting */

export const VERSION_INFO_FIELDS = [
  { statePath: 'assigneeName', label: 'Assignee' },
  {
    statePath: 'completeWithoutSubmittedDate',
    label: 'Completed Without Submitted Date'
  },
  { statePath: 'archiveComments', label: 'Archived Comments' },
  { statePath: 'completedReason', label: 'Completed Reason' },
  { statePath: 'archiveReason', label: 'Archived Reason' },
  { statePath: 'reopened', label: 'Reopened' },
  { statePath: 'submittedDate', label: 'Submitted Date' },
  { statePath: 'id', label: 'ID' },
  { statePath: 'version', label: 'Version' },
  { statePath: 'createdDate', label: 'Created Date' },
  { statePath: 'submitted', label: 'Submitted' },
  { statePath: 'reopenedDate', label: 'Reopened Date' },
  { statePath: 'submissionDue', label: 'Submission Due' },
  { statePath: 'completedReasonComments', label: 'Completed Reason Comments' },
  { statePath: 'status', label: 'Status' },
  { statePath: 'newVersion', label: 'New Version' }
];

/**
 * uses jsPDF and parses the case object to save a readable PDF
 * @function
 * @param {TrilogyCase} trilogycase the trilogy case object
 * @param {string} subcaseType type of subcase to render: 'ae', 'pq', or 'review' (mastercase)
 * @param schema - the schema document, used to determine layout of sections
 * @param tacticalData - solely used to get country label
 */
export const exportCaseToPdf = (
  trilogyCase,
  subcaseType,
  schema,
  tacticalData
) => {
  // keeps track of current page, increments when yOffset is at limit (10)
  let currPage = 1;

  // keeps track of what line of page is currently being written to
  // starts at 1.25 to space evenly from header
  let yOffset = 1.25;

  let doc = new JsPDF({
    unit: 'in',
    format: 'letter',
    orientation: 'portrait'
  });

  // used to figure out version statepaths
  let subcaseName = '';
  if (subcaseType === 'ae') {
    subcaseName = 'adverseEvent';
  } else if (subcaseType === 'pq') {
    subcaseName = 'productQuality';
  }

  const exportDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }).format(new Date());

  // header and footer
  const insertHeader = () => {
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(10);
    doc.text(`Exported: ${exportDate}`, 0.5, 0.5);
    doc.text(
      `${get(
        trilogyCase,
        `subcases.${subcaseName}.id`,
        trilogyCase.id
      )} Revision ${get(trilogyCase, 'currentRevision')}`,
      6,
      0.5
    );
    doc.text(
      'Includes PII - please align with local data privacy regulations when transmitting or storing this document',
      0.5,
      0.75
    );
  };
  const insertFooter = () => {
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(`Page ${currPage}`, 4, 10.5);
  };

  // sections
  const checkPageBreak = () => {
    if (yOffset > 10) {
      yOffset = 1.25;
      doc = doc.addPage('letter');
      currPage += 1;
      insertHeader();
      insertFooter();
    }
  };
  const insertTitle = title => {
    checkPageBreak();
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(11);
    doc.text(startCase(title), 0.5, yOffset + 0.05);
    yOffset += 0.35;
  };
  // xOffset is only used for nested items
  const insertItem = (label, value, xOffset = 0.5) => {
    checkPageBreak();
    if (value !== null && value !== undefined) {
      const numTextLines =
        0.175 *
          Math.ceil(
            Math.max(
              doc.getTextWidth(label.toString()) / 3.25,
              doc.getTextWidth(value.toString()) / 4
            )
          ) +
        0.075;
      const newY = yOffset + numTextLines;
      if (newY > 10) {
        yOffset = 1.25;
        doc = doc.addPage('letter');
        currPage += 1;
        insertHeader();
        insertFooter();
      }
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setTextColor('#0082ba');
      doc.text(`${label}:`, xOffset, yOffset, { maxWidth: 3.25 });
      doc.setTextColor('0');
      doc.text(`${value}`, 4.25, yOffset, { maxWidth: 4 });
      yOffset += numTextLines;
    }
  };

  // functions imported from PreviousVersions
  /**
   * Helper function to build the state path with respect to its parents statepath.  When parsing the schema object the path of the elements is
   * not a full path, so it is necessary to know that path of the parent when getting the full state path.
   *
   * @param parentStatePath: The state path of the parent element
   * @param currentStatePath: The state path of the current element
   * @param title: The title of the schema element that will be set to the screenLabel property in the result.  If the title is null or and empty string
   * the screenLabel property will be set to the startCase value of the currentStatePath argument
   * @returns {{screenLabel: *}}
   */
  const createStatePath = (parentStatePath, currentStatePath, title) => {
    const newPath = { screenLabel: title };

    // if the title was not provided then build it from the current state Path
    if (title === undefined || title === '') {
      newPath.screenLabel = startCase(currentStatePath);
    }
    if (parentStatePath === undefined) {
      newPath.statePath = currentStatePath;
    } else if (parentStatePath.includes('/')) {
      newPath.statePath = parentStatePath.split('/')[1];
    } else if (currentStatePath === undefined) {
      newPath.statePath = parentStatePath;
    } else if (currentStatePath.includes('/')) {
      newPath.statePath = currentStatePath.split('/')[1];
    } else {
      newPath.statePath = `${parentStatePath}.${currentStatePath}`;
    }
    return newPath;
  };

  /**
   * traverses through the schema for the current tab and builds out the schema mapping so the state paths can resolve to a screen label.
   */
  const buildMappingsFromSchema = schemaPage => {
    if (schemaPage === undefined) return [];
    const mappings = [];

    forEach(schemaPage.tabs, t => {
      forEach(t.sections, s => {
        const secPath = createStatePath(undefined, s.statePath, s.title);
        const enumerateElements = (sp, elmnts) => {
          forEach(elmnts, el => {
            const elemPath = createStatePath(
              sp.statePath,
              el.statePath,
              el.label
            );
            // get the options.
            elemPath.context = el;
            mappings.push(elemPath);
            enumerateElements(elemPath, el.elements); // Recursively go for the child elements.
          });
        };
        enumerateElements(secPath, s.elements);
      });
    });
    return mappings;
  };

  /**
   * Takes a look at path values and determines if there is an instance of country in it.  If
   * there is a match then it will leverage the country options property to resolve the ISO country code
   * to its corresponding label.
   *
   * @param path - The path property to resolve
   * @param val - The value
   * @returns {*}
   */
  const performCountryLookup = (path, val) => {
    const countries = tacticalData['document-data']['country-options'];
    if (/country/gi.test(path)) {
      const cntry = find(countries, c => c.value === val);
      return cntry ? cntry.label : val;
    }
    return val;
  };

  const pathMatch = subcaseType || 'review';
  const schemaPage = find(schema.pages, itm => itm.path === pathMatch);
  const mapping = buildMappingsFromSchema(schemaPage);

  const insertSection = ({ sectionSchema, basePath }, xOffset = 0.5) => {
    let localBasePath = basePath;

    if (sectionSchema && sectionSchema.title) {
      insertTitle(startCase(sectionSchema.title));
      if (sectionSchema.title === 'Submissions') {
        localBasePath = 'submissions';
      }
    }

    const context = get(trilogyCase, localBasePath);

    const getElementSchema = ({ statePath }) => {
      const hit = find(
        mapping,
        item => item.statePath === replace(statePath, /\[\d+\]/g, '')
      );
      return !isNil(hit) ? hit.context : null;
    };

    // perform a lookup in the mapping file to get the corresponding screen label to the state path.
    const getElementScreenLabel = ({ statePath, propName }) => {
      const schemaElement = getElementSchema({ statePath });
      return !isNil(schemaElement) && !isNil(schemaElement.label)
        ? schemaElement.label
        : startCase(propName);
    };

    const parseElementValue = ({ schemaElement, propValue }) => {
      if (
        !isNil(schemaElement) &&
        !isNil(schemaElement.options) &&
        schemaElement.options.length > 0
      ) {
        const mappedValue = find(
          schemaElement.options,
          opt => opt.value === propValue
        );
        if (!isNil(mappedValue)) return mappedValue.label;
      }
      return propValue;
    };

    const getElementScreenValue = ({ schemaElement, propValue }) => {
      // find the item in the state label mapping.
      if (isNil(schemaElement)) return propValue;
      return parseElementValue({ schemaElement, propValue });
    };

    const renderElementProperties = () => {
      if (typeof context === 'string') {
        insertItem(
          getElementScreenLabel({
            statePath: localBasePath,
            propName: localBasePath.match(/(\.\w+$)/)[1] // provide last part of statePath as fallback label
          }),
          context.toString(),
          xOffset
        );
      } else if (context !== undefined) {
        filter(
          Object.getOwnPropertyNames(context),
          s => context[s] !== null
        ).forEach(propName => {
          if (
            // blacklist Attachments Sent field
            propName === 'attachmentsSent' ||
            // remove duplicate instance of patient
            (propName === 'patient' && !sectionSchema)
          ) {
            return;
          }
          const statePath = `${localBasePath}.${propName}`;
          const propValue = context[propName];
          const schemaElement = getElementSchema({ statePath });
          const outputText = performCountryLookup(
            statePath,
            getElementScreenValue({ schemaElement, statePath, propValue })
          );

          // if the output is not an object then just display the text value.
          if (typeof outputText !== 'object' && !isNil(outputText)) {
            insertItem(
              getElementScreenLabel({
                statePath,
                propName
              }),
              outputText.toString(),
              xOffset
            );
          } else {
            renderElementObject(
              {
                elementName: propName,
                basePath: statePath
              },
              xOffset
            );
          }
        });
      } else {
        // context is blank, do nothing
      }
    };

    // check to see if there might be a referenced element based on the schema section.
    if (isNil(context) && !isNil(get(sectionSchema, 'elements'))) {
      renderReferencedElement({ sectionSchema }, xOffset);
    } else {
      const refElements = getRefElements({ sectionSchema });
      const refObjects = getRefObjects({ sectionSchema, trilogyCase });

      if (
        !isNil(context) &&
        ((!isNil(refElements) && refElements.length > 0) ||
          (!isNil(refObjects) && refObjects.length > 0))
      ) {
        renderReferencedElement({ sectionSchema }, xOffset);
        renderElementProperties();
      } else {
        renderElementProperties();
      }
    }
  };

  /**
   * At times some of the elements on the schema are a reference and point to another part of the document.  There are two
   * cases that are supported in this function.  1. Referenced Properties 2. Referenced Elements.  This function will handle that
   * lookup or return an 'no data found response'
   *
   * @param sectionSchema - The element that corresponds to the section in the schema.
   * @returns {*}
   */
  const renderReferencedElement = ({ sectionSchema }, xOffset) => {
    // call all the unique schema elements that have a reference property in the section schema.  It is
    // possible to have duplicate statePath values, so that is why a lodash uniq is being performed.
    const refElements = getRefElements({ sectionSchema });

    if (!isNil(refElements) && refElements.length > 0) {
      refElements.forEach(refElem => {
        const refProp = refElem.referencedProperties;
        const cleanPath = replace(refProp.value.statePath, /\[\d+\]/, ''); // Strip array brackets to get parent object
        if (!isNil(get(trilogyCase, cleanPath))) {
          insertSection(
            {
              sectionSchema: null,
              basePath: refProp.value.statePath
            },
            xOffset
          );
        }
      });
    }

    // sometimes there might be some elements that are not ref properties but point to another not on the object.
    // do a quick look to see. i.e. '/subcases.adverseEvent.subCaseVersion
    const refObjects = getRefObjects({ sectionSchema });

    if (!isNil(refObjects) && refObjects.length > 0) {
      refObjects.forEach(refObj => {
        if (Array.isArray(refObj.caseObject)) {
          const elementName = last(split(refObj.statePath, '.'));
          renderElementObject(
            {
              elementName,
              basePath: refObj.statePath
            },
            xOffset
          );
        } else {
          insertSection({ basePath: refObj.statePath }, xOffset);
        }
      });
    }
  };

  /**
   * sometimes and element is not a primitive value.  When an element is a array or object type then the rendering is somewhat different.
   * This will render an element object if the type of the element is an array.  It will output as as CollapseSection component.
   *
   * @param elementName: The name of the element to render
   * @param basePath: That path in the case document where this element is located.
   * @returns {*}
   */
  const renderElementObject = ({ elementName, basePath }, xOffset) => {
    const context = get(trilogyCase, basePath);

    // dynamically builds the tile of a element section if it is an array.
    const buildSectionTitle = idx => {
      let title = startCase(elementName);
      if (title === 'Revisions') {
        return '';
      }
      let showIndex = true;

      // a special case where the product name needs to be included in the reporter causality.
      if (
        /subcases\.adverseEvent\.product_section\.aeproducts$/.test(basePath)
      ) {
        const prodSummaries = get(
          trilogyCase,
          `${basePath}[${idx}].product_summary`
        );
        if (prodSummaries && prodSummaries.length > 0) {
          if (prodSummaries[0].local_trade_name) {
            title += ` [${prodSummaries[0].local_trade_name}]`;
          } else if (prodSummaries[0].other_product) {
            title += ` [${prodSummaries[0].other_product}]`;
          }
        }
        showIndex = false;
      }

      // another special case where if a reporter causality is found a look-up for the matching reaction index must be done.
      if (
        /subcases\.adverseEvent\.product_section\.aeproducts\[\d+\]\.reporter_causality/.test(
          basePath
        )
      ) {
        const reactionPath = basePath
          .replace(/product_section/, 'reactions')
          .replace(/aeproducts\[\d+\]/, `reaction[${idx}]`)
          .replace(/reporter_causality/, 'adverse_event');
        const reactionName = get(trilogyCase, reactionPath);
        title += ` [${reactionName}]`;
        showIndex = false;
      }

      if (context.length > 1 && showIndex) {
        title = `${title} ${idx + 1}`;
      }
      return title;
    };

    if (Array.isArray(context)) {
      context.forEach((_elem, idx) => {
        const title = buildSectionTitle(idx);
        if (
          !(title.includes('Revisions') || title.includes('Reconciliation'))
        ) {
          insertItem(title, '', xOffset);
          insertSection({ basePath: `${basePath}[${idx}]` }, xOffset + 0.1);
        }
      });
    } else if (elementName && elementName !== 'trilogyCase') {
      insertItem(
        elementName ? startCase(elementName) : 'Undefined',
        '',
        xOffset
      );
      insertSection({ basePath }, xOffset + 0.1);
    }
  };

  /**
   * check the section schema and returns an array of elements that are references to other properties on the object.
   *
   * @param sectionSchema - The current level of the section schema that will be analyzed for referenced properties.
   * @returns {*}
   */
  const getRefElements = (/* {sectionSchema} */) => null;
  // if (isNil(get(sectionSchema, 'elements'))) return null;
  // return uniq(
  //   filter(
  //     sectionSchema.elements,
  //     el => !isNil(get(el, 'referencedProperties.value.statePath'))
  //   )
  // );
  // };

  /**
   * check the section schema and returns an array of elements that are references to other objects.
   *
   * @param sectionSchema
   * @returns {*}
   */
  const getRefObjects = ({ sectionSchema }) => {
    if (isNil(get(sectionSchema, 'elements'))) return null;
    return filter(
      sectionSchema.elements.map(el => {
        const cleanPath = replace(el.statePath, /\//, ''); // Remove any invalid characters from the path.
        const caseObjectOrValue = get(trilogyCase, cleanPath);
        const caseObject =
          typeof caseObjectOrValue === 'string'
            ? { caseObjectOrValue }
            : caseObjectOrValue;
        return !isNil(caseObject)
          ? { caseObject, statePath: cleanPath }
          : undefined;
      }),
      obj => !isNil(obj)
    );
  };

  // start first page
  insertHeader();
  insertFooter();

  if (subcaseType === 'pq' || subcaseType === 'ae') {
    // version information
    insertTitle('Version Information');
    VERSION_INFO_FIELDS.forEach(field => {
      insertItem(
        field.label,
        get(trilogyCase, `subcases.${subcaseName}.${field.statePath}`)
      );
    });
    insertTitle('Master Case Information');
    insertItem(
      'Initial Description',
      get(trilogyCase, 'summary.narrative.long2')
    );
  }

  if (subcaseType === 'ae') {
    // shared case notes
    insertItem('Shared Case Notes', get(trilogyCase, 'common.notes[0].note'));
  }

  schemaPage.tabs.forEach(tab => {
    // blacklist SHARED INFO tab here
    if (tab.title !== 'SHARED INFO') {
      filter(tab.sections, i => i.title !== '').forEach(sectionSchema => {
        // blacklist "ClonedFrom" element
        const printableElements = sectionSchema.elements.filter(
          elem => elem.id !== 'ClonedFrom'
        );
        const printableSchemaSection = {
          ...sectionSchema,
          elements: printableElements
        };
        insertSection({
          sectionSchema: printableSchemaSection,
          basePath: printableSchemaSection.statePath
        });
      });
    }
  });

  // done
  doc.save(
    `${get(trilogyCase, `subcases.${subcaseName}.id`, trilogyCase.id)}-${get(
      trilogyCase,
      'currentRevision'
    )}.pdf`
  );
};
