import React from 'react';
import PropTypes from 'prop-types';
import ReassignAssigneeModal from 'Common/components/ReassignAssigneeModal';
import { withStyles } from 'Common/components/Form';
import stylesGenerator from './styles';

const ReassignTask = ({
  isEditing,
  task,
  showReassignModal,
  clearModal,
  reassignTask,
  session,
  userList,
  computedStyles
}) => {
  const disableReassignment =
    isEditing || task.status === 'COMPLETED' || task.status === 'CANCELLED';

  const propsForModal = {
    actions: {
      push: () => {},
      emitTempReassign: () => {},
      emitModalContentUpdate: showReassignModal,
      emitModalContentClear: clearModal
    },
    tacticalData: {
      'document-data': {
        'user-list': userList
      }
    }
  };
  const modal = assignee => (
    <ReassignAssigneeModal
      {...propsForModal}
      title="REASSIGN TASK"
      currentAssignee={assignee}
      handleAssignToMe={() => (
        <a
          onClick={() => {
            reassignTask(task, session.sub);
            clearModal();
          }}
          role="button"
          id="tasks.assignToMe"
        >
          Assign to me
        </a>
      )}
      handleAssign={assignTo => reassignTask(task, assignTo)}
    />
  );

  return (
    <a
      className={disableReassignment ? computedStyles.notAllowed : null}
      onClick={() =>
        disableReassignment
          ? null
          : showReassignModal(modal(task.form.base.assignee), true)
      }
      role="button"
    >
      Reassign
    </a>
  );
};

ReassignTask.propTypes = {
  isEditing: PropTypes.bool,
  task: PropTypes.shape({
    form: PropTypes.shape({
      base: PropTypes.shape({
        description: PropTypes.string,
        dueDate: PropTypes.string
      }).isRequired
    }).isRequired
  }).isRequired,
  showReassignModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  reassignTask: PropTypes.func.isRequired,
  session: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
  ).isRequired,
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      sub: PropTypes.string,
      email: PropTypes.string,
      un: PropTypes.string,
      fn: PropTypes.string,
      sn: PropTypes.string
    })
  ).isRequired,
  computedStyles: PropTypes.shape({
    notAllowed: PropTypes.object.isRequired
  }).isRequired
};

ReassignTask.defaultProps = {
  isEditing: false
};

export default withStyles(stylesGenerator)(ReassignTask);
