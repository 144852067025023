/* eslint global-require:0 */
/* globals window console require */
import { isEmpty } from 'lodash';

/**
 * Fetch config depending on build type
 * Development: webpack-dev-server defined in webpack/webpack.config.dev.js
 * Production:  express server defined in tests/server/index.js
 */
fetch('/config.json')
  .then(res => res.json())
  .then(({ config }) => {
    const hostName = window.location.hostname;
    if (!isEmpty(config.affiliateDomainToRegion[hostName])) {
      const {
        region,
        affiliateCode,
        affiliateCountry
      } = config.affiliateDomainToRegion[hostName];
      if (
        !isEmpty(region) &&
        !isEmpty(affiliateCode) &&
        !isEmpty(affiliateCountry) &&
        !isEmpty(config.regionalConfigs) &&
        !isEmpty(config.regionalConfigs[region])
      ) {
        window.trilogyConfig = config.regionalConfigs[region];
        window.affiliateCode = affiliateCode;
        window.affiliateCountry = affiliateCountry;
        window.sessionTimeoutMs = config.sessionTimeoutMs || 30 * 60 * 1000; // 30 minute default if config not set
        return Promise.resolve();
      }
    }
    console.log('Config is invalid or missing.');
    return Promise.reject();
  })
  .then(() => require('./App'))
  .catch(() => require('./NoConfigApp'));
