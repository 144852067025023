import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popover, Position } from '@blueprintjs/core';
import { merge } from 'glamor';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import Notifier from 'Common/components/Notifier';
import { endSession, LOGOUT_PATH } from 'config/auth';
import withStyles from 'Common/components/Form/withStyles';
import { TASKS_BASE_PATH, YOUR_TASKS_PATH } from 'Tasks/constants';
import { OPEN_DUPE_SEARCH, CMS_PROP_TYPES } from 'Common/constants';
import {
  openDuplicateSearch,
  DUPLICATE_SEARCH_WINDOW,
  redirectNewCase
} from 'CreateCase/utils';
import { QUEUE_YOUR_CASES } from 'Queue/constants';
import { PROTO_INDEX as CREATE_CASE_PROTO_INDEX } from 'CreateCase/constants';
import logo from 'public/images/trilogy-logo.png';
import logo2x from 'public/images/trilogy-logo@2x.png';
import logo3x from 'public/images/trilogy-logo@3x.png';
import styleGenerator from './styles';

class Header extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      emitInputBatchUpdate: PropTypes.func.isRequired,
      emitNavTabSelected: PropTypes.func.isRequired
    }).isRequired,
    selectedNavTab: PropTypes.string.isRequired,
    computedStyles: PropTypes.shape({
      authItem: PropTypes.object
    }).isRequired,
    session: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
    ).isRequired,
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired
  };

  static defaultProps = {
    trilogyCase: {},
    location: {}
  };

  handleConfirm = location => location;

  handleLinkClick = ({ link, protoIndex }) => () => {
    const { trilogyCase } = this.props;

    if (isEmpty(link) || !link) return;
    const { actions } = this.props;
    if (protoIndex) {
      actions.emitInputBatchUpdate({});
    }

    // window.location.pathname to stay on the current page .. Also in Nav model below.
    if (link === `${window.location.pathname}?${OPEN_DUPE_SEARCH}`) {
      window[DUPLICATE_SEARCH_WINDOW] = openDuplicateSearch(
        true,
        true,
        trilogyCase
      );
      window[DUPLICATE_SEARCH_WINDOW].redirectNewCase = redirectNewCase;
      window[DUPLICATE_SEARCH_WINDOW].linkCase = this.handleLinkCase;
    }
  };

  // This also in CaseHeader but should be moved to utils.. but Notifier was not working from utils
  handleLinkCase = (parentCaseId, caseIdToLink) => {
    const { actions } = this.props;
    const documentId = parentCaseId;
    if (caseIdToLink) {
      actions.emitLinkCases(parentCaseId, caseIdToLink).then(
        () => {
          actions
            .emitFetchCaseById(documentId)
            .then(this.handleFetchCaseSuccess);
        },
        err => {
          console.log(`Error linking cases: ${err}`);
          Notifier.show({
            message: 'Could not link cases',
            intent: Notifier.DANGER
          });
        }
      );
    }
  };

  handleLogout = () => {
    endSession();
    window.location = LOGOUT_PATH;
  };

  renderPopover(tab) {
    const { actions, computedStyles } = this.props;
    const { selectedNavTab } = this.props;
    const handleClose = () => actions.emitNavTabSelected('none');
    const styleOverrides = merge(
      computedStyles.headerText,
      computedStyles.tabItem
    );
    const tabItems = (
      <div>{tab.items.map(this.renderTabItem(styleOverrides))}</div>
    );

    const linkStyles = `${computedStyles.link} ${
      selectedNavTab === tab.id ? computedStyles.selectedNavTab : ''
    } `;
    const tabLink = isEmpty(tab.link) ? (
      <a onClick={this.handleLinkClick(tab)} role="link" className={linkStyles}>
        <span className={computedStyles.headerText}>{tab.title}</span>
      </a>
    ) : (
      <Link
        to={tab.link}
        onClick={this.handleLinkClick(tab)}
        className={linkStyles}
      >
        <span className={computedStyles.headerText}>{tab.title}</span>
      </Link>
    );

    return !isEmpty(tab.items) ? (
      <Popover
        // isOpen={tab.id === selectedNavTab}
        content={tabItems}
        position={Position.BOTTOM}
        popoverClassName={`pt-minimal ${computedStyles.tabItems}`}
        popoverWillClose={handleClose}
        onClose={handleClose}
        inline
      >
        {tabLink}
      </Popover>
    ) : (
      tabLink
    );
  }

  renderPopoverTab = tab => {
    const { selectedNavTab, actions, computedStyles } = this.props;
    const styleOverrides = merge(
      computedStyles.headerText,
      computedStyles.headerSpacing
    );

    const handleClick = () => {
      if (!isEmpty(tab.items)) actions.emitNavTabSelected(tab.id);
    };
    const isActive = selectedNavTab === tab.id;

    return (
      <span
        data-id={`header-nav-${tab.id}`}
        key={tab.id}
        role="button"
        className={`${styleOverrides} ${
          isActive ? computedStyles.selectedNavTab : ''
        }`}
        onClick={handleClick}
      >
        {this.renderPopover(tab)}
      </span>
    );
  };

  renderTabItem = styles => item => (
    <Link
      key={item.id}
      to={item.link}
      onClick={this.handleLinkClick(item)}
      role="link"
      className={`pt-menu-item pt-popover-dismiss ${styles}`}
      data-id={`header-nav-item-${item.id}`}
    >
      {item.title}
    </Link>
  );

  render() {
    const { computedStyles, session } = this.props;
    const userName = `${session.fn} ${session.sn}`;

    const NAVIGATION_MODEL = {
      tabs: [
        {
          id: 'createCase',
          title: 'Create Case',
          items: [
            {
              id: 'duplicateSearch',
              title: 'Duplicate Search',
              link: `${window.location.pathname}?${OPEN_DUPE_SEARCH}`
            },
            {
              id: 'skipSearch',
              title: 'Skip Search',
              link: '/form',
              protoIndex: CREATE_CASE_PROTO_INDEX
            }
          ]
        },
        {
          id: 'casesToReview',
          title: 'Cases to Review',
          link: '/queue/new',
          items: []
        },
        {
          id: 'takss',
          title: 'Tasks',
          items: [
            { id: 'yourTasks', title: 'Your Tasks', link: YOUR_TASKS_PATH },
            { id: 'allTasks', title: 'All Tasks', link: `/${TASKS_BASE_PATH}` }
          ]
        },
        {
          id: 'queues',
          title: 'Queues',
          items: [
            {
              id: 'yourCases',
              title: 'Your Cases',
              link: `/queue/${QUEUE_YOUR_CASES}`
            },
            { id: 'masterCases', title: 'Master Cases', link: '/queue' },
            { id: 'adverseEvents', title: 'Adverse Events', link: '/queue/ae' },
            {
              id: 'medicalInquiries',
              title: 'Medical Inquiries',
              link: '/queue/mi'
            },
            {
              id: 'productComplaints',
              title: 'Product Complaints',
              link: '/queue/pq'
            }
          ]
        },
        {
          id: 'search',
          title: 'Search Cases',
          link: '/search',
          items: []
        }
      ]
    };
    return (
      <nav className={`pt-navbar ${computedStyles.header}`}>
        <div className="pt-navbar-group pt-align-left">
          <img
            alt="Logo"
            src={logo}
            srcSet={`${logo2x} 2x, ${logo3x} 3x`}
            className={computedStyles.logo}
          />
          {NAVIGATION_MODEL.tabs.map(this.renderPopoverTab)}
        </div>
        <div className="pt-navbar-group pt-align-right">
          <div
            className={computedStyles.headerText}
            data-id={'header-nav-username'}
            title={`Roles: ${session.rs || [].join(', ')}`}
          >
            {userName}
          </div>
          <a
            onClick={this.handleLogout}
            role="link"
            className={computedStyles.logout}
          >
            <span className={computedStyles.logoutText}>Log Out</span>
          </a>
        </div>
      </nav>
    );
  }
}

export default withStyles(styleGenerator)(Header);
