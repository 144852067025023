import {
  get,
  set,
  unset,
  isNil,
  omit,
  isEmpty,
  trim,
  endsWith,
  isArray,
  reduce,
  each,
  isObject
} from 'lodash';
import moment from 'moment';
import { getOrElse } from 'Common/utils';
import {
  SUB_CASE_TYPES_MAP,
  CC_SUB_CASE_TYPES_MAP,
  CC_SUB_CASE_DEFAULTS
} from 'Common/constants';
import {
  CASE_FE_ONLY_FIELDS,
  ATTACHMENTS_FE_ONLY_FIELDS
} from 'CreateCase/constants';
import { shouldRemoveTypeFields } from './caseResponse';

const setInitialDescription = trilogyCase => {
  const newDescription = get(trilogyCase, 'summary.newDescription');
  if (!isNil(newDescription)) {
    set(trilogyCase, 'summary.narrative.long2', newDescription);
  }
  return trilogyCase;
};

// '--not_configured--' for unit testing purposes
const setAffiliateCode = trilogyCase =>
  set(trilogyCase, 'affiliate', window.affiliateCode || '--not_configured--');

const setLatestDraft = trilogyCase =>
  set(trilogyCase, 'latestDraft', moment().toISOString());

const omitExtraneousProperties = trilogyCase =>
  omit(trilogyCase, CASE_FE_ONLY_FIELDS);

const omitAttachmentsExtraneousProperties = attachments =>
  omit(attachments, ATTACHMENTS_FE_ONLY_FIELDS);

// tags get stored from multiselect as comma separated string. This function converts that to a proper array
const transformTagsToProperArray = document => {
  const hasTags = !isNil(document.tags);
  if (hasTags) {
    return {
      ...document,
      tags: document.tags.split(',')
    };
  }
  return document;
};

const setAttachments = trilogyCase => {
  if (!isEmpty(get(trilogyCase, 'documents.attachments'))) {
    const documentsToSave = trilogyCase.documents.attachments.map(
      omitAttachmentsExtraneousProperties
    );

    set(
      trilogyCase,
      'documents.attachments',
      documentsToSave.map(transformTagsToProperArray)
    );
  }
};

/**
 * Generates subcase initial state if checkbox is ticked, but subcase object does not exist
 * @param trilogyCase the case
 */
const setSubcaseInitialState = (trilogyCase, isFromCreateCaseBtn) => {
  const caseArchived = getOrElse(trilogyCase, 'archived', false);
  if (!caseArchived && isFromCreateCaseBtn) {
    each(['ae', 'mi', 'pq'], subcaseKey => {
      const subcaseChecked = getOrElse(
        trilogyCase,
        `summary.narrative.categories.${SUB_CASE_TYPES_MAP[subcaseKey]}`,
        false
      );
      const subcaseExists = getOrElse(
        trilogyCase,
        `subcases.${CC_SUB_CASE_TYPES_MAP[subcaseKey]}`
      );

      if (subcaseChecked && !subcaseExists) {
        set(
          trilogyCase,
          `subcases.${CC_SUB_CASE_TYPES_MAP[subcaseKey]}`,
          CC_SUB_CASE_DEFAULTS[subcaseKey]
        );
      }
    });
  }
};

/**
 * Generates clean subcase data
 * @param supreDocument     Document
 */
const processSubcaseData = supreDocument =>
  reduce(
    CC_SUB_CASE_TYPES_MAP,
    (subcases, subcase) => {
      const filteredSubcase = {
        ...omitExtraneousProperties(get(supreDocument, `subcases[${subcase}]`))
      };
      if (isEmpty(filteredSubcase)) {
        return {
          ...subcases
        };
      }
      return {
        ...subcases,
        [subcase]: filteredSubcase
      };
    },
    {}
  );

const setSubcases = trilogyCase => {
  if (!isNil(get(trilogyCase, 'subcases'))) {
    set(trilogyCase, 'subcases', {
      ...processSubcaseData(trilogyCase)
    });
  }
};

const setAdverseEventSourceEndpoints = (trilogyCase, tacticalData) => {
  const rootPath = 'subcases.adverseEvent.aerinfo.safety.source';
  const sourceArr = get(trilogyCase, rootPath, []);
  sourceArr &&
    sourceArr.forEach((source, idx) => {
      // remove current props if they exist
      unset(trilogyCase, `${rootPath}[${idx}].co_marketer_api_key`);
      unset(trilogyCase, `${rootPath}[${idx}].health_authority_api_key`);
      if (get(source, 'sources') === 'Co-Marketer' || 'Health authority') {
        let lookupValue, optionsList, propName;
        if (get(source, 'sources') === 'Co-Marketer') {
          lookupValue = get(source, 'co_licensing_partner');
          optionsList = 'co-licensing-partner-apikey-options';
          propName = 'co_marketer_api_key';
        } else {
          lookupValue = get(source, 'health_authority_name');
          optionsList = 'health-authority-apikey-options';
          propName = 'health_authority_api_key';
        }
        if (lookupValue) {
          const apiKey = get(
            tacticalData,
            `document-data.co-licensing-partner-options.${optionsList}`,
            []
          ).find(option => option.label === lookupValue);
          apiKey &&
            set(trilogyCase, `${rootPath}[${idx}].${propName}`, apiKey.value);
        }
      }
    });
};

/**
 * Returns whether the given `value` is considered valid
 * @param  {Any} value - The value being validated
 * @return {Boolean}   - Whether the value is a valid one
 */
const isValidFormValue = value =>
  isObject(value) ? !isEmpty(value) : !isNil(value) && value !== '';

/**
 * Sanitizes case data before sending it along to GQL mutation request.
 * Removes all empty objects, arrays, and values that are `nil` (via lodash `isNil`)
 *
 * TODO: Consolidate with customizer from submitTaskMutation.js?
 *
 * @param  {Object} form - A root case object
 * @param  {Object} key  - label, if available, of a nested object
 * @return {Object}      - The sanitized case object
 */
const sanitizeCaseForSubmission = (form, label) => {
  if (!isObject(form)) return isValidFormValue(form) ? form : null;
  if (isArray(form)) {
    // reporter causality is mapped from reactions so we do not want to remove null values from these fields
    // if there are more than one reaction
    if (label === 'reporter_causality' && form.length > 1) {
      for (let i = 0; i < form.length; i += 1) {
        if (
          get(form[i], 'reporter_causality') ||
          get(form[i], 'alternative_etiology')
        ) {
          return form;
        }
      }
    }
    return form.map(sanitizeCaseForSubmission).filter(isValidFormValue);
  }

  return Object.keys(form).reduce((newForm, key) => {
    const value = sanitizeCaseForSubmission(form[key], key);
    const invalidKey = key.charAt(0) === '/' || shouldRemoveTypeFields(key);
    if (invalidKey || !isValidFormValue(value)) return newForm;
    // This check is separate from the other `ValidFormValue` checks because it has
    // this warning associated with it. TODO: find out if we still need
    if (endsWith(trim(key), '.')) {
      console.warn(
        `Key: ${key} and value: ${value} should not exist in the document.`
      );
      return newForm;
    }

    return { ...newForm, [key]: value };
  }, null);
};

const caseRequestFormatter = (
  trilogyCase = {},
  isFromCreateCaseBtn = false,
  tacticalData
) => {
  // Setters
  setInitialDescription(trilogyCase);
  setAffiliateCode(trilogyCase);
  setLatestDraft(trilogyCase);
  setSubcases(trilogyCase);
  setAttachments(trilogyCase);
  setAdverseEventSourceEndpoints(trilogyCase, tacticalData);
  const caseToSave = sanitizeCaseForSubmission(
    omitExtraneousProperties(trilogyCase)
  );
  // Run after reducer. Subcases may need empty object if subcase checkbox is clicked
  setSubcaseInitialState(caseToSave, isFromCreateCaseBtn);
  return {
    case: caseToSave
  };
};

export default caseRequestFormatter;
