import { generateCSS } from 'Common/components/Form/styles';

const styleGenerator = theme => ({
  header: generateCSS({
    top: '0',
    height: '50px',
    boxShadow: 'none',
    padding: '0 35px',
    position: 'relative',
    zIndex: 20,
    backgroundColor: theme.colors.header,
    '> .pt-popover-target': {
      '> .pt-navbar-group': {
        padding: '13px 0',
        height: 'auto',
        '> .pt-button': {
          minHeight: 'auto' // Keeps avatar aligned vertically
        }
      }
    }
  }),
  logo: generateCSS({
    width: '110px',
    height: '22px',
    objectFit: 'contain',
    marginRight: '40px',
    '@media(max-width:768px)': {
      marginRight: '30px'
    }
  })
});

export default styleGenerator;
