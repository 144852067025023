import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { isEmpty, get, set, cloneDeep } from 'lodash';
import withStyles from 'Common/components/Form/withStyles';
import { NOT_SPECIFIED, FRAGMENT_NAME_CASE } from 'Common/constants';
import TableRow from 'Common/components/TableRow';
import DueDateDisplay from 'Common/components/DueDateDisplay';
import CaseIdLink from 'Tasks/components/CaseIdLink';
import CompleteTask from 'Tasks/components/CompleteTask';
import ReopenTask from 'Tasks/components/ReopenTask';
import ReassignTask from 'Tasks/components/ReassignTask';
import NavigateAway from 'Tasks/components/NavigateAway';
import { store } from 'config';
import submitCaseQuery from 'api/graphql/submitCaseQuery';
import {
  TYPE_MAP,
  NEW_TASK_ID,
  ATTEMPTS_WITHIN_HEIGHT_LIMIT,
  SERIOUSNESS,
  REACTIONS,
  SERIOUSNESS_OPTIONS,
  ASSIGNEE
} from 'Tasks/constants';
import { getUserName, getOrElse, isDummyUserOrGroup } from 'Common/utils';

import { Checkbox } from 'Common/components/Form';
import TaskExpandedView from './TaskExpandedView';
import stylesGenerator from './styles';

import CancelModal from '../../CancelModal';

class TaskTableRow extends Component {
  static propTypes = {
    value: PropTypes.shape({
      status: PropTypes.string,
      form: PropTypes.shape({
        base: PropTypes.shape({
          description: PropTypes.string,
          dueDate: PropTypes.string
        }).isRequired,
        additional: PropTypes.shape({
          type: PropTypes.isRequired,
          attempts: PropTypes.shape({
            attempt: PropTypes.arrayOf(
              PropTypes.shape({
                queryDueDate: PropTypes.string
              })
            )
          })
        })
      }).isRequired
    }).isRequired,
    actions: PropTypes.shape({
      onValidateComponent: PropTypes.func,
      setState: PropTypes.func.isRequired
    }).isRequired,
    isExpanded: PropTypes.bool,
    isEditing: PropTypes.bool,
    $id: PropTypes.string.isRequired,
    hasUnsavedFormData: PropTypes.bool,
    data: PropTypes.shape({
      userList: PropTypes.arrayOf(
        PropTypes.shape({
          sub: PropTypes.string,
          email: PropTypes.string,
          un: PropTypes.string,
          fn: PropTypes.string,
          sn: PropTypes.string
        })
      ).isRequired,
      session: PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.array
        ])
      ).isRequired,
      userDesiredColumns: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    children: PropTypes.node.isRequired,
    computedStyles: PropTypes.shape({
      descriptionColumn: PropTypes.object.isRequired,
      field: PropTypes.object.isRequired,
      type: PropTypes.object.isRequired,
      product: PropTypes.object.isRequired,
      caseId: PropTypes.object.isRequired,
      buttons: PropTypes.object.isRequired,
      saveButton: PropTypes.object.isRequired,
      cancelButton: PropTypes.object.isRequired,
      auditHistory: PropTypes.object.isRequired,
      dueDatesRow: PropTypes.object.isRequired
    }).isRequired,
    triggers: PropTypes.shape({
      completeTask: PropTypes.func.isRequired,
      reopenTask: PropTypes.func.isRequired,
      showModal: PropTypes.func.isRequired,
      showReassignModal: PropTypes.func.isRequired,
      clearModal: PropTypes.func.isRequired,
      reassignTask: PropTypes.func.isRequired,
      onTaskSave: PropTypes.func.isRequired,
      onTaskCancel: PropTypes.func.isRequired,
      onModalContentUpdate: PropTypes.func.isRequired,
      onModalContentClear: PropTypes.func.isRequired,
      onTaskEditCancel: PropTypes.func.isRequired,
      editTask: PropTypes.func.isRequired,
      stopEditingTask: PropTypes.func.isRequired,
      handleSelectCaseOrTask: PropTypes.func.isRequired
    }).isRequired,
    selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    isExpanded: false,
    isEditing: false,
    hasUnsavedFormData: false,
    selectedItemsToAssignOrArchive: []
  };

  state = { model: null };

  async componentDidMount() {
    const { schemaReducer: { fragments } } = store.getState();
    const { value, data: { trilogyCase } } = this.props;
    const latestTrilogyCase =
      Object.keys(trilogyCase).length === 0
        ? await submitCaseQuery(
            null,
            value.form.base.caseId,
            fragments[FRAGMENT_NAME_CASE],
            false, // do not format subcase versions
            true // suppress toaster notifications
          )
        : trilogyCase;
    this.updateIDs(value, latestTrilogyCase);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value.status === 'CANCELLED') {
      this.setState({ hasUnsavedFormData: false });
    }
  }

  updateIDs = (value, trilogyCase) => {
    const { triggers } = this.props;
    if (
      value.form.base.subcaseType === 'AE' &&
      !!get(trilogyCase, 'subcases.adverseEvent.subcaseVersions[0]')
    ) {
      const { subcaseVersions } = trilogyCase.subcases.adverseEvent;
      const initialAERIndex = subcaseVersions.findIndex(
        val => val.aerNumber != null
      );
      const initialArgusIndex = subcaseVersions.findIndex(
        val => val.argusNumber != null
      );
      if (
        initialAERIndex !== -1 &&
        get(
          trilogyCase,
          `subcases.adverseEvent.subcaseVersions[${initialAERIndex}].aerNumber`
        ) !== get(value, 'form.base.aerNumber')
      ) {
        set(
          value,
          'form.base.aerNumber',
          trilogyCase.subcases.adverseEvent.subcaseVersions[initialAERIndex]
            .aerNumber
        );
        setTimeout(() => {
          triggers.onTaskSave({ value });
        }, 500);
      }
      if (
        initialArgusIndex !== -1 &&
        get(
          trilogyCase,
          `subcases.adverseEvent.subcaseVersions[${initialArgusIndex}].argusNumber`
        ) !== get(value, 'form.base.argusNumber')
      ) {
        set(
          value,
          'form.base.argusNumber',
          trilogyCase.subcases.adverseEvent.subcaseVersions[initialArgusIndex]
            .argusNumber
        );
        setTimeout(() => {
          triggers.onTaskSave({ value });
        }, 500);
      }
    }
  };

  handleExpandToggle = e => {
    e.preventDefault();
    e.stopPropagation();

    const { isExpanded, isEditing } = this.props;
    if (isExpanded && isEditing) {
      const handleDismiss = () => {
        this.props.actions.setState({
          isEditing: this.props.isExpanded ? false : this.props.isEditing,
          isExpanded: !this.props.isExpanded,
          value: this.state.model
        });
        this.syncEditingState(false);
        this.props.triggers.onModalContentClear();
      };
      this.props.triggers.onModalContentUpdate(
        <NavigateAway
          handleConfirm={this.props.triggers.onModalContentClear}
          handleDismiss={handleDismiss}
        />
      );
    } else {
      this.props.actions.setState({
        isEditing: this.props.isExpanded ? false : this.props.isEditing,
        isExpanded: !this.props.isExpanded
      });
    }
  };

  handleEditToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    const cancelled = get(this.props.value, 'status') === 'CANCELLED';
    if (cancelled) return;
    this.syncEditingState(!this.props.isEditing);
    // if going to edit (isEditing is false - will be set to true in a few lines)
    // cloneDeep the model  (because react uses Object.freeze which only freezes shallow properties
    // so we have in local state the complete original task
    // if they cancel editing we can revert any changes they made and restore the original task pre edits
    this.setState({ model: cloneDeep(this.props.value) }, () => {
      this.props.actions.setState({
        isEditing: !this.props.isEditing,
        hasUnsavedFormData: !this.props.isEditing
      });
    });
  };

  handleEditCancel = () => {
    const cancelled = get(this.props.value, 'status') === 'CANCELLED';
    // only show the navigation confirmation if a task is being edited
    if (cancelled || !this.props.isEditing) return;

    const handleDismiss = () => {
      this.props.actions.setState({
        isEditing: false,
        value: this.state.model
      });
      this.syncEditingState(false);
      this.props.triggers.onModalContentClear();
    };

    this.props.triggers.onModalContentUpdate(
      <NavigateAway
        handleConfirm={this.props.triggers.onModalContentClear}
        handleDismiss={handleDismiss}
      />
    );
  };

  shouldAnimate = () => {
    const attempts = getOrElse(
      this.props.value,
      'form.additional.attempts.attempt',
      []
    );

    return attempts.length <= ATTEMPTS_WITHIN_HEIGHT_LIMIT;
  };

  handleSave = () => {
    const { value, triggers } = this.props;

    const handleValidationsPass = () =>
      triggers.onTaskSave({ value }).then(({ id, status }) => {
        this.props.actions.setState({
          isEditing: false,
          isExpanded: true,
          value: { ...value, id, status }
        });
      });

    const handleValidationsFail = () => {};

    this.handleValidation({ ...this.props, isComplete: false }).then(
      handleValidationsPass,
      handleValidationsFail
    );
  };

  handleCancel = () => {
    const handleConfirm = () =>
      this.props.triggers.onTaskCancel(this.props).then(cancelledTask => {
        // Reset task data if task has already been created...
        if (cancelledTask.id !== NEW_TASK_ID)
          this.props.actions.setState({
            value: { ...this.state.model, status: cancelledTask.status },
            hasUnsavedFormData: false
          });
        this.props.triggers.clearModal();
      });

    this.props.triggers.onModalContentUpdate(
      <CancelModal
        handleConfirm={handleConfirm}
        handleDismiss={this.props.triggers.onModalContentClear}
      />
    );
  };

  handleComplete = () => {
    const { value, triggers, data } = this.props;
    const currentUserId = data.session.sub;
    const handleValidationsPass = () => {
      this.props.actions.setState({ isComplete: false });
      // Get the  Current Task Assignee
      const currentAssignee = get(value, ASSIGNEE);
      // If the Task is unassigned or dummy user is assigned to task
      // complete the task with current logged user.
      if (!currentAssignee || isDummyUserOrGroup(currentAssignee)) {
        set(value, ASSIGNEE, currentUserId);
      }
      // Complete the Task .
      triggers.completeTask(value).then(completedTask => {
        this.props.actions.setState({
          value: { ...value, status: completedTask.status }
        });
      });
    };

    const handleValidationsFail = ({ payload }) =>
      this.props.actions.setState({
        ...payload.schema,
        isEditing: true,
        isExpanded: true,
        hasUnsavedFormData: true
      });

    this.handleValidation({ ...this.props, isComplete: true }).then(
      handleValidationsPass,
      handleValidationsFail
    );
  };

  handleReopen = () => {
    const { value, triggers } = this.props;
    triggers.reopenTask(value).then(reopenedTask => {
      this.props.actions.setState({
        value: { ...value, status: reopenedTask.status }
      });
    });
  };

  handleValidation = transientState =>
    this.props.actions.onValidateComponent(transientState);

  syncEditingState = isEditing =>
    isEditing
      ? this.props.triggers.editTask()
      : this.props.triggers.stopEditingTask();

  renderTaskExpandedView = () => (
    <TaskExpandedView
      id={this.props.$id}
      isEditing={this.props.isEditing}
      hasUnsavedFormData={this.props.hasUnsavedFormData}
      onEditToggleClick={this.handleEditToggle}
      onEditCancelClick={this.handleEditCancel}
      task={this.props.value}
      userList={this.props.data.userList}
      onTaskSave={this.props.triggers.onTaskSave}
      showModal={this.props.triggers.showModal}
      clearModal={this.props.triggers.clearModal}
    >
      {this.props.children}
      {this.props.isEditing ? this.renderSubmission() : null}
    </TaskExpandedView>
  );

  renderSubmission = () => (
    <section className={this.props.computedStyles.buttons}>
      <button
        id={`${this.props.$id}.cancelTask`}
        className={this.props.computedStyles.cancelButton}
        onClick={this.handleCancel}
      >
        Cancel Task
      </button>
      <button
        id={`${this.props.$id}.saveTask`}
        className={this.props.computedStyles.saveButton}
        onClick={this.handleSave}
      >
        Save Task
      </button>
    </section>
  );

  renderId = () => {
    const { value, computedStyles } = this.props;
    const isNewTask = value.id === NEW_TASK_ID;

    return (
      <span className={computedStyles.type}>
        {isNewTask ? 'Not saved' : value.id}
      </span>
    );
  };

  handleViewAuditClick = () => {
    const { triggers, value } = this.props;
    triggers.onShowAuditLog(value);
  };

  renderAuditLink = () => (
    <a role="link" onClick={this.handleViewAuditClick}>
      View Audit History
    </a>
  );

  renderDueDates = () => {
    const { base } = this.props.value.form;
    const dates = base.dueDate ? base.dueDate.split(';') : null;
    if (!dates) return 'Not Specified';
    return dates.map((date, index) => {
      if (index < 5) {
        return (
          <span key={index} className={this.props.computedStyles.dueDatesRow}>
            <DueDateDisplay date={date} key={index} />{' '}
            {index < dates.length - 1 ? ';' : null}
          </span>
        );
      }
      return null;
    });
  };

  renderCommunicationMethod = () => {
    const { attempt } = this.props.value.form.additional.attempts;
    const { type } = this.props.value.form.additional;
    const validMethods = attempt.map((value, index) => {
      if (
        get(value, 'method') &&
        ((type !== 'PQFOLLOWUP' && !value.queryDate) ||
          (type === 'PQFOLLOWUP' && !value.followUp.date))
      ) {
        return (
          <span key={index} className={this.props.computedStyles.dueDatesRow}>
            {value.method}
            {index < attempt.length - 1 ? ';' : null}
          </span>
        );
      }
      return null;
    });
    if (validMethods.every(val => val === null)) {
      return NOT_SPECIFIED;
    }
    return validMethods;
  };

  render() {
    const {
      computedStyles,
      value,
      data,
      isExpanded,
      isEditing,
      triggers,
      $id
    } = this.props;
    if (isEmpty(value)) return null;
    const caseIdToAssign = value.id;
    return (
      <TableRow
        id={$id}
        expansionLocked={value.id === NEW_TASK_ID}
        onExpandToggleClick={this.handleExpandToggle}
        expandedView={this.renderTaskExpandedView(value)}
        isExpanded={isExpanded}
        animate={this.shouldAnimate()}
      >
        <td className={computedStyles.selectionBox}>
          <Checkbox
            onChange={evt =>
              triggers.handleSelectCaseOrTask(caseIdToAssign, evt)
            }
            value={this.props.selectedItemsToAssignOrArchive.includes(
              caseIdToAssign
            )}
          />
        </td>
        {(data.userDesiredColumns || []).map(column => {
          console.log(column);
          const followUpType = getOrElse(value, column.sortPath, NOT_SPECIFIED);

          const seriousness = SERIOUSNESS_OPTIONS[get(value, SERIOUSNESS)];

          const relations = get(value, REACTIONS);

          const numberofAttempts = getOrElse(
            value,
            column.sortPath,
            NOT_SPECIFIED
          );
          const aerNumber = getOrElse(value, column.sortPath, NOT_SPECIFIED);
          const argusNumber = getOrElse(value, column.sortPath, NOT_SPECIFIED);
          const globalId = getOrElse(value, column.sortPath, NOT_SPECIFIED);

          switch (column.label) {
            case 'Description':
              return (
                <td
                  key={column.order}
                  className={computedStyles.descriptionColumn}
                >
                  <span
                    className={computedStyles.field}
                    data-id={`${this.props.$id}.header.description`}
                  >
                    {value.form.base.description}
                  </span>
                  <span
                    className={computedStyles.type}
                    data-id={`${this.props.$id}.header.type`}
                  >
                    {TYPE_MAP[value.form.additional.type]}
                  </span>
                  <span data-id={`${this.props.$id}.header.taskId`}>
                    {this.renderId()}
                  </span>
                </td>
              );

            case 'Case':
              return (
                <td key={column.order} className={computedStyles.caseColumn}>
                  <span
                    className={computedStyles.caseId}
                    data-id={`${this.props.$id}.header.caseId`}
                  >
                    <CaseIdLink taskBase={value.form.base} />
                  </span>
                  <span className={computedStyles.product}>
                    {value.display && value.display.product}
                  </span>
                </td>
              );

            case 'Due Date':
              return (
                <td key={column.order}>
                  <span
                    className={computedStyles.dueDatesRow}
                    data-id={`${this.props.$id}.header.dueDate`}
                  >
                    {this.renderDueDates()}
                  </span>
                </td>
              );

            case 'Assignee':
              return (
                <td key={column.order}>
                  <span
                    className={computedStyles.field}
                    data-id={`${this.props.$id}.header.assignee`}
                  >
                    {getUserName(data.userList, value.form.base.assignee) ||
                      'Unassigned'}
                  </span>
                  <span
                    className={computedStyles.field}
                    data-id={`${this.props.$id}.header.reassign`}
                  >
                    <ReassignTask
                      isEditing={isEditing}
                      task={value}
                      showReassignModal={triggers.showReassignModal}
                      clearModal={triggers.clearModal}
                      reassignTask={triggers.reassignTask}
                      session={data.session}
                      userList={data.userList}
                    />
                  </span>
                </td>
              );

            case 'Actions':
              return (
                <td key={column.order}>
                  <span
                    className={computedStyles.field}
                    data-id={`${this.props.$id}.header.completeTask`}
                  >
                    <CompleteTask
                      isEditing={isEditing}
                      emitTaskComplete={this.handleComplete}
                      task={value}
                    />
                  </span>
                  <span
                    className={computedStyles.field}
                    data-id={`${this.props.$id}.header.reopenTask`}
                  >
                    <ReopenTask
                      isEditing={isEditing}
                      task={value}
                      emitTaskReopen={this.handleReopen}
                    />
                  </span>
                  <span
                    className={computedStyles.field}
                    data-id={`${this.props.$id}.header.viewAuditHistory`}
                  >
                    {this.renderAuditLink()}
                  </span>
                </td>
              );
            case 'Follow Up Type':
              return <td key={column.order}>{followUpType}</td>;

            case 'Number of attempt':
              return <td key={column.order}>{numberofAttempts}</td>;
            case 'Seriousness':
              return <td key={column.order}>{seriousness}</td>;

            case 'Reactions':
              return <td key={column.order}>{relations}</td>;

            case 'AER Number':
              return <td key={column.order}>{aerNumber}</td>;
            case 'Argus Number':
              return <td key={column.order}>{argusNumber}</td>;
            case 'Global ID':
              return <td key={column.order}>{globalId}</td>;
            case 'Communication Method':
              return (
                <td key={column.order}>{this.renderCommunicationMethod()}</td>
              );

            default:
              return <td key={column.order}>Not Specified</td>;
          }
        })}
      </TableRow>
    );
  }
}

export default compose(withStyles(stylesGenerator))(TaskTableRow);
