import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get, capitalize, isNil, isString, find } from 'lodash';

import TableRow from 'Common/components/TableRow';
import { Checkbox, abbvieDateFormat } from 'Common/components/Form';
import {
  CMS_PROP_TYPES,
  STATUSES_MAP,
  NOT_SPECIFIED
  // CC_SUB_CASE_TYPES_MAP
} from 'Common/constants';
import { getOrElse, getUserName } from 'Common/utils';
import {
  PQ_STATE_PATHS,
  AE_STATE_PATHS,
  CUSTOMER_RESPONSE_REQUIRED_OPTIONS,
  REPLACEMENT_REQUIRED_OPTIONS,
  SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS,
  MASTER_STATE_PATHS
} from 'Queue/constants';
import { getTasksCount } from 'Queue/utils';
import DescriptionLink from './DescriptionLink';

const hasStringData = input =>
  !isNil(input) && isString(input) && input.length > 0;

const ProductComplaintsRow = ({
  computedStyles,
  row,
  caseLink,
  tasksLink,
  tasksCounts,
  processNonMomentDate,
  tacticalData,
  userDesiredColumns,
  selectedItemsToAssignOrArchive,
  handleSelectCaseOrTask
}) => {
  const users = get(tacticalData, 'document-data.user-list', []);
  const subcaseId = getOrElse(row, PQ_STATE_PATHS.ID);
  const isCombined = !!getOrElse(row, AE_STATE_PATHS.ID);
  const tasksCount = getTasksCount(tasksCounts, subcaseId);
  const maybeAssignee = getUserName(
    users,
    getOrElse(row, PQ_STATE_PATHS.ASSIGNEE)
  );

  const methodOfReceiptOptions = get(
    tacticalData,
    'document-data.mastercase-options.method_of_receipt',
    []
  );
  const methodOfReceiptMatch = find(
    methodOfReceiptOptions,
    ({ value }) =>
      value === getOrElse(row, MASTER_STATE_PATHS.METHOD_OF_RECEIPT)
  );

  const methodOfReceipt = get(methodOfReceiptMatch, 'label', NOT_SPECIFIED);

  const masterCaseProductOptions = get(
    tacticalData,
    'document-data.mastercase-product-options'
  );

  const masterCaseProductMatch = find(
    masterCaseProductOptions,
    ({ value }) => value === getOrElse(row, PQ_STATE_PATHS.PRODUCT)
  );

  const productName = get(masterCaseProductMatch, 'label', NOT_SPECIFIED);

  // let productName = getOrElse(row, PQ_STATE_PATHS.PRODUCT);
  // productName = productName || NOT_SPECIFIED;

  let affililateSampleReceivedDate = abbvieDateFormat(
    getOrElse(row, PQ_STATE_PATHS.AFFILIATE_RETURN_SAMPLE_RECEIVED_DATE)
  );

  affililateSampleReceivedDate = hasStringData(affililateSampleReceivedDate)
    ? affililateSampleReceivedDate
    : NOT_SPECIFIED;

  let batchLotNumber = getOrElse(row, PQ_STATE_PATHS.BATCH_LOT_NUMBER);
  batchLotNumber = batchLotNumber || NOT_SPECIFIED;

  // let customerResponceRequired = getOrElse(
  //   row,
  //   PQ_STATE_PATHS.CUSTOMER_RESPONSE_REQUIRED
  // );
  // customerResponceRequired = CUSTOMER_RESPONSE_REQUIRED_OPTIONS.find(opt => opt.value === customerResponceRequired);

  const customerResponceMatch = find(
    CUSTOMER_RESPONSE_REQUIRED_OPTIONS.options,
    ({ value }) =>
      value === getOrElse(row, PQ_STATE_PATHS.CUSTOMER_RESPONSE_REQUIRED)
  );

  const customerResponceRequired = get(
    customerResponceMatch,
    'label',
    NOT_SPECIFIED
  );
  // customerResponceRequired = customerResponceRequired || NOT_SPECIFIED;

  const countryOfReporterLabel =
    get(row, PQ_STATE_PATHS.COUNTRY_OF_REPORTER) || NOT_SPECIFIED;

  let sampleAvailableforReturn = getOrElse(
    row,
    PQ_STATE_PATHS.SAMPLE_AVAILABLE_FORRETURN
  );
  sampleAvailableforReturn =
    capitalize(sampleAvailableforReturn) || NOT_SPECIFIED;

  let replacementReferenceNumber = getOrElse(
    row,
    PQ_STATE_PATHS.REPLACEMENT_REFERENCE_NUMBER
  );
  replacementReferenceNumber = replacementReferenceNumber || NOT_SPECIFIED;

  let listNumber = getOrElse(row, PQ_STATE_PATHS.LIST_NUMBER);
  listNumber = listNumber || NOT_SPECIFIED;

  let solTRAQsRefNumber = getOrElse(
    row,
    PQ_STATE_PATHS.SOLTRAQS_REFERENCE_NUMBER
  );
  solTRAQsRefNumber = solTRAQsRefNumber || NOT_SPECIFIED;

  // let replacementRequired = getOrElse(row, PQ_STATE_PATHS.REPLACEMENT_REQUIRED);
  // replacementRequired = replacementRequired || NOT_SPECIFIED;

  const replacementRequiredOptions = REPLACEMENT_REQUIRED_OPTIONS.options;
  const replacementRequiredMatch = find(
    replacementRequiredOptions,
    ({ value }) => value === getOrElse(row, PQ_STATE_PATHS.REPLACEMENT_REQUIRED)
  );
  const replacementRequired = get(
    replacementRequiredMatch,
    'label',
    NOT_SPECIFIED
  );
  const sampleRequiredforInvestigationOptions =
    SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS.options;
  const sampleRequiredforInvestigationMatch = find(
    sampleRequiredforInvestigationOptions,
    ({ value }) =>
      value === getOrElse(row, PQ_STATE_PATHS.SAMPLE_REQUIRED_FOR_INVESTIGATION)
  );
  const sampleRequiredforInvestigation = get(
    sampleRequiredforInvestigationMatch,
    'label',
    NOT_SPECIFIED
  );

  let productMarkedName = getOrElse(row, PQ_STATE_PATHS.PRODUCT_MARKET_NAME);
  productMarkedName = productMarkedName || NOT_SPECIFIED;

  let affiliateSampleShipDate = abbvieDateFormat(
    getOrElse(row, PQ_STATE_PATHS.AFFILIATE_SAMPLE_SHIP_DATE)
  );
  affiliateSampleShipDate = hasStringData(affiliateSampleShipDate)
    ? affiliateSampleShipDate
    : NOT_SPECIFIED;

  let hCPRegulatoryAgencyCommunication = getOrElse(
    row,
    PQ_STATE_PATHS.HCP_REGULATORY_AGENCY_COMMUNICATION
  );
  hCPRegulatoryAgencyCommunication =
    capitalize(hCPRegulatoryAgencyCommunication) || NOT_SPECIFIED;

  let sampleTrackingtoCustomers = getOrElse(
    row,
    PQ_STATE_PATHS.SAMPLE_TRACKING_TO_CUSTOMERS
  );
  sampleTrackingtoCustomers = sampleTrackingtoCustomers || NOT_SPECIFIED;

  let submissionDatetoSolTRAQs = abbvieDateFormat(
    getOrElse(row, PQ_STATE_PATHS.SUBMISSION_DATE_TO_SOLTRAQS)
  );
  submissionDatetoSolTRAQs = hasStringData(submissionDatetoSolTRAQs)
    ? submissionDatetoSolTRAQs
    : NOT_SPECIFIED;

  const highImpact = capitalize(get(row, PQ_STATE_PATHS.HIGH_IMPACT, ''));

  return (
    <TableRow id="queue-pq-table-row">
      <td>
        <Checkbox
          onChange={evt => handleSelectCaseOrTask(subcaseId, evt, isCombined)}
          value={selectedItemsToAssignOrArchive.includes(subcaseId)}
        />
      </td>
      {(userDesiredColumns || []).map(column => {
        switch (column.label) {
          case 'Case Information':
            return (
              <td>
                <Link
                  to={caseLink}
                  className={computedStyles.masterCaseId}
                  target="_blank"
                >
                  {subcaseId}
                </Link>
                <DescriptionLink row={row} />
              </td>
            );
          case 'High Impact':
            return <td> {highImpact || NOT_SPECIFIED}</td>;
          case 'Country of Reporter':
            return <td>{countryOfReporterLabel}</td>;
          case 'Product':
            return (
              <td className={computedStyles.productCell}>{productName}</td>
            );
          case 'Abbvie Awareness (PQ)':
            return (
              <td>
                {processNonMomentDate(
                  abbvieDateFormat(
                    getOrElse(row, PQ_STATE_PATHS.AWARENESS_DATE)
                  )
                )}
              </td>
            );
          case 'PQ Owner':
            return <td>{maybeAssignee || 'Unassigned'}</td>;
          case 'Status':
            return (
              <td>{STATUSES_MAP[get(row, PQ_STATE_PATHS.STATUS, 'NEW')]}</td>
            );
          case 'Actions':
            return (
              <td className={computedStyles.actions}>
                <div>
                  <Link to={caseLink} target="_blank">
                    View
                  </Link>
                </div>
                <div>
                  <Link to={tasksLink} target="_blank">
                    View Tasks {tasksCount ? `(${tasksCount})` : null}
                  </Link>
                </div>
              </td>
            );
          case 'Affiliate Return Sample Received Date':
            return <td>{affililateSampleReceivedDate}</td>;
          case 'Batch/Lot Number':
            return <td>{batchLotNumber}</td>;
          case 'Customer Response Required?':
            return (
              <td className={computedStyles.customerResponse}>
                {customerResponceRequired}
              </td>
            );

          case 'Sample Available for Return':
            return <td>{sampleAvailableforReturn.toString()}</td>;
          case 'Replacement Reference Number':
            return <td>{replacementReferenceNumber}</td>;
          case 'List Number':
            return (
              <td className={computedStyles.productsData}>{listNumber}</td>
            );
          case 'Method of Receipt':
            return <td>{methodOfReceipt}</td>;

          case 'SolTRAQs Reference Number':
            return <td>{solTRAQsRefNumber}</td>;
          case 'Replacement Required':
            return <td>{replacementRequired}</td>;
          case 'Sample Required for Investigation':
            return <td>{sampleRequiredforInvestigation}</td>;
          case 'Product (Marketed Name)':
            return (
              <td className={computedStyles.productsData}>
                {productMarkedName}
              </td>
            );
          case 'Affiliate Sample Ship Date':
            return <td>{affiliateSampleShipDate}</td>;
          case 'HCP Regulatory Agency Communication':
            return <td>{hCPRegulatoryAgencyCommunication.toString()}</td>;
          case 'Sample Tracking to Customers':
            return <td>{sampleTrackingtoCustomers}</td>;
          case 'Submission Date to SolTRAQs':
            return <td>{submissionDatetoSolTRAQs}</td>;
          case 'Sample Available':
          default:
            return <td>{NOT_SPECIFIED}</td>;
        }
      })}
    </TableRow>
  );
};

ProductComplaintsRow.propTypes = {
  computedStyles: PropTypes.shape({
    masterCaseId: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    productCell: PropTypes.object.isRequired
  }).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    countryOfPrimaryReporterLabel: PropTypes.string,
    abbvieProduct: PropTypes.string,
    [PQ_STATE_PATHS.AWARENESS_DATE]: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  caseLink: PropTypes.string.isRequired,
  tasksLink: PropTypes.string.isRequired,
  tasksCounts: PropTypes.arrayOf(PropTypes.object),
  processNonMomentDate: PropTypes.func.isRequired,
  tacticalData: CMS_PROP_TYPES.tacticalData,
  userDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
  handleSelectCaseOrTask: PropTypes.func
};

ProductComplaintsRow.defaultProps = {
  tacticalData: {},
  tasksCounts: [],
  selectedItemsToAssignOrArchive: [],
  handleSelectCaseOrTask: () => {}
};

export default ProductComplaintsRow;
