module.exports = [
  {
    $ref: 'DisplayValueToggle',
    label: 'Seriousness',
    component: 'Select',
    placeholder: 'Select seriousness',
    statePath: '/summary.narrative.seriousness',
    layout: {
      width: '100%'
    },
    options: [
      { label: 'Serious', value: 'serious' },
      { label: 'Non Serious', value: 'nonserious' }
    ],
    validations: {
      required: {
        errorMessage: ''
      }
    }
  },
  {
    label: 'AbbVie Awareness Date – Adverse Event',
    component: 'DateInput',
    statePath: '/summary.awareness_date',

    layout: {
      width: '100%'
    },
    validations: {
      required: {
        constraint: true,
        errorMessage: ''
      },
      date: {
        constraint: 'full',
        errorMessage: 'Invalid full date'
      },
      dateRestriction: {
        constraint: 'future',
        errorMessage: 'Date must not occur in the future'
      }
    }
  },
  {
    $ref: 'DisplayValueToggle',
    label: 'Affiliate Awareness Date - Adverse Event',
    component: 'DateInput',
    statePath: '/summary.affiliate_awareness_date',

    layout: {
      width: '100%'
    },

    validations: {
      required: {
        constraint: false,
        errorMessage: ''
      },
      date: {
        constraint: 'full',
        errorMessage: 'Full date required'
      },
      dateRestriction: {
        constraint: 'future',
        errorMessage: 'Date must not occur in the future'
      }
    },

    referencedProperties: {
      'validations.required.constraint': {
        $if: [
          {
            'summary.narrative.categories.adverse_event': {
              $eq: true
            }
          },
          { $set: true }
        ],
        $else: { $set: false }
      }
    }
  },
  {
    id: 'aeSmartButton',
    component: 'SmartButton',
    label: 'Create AE',
    triggers: {
      onClick: ['onCreateDashboardSubcase', 'ae']
    },
    layout: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 20,
      width: '100%'
    },
    styles: {
      backgroundColor: '#F2F2F2',
      color: '#00A9E0',
      border: '2px solid #00A9E0',
      textAlign: 'center',
      width: '100%'
    }
  }
];
