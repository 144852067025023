import {
  get,
  cloneDeep,
  includes,
  isArray,
  isNil,
  isPlainObject,
  reduce
} from 'lodash';
import moment from 'moment';

import {
  GQL_TYPENAME,
  DATE_FORMAT,
  CC_SUB_CASE_TYPES_MAP
} from 'Common/constants';

import { getOrElse } from 'Common/utils';

const shouldCreateMoment = key => {
  // Trilogy generated dates in ISO format; these should not include dates from DateInput field component
  const knownDateFields = [
    'created',
    'createdDate',
    'trilogyLoadDate',
    'archivedDate',
    'reopenedDate',
    'completedDate',
    'submittedDate',
    'versionCreated'
  ];

  return includes(knownDateFields, key);
};

export const shouldRemoveTypeFields = key => key === GQL_TYPENAME;

const filterDocumentKeys = caseObj => {
  if (
    !isNil(caseObj.documents) &&
    !isNil(caseObj.documents.attachments) &&
    caseObj.documents.attachments.length > 0
  ) {
    const attachments = caseObj.documents.attachments.map(
      ({ id, name, type, ext, comment, tags, caseIdOverride }) => ({
        id,
        name,
        type,
        ext,
        comment,
        tags: !isNil(tags) ? tags.join(',') : null,
        caseIdOverride
      })
    );
    return {
      attachments
    };
  }
  return null;
};

const trilogyCaseCustomizer = (acc, value, key) => {
  if (isPlainObject(value)) {
    // Recurse into objects
    acc[key] = reduce(value, trilogyCaseCustomizer, {});
  } else if (isArray(value) && key !== 'tags') {
    acc[key] = value.map(v => reduce(v, trilogyCaseCustomizer, {}));
  } else if (shouldCreateMoment(key)) {
    // Parse BE-generated dates with MomentJs
    if (moment(value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true).isValid()) {
      acc[key] = isNil(value)
        ? value
        : moment.utc(value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    } else {
      acc[key] = isNil(value) ? value : moment.utc(value).format();
    }
  } else if (shouldRemoveTypeFields(key)) {
    // Skip GQL type fields
    return acc;
  } else {
    // Pass-through all other values
    acc[key] = value;
  }
  return acc;
};

const formatSubCaseVersions = (subcaseVersions = []) => {
  if (subcaseVersions.length) {
    return (
      subcaseVersions
        .map(subcase => ({
          ...subcase,
          submittedDateFormatted: subcase.submittedDate
            ? moment.utc(subcase.submittedDate).format(DATE_FORMAT)
            : null
        }))
        // Prevents rows with blank data
        .filter(subcase => subcase.submittedDate)
    );
  }

  return null;
};

const formatSolTRAQsSubmissions = caseObj => {
  const subcaseVersions = getOrElse(
    caseObj,
    `subcases.${CC_SUB_CASE_TYPES_MAP.pq}.subcaseVersions`
  );
  const products = get(
    caseObj,
    'subcases.productQuality.pqproduct.products',
    []
  );

  if (isNil(subcaseVersions)) return null;

  return cloneDeep(subcaseVersions).reduce(
    (submissions, version) =>
      (version.products || []).reduce(
        (acc, product) => [
          ...acc,
          {
            ...product,
            id: version.id,
            solTRAQsCreatedDate: version.submittedDate
              ? moment.utc(version.submittedDate).format(DATE_FORMAT)
              : null,
            productIndex: `Product ${products.findIndex(
              p => get(p, 'trilogyProductId') === product.trilogyProductId
            ) + 1}`
          }
        ],
        submissions
      ),
    []
  );
};

const caseResponseFormatter = (gqlCaseResponse, formatSubcases = true) => {
  const transformed = reduce(gqlCaseResponse, trilogyCaseCustomizer, {});
  transformed.masterCaseId = transformed.id; // alias for backwards compatibility
  transformed.documents = filterDocumentKeys(transformed);
  const submissions = formatSolTRAQsSubmissions(transformed);

  if (submissions) {
    transformed.submissions = submissions;
  }

  const subcaseVersions = get(
    transformed,
    'subcases.adverseEvent.subcaseVersions'
  );

  if (subcaseVersions && formatSubcases) {
    transformed.subcases.adverseEvent.subcaseVersions = formatSubCaseVersions(
      subcaseVersions
    );
  }

  return transformed;
};

export default caseResponseFormatter;
