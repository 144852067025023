/* eslint import/prefer-default-export:0 */
import { AE_STATE_PATHS, PQ_STATE_PATHS } from 'Queue/constants';
import {
  QUEUE_MASTER_CASES,
  QUEUE_CASES_TO_REVIEW,
  QUEUE_ADVERSE_EVENT,
  QUEUE_MEDICAL_INQUIRY,
  QUEUE_PRODUCT_QUALITY,
  QUEUE_YOUR_CASES
} from './routing';

const MASTER_CASE_HEADERS = [
  {
    label: 'Actions',
    isMandatoryColumn: true,
    order: 1
  },
  {
    label: 'Case Information',
    sortPath: 'id',
    isMandatoryColumn: true,
    order: 2
  },
  {
    label: 'Abbvie Awareness (AE)',
    sortPath: 'summary.awareness_date',
    order: 3,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Date',
      entityPathName: 'summary.awareness_date',
      sourceData: null
    }
  },
  {
    label: 'Abbvie Awareness (PQ)',
    sortPath: 'summary.productQuality.awareness_date',
    order: 4,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Date',
      entityPathName: 'summary.productQuality.awareness_date',
      sourceData: null
    }
  },
  {
    label: 'AE Serious',
    sortPath: 'subcases.adverseEvent.aerinfo.safety.seriousness',
    order: 5,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'summary.narrative.seriousness',
      sourceData: {
        source: null,
        options: [
          { label: 'Serious', value: 'serious' },
          { label: 'Non Serious', value: 'nonserious' },
          { label: 'Not Specified', value: '{NOTEXISTS}' }
        ]
      }
    }
  },
  {
    label: 'Country of Reporter',
    sortPath: 'countryOfPrimaryReporterLabel',
    order: 6,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'countryOfPrimaryReporter',
      sourceData: {
        source: 'document-data.country-options',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Death',
    sortPath: 'subcases.adverseEvent.reactions.reaction.serious_criteria.death',
    order: 7
  },
  {
    label: 'Method of Receipt',
    sortPath: 'tracking.methodOfReceipt',
    order: 8,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'tracking.methodOfReceipt',
      sourceData: {
        source: 'document-data.mastercase-options.method_of_receipt',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Status',
    sortPath: 'status',
    order: 9
  },
  {
    label: 'Master Case Product',
    sortPath: 'tracking.productName',
    order: 10,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'tracking.productName',
      sourceData: {
        source: 'document-data.mastercase-product-options',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Case Creator',
    sortPath: 'creatorUsername',
    order: 11,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'creatorid',
      sourceData: {
        source: 'document-data.user-list',
        options: [
          { label: 'trilogy-lite', value: '12345' },
          { label: 'Unassigned', value: '{NOTEXISTS}' }
        ]
      }
    }
  },
  {
    label: 'Trilogy Load Date',
    sortPath: 'trilogyLoadDate',
    order: 12,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Date',
      entityPathName: 'trilogyLoadDate',
      sourceData: null
    }
  },
  {
    label: 'Owner',
    sortPath: 'assigneeName',
    order: 13,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'assignee',
      sourceData: {
        source: 'document-data.user-list',
        options: [{ label: 'Unassigned', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Reactions',
    sortPath: 'subcases.adverseEvent.reactions.reaction.adverse_event',
    order: 14
  }
];

const CASE_TO_REVIEW_HEADERS = [
  {
    label: 'Case Information',
    sortPath: 'id',
    isMandatoryColumn: true,
    order: 1
  },
  {
    label: 'Country of Reporter',
    sortPath: 'countryOfPrimaryReporterLabel',
    order: 2,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'countryOfPrimaryReporter',
      sourceData: {
        source: 'document-data.country-options',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Method of Receipt',
    sortPath: 'tracking.methodOfReceipt',
    order: 3,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'tracking.methodOfReceipt',
      sourceData: {
        source: 'document-data.mastercase-options.method_of_receipt',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Abbvie Awareness (AE)',
    sortPath: 'summary.awareness_date',
    order: 4,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Date',
      entityPathName: 'summary.awareness_date',
      sourceData: null
    }
  },
  {
    label: 'Abbvie Awareness (PQ)',
    sortPath: 'summary.productQuality.awareness_date',
    order: 5,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Date',
      entityPathName: 'summary.productQuality.awareness_date',
      sourceData: null
    }
  },
  {
    label: 'Status',
    sortPath: 'status',
    order: 6,
    isFilterRequired: false,
    FilterOptions: {
      controlType: 'Select',
      controlSelectedValue: '',
      entityPathName: 'status',
      sourceData: {
        source: null,
        options: [
          { label: 'Inprogress', value: 'IN_PROGRESS' },
          { label: 'New', value: 'NEW' },
          { label: 'Not Specified', value: '{NOTEXISTS}' }
        ]
      }
    }
  },
  {
    label: 'Actions',
    isMandatoryColumn: true,
    order: 7
  },
  {
    label: 'Affiliate Awareness Date',
    sortPath: 'summary.affiliate_awareness_date',
    order: 8,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Date',
      entityPathName: 'summary.affiliate_awareness_date',
      sourceData: null
    }
  },
  {
    label: 'Seriousness',
    sortPath: 'summary.narrative.seriousness',
    order: 9,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'summary.narrative.seriousness',
      sourceData: {
        source: null,
        options: [
          { label: 'Serious', value: 'serious' },
          { label: 'Non Serious', value: 'nonserious' },
          { label: 'Not Specified', value: '{NOTEXISTS}' }
        ]
      }
    }
  },
  {
    label: 'Master Case Product',
    sortPath: 'tracking.productName',
    order: 10,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'tracking.productName',
      sourceData: {
        source: 'document-data.mastercase-product-options',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Batch/Lot Number',
    sortPath: {
      'subcases.adverseEvent.product_section.aeproducts.product_therapy.batch_lot_no': null,
      'subcases.adverseEvent.product_section.aeproducts.productIndex': 0
    },
    order: 11
    // {"subcases.adverseEvent.product_section.aeproducts.product_summary.suspect_product":null,"subcases.adverseEvent.product_section.aeproducts.productIndex":0
  },
  {
    label: 'Date of Birth',
    sortPath: 'patient.patient.dob',
    order: 12,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Date',
      entityPathName: 'patient.patient.dob',
      sourceData: null
    }
  },
  {
    label: 'Initials',
    sortPath: 'patient.patient.initials',
    order: 13
  },
  {
    label: 'Product Marketed Name',
    sortPath: {
      'subcases.adverseEvent.product_section.aeproducts.product_summary.local_trade_name': null,
      'subcases.adverseEvent.product_section.aeproducts.productIndex': 0
    },
    order: 14,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName:
        'subcases.adverseEvent.product_section.aeproducts.product_summary.local_trade_name',
      entityAditionalValue:
        'subcases.adverseEvent.product_section.aeproducts.productIndex',
      sourceData: {
        source: 'document-data.mastercase-product-options',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Program ID/Name',
    sortPath: 'contacts.contact.psp.opi_number',
    order: 15,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'contacts.contact.psp.opi_number',
      sourceData: {
        source: 'document-data.psp-options.psp_opi_number',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Service Provider',
    sortPath: 'contacts.contact.psp.name',
    order: 16,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'contacts.contact.psp.name',
      sourceData: {
        source: 'document-data.psp-options.psp_name',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Third Party Patient ID',
    sortPath: 'patient.patient.reporter',
    order: 17
  },
  {
    label: 'Trilogy Load Date',
    sortPath: 'trilogyLoadDate',
    order: 18,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Date',
      entityPathName: 'trilogyLoadDate',
      sourceData: null
    }
  },
  {
    label: 'Owner',
    sortPath: 'assigneeName',
    order: 19,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'assignee',
      sourceData: {
        source: 'document-data.user-list',
        options: [{ label: 'Unassigned', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Automated Process',
    sortPath: 'automationStatus',
    order: 20,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'automationStatus',
      sourceData: {
        source: 'document-data.mastercase-options.automation_status'
      }
    }
  },
  {
    label: 'AGN/ABV',
    sortPath: 'subcases.adverseEvent.productType',
    order: 21,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'subcases.adverseEvent.productType',
      sourceData: {
        options: [
          { label: 'AGN', value: 'AGN' },
          { label: 'ABV', value: 'ABV' },
          { label: 'Both', value: 'ABV_AGN' }
        ]
      }
    }
  }
];

export const HEADER_ITEMS_MAP = {
  [QUEUE_MASTER_CASES]: MASTER_CASE_HEADERS,
  [QUEUE_CASES_TO_REVIEW]: CASE_TO_REVIEW_HEADERS,
  [QUEUE_YOUR_CASES]: [
    {
      label: 'Actions',
      isMandatoryColumn: true,
      order: 1
    },
    {
      label: 'Case Information',
      sortPath: 'id',
      isMandatoryColumn: true,
      order: 2
    },
    {
      label: 'AE Latest Received Date',
      sortPath: 'subcases.adverseEvent.aerinfo.safety.last_received_date',
      order: 3,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName:
          'subcases.adverseEvent.aerinfo.safety.last_received_date',
        sourceData: null
      }
    },
    {
      label: 'Country of Reporter',
      sortPath: 'countryOfPrimaryReporterLabel', // references a BE-added value
      order: 4,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'countryOfPrimaryReporter',
        sourceData: {
          source: 'document-data.country-options',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Owner',
      sortPath: 'assignee',
      order: 5
    },
    {
      label: 'AE Suspect Product',
      sortPath: {
        'subcases.adverseEvent.product_section.aeproducts.product_summary.suspect_product': null,
        'subcases.adverseEvent.product_section.aeproducts.productIndex': 0
      },
      order: 6,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.adverseEvent.product_section.aeproducts.product_summary.suspect_product',
        sourceData: {
          source: 'product-data.ae-product-data.products.preferredProductName',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Status',
      sortPath: 'status',
      order: 7
    },
    {
      label: 'Due Date',
      sortPath: 'subcases.adverseEvent.aerinfo.safety.submission_due_date',
      order: 8,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName:
          'subcases.adverseEvent.aerinfo.safety.submission_due_date',
        sourceData: null
      }
    },
    {
      label: 'Affiliate Awareness Date',
      sortPath: 'summary.affiliate_awareness_date',
      order: 9,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName: 'summary.affiliate_awareness_date',
        sourceData: null
      }
    },
    {
      label: 'Abbvie Awareness (PQ)',
      sortPath: 'summary.productQuality.awareness_date',
      order: 10,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName: 'summary.productQuality.awareness_date',
        sourceData: null
      }
    },
    {
      label: 'Abbvie Awareness (AE)',
      sortPath: 'summary.awareness_date',
      order: 11,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName: 'summary.awareness_date',
        sourceData: null
      }
    },
    {
      label: 'Trilogy Load Date',
      sortPath: 'trilogyLoadDate', // references a BE-added value
      order: 12,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName: 'trilogyLoadDate',
        sourceData: null
      }
    },
    {
      label: 'Seriousness',
      sortPath: 'summary.narrative.seriousness',
      order: 13,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'summary.narrative.seriousness',
        sourceData: {
          source: null,
          options: [
            { label: 'Serious', value: 'serious' },
            { label: 'Non Serious', value: 'nonserious' },
            { label: 'Not Specified', value: '{NOTEXISTS}' }
          ]
        }
      }
    },
    {
      label: 'Method of Receipt',
      sortPath: 'tracking.methodOfReceipt',
      order: 14,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'tracking.methodOfReceipt',
        sourceData: {
          source: 'document-data.mastercase-options.method_of_receipt',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Case Creator',
      sortPath: 'creatorUsername',
      order: 15,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'creatorid',
        sourceData: {
          source: 'document-data.user-list',
          options: [
            { label: 'trilogy-lite', value: '12345' },
            { label: 'Unassigned', value: '{NOTEXISTS}' }
          ]
        }
      }
    },
    {
      label: 'High Impact',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.highImpact',
      order: 16,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.highImpact',
        sourceData: {
          source: null,
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
            { label: 'Un Assigned', value: '{NOTEXISTS}' }
          ]
        }
      }
    },
    {
      label: 'PQ Owner',
      sortPath: 'subcases.productQuality.assigneeName',
      order: 17,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'subcases.productQuality.assignee',
        sourceData: {
          source: 'document-data.user-list',
          options: [{ label: 'Unassigned', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Replacement Required',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.replacement',
      order: 18,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.replacement',
        sourceData: {
          source: null,
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
            { label: 'No - Credit Only', value: 'creditOnly' },
            { label: 'Urgent Replacement', value: 'urgentReplacement' },
            { label: 'Not Specified', value: '{NOTEXISTS}' }
          ]
        }
      }
    },
    {
      label: 'Sample Available',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.available',
      order: 19,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.available',
        sourceData: {
          source: null,
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
            {
              label: 'Asked but Unknown (ASKU)',
              value: 'Asked but Unknown (ASKU)'
            },
            {
              label: 'No Information (NI)',
              value: 'No Information (NI)'
            },
            {
              label: 'Not Applicable (NA)',
              value: 'Not Applicable (NA)'
            },
            { label: 'Not Specified', value: '{NOTEXISTS}' }
          ]
        }
      }
    },
    {
      label: 'Reactions',
      sortPath: 'subcases.adverseEvent.reactions.reaction.adverse_event',
      order: 20
    },
    {
      label: 'Sample Required for Investigation',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.sampleRequiredForInvestigation',
      order: 21,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.sampleRequiredForInvestigation',
        sourceData: {
          source: null,
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
            { label: 'Unknown', value: 'unknown' },
            { label: 'Not Specified', value: '{NOTEXISTS}' }
          ]
        }
      }
    }
  ],
  [QUEUE_ADVERSE_EVENT]: [
    {
      label: 'Actions',
      isMandatoryColumn: true,
      order: 1
    },
    {
      label: 'Case Information',
      isMandatoryColumn: true,
      sortPath: 'subcases.adverseEvent.id',
      order: 2
    },
    {
      label: 'AE Owner',
      sortPath: 'subcases.adverseEvent.assigneeName',
      order: 3,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'subcases.adverseEvent.assignee',
        sourceData: {
          source: 'document-data.user-list',
          options: [{ label: 'Unassigned', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Country',
      sortPath: AE_STATE_PATHS.COUNTRY_OF_REPORTER_LABEL, // references a BE-added value
      order: 4,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.adverseEvent.aerinfo.safety.country_of_reporter',
        sourceData: {
          source: 'document-data.country-options',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Due Date',
      sortPath: 'subcases.adverseEvent.aerinfo.safety.submission_due_date',
      order: 5,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName:
          'subcases.adverseEvent.aerinfo.safety.submission_due_date',
        sourceData: null
      }
    },
    {
      label: 'Latest Received Date',
      sortPath: 'subcases.adverseEvent.aerinfo.safety.last_received_date',
      order: 6,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName:
          'subcases.adverseEvent.aerinfo.safety.last_received_date',
        sourceData: null
      }
    },
    {
      label: 'Method of Receipt',
      sortPath: 'tracking.methodOfReceipt',
      order: 14,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'tracking.methodOfReceipt',
        sourceData: {
          source: 'document-data.mastercase-options.method_of_receipt',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Product',
      sortPath: {
        'subcases.adverseEvent.product_section.aeproducts.product_summary.suspect_product': null,
        'subcases.adverseEvent.product_section.aeproducts.productIndex': 0
      },
      order: 7,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.adverseEvent.product_section.aeproducts.product_summary.suspect_product',
        sourceData: {
          source: 'product-data.ae-product-data.products.preferredProductName',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Status',
      sortPath: 'subcases.adverseEvent.status',
      order: 8
    },
    {
      label: 'Submitted?',
      sortPath: 'subcases.adverseEvent.submitted',
      order: 9
    },
    {
      label: 'Abbvie Awareness Date',
      sortPath: 'summary.awareness_date',
      order: 10,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName: 'summary.awareness_date',
        sourceData: null
      }
    },
    {
      label: 'Reactions',
      sortPath: 'subcases.adverseEvent.reactions.reaction.adverse_event',
      order: 11
    }
  ],
  [QUEUE_MEDICAL_INQUIRY]: [
    {
      label: 'Actions',
      isMandatoryColumn: true,
      order: 1
    },
    {
      label: 'Case Information',
      isMandatoryColumn: true,
      sortPath: 'subcases.medicalInfo.id',
      order: 2
    },
    {
      label: 'Country of Requester',
      sortPath: 'countryOfPrimaryReporterLabel', // references a BE-added value
      order: 3
    },
    {
      label: 'Created On',
      sortPath: 'subcases.medicalInfo.createdDate',
      order: 4
    },
    {
      label: 'MI Owner',
      sorthPath: 'subcases.medicalInfo.assigneeName',
      order: 5
    },
    {
      label: 'Product',
      sorthPath: 'tracking.productName',
      order: 6
    },
    {
      label: 'Status',
      sortPath: 'subcases.medicalInfo.status',
      order: 7
    }
  ],
  [QUEUE_PRODUCT_QUALITY]: [
    {
      label: 'Actions',
      isMandatoryColumn: true,
      order: 1
    },
    {
      label: 'Case Information',
      sortPath: 'subcases.productQuality.id',
      isMandatoryColumn: true,
      order: 2
    },
    {
      label: 'Abbvie Awareness (PQ)',
      sortPath: 'summary.productQuality.awareness_date',
      order: 3,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName: 'summary.productQuality.awareness_date',
        sourceData: null
      }
    },
    {
      label: 'Country of Reporter',
      sortPath: PQ_STATE_PATHS.COUNTRY_OF_REPORTER, // references a BE-added value
      order: 4,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'subcases.productQuality.pqPrimaryCountry',
        sourceData: {
          source: 'document-data.country-options',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        },
        useLabelsAsValue: true
      }
    },
    {
      label: 'High Impact',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.highImpact',
      order: 5,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.highImpact',
        sourceData: {
          source: null,
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
            { label: 'Not Specified', value: '{NOTEXISTS}' }
          ]
        }
      }
    },
    {
      label: 'PQ Owner',
      sortPath: 'subcases.productQuality.assigneeName',
      order: 6,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'subcases.productQuality.assignee',
        sourceData: {
          source: 'document-data.user-list',
          options: [{ label: 'Unassigned', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Product',
      sortPath: 'tracking.productName',
      order: 7,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'tracking.productName',
        sourceData: {
          source: 'document-data.mastercase-product-options',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Replacement Required',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.replacement',
      order: 8,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.replacement',
        sourceData: {
          source: null,
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
            { label: 'No - Credit Only', value: 'creditOnly' },
            { label: 'Urgent Replacement', value: 'urgentReplacement' },
            { label: 'Not Specified', value: '{NOTEXISTS}' }
          ]
        }
      }
    },
    {
      label: 'Status',
      sortPath: 'subcases.productQuality.status',
      order: 9
    },
    {
      label: 'Affiliate Return Sample Received Date',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.affiliate_ret_sample_rec_date',
      order: 10,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.affiliate_ret_sample_rec_date',
        sourceData: null
      }
    },
    {
      label: 'Batch/Lot Number',
      sortPath: 'subcases.productQuality.pqproduct.products.details.lotNumber',
      order: 11
    },
    {
      label: 'Product (Marketed Name)',
      sortPath:
        'subcases.productQuality.pqproduct.products.details.marketedName',
      order: 12,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.details.marketedName',
        sourceData: {
          source: 'product-data.pq-product-data.products.marketedName',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Customer Response Required?',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.letterRequired',
      order: 13,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.letterRequired',
        sourceData: {
          source: null,
          options: [
            { label: 'Yes', value: 'yes' },
            {
              label: 'No, customer declined letter',
              value: 'noCustomerDeclinedLetter'
            },
            {
              label: 'No, customer declined letter / training offered',
              value: 'noCustomerDeclinedLetterTrainingOffered'
            },
            {
              label: 'No, patient contact information or response unavailable',
              value: 'noPatientContactInfoUnavailable'
            },
            { label: 'No, Ex-US', value: 'noExUS' },
            {
              label:
                'No, Complaint was not received via live call from the customer',
              value: 'noNonLiveCall'
            },
            { label: 'Not Specified', value: '{NOTEXISTS}' }
          ]
        }
      }
    },
    {
      label: 'List Number',
      sortPath: 'subcases.productQuality.pqproduct.products.details.listNumber',
      order: 14,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.details.listNumber',
        sourceData: {
          source: 'product-data.pq-product-data.products.listNumber',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'Method of Receipt',
      sortPath: 'tracking.methodOfReceipt',
      order: 14,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName: 'tracking.methodOfReceipt',
        sourceData: {
          source: 'document-data.mastercase-options.method_of_receipt',
          options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
        }
      }
    },
    {
      label: 'SolTRAQs Reference Number',
      sortPath:
        'subcases.productQuality.pqproduct.products.details.soltraqsNumber',
      order: 15
    },
    {
      label: 'Replacement Reference Number',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.replacementReferenceNumber',
      order: 16
    },
    {
      label: 'Sample Available for Return',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.available',
      order: 17,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.available',
        sourceData: {
          source: null,
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
            {
              label: 'Asked but Unknown (ASKU)',
              value: 'Asked but Unknown (ASKU)'
            },
            { label: 'No Information (NI)', value: 'No Information (NI)' },
            { label: 'Not Applicable (NA)', value: 'Not Applicable (NA)' },
            { label: 'Not Specified', value: '{NOTEXISTS}' }
          ]
        }
      }
    },
    {
      label: 'Affiliate Sample Ship Date',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.affiliate_sample_ship_date',
      order: 18,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.affiliate_sample_ship_date',
        sourceData: null
      }
    },
    {
      label: 'HCP Regulatory Agency Communication',
      sortPath: 'contacts.contact.pq.regulatory_agency_communication',
      order: 19
    },
    {
      label: 'Sample Tracking to Customers',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.sampleTrackingToCustomer',
      order: 20
    },
    {
      label: 'Submission Date to SolTRAQs',
      sortPath: 'subcases.productQuality.submittedDate',
      order: 21,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Date',
        entityPathName: 'subcases.productQuality.submittedDate',
        sourceData: null
      }
    },
    {
      label: 'Sample Required for Investigation',
      sortPath:
        'subcases.productQuality.pqproduct.products.complaint.processing.sampleRequiredForInvestigation',
      order: 22,
      isFilterRequired: true,
      FilterOptions: {
        controlType: 'Select',
        entityPathName:
          'subcases.productQuality.pqproduct.products.complaint.processing.sampleRequiredForInvestigation',
        sourceData: {
          source: null,
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
            { label: 'Unknown', value: 'unknown' },
            { label: 'Not Specified', value: '{NOTEXISTS}' }
          ]
        }
      }
    }
  ]
};

const COMMON_FILTERS = {
  masterCaseProduct: {
    type: 'masterCaseProduct',
    placeholder: 'Master Case Product',
    options: [], // Built from Tactical Data
    multi: true
  },
  methodOfReceipt: {
    type: 'methodOfReceipt',
    placeholder: 'Method of receipt',
    options: [], // Built from Tactical Data
    multi: true
  },
  country: {
    type: 'country',
    placeholder: 'Country of Reporter/Requester',
    options: [], // Built from Tactical Data
    multi: true
  },
  seriousness: {
    type: 'seriousness',
    placeholder: 'Seriousness',
    options: [
      { value: 'serious', label: 'Serious' },
      { value: 'nonserious', label: 'Non Serious' }
    ],
    multi: true
  }
};

// Tactical data options are processed in src/Queue/utils/display.js#buildDropdownFilterOptions
export const DROPDOWN_FILTERS = {
  [QUEUE_MASTER_CASES]: [
    {
      type: 'caseCreators',
      placeholder: 'Case Creators',
      options: [], // Built from Tactical Data
      multi: true
    },
    COMMON_FILTERS.methodOfReceipt,
    COMMON_FILTERS.country,
    COMMON_FILTERS.seriousness,
    {
      type: 'highImpact',
      placeholder: 'High Impact',
      options: [{ label: 'No', value: 'no' }, { label: 'Yes', value: 'yes' }],
      multi: true
    },
    {
      type: 'caseOwner',
      placeholder: 'Owner',
      options: [], // Built from Tactical Data
      multi: true
    }
  ],
  [QUEUE_CASES_TO_REVIEW]: [
    COMMON_FILTERS.masterCaseProduct,
    COMMON_FILTERS.methodOfReceipt,
    COMMON_FILTERS.country,
    {
      type: 'caseOwner',
      placeholder: 'Owner',
      options: [], // Built from Tactical Data
      multi: true
    }
  ],
  [QUEUE_YOUR_CASES]: [
    {
      type: 'product',
      placeholder: 'Product',
      options: [], // Built from Tactical Data
      multi: true
    },
    COMMON_FILTERS.country,
    COMMON_FILTERS.seriousness
  ],
  [QUEUE_ADVERSE_EVENT]: [
    COMMON_FILTERS.seriousness,
    {
      type: 'caseType',
      placeholder: 'Case Type',
      options: [
        { value: 'initial_case', label: 'Initial' },
        { value: 'follow_up', label: 'Follow Up' }
      ],
      multi: true
    },
    {
      type: 'suspectProduct',
      placeholder: 'Suspect Product',
      options: [], // Built from Tactical Data
      multi: true
    },
    {
      type: 'aeCountry',
      placeholder: 'Country of Reporter',
      options: [], // Built from Tactical Data
      multi: true
    },
    {
      type: 'aeOwner',
      placeholder: 'AE Owner',
      options: [], // Built from Tactical Data
      multi: true
    }
  ],
  [QUEUE_MEDICAL_INQUIRY]: [
    {
      type: 'miProduct',
      placeholder: 'Abbvie Product',
      options: [], // Built from Tactical Data
      multi: true
    },
    {
      type: 'country',
      placeholder: 'Country of Requester',
      options: [], // Built from Tactical Data
      multi: true
    },
    {
      type: 'miOwner',
      placeholder: 'MI Owner',
      options: [], // Built from Tactical Data
      multi: true
    }
  ],
  [QUEUE_PRODUCT_QUALITY]: [
    {
      type: 'pqProduct',
      placeholder: 'Abbvie Product',
      options: [], // Built from Tactical Data
      multi: true
    },
    {
      type: 'country',
      placeholder: 'Country of Reporter',
      options: [], // Built from Tactical Data
      multi: true
    },
    {
      type: 'pqOwner',
      placeholder: 'PQ Owner',
      options: [], // Built from Tactical Data
      multi: true
    },
    {
      type: 'highImpact',
      placeholder: 'High Impact',
      options: [{ label: 'No', value: 'no' }, { label: 'Yes', value: 'yes' }],
      multi: true
    }
  ]
};
