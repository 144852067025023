var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js!../../../node_modules/@blueprintjs/core/dist/blueprint.css"), "");
exports.i(require("-!../../../node_modules/css-loader/index.js!../../../node_modules/@blueprintjs/datetime/dist/blueprint-datetime.css"), "");

// module
exports.push([module.id, "@font-face {\n  font-family: 'SackersGothicStd-Heavy';\n  src:\n    local('SackersGothicStd-Heavy'),\n    url(" + escape(require("../../../public/fonts/SackersGothicStd-Heavy.otf")) + ") format('woff2');\n  font-weight: 600;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'SackersGothicStd-Medium';\n  src: \n    local('SackersGothicStd-Medium'),\n    url(" + escape(require("../../../public/fonts/SackersGothicStd-Medium.otf")) + ") format('woff2');\n  font-weight: 600;\n  font-style: normal;\n}\n\nbody {\n  overflow: scroll;\n  margin: 0;\n  padding: 0;\n  font-family: sans-serif;\n}\n\n.no-scroll {\n  overflow: hidden;\n}\n\n.language-javascript {\n  font-size: 10px;\n  height: 200px;\n}\n\n.pt-icon, .pt-icon-standard {\n  font-style: normal;\n  font-family: Icons16;\n  text-transform: none;\n  line-height: 1em;\n}\n\n.pt-icon, .pt-icon-large {\n  font-style: normal;\n  font-family: Icons20;\n  text-transform: none;\n  line-height: 1em;\n}\n\n#clone-product{\n  width: 30px;\n  height: 30px;\n  background-repeat: no-repeat;\n  background-position: center center;\n  background-size: 25px 25px;\n  background-image: url(" + escape(require("../../../public/images/fa-copy-regular.svg")) + ");\n  border: 0;\n  background-color: unset;\n}\n", ""]);

// exports
