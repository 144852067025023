/* eslint import/prefer-default-export:0 */
import {
  cloneDeep,
  isNil,
  set,
  pick,
  mapValues,
  find,
  forEach,
  some,
  values,
  isObject,
  flatten,
  filter,
  get,
  isEmpty
} from 'lodash';
import moment from 'moment';

import {
  SUB_CASE_TYPES_MAP,
  CC_SUB_CASE_TYPES_MAP,
  CASE_STATUS_COMPLETED
} from 'Common/constants';
import { API_DATE_FORMAT } from 'Common/components/Form';
import { isSubcaseRoute, isDashboardRoute, getOrElse } from 'Common/utils';
import { inflateDocument } from 'Common/utils/formState';
import {
  MASTER_STATE_PATHS,
  AE_STATE_PATHS,
  FORM_AE_PATH,
  PQ_STATE_PATHS,
  SUBCASE_CATEGORY_MAP
  // PQ_TYPES
} from '../constants';

/** @module Case Utils */

export const SUBCASE_INITIAL_VERSION = '00';

/**
 * Create a Case button should add the created date to the master case on clicking Create a Case.
 * BE will set on selected subcases.
 * @function
 * @param trilogyCase The case
 * @returns {{}}
 */
export const setCreatedDate = trilogyCase => ({
  ...trilogyCase,
  created: moment().toISOString(),
  subcases: {
    // Add `createdDate` to any subcases selected when creating a new case
    ...Object.entries(trilogyCase.summary.narrative.categories).reduce(
      (subcases, [subcase, selected]) => ({
        ...subcases,
        ...(selected
          ? {
              [SUBCASE_CATEGORY_MAP[subcase]]: {
                // As of this PR, there shouldn't be an existing data here when
                // creating, but just in case (and for any BE and non-creation
                // scenarios) we're including any other data already on the subcase
                ...getOrElse(
                  trilogyCase.subcases,
                  SUBCASE_CATEGORY_MAP[subcase],
                  {}
                ),
                // If `createdDate` already exists, keep it
                createdDate: getOrElse(
                  trilogyCase.subcases,
                  `${SUBCASE_CATEGORY_MAP[subcase]}.createdDate`,
                  new Date().toISOString()
                )
              }
            }
          : {})
      }),
      {}
    )
  }
});

/**
 * To calculate Submission Due Date and show immediately after Case Creation or Creating an AE Subtask from dashboard,
 * BE needs some fields populated with Master Case values
 * @function
 * @param trilogyCase The case from Case Creation
 * @returns {{}}
 */
export const syncAwarenessDatesToAE = trilogyCase => {
  // TODO: R2 consider updating for saves on return visits to the master case page
  const populateAeSubmissionOnCreate = getOrElse(
    trilogyCase,
    MASTER_STATE_PATHS.SUBCASE_CATEGORY_AE,
    false
  );

  const updatedCase = cloneDeep(trilogyCase);
  if (populateAeSubmissionOnCreate) {
    set(
      updatedCase,
      AE_STATE_PATHS.AER_RCT_NUMBER,
      getOrElse(trilogyCase, MASTER_STATE_PATHS.AER_RCT_NUMBER, null)
    );
    set(
      updatedCase,
      AE_STATE_PATHS.LAST_RECEIVED_DATE,
      getOrElse(trilogyCase, MASTER_STATE_PATHS.AWARENESS_DATE, null)
    );
    set(
      updatedCase,
      AE_STATE_PATHS.AFFILIATE_AWARENESS_DATE,
      getOrElse(trilogyCase, MASTER_STATE_PATHS.AFFILIATE_AWARENESS_DATE, null)
    );
    set(
      updatedCase,
      AE_STATE_PATHS.SERIOUSNESS,
      getOrElse(trilogyCase, MASTER_STATE_PATHS.SERIOUSNESS, null)
    );
  }
  return updatedCase;
};

/**
 *  If a AE subcase is on version AE00 in Trilogy, when updating fields on the AER Info Tab,
 *  the corresponding master case field should also be updated
 * @function
 * @param match Route
 * @param trilogyCase The case
 * @returns {{}}
 */
export const syncAwarenessDatesToMaster = (match, trilogyCase) => {
  const isAdverseEventVersionZero =
    getOrElse(match, 'params.page', '').toLowerCase() === FORM_AE_PATH &&
    getOrElse(trilogyCase, AE_STATE_PATHS.VERSION, SUBCASE_INITIAL_VERSION) ===
      SUBCASE_INITIAL_VERSION;

  const updatedCase = cloneDeep(trilogyCase);
  if (isAdverseEventVersionZero) {
    set(
      updatedCase,
      MASTER_STATE_PATHS.AWARENESS_DATE,
      getOrElse(
        trilogyCase,
        AE_STATE_PATHS.LAST_RECEIVED_DATE,
        getOrElse(trilogyCase, MASTER_STATE_PATHS.AWARENESS_DATE) // Default to itself if AE doesn't override
      )
    );
    set(
      updatedCase,
      MASTER_STATE_PATHS.AFFILIATE_AWARENESS_DATE,
      getOrElse(
        trilogyCase,
        AE_STATE_PATHS.AFFILIATE_AWARENESS_DATE,
        getOrElse(trilogyCase, MASTER_STATE_PATHS.AFFILIATE_AWARENESS_DATE) // Default to itself if AE doesn't override
      )
    );
    set(
      updatedCase,
      MASTER_STATE_PATHS.SERIOUSNESS,
      getOrElse(
        trilogyCase,
        AE_STATE_PATHS.SERIOUSNESS,
        getOrElse(trilogyCase, MASTER_STATE_PATHS.SERIOUSNESS) // Default to itself if AE doesn't override
      )
    );
  }

  return updatedCase;
};

/**
 * Archive a case
 * @function
 * @param match Route
 * @param trilogyCase The case to archive
 * @param archiveComments Archive Comments
 * @param archiveReason Archive Reason
 * @returns {{}}
 */
export const archiveCase = (
  match,
  trilogyCase,
  archiveComments,
  archiveReason
) => {
  if (isSubcaseRoute(match)) {
    const subcase = getOrElse(match, 'params.page');
    const subcaseProtoKey = `subcases.${CC_SUB_CASE_TYPES_MAP[subcase]}`;
    const updatedCase = trilogyCase;
    set(updatedCase, `${subcaseProtoKey}.archived`, true);
    set(updatedCase, `${subcaseProtoKey}.archiveComments`, archiveComments);
    set(updatedCase, `${subcaseProtoKey}.archiveReason`, archiveReason);
    return updatedCase;
  }
  return {
    ...trilogyCase,
    archived: true,
    archiveComments,
    archiveReason
  };
};

/**
 * One-time fields used in the GraphQL _request_ that should be reset after a GQL response
 * Note: These fields should not exist in the GraphQL fragments
 * @function
 */
export const resetActionFlags = (trilogyCaseFlagged, subcase) => {
  const completedFlag = `subcases.${
    CC_SUB_CASE_TYPES_MAP[subcase]
  }.completeWithoutSubmission`;
  const reopenedFlag = `subcases.${CC_SUB_CASE_TYPES_MAP[subcase]}.reopened`;

  const updatedCase = cloneDeep(trilogyCaseFlagged);
  if (subcase && getOrElse(trilogyCaseFlagged, completedFlag))
    set(updatedCase, completedFlag, null);

  if (subcase && getOrElse(trilogyCaseFlagged, reopenedFlag))
    set(updatedCase, reopenedFlag, null);

  if (
    subcase === 'ae' &&
    getOrElse(trilogyCaseFlagged, AE_STATE_PATHS.NEW_VERSION_FLAG)
  )
    set(updatedCase, AE_STATE_PATHS.NEW_VERSION_FLAG, null);
  else if (
    subcase === 'pq' &&
    getOrElse(trilogyCaseFlagged, PQ_STATE_PATHS.NEW_VERSION_FLAG)
  )
    set(updatedCase, PQ_STATE_PATHS.NEW_VERSION_FLAG, null);

  return updatedCase;
};

/**
 * Create a new version of a subcase
 * NOTE: Requires a resetActionFlags() call after mutation
 * @function
 * @param trilogyCase {Object}
 * @returns {Object}
 */
export const createNewSubcaseVersion = (trilogyCase, caseType = 'ae') => {
  const updatedCase = cloneDeep(trilogyCase);
  if (caseType === 'ae') {
    set(updatedCase, AE_STATE_PATHS.NEW_VERSION_FLAG, true); // Flag to request new AE version
    set(updatedCase, AE_STATE_PATHS.SUBMITTED_FLAG, false); // We don't want the new version to already be submitted
  } else if (caseType === 'pq') {
    set(updatedCase, PQ_STATE_PATHS.NEW_VERSION_FLAG, true); // Flag to request new AE version
    set(updatedCase, PQ_STATE_PATHS.SUBMITTED_FLAG, false); // We don't want the new version to already be submitted
    // set(updatedCase, PQ_STATE_PATHS.FOLLOW_UP, PQ_TYPES.FOLLOW_UP); // Removing as part of TRIL-635 as follow up and initial radio btns are removed
  }
  return updatedCase;
};

/**
 *
 * @function
 * @param {Object} trilogyCase
 * @param {Object} subcase - Subcase of (ae|mi|pq)
 * @returns {Object}
 */
export const setSubcaseFlagOnMasterCase = (trilogyCase, subcase) => {
  const subcaseProtoKey = `summary.narrative.categories.${
    SUB_CASE_TYPES_MAP[subcase]
  }`;
  return set(trilogyCase, subcaseProtoKey, true);
};

/**
 * Submit a subcase downstream
 * @function
 * @param {string} match - Route
 * @param {Object} trilogyCase
 * @returns {Object}
 */
export const submitSubcase = (match, trilogyCase) => {
  const updatedCase = cloneDeep(trilogyCase);
  if (isSubcaseRoute(match)) {
    const subcase = getOrElse(match, 'params.page');
    set(
      updatedCase,
      `subcases.${CC_SUB_CASE_TYPES_MAP[subcase]}.submitted`,
      true
    );
    set(
      updatedCase,
      `subcases.${CC_SUB_CASE_TYPES_MAP[subcase]}.reopened`,
      false
    );
  }
  return updatedCase;
};

/**
 * Assign a user to a subcase
 * @function
 * @param trilogyCase The case to submit
 * @param subcase The subcase to assign (ae|mi|pq)
 * @param userId The userId to assign to the subcase
 * @returns {Object}
 */
export const assignSubcase = (trilogyCase, subcase, userId) =>
  set(
    trilogyCase,
    `subcases.${CC_SUB_CASE_TYPES_MAP[subcase]}.assignee`,
    userId
  );

/**
 * AssigneeName updated to user to a subcase
 * @function
 * @param trilogyCase The case to submit
 * @param subcase The subcase to assign (ae|mi|pq)
 * @param assigneeName The assigneeName to assign to the subcase
 * @returns {Object}
 */
export const assigneeNameSubcase = (trilogyCase, subcase, assigneeName) =>
  set(
    trilogyCase,
    `subcases.${CC_SUB_CASE_TYPES_MAP[subcase]}.assigneeName`,
    assigneeName
  );

/**
 * Assign a user to a Mastercase
 * @function
 * @param trilogyCase The case to submit
 * @param userId The userId to assign to the Mastercase
 * @returns {Object}
 */
export const assigneeMastercase = (trilogyCase, userId) =>
  set(trilogyCase, 'assignee', userId);

/**
 * AssigneeName updated to user to a Mastercase
 * @function
 * @param trilogyCase The case to submit
 * @param assigneeName The assigneeName to assign to the Mastercase
 * @returns {Object}
 */
export const assigneeNameMastercase = (trilogyCase, assigneeName) =>
  set(trilogyCase, 'assigneeName', assigneeName);

/**
 * We must only submit the attachments and no other data if we trigger this implicitly
 * after a successful save of a document
 * @function
 * @param {Object} trilogyCase
 */
export const submitAttachmentsOnly = trilogyCase =>
  pick(trilogyCase, ['documents.attachments', 'currentRevision', 'id']);

/**
 * Re-open a subcase
 * Unused, but could make a come-back
 * NOTE: Requires a resetActionFlags() call after mutation
 * @function
 * @param trilogyCase
 * @param subcase
 */
export const reopenSubcase = (trilogyCase, subcase) =>
  set(trilogyCase, `subcases.${CC_SUB_CASE_TYPES_MAP[subcase]}.reopened`, true);

/**
 * Complete a subcase without submitting downstream
 * NOTE: Requires a resetActionFlags() call after mutation
 * @function
 * @param trilogyCase
 * @param subcase
 * @param completedReason Radio option from the confirmation modal
 * @param completedReasonComments Text value from the confirmation modal
 */
export const completeSubcase = (
  trilogyCase,
  subcase,
  completedReason,
  completedReasonComments
) => {
  const updatedCase = trilogyCase;
  const subcaseKey = CC_SUB_CASE_TYPES_MAP[subcase];
  if (['ae', 'pq'].includes(subcase)) {
    set(updatedCase, `subcases.${subcaseKey}.newVersion`, false);
  }
  set(updatedCase, `subcases.${subcaseKey}.completeWithoutSubmission`, true);
  set(updatedCase, `subcases.${subcaseKey}.reopened`, false);
  set(updatedCase, `subcases.${subcaseKey}.archived`, false);
  set(updatedCase, `subcases.${subcaseKey}.completedReason`, completedReason);
  set(
    updatedCase,
    `subcases.${subcaseKey}.completedReasonComments`,
    completedReasonComments
  );
  return updatedCase;
};

/**
 * Generate the countryOfPrimaryReporter field used by filtering and the case header
 * @function
 * @param trilogyCase
 * @returns {?Object} Country value or null
 */
export const genCountryOfPrimaryReporter = trilogyCase => {
  const primaryContactMatch = getOrElse(
    trilogyCase,
    'contacts.contact[0]',
    null
  );
  const patientCountry = getOrElse(
    trilogyCase,
    'patient.patient[0].contact_info[0].country',
    null
  );
  const contactCountry = getOrElse(primaryContactMatch, 'country', null);
  return primaryContactMatch && primaryContactMatch.type === 'patient'
    ? patientCountry
    : contactCountry;
};

const convertDates = value =>
  typeof value === 'object' && moment.isMoment(value)
    ? value.toISOString(API_DATE_FORMAT)
    : value;

/**
 * CSE needs the unflattened format for the sake of seeding any list values (input groups)
 * Note: Does not use genProtoDocument which we use to send data to GraphQL (and omits null sections, which CSE needs)
 * @function
 * @param {*} protoDocument
 */
export const protoDocumentToCSE = protoDocument =>
  inflateDocument(mapValues(protoDocument, convertDates));

/**
 * Get the array index for a given case instance
 * @function
 * @param {Object} caseObj - The result of calling `protoDocumentToCSE`
 * @param {string} caseKey - An object path
 * @returns {number}
 */
export const lastIndexForGroup = (caseObj, caseKey) =>
  getOrElse(caseObj, `${caseKey}.length`, 1) - 1;

/**
 * Determines whether an input group has not had _any_ value defined for that given instance
 * Example: Adding or copying a contact should never overwrite data in a contact accordion that already has data
 * Note: Use caution when using this for instances other than contacts or patients groups as it may not be generic enough.
 * @function
 * @param trilogyCase The case document
 * @param caseKey The trilogyCase path/key
 * @param lastInstanceIndex The result of calling `lastIndexForGroup`
 * @returns {boolean}
 */
export const writeToExistingInstance = (
  trilogyCase,
  caseKey,
  lastInstanceIndex
) => {
  // contacts object has values that are objects so this helper
  // will create a single array of all the values to help determine if any are not nil
  const getObjectValuesAndProcessDirectChildrenObjects = () => {
    const topLevelValuesArray = values(
      getOrElse(trilogyCase, `${caseKey}[${lastInstanceIndex}]`, {})
    );
    const processDirectChildrenThatAreObjects = topLevelValuesArray.map(
      v => (isObject(v) ? values(v) : v)
    );
    return flatten(processDirectChildrenThatAreObjects);
  };

  return (
    caseKey === 'patient.patient' || // Patients only have one instance so always overwrite the last instance
    !some(getObjectValuesAndProcessDirectChildrenObjects(), x => !isNil(x))
  );
};

/**
 * @function
 * @param {*} trilogyCase
 * @param {*} productPath
 * @param {*} expirationDatePath Manually clear this field
 */
export const removeAEProduct = (
  trilogyCase,
  productPath,
  expirationDatePath
) => {
  const updatedCase = cloneDeep(trilogyCase);
  set(updatedCase, `${productPath}.suspect_product`, null);
  set(updatedCase, `${productPath}.local_trade_name`, null);
  set(updatedCase, `${productPath}.type`, null);
  set(updatedCase, expirationDatePath, null);
  return updatedCase;
};

/**
 * @function
 * @param {*} trilogyCase
 * @param {*} $id
 */
export const removeAEProtocol = (trilogyCase, $id) => {
  const updatedCase = cloneDeep(trilogyCase);
  set(updatedCase, `${$id}.observational`, null);
  set(updatedCase, `${$id}.study_type`, null);
  set(updatedCase, `${$id}.study_phase`, null);
  set(updatedCase, `${$id}.study_design`, null);
  set(updatedCase, `${$id}.study_title`, null);
  set(updatedCase, `${$id}.eudract`, null);
  set(updatedCase, `${$id}.protocol`, null);
  set(updatedCase, `${$id}.investigator`, null);
  set(updatedCase, `${$id}.study_patient`, null);
  set(updatedCase, `${$id}.study_local`, null);
  return updatedCase;
};
/**
 * @function
 * @param {*} trilogyCase
 * @param {*} productPath
 */
export const removePQProduct = (trilogyCase, productPath) => {
  const updatedCase = cloneDeep(trilogyCase);
  set(updatedCase, `${productPath}.listNumber`, null);
  set(updatedCase, `${productPath}.marketedName`, null);
  return updatedCase;
};

/**
 * @function
 * @param {*} trilogyCase
 * @param {*} baseStatePath
 * @param {*} result
 */
export const addAEProtocolDataToCase = (trilogyCase, baseStatePath, result) => {
  const updatedCase = cloneDeep(trilogyCase);
  // The tactical data returns a `label` for `observational` as `null` or `"1"`
  // since "1" is not `true`, just comparing to `null`. `null` = false, `!null` = true
  set(
    updatedCase,
    `${baseStatePath}.observational`,
    // The `observational` object may or may not exist
    getOrElse(result, 'observational.value', null) !== null
  );
  set(
    updatedCase,
    `${baseStatePath}.study_type`,
    get(result, 'studyType.label')
  );
  set(
    updatedCase,
    `${baseStatePath}.study_phase`,
    get(result, 'studyPhase.label')
  );
  set(
    updatedCase,
    `${baseStatePath}.study_design`,
    get(result, 'studyDesign.label')
  );
  set(
    updatedCase,
    `${baseStatePath}.study_title`,
    get(result, 'studyTitle.label')
  );
  set(updatedCase, `${baseStatePath}.eudract`, get(result, 'eudract.label'));
  set(
    updatedCase,
    `${baseStatePath}.protocol`,
    get(result, 'protocolNumber.label')
  );
  return updatedCase;
};
/**
 * Copying duplicate search filters into a trilogyCase should only take the patient and contact criteria.
 * Use the
 * @function
 * @param trilogyCase The case
 * @param filters The duplicate search criteria passed back to case creation
 * @param contactIndex The last instance of the inputGroup to replace with
 * @param patientIndex The last instance of the inputGroup to replace with
 */
export const searchCriteriaCopy = (
  trilogyCase,
  filters,
  contactIndex,
  patientIndex
) => {
  const updatedCase = cloneDeep(trilogyCase);
  forEach(filters, (value, key) => {
    if (key.startsWith('contacts.contact')) {
      set(updatedCase, key.replace(/[0]/, `[${contactIndex}]`), value);
    } else if (key.startsWith('patient.patient')) {
      set(updatedCase, key.replace(/[0]/, `[${patientIndex}]`), value);
    }
    // Noop on all other search criteria fields (or you will blow out values like status)
  });
  return updatedCase;
};

/**
 * @function
 * @param {*} trilogyCase
 * @param {*} match
 */
export const shouldRedirectDueToCaseRules = (trilogyCase, match) => {
  const hasMasterCaseBeenCreated = !isNil(
    getOrElse(trilogyCase, 'created', null)
  );
  const hasMasterCaseBeenArchived = getOrElse(trilogyCase, 'archived', false);
  const isAttemptingToAccessDashboardOrSubcase =
    isSubcaseRoute(match) || isDashboardRoute(match);

  const hasSubcaseBeenCreated = () => {
    const subcasePath = CC_SUB_CASE_TYPES_MAP[match.params.page];
    return !isNil(getOrElse(trilogyCase, `subcases.${subcasePath}`, null));
  };

  const result = {
    redirect: false,
    redirectTo: null
  };
  if (!hasMasterCaseBeenCreated && isAttemptingToAccessDashboardOrSubcase) {
    result.redirect = true;
    result.redirectTo = `/form/${match.params.masterCaseId}`;
    return result;
  }

  if (hasMasterCaseBeenCreated) {
    if (isSubcaseRoute(match)) {
      if (!hasSubcaseBeenCreated(match)) {
        const masterCaseId = match.params.masterCaseId;
        result.redirect = true;
        result.redirectTo = `/form/${masterCaseId}/review/dashboard`;
        return result;
      }
    }
  }

  if (hasMasterCaseBeenArchived) {
    if (isSubcaseRoute(match && !hasSubcaseBeenCreated(match))) {
      const masterCaseId = match.params.masterCaseId;
      result.redirect = true;
      result.redirectTo = `/form/${masterCaseId}/review/dashboard`;
      return result;
    }
  }
  return result;
};

/**
 * Determines whether a new version of a case can be created via the Actions menu
 * @function
 * @returns {*} true if the user can create a new version
 */
export const canMakeNewVersion = (trilogyCase, caseType = 'ae') => {
  let submitted;
  let reopened;
  let status;

  if (caseType === 'ae') {
    submitted = getOrElse(trilogyCase, AE_STATE_PATHS.SUBMITTED_FLAG, false);
    reopened = getOrElse(trilogyCase, AE_STATE_PATHS.REOPENED_DATE, false);
    status = getOrElse(trilogyCase, AE_STATE_PATHS.STATUS, false);
  } else if (caseType === 'pq') {
    submitted = getOrElse(trilogyCase, PQ_STATE_PATHS.SUBMITTED_FLAG, false);
    reopened = getOrElse(trilogyCase, PQ_STATE_PATHS.REOPENED_DATE, false);
    status = getOrElse(trilogyCase, PQ_STATE_PATHS.STATUS, false);
  }

  const completed = status === CASE_STATUS_COMPLETED;

  // if user has re-opened a case, they must complete without submission prior to making a new version
  if (reopened) return completed;
  return completed || submitted;
};

/**
 * filterReportedCategories to remove the null values from reportedCategories
 * @function
 * @param trilogyCase The case to submit
 * @param statePath path to reportedCategories
 * @param reportedCategories The assigneeName to assign to the subcase
 * @returns -
 */
export const filterReportedCategories = (
  trilogyCase,
  statePath,
  complaintData
) => {
  const reportedCategories = filter(
    complaintData,
    reportedCat => !isNil(reportedCat.reportedCategory)
  );
  set(trilogyCase, statePath, reportedCategories);
};

/**
 * filterDependentOptions to filter the options  based on reported category selected
 * @function
 * @param props The case to submit
 * @param statePath path to reportedCategories
 * @param reportedCategories The assigneeName to assign to the subcase
 * @returns -
 */
export const filterDependentOptions = (props, stateParentPath) => {
  const { parentDataPath, options } = props;

  let filteredOptions = options;
  const path = props['../'].$id;
  const parentVal = get(props.model, `${path}.${stateParentPath}`);

  if (isNil(parentVal) || isEmpty(stateParentPath)) {
    filteredOptions = {};
  } else {
    // get subcatID
    const data = get(props, parentDataPath);
    const parentObj = find(data, { value: parentVal });
    const subCatId = get(parentObj, 'subcatid');

    if (subCatId && !isNil(subCatId) && !isEmpty(subCatId)) {
      filteredOptions = get(options, subCatId);
    }
  }
  return filteredOptions;
};

/**
 * Pulls all the subcase versions that should be rendered on the page.  There are certain
 * situations where the subcase version should not be displayed on the page.  That logic is
 * encapsulated here.
 *
 * @param trilogyCase - The current revision trilogy case object.
 * @param page - The subcase type
 * @returns - Array of versions that will be rendered in the component.
 */
export const getVersions = (trilogyCase, page) => {
  // Path to the location in the case document where the version information is stored.
  const AE_SUBCASE_VERSION_PATH = 'subcases.adverseEvent.subcaseVersions';
  const PQ_SUBCASE_VERSION_PATH = 'subcases.productQuality.subcaseVersions';

  // Create the object for the current revision.
  const currentVersion = {
    trilogyCase,
    revision: get(trilogyCase, 'currentRevision'),
    status: get(trilogyCase, 'status'),
    id: get(trilogyCase, 'id'),
    isCurrent: true
  };
  let subcaseVersions;
  if (page === 'ae') {
    subcaseVersions = get(trilogyCase, AE_SUBCASE_VERSION_PATH);
    currentVersion.id = get(trilogyCase, 'subcases.adverseEvent.id');
    currentVersion.submittedDate = get(
      trilogyCase,
      'subcases.adverseEvent.submittedDate'
    );
    currentVersion.submitted = get(
      trilogyCase,
      'subcases.adverseEvent.submitted'
    );
  } else if (page === 'pq') {
    subcaseVersions = get(trilogyCase, PQ_SUBCASE_VERSION_PATH);
    currentVersion.id = get(trilogyCase, 'subcases.productQuality.id');
    currentVersion.submittedDate = get(
      trilogyCase,
      'subcases.productQuality.submittedDate'
    );
    currentVersion.submitted = get(
      trilogyCase,
      'subcases.productQuality.submitted'
    );
  } else {
    console.error(`Unknown subcase type: ${page}`);
  }

  // Return the current revision if there aren't any subcases.  This is a scenario if there are not any submitted versions.
  if (isNil(subcaseVersions)) return [currentVersion];

  // Remove any versions that have the same id as the current version.
  return [
    ...filter(subcaseVersions, v => v.id !== currentVersion.id),
    currentVersion
  ];
};
