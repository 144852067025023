import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  search: generateCSS({
    display: 'inline-block',
    verticalAlign: 'top',
    float: 'right'
  }),

  searchBox: generateCSS({
    height: '30px',
    width: '250px',
    margin: '10px 0 15px',
    border: 'none',
    '::placeholder': {
      padding: '0 10px',
      color: '#D1D1D1',
      fontWeight: '200',
      font: '16px Calibri'
    }
  }),

  searchIcon: generateCSS({
    verticalAlign: 'middle',
    marginLeft: '-30px',
    cursor: 'pointer'
  }),

  filterButton: generateCSS({
    float: 'right',
    ' button': {
      background: 'transparent',
      border: 'none'
    }
  }),

  searchButton: generateCSS({
    marginTop: '20px',
    '.container': {
      margin: '12px 30px 17px',
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
});

export default stylesGenerator;
