/* eslint import/prefer-default-export:0 */
export const SEARCH_BASE_PATH = '/search/:duplicate?';

export const GENERIC_STATE_PATHS = {
  AE_COUNTRY_OR_REPORTER:
    'subcases.adverseEvent.aerinfo.safety.country_of_reporter',
  AE_LRD: 'subcases.adverseEvent.aerinfo.safety.last_received_date',
  AWARENESS_DATE: 'summary.awareness_date',
  PQ_AWARENESS_DATE: 'summary.productQuality.awareness_date',
  PATIENT_DOB: 'patient.patient[0].dob',
  PATIENT_FN: 'patient.patient[0].firstName',
  PATIENT_LN: 'patient.patient[0].lastName',
  PATIENT_INITIALS: 'patient.patient[0].initials',
  PATIENT_GENDER: 'patient.patient[0].gender'
};

export const HEADER_DATA = [
  {
    groupLabel: 'Select',
    columns: [
      {
        checkbox: true
      }
    ]
  },
  {
    groupLabel: 'Case Information',
    columns: [
      {
        label: 'Case ID',
        sortBy: 'id'
      },
      {
        label: 'Product',
        sortBy: ''
      },
      {
        label: 'Abbvie Awareness (AE)',
        sortBy: 'summary.awareness_date'
      },
      {
        label: 'Abbvie Awareness (PQ)',
        sortBy: 'summary.productQuality.awareness_date'
      },
      {
        label: 'Reactions'
      }
    ]
  },
  {
    groupLabel: 'Patient Information',
    columns: [
      {
        label: 'First',
        sortBy: 'patient.patient.firstName'
      },
      {
        label: 'Last',
        sortBy: 'patient.patient.lastName'
      },
      {
        label: 'Init',
        sortBy: 'patient.patient.initials'
      },
      {
        label: 'DOB',
        sortBy: 'patient.patient.dob'
      },
      {
        label: 'Third Party Patient ID'
      }
    ]
  },
  {
    groupLabel: 'Actions',
    columns: [
      {
        label: 'Status',
        sortBy: ''
      },
      {
        label: '', // blank column header for 'export'
        sortBy: ''
      },
      {
        label: '', // blank column header for 'f/u to existing case'
        sortBy: ''
      }
    ]
  }
];
