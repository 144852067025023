import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    padding: '0 24px',
    background: theme.colors.background
  })
});

export default stylesGenerator;
